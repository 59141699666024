import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import React from 'react'
import styled from 'styled-components'

export const formatPhoneNumber = (phoneNumber?: string): string => {
  if (!phoneNumber) {
    return ''
  }

  phoneNumber = phoneNumber.replace(/[^\d]/g, '')

  return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
}

interface IPhoneCardProps {
  phoneNumber: string
  index: number
  className: string
  isLoading: boolean
  onDelete: (phoneNumber: string, index: number) => void
}

export const PhoneCard = (props: IPhoneCardProps) => {
  const { phoneNumber, index, isLoading, onDelete } = props

  const handleDelete = () => {
    if (onDelete) {
      onDelete(phoneNumber, index)
    }
  }

  return (
    <Container className={props.className}>
      <PhoneNumber>{formatPhoneNumber(phoneNumber)}</PhoneNumber>
      {isLoading ? <LoadingIcon /> : <DeleteIcon onClick={handleDelete} />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const PhoneNumber = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0875px;
  width: 100%;
  color: #9a9a9a;
`

const DeleteIcon = styled(DeleteForeverIcon)`
  border: 1px solid #fc5a5a;
  border-radius: 6.5px;
  color: #fc5a5a;
`

const LoadingIcon = styled(DonutLargeIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  color: #fc5a5a;
  animation: spin 1s linear infinite;
`
