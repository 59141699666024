import { IApplicationsStatus } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/applications.interface'
import { Box } from 'grommet'
import React, { PureComponent } from 'react'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import { CardTitleHeadingStyle, NumberBoxMobileStyle, NumberHeadingStyle, SmallCardStyle, TextStyle } from './style'

interface ITotalApplicationsCardProps {
  applicationsByStatus?: IApplicationsStatus | Error
}

export default class TotalApplicationsCard extends PureComponent<ITotalApplicationsCardProps> {
  render() {
    const { applicationsByStatus } = this.props

    const totalApplicationsCardBox =
      applicationsByStatus instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        applicationsByStatus && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Clientes avaliados</CardTitleHeadingStyle>
              <Box>
                <NumberHeadingStyle>
                  {applicationsByStatus.TOTAL && applicationsByStatus.TOTAL > 9
                    ? applicationsByStatus.TOTAL
                    : '0' + applicationsByStatus.TOTAL}
                </NumberHeadingStyle>
              </Box>
            </Box>

            <Box className="is-mobile" fill justify="between">
              <CardTitleHeadingStyle>Clientes avaliados</CardTitleHeadingStyle>
              <NumberBoxMobileStyle align="center">
                <Box margin={{ right: 'medium' }}>
                  <NumberHeadingStyle>
                    {applicationsByStatus.TOTAL && applicationsByStatus.TOTAL > 9
                      ? applicationsByStatus.TOTAL
                      : '0' + applicationsByStatus.TOTAL}
                  </NumberHeadingStyle>
                </Box>
              </NumberBoxMobileStyle>
            </Box>
          </>
        )
      )
    return (
      <SmallCardStyle>
        {applicationsByStatus ? (
          totalApplicationsCardBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </SmallCardStyle>
    )
  }
}
