import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../../../App'
import ApplicationSliceReducer from '../../../../redux/reducers/application/application.reducer'
import ApplicationLayout from '../../ApplicationLayout'
import { PhoneCard } from '../../../../molecules/Application/PhoneCard'
import { useTypedSelector } from '../../../../redux/reducers/selectors'
import NumberField from '../../../../molecules/Application/NumberField'
import { NumberFormatValues } from 'react-number-format'
import { validatePhoneNumber } from '../application-utils'
import ApplicationPhoneConfirmationModal from './ApplicationPhoneConfirmationModal'

const MAX_SECONDARY_PHONE_NUMBERS = 3

export const ApplicationPhoneStepV2 = () => {
  const dispatch = useDispatch()

  const {
    hasSendPhoneNumber,
    isSubmittingPhoneNumber,
    isSubmittingSecondaryPhoneNumber,
    phoneNumber,
    secondaryPhoneNumbersArray,
    secondaryPhoneNumbersLoadingArray,
  } = useTypedSelector(state => ({
    hasSendPhoneNumber: state.application.hasSendPhoneNumber,
    secondaryPhoneNumber: state.application.secondaryPhoneNumber,
    phoneNumber:
      state.application.borrower?.unverifiedPhoneNumber ??
      state.application.phoneNumber,
    isSubmittingPhoneNumber: state.application.isSubmittingPhoneNumber,
    isSubmittingSecondaryPhoneNumber: state.application.isSubmittingSecondaryPhoneNumber,

    secondaryPhoneNumbersArray: state.application.secondaryPhoneNumbersArray,
    secondaryPhoneNumbersLoadingArray: state.application.secondaryPhoneNumbersLoadingArray,
  }))

  // Uncontrolled component: primary phone input
  const [formPhoneNumber, setFormPhoneNumber] = useState<string>(phoneNumber)

  // Uncontrolled component: secondary phone input
  const secondaryPhoneRef = useRef<HTMLInputElement>(null)
  const [formSecondaryPhoneNumber, setFormSecondaryPhoneNumber] = useState<string>('')

  // Side Effect: Clear secondary phone input after api call
  // -- Disable warnings
  useEffect(() => {
    if (!isSubmittingSecondaryPhoneNumber && isValidSecondaryPhoneNumber) {
      setFormSecondaryPhoneNumber('')
    }
  }, [isSubmittingSecondaryPhoneNumber])

  // Side Effect: When phone has changed elsewhere, update formPhoneNumber state
  useEffect(() => {
    setFormPhoneNumber(phoneNumber)
  }, [phoneNumber])

  // Side Effect: When phone is submitted successfully, focus on secondary phone input
  useEffect(() => {
    if (hasSendPhoneNumber) {
      secondaryPhoneRef.current?.focus()
    }
  }, [hasSendPhoneNumber])

  const handlePhoneNumberChange = (values: NumberFormatValues) => {
    setFormPhoneNumber(values.value)
  }

  const handlePhoneNumberSubmit = () => {
    if (!isValidPhoneNumber) {
      return toaster.showErrorToast(`Preencha um telefone válido`)
    }

    dispatch(
      ApplicationSliceReducer.actions.onFormValueChange({ formField: 'phoneNumber', formValue: formPhoneNumber })
    )
    dispatch(ApplicationSliceReducer.actions.sendPrimaryPhoneNumber({ phoneNumber: formPhoneNumber }))
  }

  const handleSecondaryPhoneNumberChange = (event: NumberFormatValues) => {
    setFormSecondaryPhoneNumber(event.value)
  }

  const handleSecondaryPhoneNumberSubmit = () => {
    if (!isValidSecondaryPhoneNumber) {
      return toaster.showErrorToast(`Preencha um telefone válido`)
    }

    dispatch(
      ApplicationSliceReducer.actions.onFormValueChange({
        formField: 'secondaryPhoneNumber',
        formValue: formSecondaryPhoneNumber,
      })
    )
    dispatch(ApplicationSliceReducer.actions.insertSecondaryPhone({ phoneNumber: formSecondaryPhoneNumber }))
  }

  const handleDeleteSecondaryPhoneNumber = (phoneNumber: string, index: number) => {
    dispatch(ApplicationSliceReducer.actions.deleteSecondaryPhone({ phoneNumber, index }))
  }

  const handleNextButton = () => {
    dispatch(ApplicationSliceReducer.actions.setPhoneConfirmationModal(true))
  }

  const isValidPhoneNumber = validatePhoneNumber(formPhoneNumber)
  const isPhoneError = formPhoneNumber.length > 0 && !isValidPhoneNumber

  const isValidSecondaryPhoneNumber = validatePhoneNumber(formSecondaryPhoneNumber)
  const isSecondaryPhoneError = formSecondaryPhoneNumber.length > 0 && !isValidSecondaryPhoneNumber

  return (
    <>
      <ApplicationLayout
        title="Telefone"
        subtitle={
          <span>
            O telefone principal será o telefone usado para confirmar as compras e <strong>para ter acesso ao app</strong>
          </span>
        }
        isNextButtonEnabled={hasSendPhoneNumber}
        overrideNextPage={handleNextButton}
      >
        <Container>
          <NumberField
            autoFocus
            Icon={hasSendPhoneNumber ? CheckCircleIcon : PhoneInTalkIcon}
            defaultValue={phoneNumber}
            value={formPhoneNumber}
            label="Celular Principal"
            placeholder="(92) 90000-0000"
            format="(##) #####-####"
            type="tel"
            mask="_"
            onValueChange={handlePhoneNumberChange}
            onEnterPressed={handlePhoneNumberSubmit}
            isLoading={isSubmittingPhoneNumber}
            isValidated={isValidPhoneNumber}
            disabled={hasSendPhoneNumber}
            error={isPhoneError}
            style={{ marginTop: '20px' }}
          />
          {hasSendPhoneNumber ? (
            <SmallText>
              <BoldGreenText>Telefone salvo com sucesso</BoldGreenText>
            </SmallText>
          ) : (
            <React.Fragment>
              <ConfirmButton disabled={isPhoneError || formPhoneNumber.length === 0} onClick={handlePhoneNumberSubmit}>
                Salvar Telefone
              </ConfirmButton>
            </React.Fragment>
          )}
        </Container>
        <MultiplePhonesText>
          Adicione até 3 números para entrarmos em contato informando promoções e novas oportunidades
        </MultiplePhonesText>
        <Container>
          {/* Uncontrolled component due to old Android phones performance */}
          <NumberField
            inputRef={secondaryPhoneRef}
            Icon={AddCircleOutlineIcon}
            label="Celular Secundário (opcional)"
            placeholder="(92) 90000-0000"
            style={{ width: '100%' }}
            type="tel"
            format="(##) #####-####"
            value={formSecondaryPhoneNumber}
            mask="_"
            onValueChange={handleSecondaryPhoneNumberChange}
            onEnterPressed={handleSecondaryPhoneNumberSubmit}
            isLoading={isSubmittingSecondaryPhoneNumber}
            isValidated={isValidSecondaryPhoneNumber}
            error={isSecondaryPhoneError}
            disabled={secondaryPhoneNumbersArray.length === MAX_SECONDARY_PHONE_NUMBERS}
          />
          {secondaryPhoneNumbersArray.map((phoneNumber: string, index: number) => (
            <StyledPhoneCard
              key={index}
              index={index}
              phoneNumber={phoneNumber}
              isLoading={secondaryPhoneNumbersLoadingArray[index]}
              onDelete={handleDeleteSecondaryPhoneNumber}
            />
          ))}
        </Container>
      </ApplicationLayout>
      <ApplicationPhoneConfirmationModal/>
    </>
  )
}

const Container = styled.div`
  margin-top: 2vh;
  margin-bottom: 6vh;

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`

const SmallText = styled.span`
  margin-top: 1vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props: any) => (props && props.bold ? '500' : '')};
  font-size: 10px;
  line-height: 12px;

  color: #000000;
`

const BoldGreenText = styled.span`
  color: #11b048;
  font-weight: 800;
`

const MultiplePhonesText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
`

const StyledPhoneCard = styled(PhoneCard)`
  margin-top: 2vh;
`

const ConfirmButton = styled.button`
  margin-top: 10px;
  outline: none;
  transition: 0.5s;

  // BUTTON
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#10A343')};
  border-radius: 10px;
  border: 0px;
  padding: 7px;

  // TEXT
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;

  :focus {
    outline: none;
  }
`
