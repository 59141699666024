import { HttpBorrowersApiErrors } from '@scudraservicos/coordinator-client/dist/src/services/borrowers-api/errors/BorrowersApi.errors'
import { AxiosError } from 'axios'
import { DocumentErrors } from '../../../common/ErrorStrings'

const HttpConnectionError = `Verifique se o seu dispositivo tem acesso a internet`
const HttpAxiosRequestError = `Erro - Contate a UME`
const HttpUnkownError = `Erro desconhecido - Contate a UME`
const HttpBadGatewayError = `Preencha os dados corretamente`

const BAD_REQUEST = 400

export enum ApplicationErrors {
  DEACTIVATED_STORE = 'DEACTIVATED_STORE',
}

export enum ApplicationErrorsMessage {
  DEACTIVATED_STORE = 'Loja inativada. Configure uma loja.',
}

const HttpErrorsToString: { [message: string]: string } = {
  [HttpBorrowersApiErrors.BORROWER_ALREADY_EXISTS]: 'Cliente já pertence a nossa base de dados',
  [HttpBorrowersApiErrors.BORROWER_NOT_FOUND]: 'Cliente não existe na nossa base de dados',
  [HttpBorrowersApiErrors.PHONE_ALREADY_ACTIVE_FOR_ANOTHER_BORROWER]: 'Este telefone já pertence a outro cliente',
  [HttpBorrowersApiErrors.PHONE_ALREADY_ACTIVE_FOR_BORROWER]: 'Este telefone já foi verificado para o cliente',
  [HttpBorrowersApiErrors.PHONE_ALREADY_REGISTERED]: 'Telefone já registrado',
  [HttpBorrowersApiErrors.PHONE_ALREADY_VERIFIED]: 'Telefone já verificado',
  [HttpBorrowersApiErrors.PHONE_NOT_FOUND]: 'Telefone não encontrado',
  [HttpBorrowersApiErrors.PHONE_VERIFICATION_CODE_MISMATCH]: 'Código de verificação incorreto',
  [HttpBorrowersApiErrors.OPERATOR_NOT_ALLOWED]: 'Senha incorreta ou operador não tem essa permissão',
  ERROR_SENDING_SMS: 'Erro na operadora de SMS. Por favor tente novamente em alguns minutos',
  [ApplicationErrors.DEACTIVATED_STORE]: ApplicationErrorsMessage.DEACTIVATED_STORE,
}

export const transformHttpBorrowersApiErrorsToString = (error: AxiosError): string => {
  let message: string
  if (error.response) {
    // Request made and server responded
    if (error.response.status === BAD_REQUEST) {
      // TODO - Too generic
      message = HttpBadGatewayError
    } else if (error.response.data && error.response.data.message) {
      message = HttpErrorsToString[error.response.data.message]
    } else {
      message = HttpUnkownError
    }
  } else if (error.request) {
    message = HttpAxiosRequestError
  } else {
    message = HttpConnectionError
  }

  if (!message) {
    return HttpUnkownError
  }

  return message
}

// TODO - Real application engine http errors
export const transformHttpApplicationEngineApiErrorsToString = (error: AxiosError): string => {
  let message: string
  if (error.response) {
    // Request made and server responded
    if (error.response.status === BAD_REQUEST) {
      // TODO - Too generic
      message = HttpErrorsToString[error.response.data.error]

      if (!message) message = HttpBadGatewayError
    } else if (error.response.data && error.response.data.message) {
      message = error.response.data.message.message
        ? HttpErrorsToString[error.response.data.message.message]
        : HttpErrorsToString[error.response.data.message]
    } else {
      message = HttpUnkownError
    }
  } else if (error.request) {
    message = HttpAxiosRequestError
  } else {
    message = HttpConnectionError
  }

  if (!message) {
    // TODO - Remove this from here
    message = DocumentErrors.INVALID_DOCUMENT
  }

  return message
}
