import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Role } from '../../common/constants'
import Home from '../../home/Home'
import DirectorDashView from '../../organisms/DirectorDash/components/DirectorDashView/DirectorDashView'
import ManagerDashView from '../../organisms/ManagerDash/components/ManagerDashView/ManagerDashView'
import RepresentativeDashView from '../../organisms/RepresentativeDash/components/RepresentativeDashView/RepresentativeDashView'
import { RetailersDashContainerStyle } from './style'

const dashViewForRole: { [key in Role]: React.ReactNode } = {
  representative: <RepresentativeDashView />,
  attendant: <RepresentativeDashView />,
  cashier: <Home />,
  manager: <ManagerDashView />,
  analyst: <DirectorDashView />,
  promoter: <RepresentativeDashView />,
  'retailer-analyst': <DirectorDashView />,
  'risk-analyst': <DirectorDashView />,
}

export interface IRetailersDashProps extends RouteComponentProps {
  userRole?: Role
}

export interface IRetailersDashState {}

export default class RetailersDash extends React.Component<IRetailersDashProps, IRetailersDashState> {
  constructor(props: IRetailersDashProps) {
    super(props)

    this.state = {}
  }

  public render() {
    return (
      <RetailersDashContainerStyle>
        {this.props.userRole && dashViewForRole[this.props.userRole]}
      </RetailersDashContainerStyle>
    )
  }
}
