import { Box, Grommet, LayerProps } from 'grommet'
import React, { Component } from 'react'
import { BottomNavItemsBox, StyledSideBar } from './styles'

interface SideBarProps {
  headIcons?: JSX.Element[]
  bottomNavItems?: React.ReactNode
  topNavItems?: React.ReactNode
  logo?: JSX.Element
  showNavBar?: boolean
}

export default class SideBar extends Component<SideBarProps & LayerProps & JSX.IntrinsicElements['div'], {}> {
  render() {
    return (
      <Grommet>
        <StyledSideBar full position="top">
          <Box margin={{ vertical: 'small' }} direction="row" justify="between" align="center">
            {this.props.headIcons}
          </Box>
          {this.props.children}

          <Box gap="large" margin={{ horizontal: 'medium', vertical: 'large' }}>
            {this.props.topNavItems}
          </Box>

          <BottomNavItemsBox direction="row" align="end" margin={{ vertical: 'xlarge' }} justify="between">
            <Box gap="medium" margin="medium">
              {this.props.bottomNavItems}
            </Box>
            <Box>{this.props.logo}</Box>
          </BottomNavItemsBox>
        </StyledSideBar>
      </Grommet>
    )
  }
}
