import { red } from '../../../legacy-lib/design-kit-ui/utils/_colors'

export const mainContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: 'auto',
  width: '100vw',
  marginTop: '2rem',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '1rem',
}

export const cardBasicStyle: React.CSSProperties = {
  scrollBehavior: 'smooth',
  padding: '3rem',
  display: 'flex',
  width: '95vw',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '5rem',
  borderRadius: '0.5rem',
}

export const selectDateContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '50%',
  columnGap: '2rem',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  alignSelf: 'flex-start',
  minWidth: 'fit-content',
}

export const buttonsBasicStyle: React.CSSProperties = {
  width: '10rem',
  height: '3rem',
  fontSize: '1.5rem',
  fontFamily: 'Roboto',
  fontWeight: 500,
}

export const aggregatorContainerStyle: React.CSSProperties = {
  display: 'flex',
  columnGap: '2rem',
  flexWrap: 'wrap',
  width: '40%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start',
}

export const invalidDateRangeErrorMessageStyle: React.CSSProperties = {
  fontSize: '1rem',
  color: red.light,
  textAlign: 'left',
  fontFamily: 'Roboto',
  fontWeight: 400,
  alignSelf: 'flex-start',
  marginTop: '-4rem',
}
