export default class DocumentUtils {
  static CPF_SIZE_WITHOUT_DIGITS = 11

  static maskCpf(cpf?: string): string {
    if (!cpf || cpf.length !== this.CPF_SIZE_WITHOUT_DIGITS) return ''

    const cleanCpf = cpf.replace(/\D/g, '')

    return `${cleanCpf.slice(0, 3)}.***.***-${cleanCpf.slice(3)}`
  }
}
