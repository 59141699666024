import React from 'react';

import {
    MainContainer, Text
} from './Button.styles';

interface Props {
    disabled?: boolean;
    expand?: boolean
    text: string
    onPress: () => void
}

const LoadingModal: React.FC<Props> = (props) => {
    const { disabled, expand, text, onPress } = props

    return (
        <MainContainer disabled={disabled} expand={expand} onClick={() => !disabled && onPress()}>
            <Text>{text}</Text>
        </MainContainer>
    )
}

export default LoadingModal