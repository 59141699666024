type Countdown = {
  borrowerId: string
  timestamp: number
}

interface CountdownWithSeconds extends Countdown {
  seconds: number
}

export const COUNTDOWN_TIMEOUT = 45

const LOCAL_STORAGE_COUNTDOWN = 'countdownSendSms'

export default class CashierSendLimitTimeoutHandler {
  private static setLocalStorage(data: Countdown[]): void {
    if (data.length > 0) {
      localStorage.setItem(LOCAL_STORAGE_COUNTDOWN, JSON.stringify(data))
    } else {
      localStorage.removeItem(LOCAL_STORAGE_COUNTDOWN)
    }
  }

  private static getLocalStorage(): Countdown[] | null {
    const data = localStorage.getItem(LOCAL_STORAGE_COUNTDOWN)

    if (data) {
      return JSON.parse(data) as Countdown[]
    }

    return null
  }

  static addCashierSendLimitTimeout(borrowerId: string): void {
    let listCountdown = this.getLocalStorage()
    var timestamp = new Date().getTime() + COUNTDOWN_TIMEOUT * 1000

    if (listCountdown) {
      if (listCountdown.length >= 5) listCountdown.pop()

      const borrower = this.getCashierSendLimitTimeout(borrowerId)

      if (borrower) {
        listCountdown = listCountdown.map(countdown => {
          if (countdown.borrowerId === borrowerId) return { borrowerId, timestamp }
          return countdown
        })
      } else {
        listCountdown.push({ borrowerId, timestamp })
      }
      this.setLocalStorage(listCountdown)
    } else {
      this.setLocalStorage([{ borrowerId, timestamp }])
    }
  }

  static getCashierSendLimitTimeout(borrowerId: string): CountdownWithSeconds | null {
    const data = this.getLocalStorage()

    if (data) {
      const limitTimeout = data.find(countdown => countdown.borrowerId === borrowerId) || null

      if (limitTimeout) {
        const timestampCurrent = new Date().getTime()
        const diffTime = Number(limitTimeout.timestamp) - timestampCurrent
        const seconds = Math.ceil(diffTime / 1000)

        return {
          borrowerId: limitTimeout.borrowerId,
          timestamp: limitTimeout.timestamp,
          seconds,
        }
      }
    }

    return null
  }
}
