import { IOperatorRecordResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-record'
import { Box } from 'grommet'
import * as React from 'react'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import { IntervalType } from '../../../../../legacy-lib/design-kit-ui/Calendar/helpers'
import { CardTitleHeadingStyle, MediumCardStyle, OrangeNumberHeadingStyle, SmallTextStyle, TextStyle } from './style'

export interface IIntervalRecordCardProps {
  totalApplicationsToday: number
  intervalRecord?: IOperatorRecordResponse | Error
}

export interface IIntervalRecordCardState {}

export default class IntervalRecordCard extends React.PureComponent<
  IIntervalRecordCardProps,
  IIntervalRecordCardState
> {
  public render() {
    const { totalApplicationsToday, intervalRecord } = this.props

    const applicationsRemaingToRecord =
      intervalRecord &&
      !(intervalRecord instanceof Error) &&
      intervalRecord.numberOfApplications - totalApplicationsToday

    const RecordApplicationsIntervalText = (interval: IntervalType) => {
      if (interval === 'day') {
        return 'Máximo de aplicações feitas em 1 dia.'
      } else if (interval === 'week') {
        return 'Máximo de aplicações feitas em 1 semana.'
      } else {
        return 'Máximo de aplicações feitas em 1 mês.'
      }
    }

    const observationText =
      applicationsRemaingToRecord && applicationsRemaingToRecord > 0 ? (
        <Box>
          <TextStyle bold>Obs:</TextStyle>
          <TextStyle>
            {'Você precisa fazer '}
            <TextStyle bold>{applicationsRemaingToRecord}</TextStyle>
            {' aplicações hoje para bater esse recorde.'}
          </TextStyle>
        </Box>
      ) : (
        applicationsRemaingToRecord !== undefined && (
          <Box>
            <TextStyle bold>Obs:</TextStyle>
            <TextStyle>
              {'Você bateu o seu recorde em '}
              <TextStyle bold>{-applicationsRemaingToRecord}</TextStyle>
              {' aplicações hoje!'}
            </TextStyle>
          </Box>
        )
      )

    const interalCardBox =
      intervalRecord instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        intervalRecord && (
          <>
            <Box className="desktop" fill direction="row">
              <Box width="165px" justify="between">
                <Box>
                  <CardTitleHeadingStyle>Recorde:</CardTitleHeadingStyle>
                  <SmallTextStyle>{RecordApplicationsIntervalText(intervalRecord.interval)}</SmallTextStyle>
                </Box>
                {observationText}
              </Box>
              <Box width="130px" justify="center" align="center">
                <OrangeNumberHeadingStyle>
                  {intervalRecord.numberOfApplications > 9
                    ? intervalRecord.numberOfApplications
                    : '0' + intervalRecord.numberOfApplications}
                </OrangeNumberHeadingStyle>
              </Box>
            </Box>
            <Box fill className="is-mobile">
              <Box fill justify="between">
                <Box>
                  <CardTitleHeadingStyle>Recorde:</CardTitleHeadingStyle>
                  <TextStyle>{RecordApplicationsIntervalText(intervalRecord.interval)}</TextStyle>
                </Box>
                <OrangeNumberHeadingStyle>
                  {intervalRecord.numberOfApplications > 9
                    ? intervalRecord.numberOfApplications
                    : '0' + intervalRecord.numberOfApplications}
                </OrangeNumberHeadingStyle>
                {observationText}
              </Box>
            </Box>
          </>
        )
      )

    return (
      <MediumCardStyle>
        {intervalRecord ? (
          interalCardBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </MediumCardStyle>
    )
  }
}
