import React from 'react'
import styled from 'styled-components'
import { black, red } from '../../../ui/utils/_colors'

interface OperatorProfileProps {
  title: string
  value?: string
  warningMessage: string
}

const OperatorProfileInformation = ({ title, value, warningMessage }: OperatorProfileProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      {value ? <Value>{value}</Value> : <Value color={red.dark}>{warningMessage}</Value>}
    </Container>
  )
}

interface ColorProps {
  color: string
}

const Container = styled.div`
  margin-top: 24px;
`

const Title = styled.h6`
  font-family: Inter;
  font-size: 14px;
  margin: 0px 0px 4px 0px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

const Value = styled.h5<ColorProps>`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  color: ${({ color }: ColorProps) => (color ? color : black.primary)};
`

export default OperatorProfileInformation
