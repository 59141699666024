export type IDbCommunicationChannelType = CommunicationChannelLabel.SMS | CommunicationChannelLabel.WHATSAPP | CommunicationChannelLabel.PHONE_CALL;
export enum CommunicationChannelLabel {
    NO_CHANNEL = "",
    SMS = "SMS",
    WHATSAPP = "WHATSAPP",
    PHONE_CALL = "PHONE_CALL"
}

export interface PhoneDto {
    id: string
    createdOn: string
    modifiedOn: string
    ownerId: string
    phoneNumber: string
}

export interface IPostPhonesParams {
    id: string
    phoneNumber: string
}

export interface IPhoneResponse {
    id: string;
    ownerId: string;
    phoneNumber: string;
    phoneType: PHONE_TYPES;
}
export declare type PHONE_TYPES = 'PRIMARY' | 'SECONDARY';
