import { Text as GrommetText } from 'grommet'
import styled from 'styled-components'

const StyledText = styled(GrommetText)`
  font-family: Roboto;
  font-weight: ${props => (props.light ? 300 : undefined)};
  color: ${props => (props.color ? props.color : 'black')};
`

export { StyledText }
