export interface Invoice {
  id: string
  invoiceDueDate: string
  barcode: string
  pixQrCodeUrl: string
  invoiceUrl: string
  invoiceTotalValue: number
  type: ChatbotInvoiceType
  installmentsInfo?: InvoiceInstallmentsInfo
  renegotiationInfo?: InvoiceRenegotiationInfo
}

export enum ChatbotInvoiceTypeEnum {
  INSTALLMENTS = 'installments',
  RENEGOTIATION = 'renegotiation',
}

export type ChatbotInvoiceType = ChatbotInvoiceTypeEnum.INSTALLMENTS | ChatbotInvoiceTypeEnum.RENEGOTIATION

export interface InvoiceRenegotiationInfo {
  numberOfRemainingRenegotiationPayments: number
}
export interface InvoiceInstallmentsInfo {
  paymentOverdueInstallmentsCount: number
  feesTotalValue: number
}

export enum CreateBorrowerNextInvoiceErrorType {
  NO_PENDING_INSTALLMENTS_FOR_BORROWER = 'NO_PENDING_INSTALLMENTS_FOR_BORROWER',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export class CreateBorrowerNextInvoiceError {
  error: CreateBorrowerNextInvoiceErrorType
  message: any

  constructor({ error, message }: { error: CreateBorrowerNextInvoiceErrorType; message?: any }) {
    this.error = error
    this.message = message
  }
}
