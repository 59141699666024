import styled from 'styled-components'
import { medium } from '../../common/assets/utils/_sizes'

export const ButtonZipCodeDialog = styled.button`
  cursor: pointer;
  text-decoration: underline;
  color: blue
  border: none;
  background-color: unset;
  margin-top: ${medium}; 
  padding: 0;
`
