import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AxiosError } from "axios"
import { IBorrowerCreditLimit } from "../../../services/bff/coordinator/dto/Borrowers.dto"
import { TransactionalCreditEvaluation } from "../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto"
import { TransactionalCreditEvaluationResult } from "../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum"
import { TransactionalCreditEvaluationStatus } from "../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum"

interface TransactionalCreditState {
  transactionalCreditEvaluation?: TransactionalCreditEvaluation
  isFetchingTransactionalCreditEvaluation: boolean

  isFetchingBorrowerLimit: boolean
  availableLimit?: number
}

const initialState: TransactionalCreditState = {
  transactionalCreditEvaluation: undefined,
  isFetchingTransactionalCreditEvaluation: false,

  isFetchingBorrowerLimit: false,
  availableLimit: undefined,
}

export type IFetchTransactionalCreditEvaluation = {
  cpf: string
  storeId?: string
  retailerId?: string
  status: TransactionalCreditEvaluationStatus
  results: TransactionalCreditEvaluationResult[]
}

const TransactionalCreditSliceReducer = createSlice({
  name: 'transactionalCredit',
  initialState,
  reducers: {
    fetchTransactionalCreditEvaluation: (state, action: PayloadAction<IFetchTransactionalCreditEvaluation>) => {
      return { ...state, transactionalCreditEvaluation: undefined }
    },
    fetchTransactionalCreditEvaluationLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isFetchingTransactionalCreditEvaluation: true }
    },
    fetchTransactionalCreditEvaluationSuccess: (state, action: PayloadAction<TransactionalCreditEvaluation>) => {
      const evaluation = action.payload

      return {
        ...state,
        transactionalCreditEvaluation: evaluation,
        isFetchingTransactionalCreditEvaluation: false,
      }
    },
    fetchTransactionalCreditEvaluationError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isFetchingTransactionalCreditEvaluation: false }
    },

    fetchBorrowerLimit: (state, action: PayloadAction<string>) => {
      return { ...state, availableLimit: undefined }
    },
    fetchBorrowerLimitLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isFetchingBorrowerLimit: true }
    },
    fetchBorrowerLimitSuccess: (state, action: PayloadAction<IBorrowerCreditLimit>) => {
      return {
        ...state,
        availableLimit: action.payload.availableLimit,
        isFetchingBorrowerLimit: false,
      }
    },
    fetchBorrowerLimitError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isFetchingBorrowerLimit: false }
    },
  },
})

export default TransactionalCreditSliceReducer
