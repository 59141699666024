import { Dispatch } from 'react'
import operatorSliceReducer from '../../redux/reducers/operator/operator.reducer'
import { bffParceirosApiService } from '../bff'

export class UserSessionService {
  static getUser() {
    return bffParceirosApiService.auth.getUser()
  }

  static fetchOperator(dispatch: Dispatch<any>) {
    const user = this.getUser()
    if (!user) return

    dispatch(operatorSliceReducer.actions.fetchOperator({ operatorId: user?.id }))
  }
}
