import React from 'react'
import { TableOutlineWhenSelected } from '../common/TableOutlineWhenSelected'
import { InstallmentRow, InstallmentUI } from './InstallmentRow'

interface Props {
  selectedInstallmentsIds: string[]
  onInstallmentSelected: (installmentId: string) => void
  installments: InstallmentUI[]
}

export const InstallmentsTable = (props: Props) => {
  const { installments, selectedInstallmentsIds, onInstallmentSelected } = props

  return (
    <TableOutlineWhenSelected>
      <thead tabIndex={-1}>
        <tr>
          <th>Contrato</th>
          <th>Parcela</th>
          <th>Vencimento</th>
          <th>Valor</th>
          <th>Descontos</th>
          <th>Juros</th>
          <th>A pagar</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {installments.map((i, idx) => (
          <InstallmentRow
            isSelected={selectedInstallmentsIds.includes(i.id)}
            onInstallmentSelected={() => onInstallmentSelected(i.id)}
            i={i}
            key={i.id}
          />
        ))}
      </tbody>
    </TableOutlineWhenSelected>
  )
}
