import * as React from 'react'
import { HistoryProps } from '../../../../App'
import umeLogo from '../../../../common/assets/images/ume-logo.jpeg'
import { Role } from '../../../../common/constants'
import { Anchor, Header } from '../../../../legacy-lib/design-kit-ui'
import { bffParceirosApiService } from '../../../../services/bff'
import { NavItem } from '../Header/Header'
import { LogoImg } from './style'

export interface INavBarDesktopProps extends HistoryProps {
  userRole: Role
  navItems: NavItem[]
}

export interface INavBarDesktopState {}

export default class NavBarDesktop extends React.Component<INavBarDesktopProps, INavBarDesktopState> {
  constructor(props: INavBarDesktopProps) {
    super(props)

    this.state = {}
  }

  onLogoutClick = () => {
    bffParceirosApiService.auth.logout()
    this.props.history.push('/')
  }

  onAnchorClick = (path: string) => {
    this.props.history.push(path)
  }

  onLogoClick = () => {
    this.props.history.push('/')
  }

  public render() {
    const { userRole } = this.props

    const user = bffParceirosApiService.auth.getUser()
    let retailerId = user?.retailerId

    const navItemsAnchorsDesktop = this.props.navItems.map((navItem, idx) => {
      // If no retailer array is given, check only if the user has the necessary role
      if (
        (!retailerId || !navItem.retailers || navItem.retailers.length === 0) &&
        navItem.authorizedRoles.includes(userRole)
      ) {
        return (
          <Anchor
            key={idx}
            icon={navItem.icon}
            onClick={() => (navItem.label === 'Sair' ? this.onLogoutClick() : this.onAnchorClick(navItem.path))}
            label={navItem.label}
            color="white"
          />
        )
      } else if (
        retailerId &&
        navItem.retailers &&
        navItem.retailers.length !== 0 &&
        navItem.retailers.find(id => retailerId === id) &&
        navItem.authorizedRoles.includes(userRole)
      ) {
        return (
          <Anchor
            key={idx}
            icon={navItem.icon}
            onClick={() => (navItem.label === 'Sair' ? this.onLogoutClick() : this.onAnchorClick(navItem.path))}
            label={navItem.label}
            color="white"
          />
        )
      } else {
        return undefined
      }
    })

    return (
      <Header
        logo={
          <Anchor onClick={this.onLogoClick}>
            <LogoImg src={umeLogo} />
          </Anchor>
        }
      >
        {navItemsAnchorsDesktop}
      </Header>
    )
  }
}
