import React from 'react';

import LoadingModal from '../../molecules/LoadingModal/LoadingModal';
import PageHeader from '../../molecules/header/components/PageHeader/PageHeader';
import { resolvePurchaseReceiptRoute } from '../../pages/Receipts/Utils';
import analyticsService from '../../services/analytics/analytics.service';
import { AnalyticsEvents } from '../../services/analytics/events';
import { bffParceirosApiService } from '../../services/bff';
import { IContract } from '../../services/bff/coordinator/dto/Contracts.dto';
import { ContentContainer, HeaderContainer, MainContainer } from './QRCodePurchase.styles';
import QRCodeCapture from './components/capture/QRCodeCapture';
import QRCodeCreation from './components/creation/QRCodeCreation';
import QRCodeResult from './components/result/QRCodeResult';

enum Steps {
    QRCodeCreation,
    QRCodeCapture,
    QRCodeResult
}

const QRCodePurchase: React.FC = () => {
    const { error, isLoading, mutate, data } = bffParceirosApiService.originations.useQRCodeCreation();
    const [step, setStep] = React.useState<Steps>(Steps.QRCodeCreation)
    const [contract, setContract] = React.useState<IContract | undefined>(undefined)

    React.useEffect(() => {
        if (!!data)
            setStep(Steps.QRCodeCapture)
    }, [data])

    React.useEffect(() => {
        if (!!error)
            alert(error.message)
    }, [error])

    const handleGoBack = () => {
        if (step === Steps.QRCodeCreation) {
            analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_go_cashier)
            window.history.back()
        }
        else if (step === Steps.QRCodeResult) {
            analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_go_cashier)
            window.location.href = '/caixa'
        }
        else {
            analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_go_input)
            setStep(Steps.QRCodeCreation)
        }
    }

    const handleGoToCashierCapture = () => {
        analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_go_legacy)
        window.location.href = '/caixa/compras'
    }

    const handlePaymentCreation = (value: number) => {
        const store = bffParceirosApiService.coordinator.getStore()

        if (!store) {
            alert('Erro ao obter loja')
            return
        }

        mutate({ value, storeId: store.id })
    }

    const handlePrintReceipt = (contractId: string) => {
        analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_print_receipt)
        const contractReceiptUri = resolvePurchaseReceiptRoute(contractId)
        window.open(contractReceiptUri, '_blank')
    }

    const handlePaymentResult = (contract: IContract) => {
        analyticsService.sendEventWithDuration(AnalyticsEvents.qr_code_payment_success, AnalyticsEvents.qr_code_payment_creation)
        setContract(contract)
        setStep(Steps.QRCodeResult)
    }

    return (
        <MainContainer>
            <HeaderContainer>
                <PageHeader/>
            </HeaderContainer>

            <LoadingModal enabled={isLoading}/>

            <ContentContainer>
                {step === Steps.QRCodeCreation && (
                    <QRCodeCreation
                        handleGoBack={handleGoBack}
                        handleGoToCashierVersion={handleGoToCashierCapture}
                        onPaymentCreation={handlePaymentCreation}
                    />
                )}

                {step === Steps.QRCodeCapture && !!data && (
                    <QRCodeCapture
                        id={data.details.id}
                        value={data.details.value}
                        qrcode={data.qrCodeString}
                        handleGoToCashierCapture={handleGoToCashierCapture}
                        handleGoBack={handleGoBack}
                        handlePaymentResult={handlePaymentResult}
                    />
                )}

                {step === Steps.QRCodeResult && !!data && !!contract && (
                    <QRCodeResult
                        contractId={contract.id}
                        value={data.details.value}
                        handlePrintReceipt={handlePrintReceipt}
                        handleGoBack={handleGoBack}
                    />
                )}
            </ContentContainer>
        </MainContainer>
    )
}

export default QRCodePurchase