import { FormGroup, HTMLSelect, InputGroup } from '@blueprintjs/core'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import styled from 'styled-components'
import { toaster } from '../../App'
import { UFsList } from '../../common/UFsList'
import { Button } from 'antd'
import { ButtonZipCodeDialog } from '../zipCodeSearch'

const ViaCep = require('node-viacep').default
const viacep = new ViaCep()

interface ZipCodeAddress {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  unidade: string
  ibge: string
  gia: string
}

export interface IApplicationZipCodeSearchDialogState {
  stateUF: string
  city: string
  street: string
  isLoading: boolean
  zipCodesList?: ZipCodeAddress[]
  isApplicationZipCodeSearchDialogOpen: boolean
}

export interface IApplicationZipCodeSearchDialogProps {
  zipCodeSelected?: string
  onZipCodeSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export default class ApplicationZipCodeSearchDialog extends React.Component<IApplicationZipCodeSearchDialogProps, IApplicationZipCodeSearchDialogState> {
  state: IApplicationZipCodeSearchDialogState = {
    stateUF: 'AM',
    city: 'Manaus',
    street: '',
    isLoading: false,
    isApplicationZipCodeSearchDialogOpen: false,
  }

  openZipCodeDialog = () => {
    this.setState({ street: '', zipCodesList: undefined, isApplicationZipCodeSearchDialogOpen: true })
  }

  closeZipCodeDialog = () => {
    this.setState({ isApplicationZipCodeSearchDialogOpen: false })
  }

  onStateUFSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ stateUF: event.currentTarget.value })
  }

  onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ city: event.currentTarget.value })
  }

  onStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ street: event.currentTarget.value })
  }

  searchZipCodeByAddress = () => {
    const { stateUF, city, street } = this.state
    this.setState({ isLoading: true })

    if (!street) {
      toaster.showWarningToast('Preencha o campo Logradouro')
    }

    viacep.address
      .getJson(stateUF, city, street)
      .then((data: any) => data.json())
      .then((data: ZipCodeAddress[]) => {
        this.setState({ zipCodesList: data, isLoading: false })
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
        console.error('ApplicationZipCodeSearch#searchZipCodeByAddress: ', error)
        toaster.showErrorToast('Valores informados inválidos')
      })
  }

  render() {
    const { stateUF, city, street, isLoading, isApplicationZipCodeSearchDialogOpen, zipCodesList } = this.state
    const { zipCodeSelected, onZipCodeSelect } = this.props

    return (
      <div>
        <ButtonZipCodeDialog onClick={this.openZipCodeDialog}>Não sabe o CEP?</ButtonZipCodeDialog>
        {
          <Modal
            bodyStyle={{ padding: '14px 20px 14px 20px' }}
            visible={isApplicationZipCodeSearchDialogOpen}
            footer={
              <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                  <StyledBackButton onClick={this.closeZipCodeDialog} intent="none" >
                    Voltar
                  </StyledBackButton>
                  {zipCodeSelected && (
                    <StyledConfirmButton intent="primary" tabIndex={1} onClick={this.closeZipCodeDialog} >
                      Confirmar
                    </StyledConfirmButton>
                  )}
                </div>
              </div>
            }
            title={null}
            onCancel={this.closeZipCodeDialog}
          >
            <div className="bp3-dialog-body">
              <FormGroup label="Estado">
                <HTMLSelect tabIndex={1} fill={true} value={stateUF} onChange={this.onStateUFSelect}>
                  {UFsList.map((uf, idx) => (
                    <option value={uf} key={idx}>
                      {uf}
                    </option>
                  ))}
                </HTMLSelect>
              </FormGroup>
              <FormGroup label="Cidade">
                <InputGroup tabIndex={1} placeholder="Digite a cidade" onChange={this.onCityChange} value={city} />
              </FormGroup>
              <FormGroup label="Logradouro">
                <InputGroup
                  autoFocus
                  tabIndex={1}
                  placeholder="Rua/Avenida/Beco"
                  onChange={this.onStreetChange}
                  value={street}
                />
              </FormGroup>
              <FormGroup>
                <StyledSearchButton
                  tabIndex={1}
                  loading={isLoading}
                  onClick={this.searchZipCodeByAddress}
                  disabled={!this.state.street}
                >
                  Pesquisar
                </StyledSearchButton>
              </FormGroup>
              {zipCodesList && (
                <FormGroup label="CEP">
                  <HTMLSelect tabIndex={1} fill={true} value={zipCodeSelected} onChange={onZipCodeSelect}>
                    <option>Selecione o CEP</option>
                    {zipCodesList.map((address, idx) => (
                      <option value={address.cep.replace('-', '')} key={idx}>
                        {address.cep}: {address.logradouro}, {address.bairro} - {address.localidade}/{address.uf}
                      </option>
                    ))}
                  </HTMLSelect>
                </FormGroup>
              )}
            </div>
          </Modal>
        }
      </div>
    )
  }
}

const StyledConfirmButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 50% !important;
  height: 100% !important;

  // TODO - Add hover
  ${(props: any) =>
    props && props.disabled
      ? 'opacity: 0.3;'
      : ''} 
`

const StyledBackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048  !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #ffffff !important;
  border-radius: 10px !important;

  width: 50% !important;
  height: 100% !important;

  // TODO - Add hover
  ${(props: any) =>
    props && props.disabled
      ? 'opacity: 0.3;'
      : ''} 
`

const StyledSearchButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;

  // BUTTON
  padding: 5px 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 100% !important;
  height: 100% !important;

  // TODO - Add hover
  ${(props: any) =>
    props && props.disabled
      ? 'opacity: 0.3;'
      : ''} 
`