import styled from 'styled-components'
import { gray, white } from '../utils/_colors'

const Container = styled.div`
  // Static Styles
  border-radius: 19px;
  box-sizing: border-box;
  box-shadow: ${props => (props.boxShadow ? '0 2px 15px 0 rgba(0, 0, 0, 0.1)' : undefined)};
  border: ${props => (props.border ? props.border : `1px solid ${gray.alto}`)};
  font-family: Roboto;

  // Dynamic Styles Props
  padding: ${props => (props.padding ? props.padding : '16px')};
  margin: ${props => (props.margin ? props.margin : '8px')};
  height: ${props => props.height};
  width: ${props => props.width};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : white.primary)};
`

export { Container }
