import React from 'react';
import styled from 'styled-components';

export const Bold = (props: JSX.IntrinsicElements['p']) => {
  return (
    <BoldText {...props}>{props.children}</BoldText>
  );
};

const BoldText = styled.span`
  font-weight: bold;
`;