import { Box } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../../../../common/assets/utils/_breakpoints'
import { Card, Heading, Text } from '../../../../../legacy-lib/design-kit-ui'
import { gray } from '../../../../../legacy-lib/design-kit-ui/Palette'

export const CardTitleHeadingStyle = styled(Heading)`
  color: ${gray.spanish};
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  letter-spacing: -0.45px;
  line-height: 22px;
`

export const TextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '14px')};
  font-weight: ${(props: any) => (props.bold ? '500' : '300')};
  letter-spacing: 0;
  line-height: 16px;
`

export const RankingPositionTextStyle = styled(Text)`
  font-size: 39px;
  letter-spacing: normal;
  line-height: 46px;
  font-weight: bold;
`

export const RankingSizeTextStyle = styled(Text)`
  font-size: 18px;
  letter-spacing: 0.85px;
  line-height: 21px;
  color: ${gray.spanish};

  @media (min-width: ${tablet}) {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 24px;
  }
`

export const NumberBoxMobileStyle = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const RankingPositionBoxStyle = styled(Box)`
  margin-bottom: 15px;
`

export const SmallTextStyle = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
`

export const SmallCardStyle = styled(Card)`
  height: 128px;
  min-width: 128px;
  border: none;
  padding-bottom: 20px;
  margin: 8px 8px 8px 0;

  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    height: 184px;
    width: 164px;
    padding-bottom: 20px;
    margin: 8px;

    > div {
      &.desktop {
        display: flex;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`

export const NumberHeadingStyle = styled(Heading)`
  margin: 0;
  font-size: 46px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 54px;

  @media (min-width: ${tablet}) {
    letter-spacing: -1px;
    line-height: 75px;
    font-size: ${(props: any) => (props.size ? props.size : '64px')};
  }
`
