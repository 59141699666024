import React, { useEffect, useState } from 'react';

import loadingAnimation from '../../../../common/assets/animations/3_dots.json';
import confirmationAnimation from '../../../../common/assets/animations/success.json';
import { formatBRL } from '../../../common/formatBRL';
import {
    Animation, ContentContainer, DetailsContainer,
    LeftCard,
    PrimaryButton,
    PrimaryButtonText,
    Row,
    SecondaryButton,
    SecondaryButtonText,
    Text, Title, Value
} from './QRCodeResult.styles';

interface Props {
    contractId: string
    value: number
    handleGoBack: () => void
    handlePrintReceipt: (installmentId: string) => void;
}

const QRCodeResult: React.FC<Props> = (props) => {
    const [inAnimation, setInAnimation] = useState(true)
    const { value, contractId, handleGoBack, handlePrintReceipt } = props;

    useEffect(() => {
        const timer = setTimeout(() => {
            setInAnimation(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const getTitle = (inAnimation: boolean) => {
        if (inAnimation)
            return 'Finalizando a compra'
        else
            return 'Compra finalizada com sucesso!'
    }

    const getSideText = (inAnimation: boolean) => {
        if (inAnimation)
            return 'Em breve o comprovante desta compra estará disponível para a impressão.'
        else
            return 'Tudo certo! O comprovante desta compra foi gerado com sucesso.'
    }

    return (
        <ContentContainer>
            <Title>{getTitle(inAnimation)}</Title>
            <Row>
                <LeftCard>
                    {inAnimation ? (
                        <Animation animationData={loadingAnimation} loop={true}/>
                    ) : (
                        <Animation animationData={confirmationAnimation} loop={false}/>
                    )}
                </LeftCard>
                <DetailsContainer>
                    <Text>Valor da compra</Text>
                    <Value>{formatBRL(value)}</Value>
                    <Text>{getSideText(inAnimation)}</Text>

                    <PrimaryButton disabled={inAnimation} onClick={() => handlePrintReceipt(contractId)}>
                        <PrimaryButtonText disabled={inAnimation}>Imprimir comprovante</PrimaryButtonText>
                    </PrimaryButton>
                    <SecondaryButton disabled={inAnimation} onClick={() => handleGoBack()}>
                        <SecondaryButtonText disabled={inAnimation}>Voltar para a página inicial</SecondaryButtonText>
                    </SecondaryButton>
                </DetailsContainer>
            </Row>
        </ContentContainer>
    )
}

export default QRCodeResult