import { HttpBiometryApiErrors } from '@scudraservicos/coordinator-client/dist/src/services/biometry/errors/BiometryApi.errors'
import { AxiosError } from 'axios'

const HttpConnectionError = `Verifique se o seu dispositivo tem acesso a internet`
const HttpAxiosRequestError = `Erro - Contate a UME`
const HttpUnkownError = `Erro desconhecido - Contate a UME`
// const HttpBadGatewayError = `Preencha os dados corretamente`
// const BAD_GATEWAY = 400

const HttpErrorsToString: { [message: string]: string } = {
  [HttpBiometryApiErrors.SUCCESS]: 'Imagem sendo processada',
  [HttpBiometryApiErrors.BIOMETRY_ALREADY_CONCLUDED]: 'Processo de biometria já realizado',
  [HttpBiometryApiErrors.BIOMETRY_NOT_FOUND]: 'Processo de biometria não encontrado',
  [HttpBiometryApiErrors.BORROWER_ALREADY_HAS_PROCESS_IN_PROGRESS]:
    'Já existe um processo de biometria em andamento.',
  [HttpBiometryApiErrors.CLIENT_NOT_FOUND]: 'Cliente não encontrado.',
  [HttpBiometryApiErrors.DOCUMENT_ALREADY_EXISTS]: 'Documento já registrado',
  [HttpBiometryApiErrors.FACE_NEEDS_CENTERING]: 'Centralize o rosto',
  [HttpBiometryApiErrors.FACE_TILTED]: 'Imagem borrada',
  [HttpBiometryApiErrors.FACE_TOO_CLOSE]: 'Rosto próximo demais',
  [HttpBiometryApiErrors.FACE_TOO_FAR]: 'Rosto longe demais',
  [HttpBiometryApiErrors.FACE_WITH_GLASSES]: 'Rosto com óculos',
  [HttpBiometryApiErrors.INVALID_CPF]: 'CPF inválido',
  [HttpBiometryApiErrors.INVALID_DOCUMENT]: 'Documento inválido',
  [HttpBiometryApiErrors.OUT_OF_FOCUS]: 'Imagem sem foco',
  [HttpBiometryApiErrors.PERSON_NOT_FOUND]: 'Score biométrico não encontrado.',
  [HttpBiometryApiErrors.PROCESS_IN_PROGRESS_NOT_FOUND]: 'Biometria não encontrada. Tire outra foto de rosto.',
  [HttpBiometryApiErrors.SERVICE_UNAVAIBLE]: 'Serviço indisponível.',
  [HttpBiometryApiErrors.SERVICE_UNAVAILABLE]: 'Serviço indisponível.',
  [HttpBiometryApiErrors.SUCCESSFUL_DOCUMENT_IMAGE_NOT_FOUND]: 'Documento com sucesso não encontrada.',
  [HttpBiometryApiErrors.SUCCESSFUL_FACE_IMAGE_NOT_FOUND]: 'Imagem de rosto com sucesso não encontrada.',
  [HttpBiometryApiErrors.TOO_DARK]: 'Imagem muito escura',
  [HttpBiometryApiErrors.UNABLE_TOKEN]: 'Token inválido.',
  [HttpBiometryApiErrors.UNABLE_TO_CREATE_PROCESS]: 'Incapaz de criar processo.',
  [HttpBiometryApiErrors.UNKNOWN_DOCUMENT]: 'Documento desconhecido',
  [HttpBiometryApiErrors.UNKNOWN_ERROR]: 'Erro desconhecido',
  [HttpBiometryApiErrors.UNSUPORTED_BASE64]: 'Formato de imagem não suportado',
  [HttpBiometryApiErrors.WRONG_DOCUMENT_TYPE]: 'Documento do tipo incorreto',
  [HttpBiometryApiErrors.INVALID_IMAGE]: 'Imagem inválida. Tente novamente.',
  [HttpBiometryApiErrors.INVALID_WDD_CREDENTIALS]: 'Credenciais de parceiro inválidas. Contate a Engenharia.', // Unlikely to occurr
  [HttpBiometryApiErrors.IMAGE_FILE_ERROR]: 'Erro ao fazer upload da imagem. Tente novamente.',
  [HttpBiometryApiErrors.FAILED_TO_REQUEST]: 'Serviço indisponível. Tente novamente ou contate a engenharia.',
  [HttpBiometryApiErrors.SPOOF_IMAGE]: 'Não identificamos uma pessoa ao vivo na foto. Tente novamente com uma foto do cliente ao vivo.'
}

export const transformHttpBiometryErrorsToString = (error: AxiosError): string => {
  let message = null 
  if (error.response) {
    // Request made and server responded
    // if (error.response.status === BAD_GATEWAY) {
    // TODO - Too generic
    // message = HttpBadGatewayError
    // }

    // TODO - This API uses the error attribute in the response
    if (error?.response?.data && error?.response?.data?.message) {
      message = HttpErrorsToString[error?.response?.data?.message]
    } else {
      message = HttpUnkownError
    }
  } else if (error?.request) {
    message = HttpAxiosRequestError
  } else {
    message = HttpConnectionError
  }

  if(!message) {
    return `Serviço indisponível temporariamente`
  }

  return message
}
