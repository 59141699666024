import { IApplicationsStatus } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/applications.interface'
import { IOperatorsRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operators-ranking'
import { IStoresRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/stores-ranking'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { IOriginationFirstPurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-first-purchase'
import { IOriginationRecurrencePurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-recurrence-purchase'
import { IStoresOriginationRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/stores-ranking'
import { Box, Grid } from 'grommet'
import moment from 'moment'
import * as React from 'react'
import { Role } from '../../../../common/constants'
import { RichTabTitle, Tabs } from '../../../../legacy-lib/design-kit-ui'
import { IntervalType } from '../../../../legacy-lib/design-kit-ui/Calendar/helpers'
import { orange } from '../../../../legacy-lib/design-kit-ui/Palette'
import ApplicationByOperator from '../../../../molecules/dashView/components/ApplicationsByOperator/ApplicationsByOperator'
import ApplicationByStore from '../../../../molecules/dashView/components/ApplicationsByStore/ApplicationsByStore'
import CalendarSidebar from '../../../../molecules/dashView/components/CalendarSidebar/CalendarSidebar'
import CalendarSidebarMobile from '../../../../molecules/dashView/components/CalendarSidebar/CalendarSidebarMobile'
import OriginationsByStore from '../../../../molecules/dashView/components/OriginationsByStore/OriginationsByStore'
import OriginationsByStoreComparative from '../../../../molecules/dashView/components/OriginationsByStoreComparative/OriginationsByStoreComparative'
import ManagerOverview from '../../../../molecules/dashView/components/Overview/ManagerOverview'
import OverviewHeading from '../../../../molecules/dashView/components/Overview/ManagerOverviewHeading'
import StoresSelectSidebarMobile from '../../../../molecules/dashView/components/StoresSelectSidebar/StoreSelectSidebarMobile'
import StoresSelectSidebar from '../../../../molecules/dashView/components/StoresSelectSidebar/StoresSelectSidebar'
import LoggedUserInfoCard from '../../../../molecules/LoggedUserInfoCard/LoggedUserInfoCard'
import { bffParceirosApiService } from '../../../../services/bff'
import { Group, Retail } from '../../../../ui/Icons'
import {
  MainBoxStyle,
  MainMobileBoxStyle,
  ManagerViewBoxStyle,
  SidebarBoxFixedStyle,
  SidebarBoxStyle,
  TabStyle,
} from './style'

export enum DashTabs {
  STORES,
  OPERATORS,
}

export interface IDirectorDashViewProps {}

export interface IDirectorDashViewState {
  startDate: string
  endDate: string
  interval: IntervalType
  retailerId?: string
  storeId?: string
  stores: IStoreResponse[]
  stringDate?: string
  intervalBeforeStringDate?: string
  averageTicket?: number | Error
  applicationsByStatus?: IApplicationsStatus | Error
  applicationsRankingByOperator?: IOperatorsRankingResponse | Error
  applicationsRankingByStore?: IStoresRankingResponse | Error
  originationsRankingByStore?: IStoresOriginationRankingResponse | Error
  originationsRankingByStoreIntervalBefore?: IStoresOriginationRankingResponse | Error
  originationsFirstPurchase?: IOriginationFirstPurchaseResponse | Error
  originationsRecurrencePurchase?: IOriginationRecurrencePurchaseResponse | Error
}

const initialState: IDirectorDashViewState = {
  startDate: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
  interval: 'month',
  stores: [],
}
export default class DirectorDashView extends React.Component<IDirectorDashViewProps, IDirectorDashViewState> {
  _isMounted = false

  constructor(props: IDirectorDashViewProps) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    this._isMounted = true
    this.getStores()
    this.getManagerStore()
    this.getApplicationsStoresRanking()
    this.getFirstPurchase()
    this.getRecurrencePurchase()
  }

  componentDidUpdate(prevProps: IDirectorDashViewProps, prevState: IDirectorDashViewState) {
    if (this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {
      this.setState({
        applicationsRankingByOperator: initialState.applicationsRankingByOperator,
        applicationsRankingByStore: initialState.applicationsRankingByStore,
        applicationsByStatus: initialState.applicationsByStatus,
        originationsRankingByStore: initialState.originationsRankingByStore,
        originationsFirstPurchase: initialState.originationsFirstPurchase,
        originationsRecurrencePurchase: initialState.originationsRecurrencePurchase,
        averageTicket: initialState.averageTicket,
      })
      this.getApplicationsOperatorsRanking()
      this.getApplicationsStoresRanking()
      this.getApplicationsStatus()
      this.getOriginationsStoresRanking()
      this.getFirstPurchase()
      this.getRecurrencePurchase()
    }
    if (this.state.storeId !== prevState.storeId) {
      this.setState({
        originationsFirstPurchase: initialState.originationsFirstPurchase,
        originationsRecurrencePurchase: initialState.originationsRecurrencePurchase,
        applicationsByStatus: initialState.applicationsByStatus,
        applicationsRankingByOperator: initialState.applicationsRankingByOperator,
        averageTicket: initialState.averageTicket,
      })
      this.setAverageTicket()
      this.getApplicationsStatus()
      this.getApplicationsOperatorsRanking()
      this.getFirstPurchase()
      this.getRecurrencePurchase()
    }
    if (this.state.stores !== prevState.stores) {
      this.getOriginationsStoresRanking()
    }
    if (this.state.originationsRankingByStore !== prevState.originationsRankingByStore) {
      this.setState({
        originationsRankingByStoreIntervalBefore: initialState.originationsRankingByStoreIntervalBefore,
      })
      this.getOriginationsStoresRankingIntervalBefore()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onSelectStore = (store: { value: IStoreResponse }) => {
    this.setState({ storeId: store.value.id ? store.value.id : undefined })
  }

  setAverageTicket = () => {
    const { storeId, originationsRankingByStore } = this.state
    if (originationsRankingByStore && !(originationsRankingByStore instanceof Error)) {
      const averageTicket = storeId
        ? originationsRankingByStore.storesRanking.find(store => store.storeId === storeId)?.averageTicket || 0
        : originationsRankingByStore.averageTicket

      this.setState({ averageTicket })
    }
  }

  intervalSelected = (interval: IntervalType) => {
    this.setState({ interval })
  }

  dateRangeSelected = (startDate: string, endDate: string) => {
    this.setState({ startDate, endDate })
  }

  getStores = () => {
    bffParceirosApiService.retailers.fetchStores().then(data => {
      this.setState({
        stores: [
          ...[
            {
              name: 'Todas as lojas',
              id: '',
              zipcode: '',
              retailerId: '',
              finalBankAccountBankCode: '',
              finalBankAccountAccountType: '',
              finalBankAccountAgencyCode: '',
              finalBankAccountAgencyDigit: '',
              finalBankAccountCode: '',
              finalBankAccountDigit: '',
            },
          ],
          ...data.stores.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
        ],
      })
    })
  }
  getManagerStore = () => {
    const user = bffParceirosApiService.auth.getUser()
    user &&
      bffParceirosApiService.coordinator
        .fetchOperatorById(user.id)
        .then(data => this.setState({ retailerId: data.retailerId, storeId: data.storeId }))
  }

  getApplicationsStatus = () => {
    const { startDate, endDate, retailerId, storeId } = this.state

    bffParceirosApiService.backoffice
      .getApplicationStatus({
        startDate,
        endDate,
        retailerId: Number(retailerId) || undefined,
        storeId: Number(storeId) || undefined,
      })
      .then(data =>
        this.setState({
          applicationsByStatus: { ...data, TOTAL: data.APPROVED + data.DENIED + data.FAILURE + data.UNDER_ANALYSIS },
        })
      )
      .catch(error => this.setState({ applicationsByStatus: new Error() }))
  }

  getApplicationsStoresRanking = () => {
    const { startDate, endDate } = this.state

    bffParceirosApiService.backoffice
      .getApplicationsRankingByStores({ startDate, endDate })
      .then(data => {
        this._isMounted && this.setState({ applicationsRankingByStore: data })
      })
      .catch(error => this._isMounted && this.setState({ applicationsRankingByStore: error }))
  }

  getFirstPurchase = () => {
    const { startDate, endDate, storeId } = this.state

    bffParceirosApiService.transactions
      .getOriginationsFirstPurchase({ startDate, endDate, storeId })
      .then(data => this.setState({ originationsFirstPurchase: data }))
      .catch(error => this.setState({ originationsFirstPurchase: error }))
  }

  getRecurrencePurchase = () => {
    const { startDate, endDate, storeId } = this.state

    bffParceirosApiService.transactions
      .getOriginationsRecurrencePurchase({ startDate, endDate, storeId })
      .then(data => this.setState({ originationsRecurrencePurchase: data }))
      .catch(error => this.setState({ originationsRecurrencePurchase: error }))
  }

  getOriginationsStoresRanking = () => {
    const { startDate, endDate, storeId, stores } = this.state

    bffParceirosApiService.transactions
      .getOriginationsRankingByStore({ startDate, endDate })
      .then(data => {
        if (this._isMounted) {
          // list of all stores including stores that dont have originations
          const allStores = stores.map(store => {
            const storeMatch = data.storesRanking.find(storeRanking => storeRanking.storeId === store.id)
            if (storeMatch) {
              return storeMatch
            } else {
              return {
                storeId: store.id,
                storeName: store.name,
                retailerId: store.retailerId,
                storeRetailerName: store.retailerId,
                rankingPosition: 0,
                originationsCount: 0,
                principalSum: 0,
                averageTicket: 0,
              }
            }
          })

          this.setState({
            originationsRankingByStore: {
              ...data,
              averageVolumeOriginations: allStores.reduce((a, b) => a + b.principalSum, 0) / allStores.length - 1 || 0, //update orginations sum average
              storesRanking: allStores
                .filter(store => store.storeId !== '') // remove the store 'Todas as lojas'
                .sort((a, b) => b.principalSum - a.principalSum) // sort in descending order
                .map((store, idx) => ({ ...store, rankingPosition: idx + 1 })), // update ranking position
            },
            averageTicket: storeId
              ? data.storesRanking.find(store => store.storeId === storeId)?.averageTicket || 0
              : data.averageTicket,
          })
        }
      })
      .catch(error => this._isMounted && this.setState({ originationsRankingByStore: error, averageTicket: error }))
  }

  getOriginationsStoresRankingIntervalBefore = () => {
    const { startDate, endDate, interval, originationsRankingByStore } = this.state
    let startDateIntervalBefore, endDateIntervalBefore

    if (interval === 'month') {
      startDateIntervalBefore = moment(startDate, 'YYYY-MM-DD')
        .subtract(1, 'month')
        .format('YYYY-MM-DD')
      endDateIntervalBefore = moment(endDate, 'YYYY-MM-DD')
        .subtract(1, 'month')
        .format('YYYY-MM-DD')

      this.setState({
        stringDate: moment(startDate, 'YYYY-MM-DD').format('MMM'),
        intervalBeforeStringDate: moment(startDateIntervalBefore, 'YYYY-MM-DD').format('MMM'),
      })
    } else if (interval === 'week') {
      startDateIntervalBefore = moment(startDate, 'YYYY-MM-DD')
        .subtract(1, 'week')
        .format('YYYY-MM-DD')
      endDateIntervalBefore = moment(endDate, 'YYYY-MM-DD')
        .subtract(1, 'week')
        .format('YYYY-MM-DD')

      this.setState({
        stringDate: 'S ' + moment(startDate, 'YYYY-MM-DD').format('DD/M'),
        intervalBeforeStringDate: 'S ' + moment(startDateIntervalBefore, 'YYYY-MM-DD').format('DD/M'),
      })
    } else {
      startDateIntervalBefore = moment(startDate, 'YYYY-MM-DD')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      endDateIntervalBefore = moment(endDate, 'YYYY-MM-DD')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')

      this.setState({
        stringDate: moment(startDate, 'YYYY-MM-DD').format('DD/MM'),
        intervalBeforeStringDate: moment(startDateIntervalBefore, 'YYYY-MM-DD').format('DD/MM'),
      })
    }

    bffParceirosApiService.transactions
      .getOriginationsRankingByStore({ startDate: startDateIntervalBefore, endDate: endDateIntervalBefore })
      .then(data => {
        if (this._isMounted) {
          // list of all stores including stores that dont have originations
          const allStores =
            originationsRankingByStore &&
            !(originationsRankingByStore instanceof Error) &&
            originationsRankingByStore.storesRanking.map(store => {
              const storeMatch = data.storesRanking.find(storeRanking => storeRanking.storeId === store.storeId)

              return {
                storeId: store.storeId,
                storeName: store.storeName,
                retailerId: store.retailerId,
                storeRetailerName: store.storeRetailerName,
                rankingPosition: store.rankingPosition,
                originationsCount: storeMatch ? storeMatch.originationsCount : 0,
                principalSum: storeMatch ? storeMatch.principalSum : 0,
                averageTicket: storeMatch ? storeMatch.averageTicket : 0,
              }
            })
          this.setState({
            originationsRankingByStoreIntervalBefore: {
              ...data,
              storesRanking: allStores || [],
            },
          })
        }
      })
      .catch(error => this._isMounted && this.setState({ originationsRankingByStoreIntervalBefore: error }))
  }

  getApplicationsOperatorsRanking = () => {
    const { startDate, endDate, storeId } = this.state

    bffParceirosApiService.backoffice
      .getApplicationsRankingByOperators({ startDate, endDate, storeId })
      .then(data => {
        this._isMounted && this.setState({ applicationsRankingByOperator: data })
      })
      .catch(error => this._isMounted && this.setState({ applicationsRankingByOperator: error }))
  }

  public render() {
    const {
      applicationsRankingByStore,
      applicationsRankingByOperator,
      stores,
      storeId,
      stringDate,
      intervalBeforeStringDate,
      applicationsByStatus,
      originationsRankingByStore,
      originationsRankingByStoreIntervalBefore,
      originationsFirstPurchase,
      originationsRecurrencePurchase,
      averageTicket,
    } = this.state

    const groupIcon = <Group width="28px" height="28px" color={orange.amber} />
    const retailerIcon = <Retail width="28px" height="28px" color={orange.amber} />

    return (
      <ManagerViewBoxStyle>
        <Grid className="desktop" fill rows={['full']} columns={['340px', 'auto']} areas={[['sidebar', 'main']]}>
          <SidebarBoxStyle gridArea="sidebar">
            <LoggedUserInfoCard containerStyle={{ marginBottom: 79 }} />
            <SidebarBoxFixedStyle>
              <CalendarSidebar intervalSelected={this.intervalSelected} dateRangeSelected={this.dateRangeSelected} />
              <StoresSelectSidebar stores={stores} selectedStore={storeId} onStoreSelect={this.onSelectStore} />
            </SidebarBoxFixedStyle>
          </SidebarBoxStyle>
          <MainBoxStyle gridArea="main" gap="small">
            <OverviewHeading />
            <ManagerOverview
              applicationsByStatus={applicationsByStatus}
              originationsFirstPurchase={originationsFirstPurchase}
              originationsRecurrencePurchase={originationsRecurrencePurchase}
              averageTicket={averageTicket}
            />
            <Tabs justify="start">
              <TabStyle className="stores" title={<RichTabTitle icon={retailerIcon} label={'Lojas'} />}>
                <Box margin={{ top: 'medium' }}>
                  <ApplicationByStore
                    applicationsRankingByStore={applicationsRankingByStore}
                    userRole={Role.RETAILER_ANALYST}
                  />
                  <OriginationsByStore
                    originationsRankingByStore={originationsRankingByStore}
                    userRole={Role.RETAILER_ANALYST}
                  />
                  <OriginationsByStoreComparative
                    originationsRankingByStore={originationsRankingByStore}
                    originationsRankingByStoreIntervalBefore={originationsRankingByStoreIntervalBefore}
                    stringDate={stringDate}
                    intervalBeforeStringDate={intervalBeforeStringDate}
                    userRole={Role.RETAILER_ANALYST}
                  />
                </Box>
              </TabStyle>
              <TabStyle className="operators" title={<RichTabTitle icon={groupIcon} label={'Operadores'} />}>
                <Box margin={{ top: 'medium' }}>
                  <ApplicationByOperator
                    applicationsRankingByOperator={applicationsRankingByOperator}
                    userRole={Role.RETAILER_ANALYST}
                  />
                </Box>
              </TabStyle>
            </Tabs>
          </MainBoxStyle>
        </Grid>
        <Box className="is-mobile">
          <MainMobileBoxStyle gap="medium">
            <LoggedUserInfoCard containerStyle={{ marginTop: 56 }} />
            <OverviewHeading />
            <Box margin={{ top: 'small' }} direction="row" gap="small" align="end">
              <CalendarSidebarMobile
                intervalSelected={this.intervalSelected}
                dateRangeSelected={this.dateRangeSelected}
              />
              <StoresSelectSidebarMobile stores={stores} selectedStore={storeId} onStoreSelect={this.onSelectStore} />
            </Box>
            <ManagerOverview
              applicationsByStatus={applicationsByStatus}
              originationsFirstPurchase={originationsFirstPurchase}
              originationsRecurrencePurchase={originationsRecurrencePurchase}
              averageTicket={averageTicket}
            />
            <Tabs justify="start">
              <TabStyle className="stores" title={<RichTabTitle icon={retailerIcon} label={'Lojas'} />}>
                <Box margin={{ top: 'large' }}>
                  <ApplicationByStore
                    applicationsRankingByStore={applicationsRankingByStore}
                    userRole={Role.RETAILER_ANALYST}
                  />
                  <OriginationsByStore
                    originationsRankingByStore={originationsRankingByStore}
                    userRole={Role.RETAILER_ANALYST}
                  />
                  <OriginationsByStoreComparative
                    originationsRankingByStore={originationsRankingByStore}
                    originationsRankingByStoreIntervalBefore={originationsRankingByStoreIntervalBefore}
                    userRole={Role.RETAILER_ANALYST}
                  />
                </Box>
              </TabStyle>
              <TabStyle className="operators" title={<RichTabTitle icon={groupIcon} label={'Operadores'} />}>
                <Box margin={{ top: 'large' }}>
                  <ApplicationByOperator
                    applicationsRankingByOperator={applicationsRankingByOperator}
                    userRole={Role.RETAILER_ANALYST}
                  />
                </Box>
              </TabStyle>
            </Tabs>
          </MainMobileBoxStyle>
        </Box>
      </ManagerViewBoxStyle>
    )
  }
}
