import { Box, Calendar, Drop, Keyboard, TextInput } from 'grommet'
import moment from 'moment'
import React, { Component } from 'react'

// Yes, these are for the odd but conventional U.S. way of representing dates.
const MONTHS = ['[2-9]', '0[1-9]', '1[0-2]']
const DAYS = ['[4-9]', '0[1-9]', '[1-2][0-9]', '3[0-1]']
const MONTH_REGEXP = new RegExp(MONTHS.map(m => `^${m}$`).join('|'))
const MONTH_DAY_REGEXP = new RegExp(DAYS.map(d => MONTHS.map(m => `^${m}/${d}$`).join('|')).join('|'))
const MONTH_DAY_YEAR_REGEXP = new RegExp('^(\\d{1,2})/(\\d{1,2})/(\\d{4})$')

export default class GrommetDatePicker extends Component<any, any> {
  state = { text: '', active: false, date: undefined }
  private focusInput?: any
  private ref: any
  private onBlur: any
  // private onClose: any
  // private element?: any

  componentDidUpdate() {
    if (this.focusInput) {
      const element = document.getElementById(`date-input-${this.props.classKey}`)
      if (element) element.focus()
    }
  }

  onFocus = () => {
    if (!this.focusInput) {
      this.setState({ active: true })
    } else {
      this.focusInput = false
    }
  }

  onInput = (event: any) => {
    const { text } = this.state
    let {
      target: { value },
    } = event
    let date
    const match = value.match(MONTH_DAY_YEAR_REGEXP)
    if (match) {
      date = new Date(match[3], parseInt(match[1], 10) - 1, match[2]).toISOString()
    } else if (value.length > text.length) {
      // never add characters if the user is backspacing
      // add trailing '/' when it looks appropriate
      if (value.match(MONTH_REGEXP)) {
        value = `${value}/`
      } else if (value.match(MONTH_DAY_REGEXP)) {
        value = `${value}/`
      }
    }
    this.setState({ text: value, date, active: true })
  }

  onSelect = (isoDate: any) => {
    const date = new Date(isoDate)
    let text = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    text = moment(text, 'DD/MM/YYYY').format('DD/MM/YYYY')
    this.setState({ date, text, active: false })
    if (this.props.onDateSelect) {
      this.props.onDateSelect(text)
    }
    this.focusInput = true
  }

  render() {
    const { classKey, value, onChange } = this.props
    const { active, date } = this.state

    return (
      <Box>
        <Keyboard onDown={() => this.setState({ active: true })}>
          <TextInput
            ref={(ref: any) => {
              this.ref = ref
            }}
            id={`date-input-${classKey}`}
            placeholder="DD/MM/YYYY"
            onChange={(event: any) => onChange(event.target.value)}
            value={value}
            onInput={this.onInput}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            className={this.props.className}
          />
        </Keyboard>
        {active ? (
          <Drop
            target={this.ref}
            align={{ top: 'bottom', left: 'left' }}
            // onClose={() => this.setState({ active: false })}
            onClickOutside={() => this.setState({ active: false })}
          >
            <Box pad="small">
              <Calendar
                size="small"
                daysOfWeek={true}
                date={date}
                onSelect={this.onSelect}
                bounds={this.props.bounds}
              />
            </Box>
          </Drop>
        ) : null}
      </Box>
    )
  }
}
