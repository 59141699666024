import { Icon, IconSize } from '@blueprintjs/core'
import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { black, gray, white } from '../../legacy-lib/design-kit-ui/utils/_colors'
import { resolvePurchaseReceiptRoute } from '../Receipts/Utils'

export const OriginationsReportActions = (props: { contractId: string }) => {
  return (
    <>
      <Tooltip
        overlayInnerStyle={tooltipInnerStyle}
        placement="topRight"
        title={
          <div>
            <LinkAnchor target="_blank" href={resolvePurchaseReceiptRoute(props.contractId)}>
              Visualizar comprovante
            </LinkAnchor>
          </div>
        }
        color={'#142DE5'}
      >
        <div style={IconContainerStyle}>
          <Icon icon="search" size={IconSize.STANDARD} color={white.primary} />
        </div>
      </Tooltip>
    </>
  )
}

export const LinkAnchor = styled.a`
  text-align: center;
  color: ${white.primary};
  font-family: Roboto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: 0;
  &:hover {
    color: ${white.primary};
  }
`
const tooltipInnerStyle: React.CSSProperties = {
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  border: `1px solid ${black.primary},`,
  width: '18rem',
  textAlign: 'center',
}

const IconContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#142DE5',
  width: '80%',
  height: '2.5rem',
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  border: `1px solid ${gray.aluminium}`,
}
