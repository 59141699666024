import styled from 'styled-components'
import { white, blue, red, purple, green, violet, orange, rose, black } from '../utils/_colors'

const colors = [blue.medium, red.primary, purple.primary, green.primary, violet.primary, rose.primary, orange.amber]

const StyledTag = styled.div`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : colors[Math.floor(Math.random() * colors.length)]};
  color: ${props => props.color ? props.color : white.primary};
  font-size: ${props => props.fontSize ? props.fontSize : "14px"};

  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  > Icon {
    margin-left: 5px;
    width: 1em;
    height: 1em;
    font-weight: 500;
    border-radius: 100%;
    background-color: ${white.primary};
  }

  ${props => props.disable ? "" : `&:hover {
    opacity: 0.5;
    transition: 0.3s;
    cursor: pointer;
  }`};
`

const Label = styled.span`
  height: 100%;
  font-family: Roboto;
`

const TagIcon = styled.span`
display: ${props => !props.icon ? "none" : "flex"};
margin-left: 5px;
width: 1em;
height: 1em;
font-weight: 500;
border-radius: 100%;
background-color: ${props => props.iconBackground ? props.iconBackground : white.primary};
> svg {
  width: 1em;
  height: 1em;
  stroke: ${props => props.iconColor ? props.iconColor : black.primary};
  fill: ${props => props.iconColor ? props.iconColor : black.primary};
}
`

export { TagIcon, StyledTag, Label }
