import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { Box } from 'grommet'
import * as React from 'react'
import { SideBar, Text } from '../../../../legacy-lib/design-kit-ui'
import { rose } from '../../../../legacy-lib/design-kit-ui/Palette'
import { Close, Search, Store } from '../../../../ui/Icons'
import { DropdownStyle, IconCardStyle, SubTitleStyle, TitleBoxStyle, TitleHeadingStyle } from './style'

export interface IStoresSelectSidebarMobileProps {
  stores: IStoreResponse[]
  selectedStore?: string
  onStoreSelect: (store: { value: IStoreResponse }) => void
}

export interface IStoresSelectSidebarMobileState {
  sidebarOpen: boolean
}

export default class StoresSelectSidebarMobile extends React.Component<
  IStoresSelectSidebarMobileProps,
  IStoresSelectSidebarMobileState
> {
  constructor(props: IStoresSelectSidebarMobileProps) {
    super(props)

    this.state = {
      sidebarOpen: false,
    }
  }

  onIconClick = () => {
    this.setState({ sidebarOpen: true })
  }

  onSidebarClose = () => {
    this.setState({ sidebarOpen: false })
  }

  public render() {
    const { sidebarOpen } = this.state
    const { stores, selectedStore, onStoreSelect } = this.props

    const closeBox = (
      <Box key="close" align="center">
        <Close color="white" onClick={this.onSidebarClose} />
        <Text size="small" color="white">
          Fechar
        </Text>
      </Box>
    )

    return (
      <>
        <IconCardStyle onClick={this.onIconClick}>
          <Store width="32px" height="32px" color={rose.primary} />
        </IconCardStyle>
        {sidebarOpen && (
          <SideBar
            showNavBar
            position="center"
            animation="fadeIn"
            headIcons={[<Store key="icon" width="40px" height="40px" color={rose.primary} />, closeBox]}
          >
            <Box margin={{ vertical: 'xlarge' }} fill>
              <Box align="start" justify="center">
                <TitleBoxStyle direction="row" gap="small" align="center">
                  <TitleHeadingStyle color="white">Encontre uma loja</TitleHeadingStyle>
                </TitleBoxStyle>
                <Box width="240px">
                  <SubTitleStyle color="white">Seleciona uma loja específica para poder ver seus dados.</SubTitleStyle>
                </Box>
              </Box>
              <Box align="start">
                <DropdownStyle
                  icon={<Search />}
                  placeholder="Procure pela loja"
                  labelKey="name"
                  valueKey="id"
                  value={selectedStore ? stores.find(s => s.id === selectedStore) : stores[0]}
                  options={stores}
                  onChange={onStoreSelect}
                ></DropdownStyle>
              </Box>
            </Box>
          </SideBar>
        )}
      </>
    )
  }
}
