import { Dispatch } from 'redux';
import { BUDGET_PAGES } from '../../../organisms/Budget/BudgetSteps/budget-utils';
import BudgetReducer from '../../../redux/reducers/budget/budget.reducer';

const goToPage = (
  dispatch: Dispatch<any>, 
  page: BUDGET_PAGES
) => ():void => {
  dispatch(BudgetReducer.actions.setPage(page));
};

export { goToPage };