import { Box } from 'grommet'
import React, { PureComponent } from 'react'
import { numberToCurrency } from '../../../../../common/NumberToCurrency'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  BlackCurrencyHeadingStyle,
  CardTitleHeadingStyle,
  NumberValueHeadingStyle,
  SmallCardStyle,
  TextStyle,
} from './style'

interface IAverageTicketCardProps {
  averageTicket?: number | Error
}

export default class AverageTicketCard extends PureComponent<IAverageTicketCardProps> {
  render() {
    const { averageTicket } = this.props

    const averageTicketCardBox =
      averageTicket instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        averageTicket !== undefined && (
          <>
            <Box className="desktop" fill justify="between">
              <Box margin={{ bottom: '11px' }}>
                <CardTitleHeadingStyle>Ticket médio</CardTitleHeadingStyle>
              </Box>
              <Box direction="row" align="baseline" gap="xsmall">
                <BlackCurrencyHeadingStyle>{'R$'}</BlackCurrencyHeadingStyle>
                <NumberValueHeadingStyle>{numberToCurrency(averageTicket)}</NumberValueHeadingStyle>
              </Box>
            </Box>

            <Box className="is-mobile" direction="row" fill align="center" gap="xsmall" justify="between">
              <CardTitleHeadingStyle>Ticket médio</CardTitleHeadingStyle>
              <Box direction="row" align="center" gap="xsmall">
                <BlackCurrencyHeadingStyle>{'R$'}</BlackCurrencyHeadingStyle>
                <NumberValueHeadingStyle>{numberToCurrency(averageTicket)}</NumberValueHeadingStyle>
              </Box>
            </Box>
          </>
        )
      )
    return (
      <SmallCardStyle>
        {averageTicket === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : (
          averageTicketCardBox
        )}
      </SmallCardStyle>
    )
  }
}
