import React, { PureComponent } from 'react'
import { EllipsisSpinner } from './style'

export interface ILoadingProps {}

export interface ILoadingState {}

export default class Loading extends PureComponent<ILoadingProps, ILoadingState> {
  render() {
    return (
      <EllipsisSpinner>
        <div />
        <div />
        <div />
        <div />
      </EllipsisSpinner>
    )
  }
}
