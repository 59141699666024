interface BorrowerHighRecurrenceConfig {
  allowedBillDueDays: number[]
}

export type BorrowerConfigurations = {
  highRecurrenceConfigs: BorrowerHighRecurrenceConfig
}

export const defaultBorrowerConfig: BorrowerConfigurations = {
  highRecurrenceConfigs: {
    allowedBillDueDays: [],
  },
}
