import { ChatbotError } from '@scudraservicos/coordinator-client/dist/src/services/budgets/interfaces/ChatbotError';
import { Action, ChatbotLimitBudgetResponse } from '@scudraservicos/coordinator-client/dist/src/services/budgets/interfaces/ChatbotLimitBudgetResponse';
import { AxiosError } from 'axios';

export type BudgetError = 'HTTP_ERROR' | 'REQUEST_ERROR' | 'CONNECTION_ERROR' | 'UNKNOWN_ERROR' | Action;

export const processBudgetRequestError =
  (error: AxiosError | ChatbotLimitBudgetResponse | ChatbotError): BudgetError => {
    let message = null;
    if ('budget' in error) {
      message = (error as any)?.budget?.action
    } else if ('response' in error) {
      if (error?.response?.data && error?.response?.data?.action) {
        message = error.response.data.action;
      } else {
        message = 'HTTP_ERROR';
      }
    } else if ('action' in error) {
      message = error.action
    } else if ('request' in error) {
      message = 'CONNECTION_ERROR';
    } else {
      message = 'REQUEST_ERROR';
    }

    if (!message) {
      return 'UNKNOWN_ERROR';
    }

    return message;
  };
