export default class WhatsAppUtils {
    static WPP_BREAKLINE = '%0a'

    static buildUrlWithText(phoneNumber: string, text: string) {
        return `${WhatsAppUtils.buildUrl(phoneNumber)}&text=${text}`
    }

    static buildUrl(phoneNumber: string) {
        return `https://api.whatsapp.com/send?phone=${phoneNumber}`
    }
}