import { createTheme, ThemeProvider } from '@material-ui/core'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import umeLogo from '../../common/assets/images/ume-logo.jpeg'
import { get as getFromLocalStorage } from '../../common/assets/utils/localStorage'
import { Anchor } from '../../legacy-lib/design-kit-ui'
import { LogoImg } from '../../molecules/header/components/Header/style'
import { BUDGET_PAGES } from '../../organisms/Budget/BudgetSteps/budget-utils'
import BudgetHowItWorks from '../../organisms/Budget/BudgetSteps/BudgetHowItWorks'
import BudgetIncreaseLimitOnboarding from '../../organisms/Budget/BudgetSteps/BudgetIncreaseLimitOnboarding'
import BudgetOnboarding from '../../organisms/Budget/BudgetSteps/BudgetOnboarding'
import BudgetPhone from '../../organisms/Budget/BudgetSteps/BudgetPhone'
import BudgetRequest from '../../organisms/Budget/BudgetSteps/BudgetRequest'
import BudgetResponse from '../../organisms/Budget/BudgetSteps/BudgetResponse'
import { BUDGET_ASYNC_STORAGE_KEYS } from '../../redux/reducers/budget/budget.constants'
import BudgetReducer, { ISetPhonePayload } from '../../redux/reducers/budget/budget.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { PhoneLayout } from '../../ui/PhoneLayout'

const theme = createTheme({
  palette: {
    primary: {
      main: '#11B048',
    },
  },
})

const BudgetPage = (): JSX.Element => {
  const dispatch = useDispatch()

  const userIsLoggedin = bffParceirosApiService.auth.isLogged()

  const { page } = useTypedSelector(state => state.budget)

  React.useEffect(() => {
    const budgetPhone = getFromLocalStorage(BUDGET_ASYNC_STORAGE_KEYS.phone)

    if (budgetPhone) {
      const payload: ISetPhonePayload = { phone: budgetPhone }
      dispatch(BudgetReducer.actions.setPhone(payload))
    }
  }, [])

  const renderPage = () => {
    switch (page) {
      case BUDGET_PAGES.PHONE_PAGE:
        return <BudgetPhone />
      case BUDGET_PAGES.ONBOARDING_PAGE:
        return <BudgetOnboarding />
      case BUDGET_PAGES.HOW_IT_WORKS_PAGE:
        return <BudgetHowItWorks />
      case BUDGET_PAGES.BUDGET_REQUEST_FORM_PAGE:
        return <BudgetRequest />
      case BUDGET_PAGES.INCREASE_LIMIT_ONBOARDING_PAGE:
        return <BudgetIncreaseLimitOnboarding />
      case BUDGET_PAGES.BUDGET_RESPONSE_PAGE:
        return <BudgetResponse />
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {!userIsLoggedin && (
        <UnloggedHeader>
          <Anchor href="/">
            <LogoImg src={umeLogo} />
          </Anchor>
        </UnloggedHeader>
      )}

      <Container>{renderPage()}</Container>
    </ThemeProvider>
  )
}

const UnloggedHeader = styled.div`
  height: 56px;
  background-color: #000000;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Container = styled(PhoneLayout).attrs({
  style: {
    justifyContent: 'space-between',
    padding: 40,
  },
})``

export default BudgetPage
