import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { black, gray, green, white } from '../../../../ui/utils/_colors';

export const ContentContainer = styled.div`
    width: 100%;
    max-width: 394px;
    margin: 24px;
    padding: 48px 72px;
    border: solid 1px ${gray.border};
    border-radius: 8px;
    @media only screen and (max-width: 450px) {
        padding: 24px;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 394px;
`

export const BackButtonContainer = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border: none;
    background-color: ${white.primary};
    &:hover {
        opacity: 0.8
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
`

export const BackText = styled.p`
    color: ${black.text};
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    margin-left: 8px;
`

export const InputTitle = styled.p`
    text-align: left;
    color: ${black.text};
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 24px;
`

export const Input = styled(NumberFormat)`
    margin-bottom: 24px;
    border-radius: 8px;
    border: solid 1px ${gray.border};
    padding: 16px;
    width: 100%;
    &:focus {
        border: solid 1px ${green.confirmation};
        outline: ${green.confirmation} auto 1px;
        outline-offset: 1px;
    }
`

export const GoToCashierVersionButton = styled.button`
    color: ${black.text};
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: underline;
    margin: 0 auto;
    margin-top: 10px;
    inline-size: fit-content;
    border: none;
    background-color: ${white.primary};
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
    @media only screen and (max-width: 770px) {
        display: none;
    }
`