const colors = {
  blue: {
    dark: '#0000ff',
    dark1: '5D667D',
    medium: '#3784be',
    primary: '#8cafe6',
    bay: '#1F13AB',
    teal: '#1392AB',
    azure: '#0184FF',
    navy: '#1B73E8',
    vivid: '#0021FF',
    alert50: '#E8F3FF',
    yonder: '#4A76CB'
  },
  gray: {
    primary: '#808080',
    light: '#bebebe',
    light1: '#F8F8F8',
    light2: '#F3F7FA',
    light3: '#EDEDED',
    light4: '#EFF3F0',
    light5: '#F7F7F7',
    light6: '#454B46',
    scale200: '#E0E0E0',
    scale300: '#E4ECF1',
    scale400: '#C4D0D8',
    scale600: '#8F9AA2',
    scale700: '#596975',
    scale800: '#3C4852',
    alto: '#DADADA',
    gainsboro: '#E3E3E3',
    eclipse: '#3A3A3A',
    aluminium: '#80868C',
    ivory: '#F6F6F4',
    nobel: '#949494',
    dim: '#6A6A6A',
    azure: '#F5F6F6',
    spanish: '#9D9D9D',
    silver: '#C2C2C2',
    border: '#E0E9E2',
    disabled: '#AFB1AE',
    text: '#757E79',
    darkDisabled: '#B3BEB6',
    muted: '#767f79',
    grayishBlue: '#72818C',
    slate: '#6C747A'
  },
  yellow: {
    primary: '#FFC960',
    light: '#FFFF99',
    dark: '#CCCC00',
    citrine: '#F7B500',
    alert50: '#FDF6EB',
  },
  green: {
    primary: '#28C264',
    primary300: '#11B048',
    primary600: '#0E8C39',
    primary900: '#0A662A',
    light: '#7FFF00',
    light1: '#6EEF9E',
    light2: '#EDFFF2',
    light3: '#A9E1BC',
    lightMint: '#BDE3CA',
    dark: '#006400',
    dark1: '#12AA6A',
    dark2: '#0D8754',
    seagreen: '#8FBC8F',
    sushi: '#8BC34A',
    tea: '#B0E794',
    darkPastel: '#00C745',
    laurel: '#048F0E',
    background1: '#035E46E5',
    confirmation: '#13C450',
    background2: '#F3FFF3',
    background3: '#0D8050',
  },
  black: {
    primary: '#000000',
    text: '#252927',
    text1: '#262A28',
    text2: '#182026',
  },
  white: {
    primary: '#FFFFFF',
    light: '#F1F1F5',
  },
  red: {
    primary: '#FF0000',
    dark: '#CC0000',
    light: '#FF4040',
    persian: '#E02020',
    rubi: '#C92C46',
  },
  violet: {
    primary: '#C603CC',
    eletric: '#6236FF',
  },
  rose: {
    primary: '#EF3E63',
  },
  purple: {
    primary: '#752CD0',
  },
  orange: {
    amber: '#FA6400',
  },
}

export const { blue, gray, yellow, green, orange, black, white, red, violet, rose, purple } = colors
