import { Button } from "grommet";
import React, { useEffect } from "react";
import { Document, Page } from 'react-pdf';
import styled from "styled-components";
import analyticsService from "../../services/analytics/analytics.service";
import { AnalyticsEvents } from "../../services/analytics/events";
import { Loading, Text } from "../../ui";
import { green, white } from "../../ui/utils/_colors";
import { useGetReceipt } from "./Service";
import { resolveExternalReceiptUrl } from "./Utils";

interface ReceiptProps {
  match: {
    params: {
      ids: string
      type: string
    }
  }
}

const Receipt = (props: ReceiptProps) => {
  const [blobUrl, setBlobUrl] = React.useState('');
  const [numPages, setNumPages] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }: {numPages: number}) => {
    setNumPages(numPages);
  }

  const openFile = (blobUrl: string) => {
    analyticsService.sendEvent(AnalyticsEvents.purchase_print_receipt)
    window.open(blobUrl, "_blank")
  }

  const { data: file, error, isLoading } = useGetReceipt(resolveExternalReceiptUrl(props.match.params.type, props.match.params.ids))

  useEffect(() => {
    if (!isLoading && !!file) {
      const blob = URL.createObjectURL(file) 
      openFile(blob)
      setBlobUrl(blob);
    }
  }, [file]);

  if (isLoading) {
      return (
          <MainContainer><Loading/></MainContainer>
      )
  }

  if (!!error) return <ErrorText>Erro ao carregar o arquivo</ErrorText>

  return (
    <MainContainer>
        <ClickHereButton onClick={() => openFile(blobUrl)}>
          <ButtonText>Clique aqui para imprimir o comprovante</ButtonText>
        </ClickHereButton>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page width={350} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
    </MainContainer>
  );
}

export default Receipt

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${white.primary};
`;

const ClickHereButton = styled(Button)`
    margin: 30px;
    padding: 4px;
    border: 1px solid ${green.primary};
    border-radius: 4px;
`;

const ButtonText = styled(Text)`
  color: ${green.primary};
  font-size: 14px;
`

const ErrorText = styled.p`
  font-size: 16px;
  color: red;
  margin: 30px;
`