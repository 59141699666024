import { Box } from 'grommet'
import styled from 'styled-components'
import { laptop, tablet } from '../../../../common/assets/utils/_breakpoints'
import { gray } from '../../../../legacy-lib/design-kit-ui/Palette'

export const RepresentativeViewBoxStyle = styled(Box)`
  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    > div {
      &.desktop {
        display: grid;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`
export const MainBoxStyle = styled(Box)`
  background-color: ${gray.azure};
  box-shadow: 2px 0px 8px 2px rgba(152, 152, 152, 0.5);
  padding: 30px 60px 150px 100px;

  @media (max-width: ${laptop}) {
    padding: 24px 30px 150px 36px;
  }
`

export const SidebarBoxStyle = styled(Box)`
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(152, 152, 152, 0.5);
  padding: 40px 30px;

  @media (max-width: ${laptop}) {
    padding: 40px 30px;
  }
`

export const MainMobileBoxStyle = styled(Box)`
  background-color: ${gray.azure};
  padding: 0px 44px 80px 44px;
`
