import axios, { AxiosError, AxiosInstance } from 'axios'
import moment from 'moment'
import { useQuery } from 'react-query'
import { isContractAccepted } from '../../../common/ContractHelper'
import LocalStorageWrapper, { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { shouldGenerateApplicationCoupon } from '../../../config/config'
import { CommunicationChannelLabel } from '../borrowers/dto/BorrowerPhone.dto'
import { getAxiosInstance } from '../utils'
import {
  BorrowerData,
  IBorrower,
  IBorrowerCreditLimit,
  IManagerAuthorizationRequest,
  IPhoneVerificationRequest,
  IVerificationCodeResponse,
} from './dto/Borrowers.dto'
import { IContract, IContractPostBody, IInstallments, ISingleInstallment } from './dto/Contracts.dto'
import { CreateBorrowerNextInvoiceError, CreateBorrowerNextInvoiceErrorType, Invoice } from './dto/Invoices.dto'
import {
  IOperator,
  IOperatorListResponse,
  IOperatorPostReq,
  IOperatorPutReq,
  IPostCreateManyOperatorsReq,
} from './dto/Operators.dto'
import { FetchOriginationSummariesQueryParams, IOriginationSummaryView } from './dto/OriginationSummaries.dto'
import { IRenegotiationPaymentReceived, IRenegotiationPayments, IRenegotiationResponse } from './dto/Renegotiations.dto'
import { IStoreResponse } from './dto/Stores.dto'
import { FetchBorrowerDataQueryParams } from './interfaces/fetchBorrowerData.req.interfaces'

export default class CoordinatorService {
  private axiosInstance: AxiosInstance
  private retryInterval = 2000

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async acceptProposal(proposalId: string): Promise<IContract> {
    const response = await this.axiosInstance.post<IContract>(`coordinator/contracts/proposals/${proposalId}/accept`)

    return response.data
  }

  async askForVerification(
    phoneNumber: string,
    ownerCpf: string,
    process: string,
    extra?: { proposalCode?: string },
    communicationChannel: CommunicationChannelLabel = CommunicationChannelLabel.SMS
  ): Promise<IVerificationCodeResponse> {
    const verificationBody: IPhoneVerificationRequest = {
      owner: {
        cpf: ownerCpf,
      },
      requestedByProcess: process,
      extra,
      communicationChannel,
    }

    const response = await this.axiosInstance.post<IVerificationCodeResponse>(
      `/coordinator/phones/${phoneNumber}/verifications`,
      verificationBody
    )

    return response.data
  }

  async authorizeVerificationCodeByManager(
    verificationId: string,
    managerBody: IManagerAuthorizationRequest
  ): Promise<void> {
    const response = await this.axiosInstance.post(
      `coordinator/phones/verifications/${verificationId}/authorize`,
      managerBody
    )

    return response.data
  }

  async createOperator(body: IOperatorPostReq): Promise<IOperator> {
    const response = await this.axiosInstance.post<IOperator>(`coordinator/operators`, body)

    return response.data
  }

  async getProposals(body: IContractPostBody): Promise<IContract> {
    const response = await this.axiosInstance.post<IContract>(`coordinator/contracts`, body)

    return response.data
  }

  async createBorrowerNextInvoice(borrowerId: string) {
    return this.axiosInstance
      .post<Invoice>(`coordinator/invoices/next`, { borrowerId })
      .then(response => {
        return response.data
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.message == CreateBorrowerNextInvoiceErrorType.NO_PENDING_INSTALLMENTS_FOR_BORROWER) {
          throw new CreateBorrowerNextInvoiceError({
            error: CreateBorrowerNextInvoiceErrorType.NO_PENDING_INSTALLMENTS_FOR_BORROWER,
          })
        }

        throw new CreateBorrowerNextInvoiceError({
          error: CreateBorrowerNextInvoiceErrorType.INTERNAL_SERVER_ERROR,
          message: error,
        })
      })
  }

  async createManyOperators(body: IPostCreateManyOperatorsReq): Promise<any> {
    const response = await this.axiosInstance.post<any>(`coordinator/operators/many`, body)

    return response.data
  }

  async checkVerification(verificationId: string, verificationCode: string): Promise<void> {
    const result = await this.axiosInstance.post(`coordinator/phones/verifications/${verificationId}/code`, {
      verificationCode,
    })

    return result.data
  }

  async editOperator(operatorId: string, body: IOperatorPutReq): Promise<IOperator> {
    const response = await this.axiosInstance.put<IOperator>(`coordinator/operators/${operatorId}`, body)

    return response.data
  }

  async fetchBorrowerByCPF(cpf: string): Promise<IBorrower> {
    const response = await this.axiosInstance.get<IBorrower>(`coordinator/borrowers/find-one`, {
      params: { cpf },
    })

    return response.data
  }

  async fetchBorrowerLimitForStore(borrowerId: string, storeId: string): Promise<IBorrowerCreditLimit> {
    const response = await this.axiosInstance.get<IBorrowerCreditLimit>(`coordinator/borrowers/${borrowerId}/limits`, {
      params: { storeId },
    })

    return response.data
  }

  async fetchBorrowerData(borrowerId: string, storeId: string, retailerId?: string): Promise<BorrowerData> {
    const qParams: FetchBorrowerDataQueryParams = {
      storeId,
      shouldGenerateApplicationCoupon,
      retailerId,
    }

    const response = await this.axiosInstance.get<BorrowerData>(`coordinator/borrowers/${borrowerId}/data`, {
      params: qParams,
    })

    return response.data
  }

  async fetchBorrowerNotifyLimit(borrowerId: string, storeId: string, operatorId: string): Promise<void> {
    const data = {
      storeId,
      operatorId,
    }

    await this.axiosInstance.post<void>(`coordinator/borrowers/${borrowerId}/limit/notify`, data)
  }

  async fetchInstallments({
    borrowerId,
    status,
    contractId,
  }: {
    borrowerId?: string
    status?: string
    contractId?: string
  }): Promise<IInstallments> {
    const query = {
      ...(borrowerId && { borrowerId }),
      ...(contractId && { contractId }),
      ...(status && { status }),
    }

    const response = await this.axiosInstance.get<IInstallments>(`coordinator/contracts/installments`, {
      params: query,
    })

    return response.data
  }

  async fetchOriginationSummaries(params: FetchOriginationSummariesQueryParams): Promise<IOriginationSummaryView[]> {
    const response = await this.axiosInstance.get<IOriginationSummaryView[]>(
      `coordinator/contracts/originations/summaries`,
      {
        params,
      }
    )

    return response.data
  }

  async fetchContract(id: string): Promise<IContract | undefined> {
    return this.axiosInstance.get<IContract>(`coordinator/contracts/find-one`, { params: { id } })
      .then((res) => {
        return res.data
      })
      .catch(() => {
        return
      })
  }

  async fetchOperators(storeId?: string): Promise<IOperatorListResponse> {
    const response = await this.axiosInstance.get<IOperatorListResponse>(`coordinator/operators`, {
      params: { storeId },
    })

    return response.data
  }

  async fetchOperatorById(operatorId: string): Promise<IOperator> {
    const response = await this.axiosInstance.get<IOperator>(`coordinator/operators/${operatorId}`)

    return response.data
  }

  async fetchPaymentsForBorrower(borrowerId: string): Promise<IRenegotiationPayments> {
    const response = await this.axiosInstance.get<IRenegotiationPayments>(`coordinator/renegotiations/payments`, {
      params: { borrowerId },
    })

    return response.data
  }

  async payInstallment(installmentId: string, storeId: string): Promise<ISingleInstallment> {
    const response = await this.axiosInstance.post<ISingleInstallment>(
      `coordinator/contracts/installments/${installmentId}/payment`,
      { storeId }
    )

    return response.data
  }

  async registerMultiplePayments(installmentIds: string[], storeId: string): Promise<ISingleInstallment[]> {
    const response = await this.axiosInstance.post<ISingleInstallment[]>(
      `coordinator/contracts/installments/pay-multiple`,
      {
        installmentIds,
        storeId,
      }
    )
    return response.data
  }

  async registerRenegotiationPayment(payment: IRenegotiationPaymentReceived): Promise<IRenegotiationResponse> {
    const response = await this.axiosInstance.post<IRenegotiationResponse>(
      `coordinator/renegotiations/payments`,
      payment
    )

    return response.data
  }

  async verifyProposal(proposalId: string, verificationCode: string): Promise<void> {
    const response = await this.axiosInstance.post(`coordinator/contracts/proposals/${proposalId}/verify`, {
      verificationCode,
    })

    return response.data
  }

  getStore(): IStoreResponse | undefined {
    const store = LocalStorageWrapper.getItem(LocalStorageItem.STORE)

    if (!store) {
      return undefined
    } else if (moment(store.exp) < moment()) {
      return undefined
    } else {
      return store
    }
  }

  setStore(store: IStoreResponse) {
    const STORE_EXP_DAYS = 30
    const exp = new Date()
    exp.setDate(exp.getDate() + STORE_EXP_DAYS)

    const savedStore = { ...store, exp }
    LocalStorageWrapper.setItem(LocalStorageItem.STORE, savedStore)
  }

  isStoreSetUp(): boolean {
    const store = this.getStore()
    return !!store
  }

  useGetQRCodeContract = (qrcodeId: string) => {
    return useQuery<IContract, Error>({
      queryKey: ['QRCodeContract', qrcodeId],
      refetchOnWindowFocus: false,
      queryFn: () => {
        return this.axiosInstance
          .get<IContract[]>(`coordinator/contracts`, {
            params: {
              captureMethod: 'QR_CODE',
              captureReferenceId: qrcodeId,
            },
          })
          .then(res => {
            const result = res.data
            if (!result || result.length !== 1 || !isContractAccepted(result[0])) throw new Error()
            return result[0]
          })
      },
      retry: true,
      retryDelay: this.retryInterval,
    })
  }
}
