import { Button, Card, Elevation } from '@blueprintjs/core'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import { toaster } from '../../App'
import { GenericTable } from '../../molecules/GenericTable/GenericTable'
import PageHeader from '../../molecules/header/components/PageHeader/PageHeader'
import { bffParceirosApiService } from '../../services/bff'
import { ILoginProfileResponse } from '../../services/bff/auth/dto/Login.dto'
import { IOriginationSummaryView } from '../../services/bff/coordinator/dto/OriginationSummaries.dto'
import { Loading } from '../../ui'
import { Configs } from './configs'
import { DateRangeContainer } from './DateRangeContainer'
import { MAXIMUM_DATE_RANGE_ERROR_MESSAGE } from './resources/originations-report-resources'
import {
  aggregatorContainerStyle,
  buttonsBasicStyle,
  cardBasicStyle,
  invalidDateRangeErrorMessageStyle,
  mainContainerStyle,
  selectDateContainerStyle,
} from './style'
import { mapCsvTableRows } from './utils/originations-report-helpers'

const ReportsPage = () => {
  const [user, setUser] = useState<ILoginProfileResponse>()

  useEffect(() => {
    const loggedUser = bffParceirosApiService.auth.getUser()
    setUser(loggedUser)
  }, [])

  const [startDate, setStartDate] = useState<string>(
    moment()
      .tz('America/Manaus')
      .toISOString()
  )
  const [endDate, setEndDate] = useState<string>(
    moment()
      .tz('America/Manaus')
      .toISOString()
  )
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [originationSummaries, setOriginationSummaries] = useState<IOriginationSummaryView[]>([])
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')

  const onStartDateChanged = (event: { value: string | string[] }) => {
    const date = event.value as string
    setStartDate(date)
    const oneMonthLaterStartDate = moment(date)
      .add(1, 'month')
      .toISOString()

    if (moment(endDate).isAfter(oneMonthLaterStartDate)) {
      setDateErrorMessage(MAXIMUM_DATE_RANGE_ERROR_MESSAGE)
      setEndDate('')
    }
  }

  const onEndDateChanged = (event: { value: string | string[] }) => {
    const date = event.value as string
    setEndDate(date)
    setDateErrorMessage('')
  }

  const handleSearchOriginations = async () => {
    setOriginationSummaries([])
    setIsDataLoading(true)

    try {
      const userStoreId = user?.storeId
      const userRetailerId = user?.retailerId

      const originationSummaries = await bffParceirosApiService.coordinator.fetchOriginationSummaries({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        retailerId: userRetailerId,
        storeId: userStoreId,
      })

      setIsDataLoading(false)
      setOriginationSummaries(originationSummaries)
      setIsDataLoaded(true)
    } catch (error) {
      setIsDataLoading(false)
      return toaster.showErrorToast('Erro ao buscar histórico de vendas.')
    }
  }

  const handleDownloadReport = () => {
    const originationsList = mapCsvTableRows(originationSummaries)

    const ws = XLSX.utils.json_to_sheet(originationsList)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório de Vendas UME')
    XLSX.writeFile(
      wb,
      `relatorio_vendas_INICIO_${moment(startDate).format('YYYY-MM-DD')}_FIM_${moment(endDate).format(
        'YYYY-MM-DD'
      )}.xlsx`
    )
  }

  return (
    <div>
      <PageHeader identificationUserContainerStyle={{ marginBottom: '2%', marginTop: '2%', marginLeft: '2%' }} />
      <Header>
        <h1 style={h5Style} className="bp3-heading">
          Relatório de Vendas
        </h1>
      </Header>
      <div style={mainContainerStyle}>
        <Card interactive={false} elevation={Elevation.TWO} style={cardBasicStyle}>
          <div style={selectDateContainerStyle}>
            <DateRangeContainer
              onEndDateChange={onEndDateChanged}
              onStartDateChange={onStartDateChanged}
              startDate={startDate}
              endDate={endDate}
            />
            <Button
              style={buttonsBasicStyle}
              intent="primary"
              large={true}
              rightIcon={'search'}
              onClick={handleSearchOriginations}
              disabled={endDate === ''}
            >
              Buscar
            </Button>
          </div>
          {dateErrorMessage !== '' && <div style={invalidDateRangeErrorMessageStyle}>{dateErrorMessage}</div>}
          {isDataLoading && <Loading />}
          {isDataLoaded && !isDataLoading && (
            <>
              <div style={aggregatorContainerStyle}>
                <div style={{ fontSize: '1.5rem', height: 'auto' }}>{`Total: ${originationSummaries.length} venda${
                  originationSummaries.length > 1 ? 's' : ''
                }.`}</div>

                <Button
                  style={buttonsBasicStyle}
                  intent="primary"
                  outlined={true}
                  large={true}
                  rightIcon={'import'}
                  onClick={handleDownloadReport}
                  disabled={originationSummaries.length === 0}
                >
                  Download
                </Button>
              </div>

              <GenericTable header={Configs['Vendas'].header} data={originationSummaries} />
            </>
          )}
        </Card>
      </div>
    </div>
  )
}

const Header = styled.div`
  display: flex;
  margin-top: 70px;
  margin-left: 30px;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const h5Style = {
  alignSelf: 'flex-start',
  marginRight: '20px',
  marginLeft: '20px',
}
export default ReportsPage
