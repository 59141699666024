import { Alignment, Switch } from '@blueprintjs/core'
import * as React from 'react'
import styled from 'styled-components'
import { formatDate } from '../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../common/TransactionalCreditHelper'
import { ReactComponent as ArrowTrendUp } from '../../common/assets/images/arrow-trend-up.svg'
import { ReactComponent as CalendarRepeat } from '../../common/assets/images/calendar-repeat.svg'
import { ReactComponent as Coin } from '../../common/assets/images/coin.svg'
import { ReactComponent as Coins } from '../../common/assets/images/coins.svg'
import { ReactComponent as StoreCheck } from '../../common/assets/images/store-check.svg'
import { StoreDto } from '../../services/bff/retailers/dto/Store.dto'
import { TransactionalCreditEvaluation } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { black, gray, green, white } from '../../ui/utils/_colors'
import { formatBRL } from '../common/formatBRL'

interface TransactionalCreditEvaluationProps {
  checked: boolean
  evaluation: TransactionalCreditEvaluation
  availableLimit: number
  store?: StoreDto
  onActivationChanged: () => void
}

export default class TransactionalCreditEvaluationCard extends React.Component<TransactionalCreditEvaluationProps> {
  render() {
    const cardsData = [
      {
        icon: <ArrowTrendUp stroke={green.light1} height="24px" width="24px" />,
        title: 'Valor máximo de compra',
        value: formatBRL(calculateTxCreditTotalAmount(this.props.evaluation.policy!!.maxAmount, this.props.availableLimit || 0)),
      },
      {
        icon: <Coins stroke={green.light1} height="24px" width="24px" />,
        title: 'Valor mínimo de compra',
        value: formatBRL(this.props.evaluation.policy?.minAmount || 0),
      },
      {
        icon: <CalendarRepeat stroke={green.light1} height="24px" width="24px" />,
        title: 'O limite extra expira em',
        value: formatDate(this.props.evaluation.expirationTimestamp),
      },
      {
        icon: <StoreCheck stroke={green.light1} height="24px" width="24px" />,
        title: 'Exclusivo para',
        value: this.props.store?.name,
      },
    ]

    return (
      <Container>
        <Row>
          <Title>
            <Coin style={{ width: 18, height: 19, marginRight: 8, stroke: black.text1 }} />
            Super Compra disponível
          </Title>
          <Switch
            onChange={this.props.onActivationChanged}
            alignIndicator={Alignment.RIGHT}
            labelElement={<ActivateLabel>Ativar proposta</ActivateLabel>}
            large={true}
            checked={this.props.checked}
          />
        </Row>
        <CardsRow>
          {cardsData.map(card => (
            <Card key={card.title}>
              <IconContainer>{card.icon}</IconContainer>
              <CardTextContainer>
                <CardTitle>{card.title}</CardTitle>
                <CardValue>{card.value}</CardValue>
              </CardTextContainer>
            </Card>
          ))}
        </CardsRow>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  background-color: ${gray.light4};
  padding: 24px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CardsRow = styled(Row)`
  gap: 24px;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
`

const ActivateLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

const Card = styled.div`
  display: flex;
  gap: 16px;
  border-radius: 16px;
  padding: 16px;
  background-color: ${white.primary};
  width: 100%;
`

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const CardTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${gray.muted};
`

const CardValue = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
`

const IconContainer = styled.div`
  border-radius: 24px;
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background-color: ${black.text1};
`
