import { Header as GrommetHeader } from 'grommet'
import styled from 'styled-components'
import { black, white } from '../utils/_colors'

const StyledHeader = styled(GrommetHeader)`
  height: ${props => (props.height ? props.height : '56px')};
  color: ${props => (props.color ? props.color : white.primary)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : black.primary)};
`

export { StyledHeader }
