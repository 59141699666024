export declare type IDbImageStatusType =
  | ImageStatus.FACE_NEEDS_CENTERING
  | ImageStatus.UNSUPORTED_BASE64
  | ImageStatus.FACE_TOO_FAR
  | ImageStatus.FACE_TOO_CLOSE
  | ImageStatus.TOO_DARK
  | ImageStatus.OUT_OF_FOCUS
  | ImageStatus.FACE_TILTED
  | ImageStatus.FACE_WITH_GLASSES
  | ImageStatus.INVALID_DOCUMENT
  | ImageStatus.SUCCESS
  | ImageStatus.UNKNOWN_ERROR
  | ImageStatus.NOT_CONFIRMED
export declare type IDbImageLabelType =
  | ImageLabel.PORTRAIT
  | ImageLabel.RG_FRONT
  | ImageLabel.RG_BACK
  | ImageLabel.CNH_FRONT
  | ImageLabel.CNH_BACK
  | ImageLabel.MILITARY_RG_FRONT
  | ImageLabel.MILITARY_RG_BACK
  | ImageLabel.PASSPORT_FRONT
  | ImageLabel.PASSPORT_BACK
  | ImageLabel.CTPS_FRONT
  | ImageLabel.CTPS_BACK
  | ImageLabel.FOREING_ID_FRONT
  | ImageLabel.FOREING_ID_BACK
  | ImageLabel.NEW_CNH_FRONT
  | ImageLabel.NEW_CNH_BACK
  | ImageLabel.DIGITAL_RG_FRONT
  | ImageLabel.DIGITAL_RG_BACK
  | ImageLabel.DIGITAL_CNH_FRONT
  | ImageLabel.DIGITAL_CNH_BACK

export interface ImageDto {
  id: string
  imagebase64: string
  providerResponse?: any
  status: IDbImageStatusType
  label: IDbImageLabelType
  fraudBiometryProcessId: string
}

export enum ImageStatus {
  SUCCESS = 'SUCCESS',
  UNSUPORTED_BASE64 = 'UNSUPORTED_BASE64',

  /************** ERRORS DURING FACE INSERT **************/
  FACE_NEEDS_CENTERING = 'FACE_NEEDS_CENTERING',
  FACE_TOO_FAR = 'FACE_TOO_FAR',
  FACE_TOO_CLOSE = 'FACE_TOO_CLOSE',
  TOO_DARK = 'TOO_DARK',
  OUT_OF_FOCUS = 'OUT_OF_FOCUS',
  FACE_TILTED = 'FACE_TILTED',
  FACE_WITH_GLASSES = 'FACE_WITH_GLASSES',

  /************** ERRORS DURING DOCUMENT INSERT **************/
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  INVALID_DOCUMENT = 'INVALID_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum ImageLabel {
  PORTRAIT = 'portrait',

  CNH_FRONT = 'cnh_frente',
  CNH_BACK = 'cnh_verso',

  RG_FRONT = 'rg_frente',
  RG_BACK = 'rg_verso',

  MILITARY_RG_FRONT = 'identidade_militar_frente',
  MILITARY_RG_BACK = 'identidade_militar_verso',

  PASSPORT_FRONT = 'passaporte',
  PASSPORT_BACK = 'passaporte_verso',

  CTPS_FRONT = 'carteira_de_trabalho',
  CTPS_BACK = 'carteira_de_trabalho_verso',

  FOREING_ID_FRONT = 'rg_estrangeiro',
  FOREING_ID_BACK = 'rg_estrangeiro_verso',

  NEW_CNH_FRONT = 'nova_cnh_frente',
  NEW_CNH_BACK = 'nova_cnh_verso',

  DIGITAL_RG_FRONT = 'rg_digital_frente',
  DIGITAL_RG_BACK = 'rg_digital_verso',

  DIGITAL_CNH_FRONT = 'cnh_digital_frente',
  DIGITAL_CNH_BACK = 'cnh_digital_verso',
}

export enum IDbLivenessCheck {
  LIVE = 'live',
  SPOOF = 'spoof',
  ERROR = 'error',
}
