import { Add } from 'grommet-icons'
import React from 'react'
import * as UMEColors from '../../../legacy-lib/design-kit-ui/utils/_colors'
import { AuthenticatedIconContainer, BiometryLegend, ImageBox } from './style'

interface IPortraitCardProps {
  onClick: () => void
}

export const TakePhoto = (props: IPortraitCardProps) => {
  const { onClick } = props

  const handleOnClick = () => {
    onClick()
  }

  return (
    <ImageBox gap="small" onClick={handleOnClick}>
      <AuthenticatedIconContainer>
        <Add color={UMEColors.green.seagreen} />
      </AuthenticatedIconContainer>
      <BiometryLegend>Tirar foto</BiometryLegend>
    </ImageBox>
  )
}
