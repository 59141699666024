import { Box } from 'grommet'
import * as React from 'react'
import { Calendar, Text } from '../../../../legacy-lib/design-kit-ui'
import { blue } from '../../../../legacy-lib/design-kit-ui/Palette'
import { CalendarToday } from '../../../../ui/Icons'
import { BarSideBoxStyle, CalendarBoxStyle, TitleBoxStyle, TitleHeadingStyle } from './style'

export interface ICalendarSidebarProps {
  dateRangeSelected: (startDate: string, endDate: string) => void
  intervalSelected: (interval: 'day' | 'month' | 'week' | 'range') => void
}

export interface ICalendarSidebarState {}

export default class CalendarSidebar extends React.PureComponent<ICalendarSidebarProps, ICalendarSidebarState> {
  public render() {
    return (
      <BarSideBoxStyle>
        <Box>
          <TitleBoxStyle direction="row" gap="small" align="center">
            <CalendarToday width="30px" height="30px" color={blue.vivid} />
            <TitleHeadingStyle>Escolha a data</TitleHeadingStyle>
          </TitleBoxStyle>
          <Text size="small">Selecione uma data para poder ver os dados</Text>
        </Box>
        <CalendarBoxStyle>
          <Calendar
            singleMonthOnly
            onDateIntervalChange={this.props.dateRangeSelected}
            onIntervalSelected={this.props.intervalSelected}
          />
        </CalendarBoxStyle>
      </BarSideBoxStyle>
    )
  }
}
