import React, { useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';

import { Icon } from '@blueprintjs/core';
import { jsPDF } from "jspdf";
import umeLogo from '../../../../common/assets/images/borded_logo.svg';
import analyticsService from '../../../../services/analytics/analytics.service';
import { AnalyticsEvents } from '../../../../services/analytics/events';
import { bffParceirosApiService } from '../../../../services/bff';
import { IContract } from '../../../../services/bff/coordinator/dto/Contracts.dto';
import { black } from '../../../../ui/utils/_colors';
import { pt15, pt25, pt50, pt75, pt80 } from '../../../../utils/pdf.utils';
import { formatBRL } from '../../../common/formatBRL';
import {
    CloseButton, ContentContainer, DetailsContainer, GoBackText, Modal, PrintButton, QRCodeContainer, Row,
    SecondaryButton, SecondaryButtonText, Text, Title, Value, ZoomButton, ZoomOrPrintText
} from './QRCodeCapture.styles';

interface Props {
    qrcode: string
    value: number
    id: string
    handleGoBack: () => void
    handleGoToCashierCapture: () => void;
    handlePaymentResult: (contract: IContract) => void;
}

const QRCodeCapture: React.FC<Props> = (props) => {
    const [zoomedIn, setZoomedIn] = React.useState<boolean>(false)
    const { id, qrcode, value, handleGoBack, handleGoToCashierCapture, handlePaymentResult } = props;
    const { data: contract } = bffParceirosApiService.coordinator.useGetQRCodeContract(id);
    const qrcodeFullscreenSize = Math.min(window.innerWidth, window.innerHeight) - 150;

    useEffect(() => {
        if (!!contract) {
            handlePaymentResult(contract)
        }
    }, [contract])

    const handleZoomPressed = () => {
        analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_zoom)
        setZoomedIn(true)
    }

    const printQrCodePressed = (id: string, value: number) => {
        const qrCodeElement = document.getElementById(id);
        if (qrCodeElement) {
            analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_print_qr_code)

            const qrCodeURL = (qrCodeElement as HTMLCanvasElement)
                ?.toDataURL("image/png")
                .replace("image/png", "image/octet-stream");

            var doc = new jsPDF("portrait", "pt", [pt80, pt80], true);
            doc.addImage(qrCodeURL, "JPEG", pt15, pt15, pt50, pt50);
            doc.setFont('Courier-Bold')
            doc.setFontSize(10)
            doc.text(`Valor: ${formatBRL(value)}`, pt25, pt75);

            window.open(doc.output('bloburl').toString(), "_blank")
        }
    }

    return (
        <>
            {zoomedIn && (
                <Modal onClick={() => setZoomedIn(false)}>
                    <CloseButton>
                        <Icon icon="cross"/>
                    </CloseButton>
                    <QRCode
                        fgColor={black.text}
                        size={qrcodeFullscreenSize}
                        logoImage={umeLogo}
                        logoHeight={40}
                        logoWidth={40}
                        logoPadding={5}
                        value={qrcode}
                        ecLevel='H'
                    />
                </Modal>
            )}

            <ContentContainer>
                <Title>Solicite ao cliente o escaneamento do QR Code</Title>
                <ZoomOrPrintText>Você pode 
                    <ZoomButton onClick={handleZoomPressed}>ampliar</ZoomButton>em tela cheia ou 
                    <PrintButton onClick={() => printQrCodePressed(id, value)}>imprimir</PrintButton>o QR Code
                </ZoomOrPrintText>
                <Row>
                    <QRCodeContainer>
                        <QRCode
                            id={id}
                            fgColor={black.text}
                            size={270}
                            logoImage={umeLogo}
                            logoHeight={40}
                            logoWidth={40}
                            logoPadding={5}
                            value={qrcode}
                            quietZone={0}
                            ecLevel='H'
                        />
                    </QRCodeContainer>
                    <DetailsContainer>
                        <Text>Valor da compra</Text>
                        <Value>{formatBRL(value)}</Value>
                        <GoBackText onClick={handleGoBack}>Alterar o valor da venda</GoBackText>
                        <Text>Este código deve ser lido pelo aplicativo Ume. Caso o cliente não possua o app, opte por vender identificando via CPF.</Text>

                        <SecondaryButton onClick={handleGoToCashierCapture}>
                            <SecondaryButtonText>Identificar o cliente via CPF</SecondaryButtonText>
                        </SecondaryButton>
                    </DetailsContainer>
                </Row>
                
            </ContentContainer>
        </>
    )
}

export default QRCodeCapture