import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { bffParceirosApiService } from '../../services/bff'

export default class LoggedUserSaverService {
  static async fetchAndSaveUser() {
    try {
      const storeId = LocalStorageWrapper.getItem(LocalStorageItem.STORE)?.id

      const user = await bffParceirosApiService.users.fetchCurrentUser({ storeId })

      LocalStorageWrapper.setItem(LocalStorageItem.LOGGED_USER, user)
    } catch {}
  }
}
