import moment, { Moment } from 'moment';
import TagManager from 'react-gtm-module';

import { getGTMId } from '../../config/config';

interface EventRef {
    date: Moment
}

interface EventProperties {
    value?: number
    durationInMs?: number
    durationElapsedSinceEvent?: string
}

class AnalyticsService {
    private readonly eventRefsMaxSize: number = 50
    private readonly eventRefs: Map<string, EventRef> = new Map<string, EventRef>()

    initalize() {
        const tagManagerArgs = {
            gtmId: getGTMId(),
        }

        TagManager.initialize(tagManagerArgs)
    }

    sendEvent(event: string, properties?: EventProperties) {
        if (this.eventRefs.size >= this.eventRefsMaxSize)
            this.removeFirstEventRef()

        this.eventRefs.set(event, { date: moment() })

        TagManager.dataLayer({
            dataLayer: {
                ...properties,
                event
            },
        });
    }

    sendEventWithDuration(event: string, comparedEvent: string, properties?: EventProperties) {
        const eventProperties = properties ?? {}

        const timeElapsedSinceEvent = this.getTimeElapsedSinceEvent(comparedEvent)
        if (!!timeElapsedSinceEvent) {
            eventProperties.durationInMs = 0
            eventProperties.durationElapsedSinceEvent = comparedEvent
        }

        this.sendEvent(event, eventProperties)
    }

    private getTimeElapsedSinceEvent(event: string): number | undefined {
        const eventRef = this.eventRefs.get(event)

        if (!eventRef)
            return undefined

        return moment().diff(eventRef.date, 'milliseconds')
    }

    private removeFirstEventRef() {
        const firstKey = this.eventRefs.keys().next().value
        this.eventRefs.delete(firstKey)
    }
}

const analyticsService = new AnalyticsService();
export default analyticsService;