import React from 'react';

import loadingAnimation from '../../common/assets/animations/3_dots.json';
import {
    Animation, ContentContainer, InnerContainer, MainContainer, Text
} from './LoadingModal.styles';

interface Props {
    enabled: boolean
    text?: string
}

const LoadingModal: React.FC<Props> = (props) => {
    const { enabled, text } = props

    if (!enabled) return (<></>)

    return (
        <MainContainer>
            <InnerContainer>
                <ContentContainer>
                    <Animation animationData={loadingAnimation} loop={true} />
                    {text && <Text>{text}</Text>}
                </ContentContainer>
            </InnerContainer>
        </MainContainer>
    )
}

export default LoadingModal