import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class CalculatorIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg viewBox="-2.5 -2.5 24 24"  xmlns="http://www.w3.org/2000/svg" >
            <path d="M4.53844 16.3333H6.0769V14.8718H7.53844V13.3333H6.0769V11.8974H4.53844V13.3333H3.10254V14.8718H4.53844V16.3333Z" />
            <path d="M16.6666 14.8718H12.2307V16.4102H16.6666V14.8718Z" />
            <path d="M16.6666 12.5641H12.2307V14.1025H16.6666V12.5641Z" />
            <path d="M20 3.84615C20 1.71795 18.2821 0 16.1538 0H3.84615C1.71795 0 0 1.71795 0 3.84615V16.1538C0 18.2821 1.71795 20 3.84615 20H16.1538C18.2821 20 20 18.2821 20 16.1538V3.84615ZM3.10256 4.79487H7.53846V6.33333H3.10256V4.79487ZM9.23077 18.4615H3.84615C2.5641 18.4615 1.53846 17.4359 1.53846 16.1538V10.7692H9.23077V18.4615ZM18.4615 16.1538C18.4615 17.4359 17.4359 18.4615 16.1538 18.4615H10.7692V10.7692H18.4615V16.1538ZM18.4615 9.23077H10.7692V1.53846H16.1538C17.4359 1.53846 18.4615 2.5641 18.4615 3.84615V9.23077Z" />
            <path d="M15.4871 3.43585L14.4615 4.46149L13.4102 3.43585L12.3333 4.53842L13.3589 5.56406L12.3333 6.5897L13.4102 7.66662L14.4615 6.64098L15.4871 7.66662L16.564 6.5897L15.5384 5.56406L16.564 4.53842L15.4871 3.43585Z" />
        </svg>
      </Icon>
    )
  }
}
