import { Box } from 'grommet'
import * as React from 'react'
import { SectionTextStyle, SectionTitleStyle } from './style'

export interface IOverviewHeadingProps {}

export default class OverviewHeading extends React.PureComponent<IOverviewHeadingProps> {
  public render() {
    return (
      <Box>
        <SectionTitleStyle>Visão geral</SectionTitleStyle>
        <SectionTextStyle>Análise dos dados de acordo com a data selecionada.</SectionTextStyle>
      </Box>
    )
  }
}
