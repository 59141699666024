import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  text?: string
}

const LoadingSpinner = (props: Props): JSX.Element => {
  return (
    <SpinnerContainer>
      <Circle />
      {props.text && <LoadingText>{props.text}</LoadingText>}
    </SpinnerContainer>
  )
}

export default LoadingSpinner

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2147483647;
  flex-direction: column;
  display: flex;
`

const Circle = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 128, 0, 0.2); /* Bordas esverdeadas mais claras */
  border-top: 4px solid #008000; /* Verde escuro para a parte superior */
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite; /* Animação de rotação */
`

const LoadingText = styled.div`
  max-width: 160px;
  margin-top: 16px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`
