import { Box, Chart as GrommetBarChart } from 'grommet'
import styled from 'styled-components'
import { Heading } from '../Heading'
import { Text } from '../Text'

const StyledBarChart = styled(GrommetBarChart)``

const BarChartBoxStyle = styled(Box)`
  overflow-x: auto;
  width: 100%;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`

const YLabelHeadingStyle = styled(Heading)`
  margin-top: 0;
`

const BlackLineBoxStyle = styled(Box)`
  margin-right: 20px;
`
const YAxisLabelsBoxStyle = styled(Box)`
  padding-bottom: 24px;
`

const ValueTextStyle = styled(Text)`
  transform: translateY(-32px);
  bottom: ${props => `${props.positionY}%`};
  position: absolute;
  font-weight: 500;
`

const TopLabelTextStyle = styled(Text)`
  transform: translateY(-46px);
  bottom: ${props => `${props.positionY}%`};
  position: absolute;
  font-size: 10px;
`

const AverageValueHeadingStyle = styled(Heading)`
  margin: 0;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 0;
  right: 0;
`

export {
  BarChartBoxStyle,
  StyledBarChart,
  BlackLineBoxStyle,
  AverageValueHeadingStyle,
  ValueTextStyle,
  YAxisLabelsBoxStyle,
  YLabelHeadingStyle,
  TopLabelTextStyle,
}
