import React, { HTMLProps } from 'react'
import styled, { css } from 'styled-components'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

interface CommunicationChannelPickerProps extends HTMLProps<HTMLDivElement> {
  onDropDownSelect?: (option?: number) => void
  // TODO - Set as react component
  selectOptions?: string[]
  disabled?: boolean
  className?: string
  selectedIndex?: number
  isLoading?: boolean
  isValidated?: boolean
}

export const CommunicationChannelPicker = (props: CommunicationChannelPickerProps) => {
  let { selectOptions, disabled, className, selectedIndex, isLoading, isValidated, ...rest } = props

  const handleDropDownSelect = (event: any) => {
    if (props.onDropDownSelect) {
      props.onDropDownSelect(event.target.value)
    }
  }

  // TODO - Loading when props select is null
  return (
    <PickerContainer disabled={disabled} className={className} {...rest}>
      <PickerSelect disabled={disabled} onChange={handleDropDownSelect} value={selectedIndex}>
        <option key="default" value="default">
          Selecione abaixo
        </option>
        {selectOptions &&
          selectOptions.map((value: any, index: number) => (
            <option key={index} value={index}>
              {value}
            </option>
          ))}
      </PickerSelect>
    </PickerContainer>
  )
}

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 400px;
  max-height: 40px;

  ${(props: any) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}

  &:nth-child(1) {
    margin-right: 5vw;
  }
`

const PickerSelect = styled.select`
  flex: 1 1 210px;
  padding: 12px 15px 12px 15px;

  border: 0;
  border-bottom: 1px solid #c2c2c2;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in design kit
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.10px;
  text-align: left;
  color: #92929D;
 

  ${(props: any) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    // Select Image
    background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .95em auto, 100%;
  }
`
