import React from 'react'
import styled from 'styled-components'

import { green, gray, white, blue } from '../../ui/utils/_colors'
import { ReactComponent as ScheduleIcon } from '../../common/assets/images/schedule.svg'
import { Loading } from '../../ui'

export default function CashierSendLimitButtonText({
  isLoading,
  countdown,
}: {
  isLoading: boolean
  countdown: number
}) {
  if (isLoading) {
    return (
      <GroupTextLoading>
        <TextSendingSms>Enviando SMS</TextSendingSms>
        <Loading color={green.primary} />
      </GroupTextLoading>
    )
  } else if (countdown > 0) {
    return (
      <GroupTextClock>
        <TextAvailableIn>Disponível em</TextAvailableIn>
        <TagClock>
          <ScheduleIcon />
          <span>{countdown} segundos</span>
        </TagClock>
      </GroupTextClock>
    )
  } else {
    return (
      <GroupTextClock style={{ opacity: 0, backgroundColor: 'transparent' }}>
        <TextAvailableIn>off</TextAvailableIn>
        <TagClock>
          <ScheduleIcon opacity={0} />
          <span>{countdown} segundos</span>
        </TagClock>
      </GroupTextClock>
    )
  }
}

const GroupTextLoading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 230px;
`

const TextSendingSms = styled.span`
  font-family: 'Roboto', 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${gray.light};
`

const GroupTextClock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
`

const TextAvailableIn = styled.span`
  font-family: 'Roboto', 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${blue.dark};
`

const TagClock = styled.div`
  width: 109px;
  padding: 5px;
  background-color: ${white.light};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: ${gray.nobel};
  }
`
