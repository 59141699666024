import { Button, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ApplicationSliceReducer from '../../../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../../../redux/reducers/selectors'
import NumberField from '../../../../molecules/Application/NumberField'

const ApplicationPhoneConfirmationModal = () => {
  const dispatch = useDispatch()

  const {
    phoneNumber,
    isPhoneConfirmationModalVisible,
  } = useTypedSelector(state => ({
    phoneNumber: state.application.phoneNumber,
    isPhoneConfirmationModalVisible: state.application.isPhoneConfirmationModalVisible,
  }))

  const [phoneConfirmed, setPhoneConfirmationFlag] = useState<boolean>(false)

  const handleCheckBoxClick = () => {
    setPhoneConfirmationFlag(!phoneConfirmed)
  }

  const handleBackButton = () => {
    dispatch(ApplicationSliceReducer.actions.setPhoneConfirmationModal(false))
  }

  const handleNextButton = () => {
    dispatch(ApplicationSliceReducer.actions.setPhoneConfirmationModal(false))
    dispatch(ApplicationSliceReducer.actions.nextPage())
  }

  return (
    <Modal
      style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
      visible={isPhoneConfirmationModalVisible}
      footer={null}
      title={null}
      onCancel={handleBackButton}
    >
      <ModalContainer>
        <TitleContainer>
            <Title> Atenção! </Title>
            <SubTitle> Confirme o telefone</SubTitle>
        </TitleContainer>
        <SmallText>
            Após a confirmação, a troca de número <strong>só poderá ser feita pelo canal de atendimento</strong>
        </SmallText>
        <PhoneInputContainer>
          <NumberField
            autoFocus
            defaultValue={phoneNumber}
            value={phoneNumber}
            placeholder="(92) 90000-0000"
            format="(##) #####-####"
            type="tel"
            mask="_"
            isValidated={true}
            disabled={true}
            error={false}
            style={{ marginTop: '20px', alignSelf: 'center', width: '100%'}}
          />
        </PhoneInputContainer>
        <CheckboxContainer onClick={handleCheckBoxClick}>
            <Checkbox
                type="checkbox"
                checked={phoneConfirmed}
                onChange={handleCheckBoxClick}
            />
            <Label>
                Confirmei o número com o cliente
            </Label>
        </CheckboxContainer>
        <Footer>
          <BackButton onClick={handleBackButton}> Voltar </BackButton>
          <NextButton disabled={!phoneConfirmed} onClick={handleNextButton}>
            Próximo
          </NextButton>
        </Footer>
      </ModalContainer>
    </Modal>
  )
}

export default ApplicationPhoneConfirmationModal

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const PhoneInputContainer = styled.div` 
  width: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;
  color: #000000;
`

const SubTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;

  margin-top: 3%;

  letter-spacing: 0.18px;
  color: #000000;
`

const SmallText = styled.span`
  margin-top: 1vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props: any) => (props && props.bold ? '500' : '')};
  font-size: 14px;
  line-height: 22px;

  margin-top: 5%;

  color: #000000;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  margin-top: 5%;

  align-items: center;

  cursor: default;
`

const Checkbox = styled.input`
`

const Label = styled.span`
  margin-left: 5%;

  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-top: 5%;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048 !important;

  // BUTTON
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #11b048 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  width: 90% !important;
  height: 100% !important;
`

const NextButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 90% !important;
  height: 100% !important;
  margin-left: 6vw !important;

  ${(props: any) =>
    props && props.disabled
      ? 'opacity: 0.3;'
      : ''}
`
