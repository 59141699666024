import { Button, Card, Elevation, InputGroup, Intent } from '@blueprintjs/core'
import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { toaster } from '../App'
import { Role } from '../common/constants'
import { operatorsSetupActive } from '../config/config'
import { bffParceirosApiService } from '../services/bff'
import { formContainerStyle, formItemsContainerStyle, formItemStyle, h5Style } from './styles'

export const errTypes = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
}
export interface HomeRouterProps {
  title?: string
  history?: any
  location?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export interface LoginState {
  username: string
  password: string
  submitted: boolean
  showPassword: boolean
  loading: boolean
  error?: string
  redirectToReferrer: boolean
}

export default class Login extends Component<HomeRouterProps, LoginState> {
  public state: Readonly<LoginState> = {
    username: '',
    password: '',
    submitted: false,
    loading: false,
    showPassword: false,
    error: '',
    redirectToReferrer: false,
  }

  public handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      this.handleSubmit()
    }
  }

  private handleLockClick = () => this.setState({ showPassword: !this.state.showPassword })

  public onusernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ error: '', submitted: false, username: e.target.value })
  }

  public onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ error: '', submitted: false, password: e.target.value })
  }

  public handleSubmit = async () => {
    this.setState({ submitted: true })
    const { username, password } = this.state

    // stop here if form is invalid
    if (!username) {
      toaster.showErrorToast('E-mail é obrigatório')
      return
    }

    // stop here if form is invalid
    if (!password) {
      toaster.showErrorToast('Senha é obrigatória')
      return
    }

    this.setState({ loading: true })

    try {
      await bffParceirosApiService.auth.login({
        username,
        password,
      })

      const user = await bffParceirosApiService.auth.decodeJwt()

      if (operatorsSetupActive) {
        const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)

        if (!operator.active) {
          this.props.handleUserLogged()
          toaster.showErrorToast('Usuário bloqueado')
          this.setState({
            loading: false,
            error: 'user blocked',
            submitted: false,
          })
          bffParceirosApiService.auth.logout()
        }
      }

      this.setState({ redirectToReferrer: true, loading: false })
      this.props.handleUserLogged(user)
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
        submitted: false,
      })

      if (error.message === 'UNAUTHORIZED') {
        toaster.showErrorToast('Usuário não cadastrado ou senha incorreta')
      }

      this.props.handleUserLogged()
    }
  }

  public render() {
    const { username, password, submitted, showPassword, loading } = this.state
    const user = bffParceirosApiService.auth.getUser()

    if (bffParceirosApiService.auth.isLogged() && user) {
      let pathname = '/'
      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (user.role === Role.MANAGER && !isStoreSetUp) {
        pathname = '/configuracao'
      } else if ((user.role === Role.CASHIER || user.role === Role.MANAGER) && isStoreSetUp) {
        pathname = '/caixa'
      } else if (user.role === Role.REPRESENTATIVE || user.role === Role.ATTENDANT || user.role === Role.PROMOTER) {
        pathname = '/aquisicao'
      } else {
        pathname = '/'
      }
      const { from } = { from: { pathname } }

      return <Redirect to={from} />
    }

    const lockButton = (
      <Button
        icon={showPassword ? 'unlock' : 'lock'}
        intent={Intent.WARNING}
        style={formItemStyle}
        minimal={true}
        onClick={this.handleLockClick}
      />
    )

    return (
      <div style={formContainerStyle}>
        <div>
          <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
            <h1>Portal do Varejista</h1>
            <h5 style={h5Style} className="bp3-heading">
              {'Login'}
            </h5>
            <InputGroup
              style={{ ...formItemStyle, marginTop: '0.5em' }}
              placeholder="E-mail"
              autoFocus={true}
              type="username"
              onKeyDown={this.handleKeyPress}
              intent={submitted && !username ? Intent.DANGER : Intent.NONE}
              value={username}
              onChange={this.onusernameChange}
            />

            <InputGroup
              placeholder="Senha"
              style={formItemStyle}
              intent={submitted && !password ? Intent.DANGER : Intent.NONE}
              value={password}
              onKeyDown={this.handleKeyPress}
              onChange={this.onPasswordChange}
              rightElement={lockButton}
              type={showPassword ? 'text' : 'password'}
            />
            {/* <a
              style={{
                textAlign: 'right',
                marginTop: '4px',
              }}
              href={'/senha'}
            >
              {'esqueci minha senha'}
            </a> */}
            <Button
              style={formItemStyle}
              intent="primary"
              onClick={this.handleSubmit}
              rightIcon="log-in"
              loading={loading}
              disabled={loading}
            >
              {'Entrar'}
            </Button>
          </Card>
        </div>
      </div>
    )
  }
}
