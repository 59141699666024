import imageCompression from 'browser-image-compression'

export interface ImageCompressionOptions {
  maxSizeMB: number
}

export default class FileUtils {
  static async toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result?.toString() || '')
      reader.onerror = error => reject(error)
    })
  }

  static async compressImage(file: File, compressionOptions: ImageCompressionOptions): Promise<File> {
    return imageCompression(file, compressionOptions)
  }
}
