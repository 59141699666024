import { AnchorProps } from 'grommet'
import React from 'react'
import { StyledAnchor } from './styles'

interface StyledAnchorProps extends AnchorProps {
  bold?: boolean
  fontSize?: string
  fontFamily?: string
}

export default class Anchor extends React.Component<StyledAnchorProps & Omit<JSX.IntrinsicElements['a'], 'color'>> {
  render() {
    return <StyledAnchor {...this.props}>{this.props.children}</StyledAnchor>
  }
}