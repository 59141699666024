import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { white } from '../../../ui/utils/_colors'

export interface Product {
  name: string
  price: number
  downPayment?: number
  priceMinusDownPayment?: number
}

export type Layout = 'responsive' | 'single-column'
interface ProductListProps {
  products: Product[]
  onProductSelect?: (product: Product) => void
  canSelectItems?: boolean
  layout?: Layout
}

export const ProductsList: React.FC<ProductListProps> = (props: ProductListProps) => {
  const { canSelectItems, layout = 'responsive' } = props
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const handleSelectProduct = (product: Product) => {
    setSelectedProduct(product)

    if (props.onProductSelect) {
      props.onProductSelect(product)
    }
  }

  const ProductCard: React.FC<{ product: Product }> = ({ product }) => (
    <Card>
      <ListContainer>
        {canSelectItems ? (
          <InputRadioContainer>
            <InputRadio
              id="selectProduct"
              name="products"
              type="radio"
              checked={_.isEqual(selectedProduct, product)}
              onChange={() => handleSelectProduct(product)}
            />
          </InputRadioContainer>
        ) : null}
        <div>
          <ProductTitle>{product.name}</ProductTitle>
          <Price>{formatBRL(product.price)}</Price>
          {product.downPayment ? <DownPayment>Entrada de {formatBRL(product.downPayment)}</DownPayment> : null}
        </div>
      </ListContainer>
    </Card>
  )

  const ProductList: React.FC<{ products: Product[] }> = (props: { products: Product[] }) => (
    <List layout={layout}>
      {props.products.map((product, index) => (
        <ProductCard key={index} product={product} />
      ))}
    </List>
  )

  return <ProductList products={props.products} />
}

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  gap: 16px;
`

const Card = styled.div`
  border: 1px solid #CDD6DD
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;

  @media (max-width: 480px) {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 16px 16px 16px 0px;
  }
  `
const List = styled.div<{ layout: Layout }>`
  display: grid;
  ${({ layout }: { layout: Layout }) => {
    if (layout == 'responsive') {
      return `
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  `
    } else if (layout == 'single-column') {
      return `
    grid-template-columns: 1fr;
  `
    }
  }}
`

const ProductTitle = styled.h3`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: left;
`

const Price = styled.p`
  color: #182026;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 8px;
`

const DownPayment = styled.p`
  margin-bottom: 0px;
  display: inline-block;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
  opacity: 0px;
  background: #4a76cb;
  color: ${white.primary};
  font-size: 12px;
  text-align: left;
`

const InputRadio = styled.input`
  width: 18px;
  height: 18px;
  accent-color: green;
`

const InputRadioContainer = styled.div`
  width: 18px;
  height: 18px;
`
