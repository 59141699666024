import React, { CSSProperties } from 'react'
import { black, gray, green, white } from '../../ui/utils/_colors'
import { formatBRL } from '../common/formatBRL'

interface Props {
  valueToPay: number
  hasSelectedRows: boolean
  hasFees?: boolean
}

const setCardStyle = (hasSelectedRow: boolean, hasFees: boolean | undefined) => {
  const cardStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '25rem',
    height: '4.75rem',
    padding: '0 1rem',
    backgroundColor: gray.silver,
    border: `1px solid ${black.primary}`,
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    color: black.primary,
  }
  if (hasSelectedRow) {
    return {
      ...cardStyle,
      backgroundColor: green.primary300,
      border: `1px solid ${green.primary300}`,
      color: white.primary,
    }
  } else if (hasFees) {
    return {
      ...cardStyle,
      backgroundColor: green.lightMint,
      border: `1px solid ${green.primary300}`,
      color: green.primary600,
    }
  }
  return cardStyle
}

export const PaymentResumeCard = ({ valueToPay, hasSelectedRows, hasFees }: Props) => {
  return (
    <div style={setCardStyle(hasSelectedRows, hasFees)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.6rem',
          height: '1.8rem',
        }}
      >
        {(hasSelectedRows || !hasFees) && <p>Total a pagar </p>}
        {hasFees && !hasSelectedRows && (
          <div
            style={{
              width: '70%',
              fontWeight: 500,
              fontSize: '0.9rem',
              textAlign: 'left',
            }}
          >
            Taxa sobre compras sem juros está disponível para pagamento
          </div>
        )}
        <div style={{ color: hasFees && !hasSelectedRows ? green.primary900 : 'inherit' }}>{formatBRL(valueToPay)}</div>
      </div>
      {hasFees && hasSelectedRows && (
        <div
          style={{
            fontWeight: 400,
            fontSize: '0.9rem',
            textAlign: 'left',
            marginBottom: '0.6rem',
          }}
        >
          Taxa de utilização inclusa*
        </div>
      )}
    </div>
  )
}
