/* eslint-disable react/display-name */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { history } from '../../App';
import {
  ReactComponent as BudgetError
} from '../../common/assets/images/budget-error.svg';
import {
  ReactComponent as Error404
} from '../../common/assets/images/error-404.svg';
import {
  ReactComponent as ErrorWithoutCommunication
} from '../../common/assets/images/error-without-communication.svg';
import {
  BigButton,
  Bold,
  Text as BudgetText
} from '../../molecules/Budget/';
import { CancelButton } from '../../molecules/Budget/CancelButton';
import BudgetReducer from '../../redux/reducers/budget/budget.reducer';
import { useTypedSelector } from '../../redux/reducers/selectors';

const withErrors = (Component: React.ComponentType<object>) => (props: object): JSX.Element => {
  const dispatch = useDispatch();
  const { cpf, value } = useTypedSelector((state) => state.budget);

  const retry = () => {
    dispatch(BudgetReducer.actions.createBudget({ cpf, value }));
  }

  const budgetError = useTypedSelector((state) => state.budget.budgetError);

  const renderServerError = (
    title: string,
    svgIcon: JSX.Element,
    text: string
  ): JSX.Element => {
    return <Container>
      <CancelWrapper><CancelButton dispatch={dispatch} text='Voltar' /></CancelWrapper>

      <TitleWrapper>
        <ServerErrorTitle>Ops...</ServerErrorTitle>
        <ServerErrorTitle>{title}</ServerErrorTitle>
      </TitleWrapper>

      {svgIcon}

      <Text>{text}</Text>

      <BigButton onClick={retry}>Tentar Novamente</BigButton>
    </Container>;
  }

  const handleMakeNewBudgetClick = () => {
    dispatch(BudgetReducer.actions.resetRequest());
  };

  const renderBudgetError = (
    title: JSX.Element,
    text?: string
  ): JSX.Element => {
    return <Container>
      {title}

      <BudgetError style={{ alignSelf: 'center' }} />

      {text && <Text>{text}</Text>}

      <Footer>
        <MakeNewBugetButton onClick={handleMakeNewBudgetClick}>
          Fazer novo orçamento
        </MakeNewBugetButton>

        <BudgetText
          color='#0E2DE6'
          onClick={() => history.push('/applications')}
        >
          Fazer cadastro Ume
        </BudgetText>
      </Footer>
    </Container>;
  }

  const NO_ERROR = undefined;
  switch (budgetError) {
    case 'HTTP_ERROR':
    case 'REQUEST_ERROR':
    case 'UNKNOWN_ERROR':
      return renderServerError(
        'algo deu errado',
        <Error404 />,
        'Um erro inesperado aconteceu. Por favor, tente novamente em alguns instantes.'
      )
    case 'CONNECTION_ERROR':
      return renderServerError(
        'sem comunicação',
        <ErrorWithoutCommunication />,
        'Procure um local com sinal de telefone melhor ou conecte-se a uma rede wifi para maior estabilidade.'
      )
    case 'CPF_NOT_FOUND':
    case 'LIMIT_NOT_FOUND':
      return renderBudgetError(
        <BudgetErrorTitle>
          Cadastro ume <Bold>não encontrado...</Bold>
        </BudgetErrorTitle>
      )
    case 'OPEN_RENEGOTIATIONS':
      return renderBudgetError(
        <BudgetErrorTitle style={{ color: '#E10000' }}>
          O CPF possui <Bold>renegociações em aberto</Bold>
        </BudgetErrorTitle >,
        'Quite o acordo para voltar a usar o crédito ume.'
      )
    case 'OVERDUE_INSTALLMENTS':
      return renderBudgetError(
        <BudgetErrorTitle style={{ color: '#E10000' }}>
          O CPF possui <Bold>parcelas atrasadas</Bold>
        </BudgetErrorTitle >,
        'Pague os atrasos para usar a Ume.'
      )
    case 'LIMIT_IS_BLOCKED':
      return renderBudgetError(
        <BudgetErrorTitle>
          CPF bloqueado <Bold>procure o atendimento</Bold>
        </BudgetErrorTitle >
      )
    case 'NOT_APPROVED':
      return renderBudgetError(
        <BudgetErrorTitle>
          O cadastro desse <Bold>CPF está negado...</Bold>
        </BudgetErrorTitle >
      )
    case 'MANUAL_EVALUATION':
      return renderBudgetError(
        <BudgetErrorTitle>
          Aguarde, este CPF está sendo analisado manualmente.
        </BudgetErrorTitle >
      )
    case NO_ERROR:
      return <Component {...props} />

    default:
      return renderServerError(
        'algo deu errado',
        <Error404 />,
        'Um erro inesperado aconteceu. Por favor, tente novamente em alguns instantes.'
      )
  }
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

const CancelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Footer = styled.div`
  display: flex;
  height: 20%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  margin: 0;
`;

const ServerErrorTitle = styled(Title)`
  font-size: 34px;
  line-height: 36px;
  text-align: center;
`;

const BudgetErrorTitle = styled(Title)`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.18px;
`;

const MakeNewBugetButton = styled(BigButton).attrs({
  backgroundColor: '#3350FF'
})`
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export default withErrors;
