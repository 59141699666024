import * as React from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ReactComponent as BudgetIncreaseCelebration
} from '../../../common/assets/images/budget-increase-celebration.svg';
import {
  ReactComponent as TimeManagement
} from '../../../common/assets/images/time-management.svg';
import BudgetBlackCampaign from '../../../common/assets/utils/budgetBlackCampaign';
import FeatureFlags from '../../../common/assets/utils/featureFlags';
import { Bold, Title } from '../../../molecules/Budget';
import { BodyText } from '../../../molecules/Budget/BodyText';
import { CancelButton } from '../../../molecules/Budget/CancelButton';
import { FooterButtons } from '../../../molecules/Budget/FooterButtons';
import { goToPage } from '../../../molecules/Budget/utils';
import { BUDGET_PAGES } from './budget-utils';


const BudgetIncreaseLimitOnboarding = (): JSX.Element => {
  const dispatch = useDispatch();

  const FIRST_SLIDE = 0;
  const SECOND_SLIDE = 1;
  const [activeIndex, setActiveIndex] = React.useState<number>(FIRST_SLIDE)

  const renderCarouselItem = (
    title: string,
    subtitle: JSX.Element,
    image: JSX.Element,
    text: JSX.Element
  ): JSX.Element => {
    return (
      <Carousel.Item style={{ height: '100%' }}>
        <ItemWrapper>
          <div>
            <Title>
              <Bold>{title}</Bold>
            </Title>
            <Title>
              {subtitle}
            </Title>
          </div>

          {image}

          {text}
        </ItemWrapper>
      </Carousel.Item>
    );
  };

  const handleNexButtonClick = (): void => {
    if (activeIndex === FIRST_SLIDE) {
      setActiveIndex(SECOND_SLIDE)
    } else {
      goToPage(dispatch, BUDGET_PAGES.BUDGET_RESPONSE_PAGE)();
    }
  }

  const handleBackButtonClick = (): void => {
    if (activeIndex === FIRST_SLIDE) {
      goToPage(dispatch, BUDGET_PAGES.BUDGET_REQUEST_FORM_PAGE)()
    } else {
      setActiveIndex(FIRST_SLIDE)
    }
  }

  return (
    <>
      <CancelButton dispatch={dispatch} />

      <Carousel
        activeIndex={activeIndex}
        onSlide={setActiveIndex}
      >
        {renderCarouselItem(
          'Uhul! 🎉',
          <>Conseguimos o aumento de limite</>,
          <BudgetIncreaseCelebration style={{ alignSelf: 'center' }} />,
          <BodyText>
            O aumento está disponível <Bold>apenas <GreenText>nesse varejo Ume.</GreenText></Bold>
          </BodyText>
        )}
        {renderCarouselItem(
          'Não perca tempo!',
          FeatureFlags.isBudgetBlackCampaignActive() 
          ? <>O aumento está disponível <Bold><GreenText>até {BudgetBlackCampaign.campaignExpirationDate()} às 23:59</GreenText></Bold></> 
          : <>O aumento está disponível <Bold><GreenText>apenas hoje até às 23:59</GreenText></Bold></>,
          <TimeManagement style={{ alignSelf: 'center' }} />,
          <BodyText style={{ textAlign: 'center' }}>
            <Bold>Confira o orçamento</Bold>
          </BodyText>
        )}
      </Carousel>

      <FooterButtons
        onBackButtonClick={handleBackButtonClick}
        onNextButtonClick={handleNexButtonClick}
      />
    </>
  );
};

const GreenText = styled.span`
  color: #11B048;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Carousel = styled(BootstrapCarousel).attrs({
  interval: null,
  controls: false,
  autoFocus: true
})`
  justify-content: space-between;
  flex: 1;
  margin-bottom: 30px;
`;

export default BudgetIncreaseLimitOnboarding;
