import { IOriginationFirstPurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-first-purchase'
import { IOriginationRecurrencePurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-recurrence-purchase'
import { Box } from 'grommet'
import React, { PureComponent } from 'react'
import { numberToCurrency } from '../../../../../common/NumberToCurrency'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  CardTitleHeadingStyle,
  GreenCurrencyHeadingStyle,
  SmallCardStyle,
  SmallGreenValueHeadingStyle,
  TextStyle,
} from './style'

interface IOriginationsSumCardProps {
  originationsFirstPurchase?: IOriginationFirstPurchaseResponse | Error
  originationsRecurrencePurchase?: IOriginationRecurrencePurchaseResponse | Error
}

export default class OriginationsSumCard extends PureComponent<IOriginationsSumCardProps> {
  render() {
    const { originationsFirstPurchase, originationsRecurrencePurchase } = this.props

    const firstPurchaseCardBox =
      originationsFirstPurchase instanceof Error || originationsRecurrencePurchase instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        originationsFirstPurchase &&
        originationsRecurrencePurchase && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Total de compras</CardTitleHeadingStyle>
              <Box>
                <Box direction="row" align="end" gap="xsmall">
                  <GreenCurrencyHeadingStyle>{'R$ '}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(
                      originationsFirstPurchase.firstPurchaseSum + originationsRecurrencePurchase.recurrenceSum
                    )}
                  </SmallGreenValueHeadingStyle>
                </Box>
              </Box>
              <TextStyle>Primeira compra + recorrência </TextStyle>
            </Box>

            <Box className="is-mobile" direction="row" gap="xsmall" fill align="center" justify="between">
              <CardTitleHeadingStyle>Total de compras</CardTitleHeadingStyle>
              <Box>
                <Box direction="row" align="center" gap="xsmall">
                  <GreenCurrencyHeadingStyle>{'R$'}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(
                      originationsFirstPurchase.firstPurchaseSum + originationsRecurrencePurchase.recurrenceSum
                    )}
                  </SmallGreenValueHeadingStyle>
                </Box>
                <Box>
                  <TextStyle>Primeira compra + recorrência </TextStyle>
                </Box>
              </Box>
            </Box>
          </>
        )
      )
    return (
      <SmallCardStyle>
        {originationsFirstPurchase && originationsRecurrencePurchase ? (
          firstPurchaseCardBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </SmallCardStyle>
    )
  }
}
