import { Icon } from '@blueprintjs/core'
import { Box } from 'grommet'
import styled from 'styled-components'
import { mobile } from '../../../../common/assets/utils/_breakpoints'
import { Card, Text } from '../../../../legacy-lib/design-kit-ui'

export const LoginBox = styled(Box)`
  margin-top: 130px;
`
export const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobile}) {
    border: none;
  }
`
export const ForgotPassword = styled(Box)`
  align-self: center;
  background-color: #f4f4f4;
  width: 280px;
  height: 100px;
  padding: 16px;
  border-radius: 8px;
  gap: 10px;
`

export const MessageIcon = styled(Icon)`
  margin-right: 6px;
`

export const Message = styled(Text)`
  font-size: 13px;
  font-weight: ${(props: any) => (props.bold ? '500' : '400')};
  line-height: 20px;
`

export const Link = styled.a`
  font-size: 13px;
  line-height: 20px;
  text-decoration: underline;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
