import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toaster } from '../../App'
import Loading from '../../atoms/Loading/Loading'
import ApplicationPhoneModal from '../../organisms/Application/ApplicationPhoneModal'
import { APPLICATION_PAGES } from '../../organisms/Application/ApplicationSteps/application-utils'
import ApplicationBiometryStep from '../../organisms/Application/ApplicationSteps/ApplicationBiometryStep'
import { ApplicationBorrowerDisabledToPurchasePage } from '../../organisms/Application/ApplicationSteps/ApplicationBorrowerDisabledToPurchasePage'
import ApplicationBorrowerStep from '../../organisms/Application/ApplicationSteps/ApplicationBorrowerStep'
import ApplicationChooseDueDayStep from '../../organisms/Application/ApplicationSteps/ApplicationChooseDueDayStep'
import ApplicationCpfStep from '../../organisms/Application/ApplicationSteps/ApplicationCpfStep'
import ApplicationLoading from '../../organisms/Application/ApplicationSteps/ApplicationLoadingStep'
import ApplicationPreApprovedPage from '../../organisms/Application/ApplicationSteps/ApplicationPreApprovedStep'
import ApplicationPhoneStep from '../../organisms/Application/ApplicationSteps/PhoneStep/ApplicationPhoneStep'
import PhoneVerificationStep from '../../organisms/Application/ApplicationSteps/PhoneVerificationStep'
import SendVerificationCodeStep from '../../organisms/Application/ApplicationSteps/SendVerificationCodeStep'
import ApplicationAlreadyApprovedPage from '../../organisms/Application/ProcessEndPages/ApplicationAlreadyApprovedPage'
import ApplicationApprovedPage from '../../organisms/Application/ProcessEndPages/ApplicationApprovedPage'
import ApplicationWaitingManualApprovalPage from '../../organisms/Application/ProcessEndPages/ApplicationWaitingManualApprovalPage'
import BorrowerRestrictionPage from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerRestriction'
import ConfigurationsSliceReducer from '../../redux/reducers/configurations/configurations.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE } from '../OperatorServiceCenter/enums/operator-service-center-restrictions.enum'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#11B048',
    },
  },
})

interface Props {
  match: {
    params: {
      cpf: string
    }
  }
}

const ApplicationPage = (props: Props) => {
  const dispatch = useDispatch()
  const { page, pagesOrder, loadingConfigurations, hasFetchedConfigurations } = useTypedSelector(state => ({
    page: state.application.page,
    pagesOrder: state.application.pagesOrder,
    loadingConfigurations: state.configurations.isLoadingConfigurations,
    hasFetchedConfigurations: state.configurations.hasFetchedConfigurations,
    borrowerRestriction: state.operatorServiceCenter.borrowerRestriction,
  }))

  useEffect(() => {
    if (!hasFetchedConfigurations) {
      loadConfigurations()
    }
  }, [])

  const loadConfigurations = () => {
    dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))
  }

  const renderPage = () => {
    if (page >= pagesOrder.length || page < 0) {
      toaster.showErrorToast(`Página inexistente`)
      return <h1> Essa página não existe {page}</h1>
    }

    if (loadingConfigurations) {
      return <Loading />
    }

    const currentPage = pagesOrder[page]
    switch (currentPage) {
      case APPLICATION_PAGES.CPF_PAGE: {
        return <ApplicationCpfStep cpf={props.match.params.cpf} />
      }
      case APPLICATION_PAGES.PHONE_PAGE: {
        return <ApplicationPhoneStep />
      }
      case APPLICATION_PAGES.BORROWER_PAGE: {
        return <ApplicationBorrowerStep />
      }
      case APPLICATION_PAGES.BIOMETRY_PAGE: {
        return <ApplicationBiometryStep />
      }
      case APPLICATION_PAGES.PRE_DENIED_PAGE: {
        return (
          <PhoneLayout>
            <BorrowerRestrictionPage restriction={OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_DENIED} />
          </PhoneLayout>
        )
      }
      case APPLICATION_PAGES.DENIED_PAGE: {
        return (
          <PhoneLayout>
            <BorrowerRestrictionPage restriction={OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_DENIED} />
          </PhoneLayout>
        )
      }
      case APPLICATION_PAGES.ALREADY_APPROVED_PAGE: {
        return <ApplicationAlreadyApprovedPage />
      }
      case APPLICATION_PAGES.PRE_APPROVED_PAGE: {
        return <ApplicationPreApprovedPage />
      }
      case APPLICATION_PAGES.APPROVED_PAGE: {
        return <ApplicationApprovedPage />
      }
      case APPLICATION_PAGES.IN_PROGRESS_PAGE: {
        return <ApplicationLoading />
      }
      case APPLICATION_PAGES.WAITING_MANUAL_APPROVAL_PAGE: {
        return <ApplicationWaitingManualApprovalPage />
      }
      case APPLICATION_PAGES.SEND_VERIFICATION_CODE_PAGE: {
        return <SendVerificationCodeStep />
      }
      case APPLICATION_PAGES.PHONE_VERIFICATION_PAGE: {
        return <PhoneVerificationStep />
      }
      case APPLICATION_PAGES.CHOOSE_BILL_DUE_DAY_PAGE: {
        return <ApplicationChooseDueDayStep />
      }
      case APPLICATION_PAGES.BIOMETRY_CONFLICT_PAGE: {
        return (
          <PhoneLayout>
            <BorrowerRestrictionPage restriction={OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_MANUAL_ANALYSIS} />
          </PhoneLayout>
        )
      }
      case APPLICATION_PAGES.BORROWER_DISABLED_TO_PURCHASE_PAGE: {
        return <ApplicationBorrowerDisabledToPurchasePage />
      }
      default:
        return <h1>Essa página não existe {page}</h1>
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        {renderPage()}
        <ApplicationPhoneModal />
      </ThemeProvider>
    </>
  )
}

export default ApplicationPage
