import { Box } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../../../common/assets/utils/_breakpoints'
import { Card, Heading, Text } from '../../../../legacy-lib/design-kit-ui'
import { Sort } from '../../../../ui/Icons'

export const ApplicationsByStoreBoxStyle = styled(Box)`
  width: 100%;
  max-width: 884px;
  padding-bottom: 20px;
`

export const SectionTitleStyle = styled(Heading)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 47px;

  @media (min-width: ${tablet}) {
    margin-bottom: 12px;
    margin-top: 16px;
    font-size: 32px;
  }
`

export const TextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '16px')};
  font-weight: ${(props: any) => (props.bold ? '500' : '300')};
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 24px;
  margin-right: 20px;
`

export const BigCardStyle = styled(Card)`
  border: none;
  height: 396px;
  padding: 30px 0 0 0;
  margin: 0;
  display: flex;
  box-shadow: none;
  background-color: transparent;

  @media (min-width: ${tablet}) {
    width: 100%;
    padding: 30px;
    background-color: white;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  }
`

export const IconCardStyle = styled(Card)`
  border: none;
  padding: 10px;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 16px;
`

export const SortRightIconStyled = styled(Sort)`
  transform: scaleX(-1) rotate(-90deg);
`

export const SortLeftIconStyled = styled(Sort)`
  transform: rotate(-90deg);
`
