import { Button, Card, Classes, Dialog, H4 } from '@blueprintjs/core'
import React, { CSSProperties } from 'react'
import { black } from '../../ui/utils/_colors'
import { formatBRL } from '../common/formatBRL'

interface Props {
  shouldShowPaymentOverlay: boolean
  hasFees: boolean
  numberOfInstallments: number
  totalValue: number
  confirmPayment: () => Promise<void>
  closeOverlay: () => void
}

const textStyle: CSSProperties = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontSize: '1.125rem',
  fontWeight: 350,
  lineHeight: '100%',
  letterSpacing: '0.18px',
  color: black.primary,
}

export const ConfirmPaymentDialog = (props: Props) => {
  const { shouldShowPaymentOverlay, hasFees, numberOfInstallments, totalValue, confirmPayment, closeOverlay } = props
  const installmentsDisplayText = `${numberOfInstallments} parcela${numberOfInstallments > 1 ? 's' : ''}`

  return (
    <Dialog
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      hasBackdrop
      isOpen={shouldShowPaymentOverlay}
      onClose={closeOverlay}
      style={{
        padding: 0,
        width: '60vw',
      }}
    >
      <Card
        style={{
          padding: '32px',
          width: '100%',
          height: '100%',
          display: 'grid',
          gap: '2rem',
        }}
        elevation={3}
      >
        <div style={textStyle}>
          <p style={{ fontWeight: 700 }}>Confirmar pagamento: {!hasFees && installmentsDisplayText} </p>

          {hasFees && <p>{installmentsDisplayText} + Taxa de utilização</p>}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <H4>Total de {formatBRL(totalValue)}</H4>
          <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <Button large rightIcon="tick" intent="success" text="Sim" tabIndex={1} onClick={confirmPayment} />

            <Button large rightIcon="cross" intent="danger" text="Não" tabIndex={1} onClick={closeOverlay} />
          </div>
        </div>
      </Card>
    </Dialog>
  )
}
