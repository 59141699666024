import { ImageLabel } from '../../../services/bff/datalake-acesso/dto/Image.dto'

export const DOCUMENT_FRONT_OPTIONS = [
  { label: 'RG', type: ImageLabel.RG_FRONT },
  { label: 'RG Digital', type: ImageLabel.DIGITAL_RG_FRONT },
  { label: 'CNH', type: ImageLabel.CNH_FRONT },
  { label: 'Nova CNH', type: ImageLabel.NEW_CNH_FRONT },
  { label: 'CNH Digital', type: ImageLabel.DIGITAL_CNH_FRONT },
  { label: 'Passaporte', type: ImageLabel.PASSPORT_FRONT },
  { label: 'CTPS', type: ImageLabel.CTPS_FRONT },
  { label: 'Identidade Militar', type: ImageLabel.MILITARY_RG_FRONT },
  { label: 'RG Estrangeiro', type: ImageLabel.FOREING_ID_FRONT },
]

export const DOCUMENT_BACK_OPTIONS = [
  { label: 'RG VERSO', type: ImageLabel.RG_BACK },
  { label: 'RG Digital VERSO', type: ImageLabel.DIGITAL_RG_BACK },
  { label: 'CNH VERSO', type: ImageLabel.CNH_BACK },
  { label: 'Nova CNH VERSO', type: ImageLabel.NEW_CNH_BACK },
  { label: 'CNH Digital VERSO', type: ImageLabel.DIGITAL_CNH_BACK },
  { label: 'Passaporte VERSO', type: ImageLabel.PASSPORT_BACK },
  { label: 'CTPS VERSO', type: ImageLabel.CTPS_BACK },
  { label: 'Identidade Militar VERSO', type: ImageLabel.MILITARY_RG_BACK },
  { label: 'RG Estrangeiro VERSO', type: ImageLabel.FOREING_ID_BACK },
]

export const DocumentsBack = [
  ImageLabel.RG_BACK,
  ImageLabel.MILITARY_RG_BACK,
  ImageLabel.CNH_BACK,
  ImageLabel.CTPS_BACK,
  ImageLabel.FOREING_ID_BACK,
  ImageLabel.PASSPORT_BACK,
  ImageLabel.NEW_CNH_BACK,
  ImageLabel.DIGITAL_RG_BACK,
  ImageLabel.DIGITAL_CNH_BACK 
]

export const ImageLabelsText = {
  [ImageLabel.PORTRAIT]: 'Foto do Rosto',
  [ImageLabel.RG_FRONT]: 'RG FRENTE',
  [ImageLabel.MILITARY_RG_FRONT]: 'Identidade Militar FRENTE',
  [ImageLabel.CNH_FRONT]: 'CNH FRENTE',
  [ImageLabel.PASSPORT_FRONT]: 'Passaporte FRENTE',
  [ImageLabel.CTPS_FRONT]: 'CTPS FRENTE',
  [ImageLabel.FOREING_ID_FRONT]: 'RG Estrangeiro FRENTE',
  [ImageLabel.RG_BACK]: 'RG VERSO',
  [ImageLabel.MILITARY_RG_BACK]: 'Identidade Militar VERSO',
  [ImageLabel.CNH_BACK]: 'CNH VERSO',
  [ImageLabel.PASSPORT_BACK]: 'Passaporte VERSO',
  [ImageLabel.CTPS_BACK]: 'CTPS VERSO',
  [ImageLabel.FOREING_ID_BACK]: 'RG Estrangeiro VERSO',
  [ImageLabel.NEW_CNH_FRONT]: 'Nova CNH FRENTE',
  [ImageLabel.NEW_CNH_BACK]: 'Nova CNH VERSO',
  [ImageLabel.DIGITAL_RG_FRONT]: 'RG Digital FRENTE',
  [ImageLabel.DIGITAL_RG_BACK]: 'RG Digital VERSO',
  [ImageLabel.DIGITAL_CNH_FRONT]: 'CNH Digital FRENTE',
  [ImageLabel.DIGITAL_CNH_BACK]: 'CNH Digital VERSO',
}

export interface IBiometryImageError {
  imageType?: string
  errorMessage?: string
  imageBase64?: string
}
