import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { toaster } from '../../../App'
import { bffParceirosApiService } from '../../../services/bff'
import { IBorrowerCreditLimit } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import TransactionalCreditSliceReducer, { IFetchTransactionalCreditEvaluation } from './tx-credit.reducer'

function* fetchTransactionalCreditEvaluation(action: PayloadAction<IFetchTransactionalCreditEvaluation>) {
  try {
    const cpf = action.payload.cpf
    const storeId = action.payload.storeId
    const retailerId = action.payload.retailerId
    const status = action.payload.status
    const results = action.payload.results

    if (!storeId || !retailerId) {
      return toaster.showErrorToast('Erro ao obter loja na verificação da Super Compra')
    }

    yield put(TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluationLoading())

    const response: TransactionalCreditEvaluation = yield bffParceirosApiService.transactionalCredit.getAvailableEvaluation(
      cpf,
      storeId,
      retailerId,
      status,
      results
    )

    yield put(TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluationSuccess(response))
  } catch (error) {
    yield put(TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluationError(error))
  }
}

function* fetchBorrowerLimit(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    const store = bffParceirosApiService.coordinator.getStore()
    const storeId = store?.id

    if (!storeId) {
      return toaster.showErrorToast(`Loja não configurada`)
    }

    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitLoading())

    const response: IBorrowerCreditLimit = yield bffParceirosApiService.coordinator.fetchBorrowerLimitForStore(
      borrowerId,
      storeId,
    )
    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitSuccess(response))
  } catch (error) {
    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitError(error))
  }
}

const transactionalCreditSaga = [
  takeLatest(TransactionalCreditSliceReducer.actions.fetchTransactionalCreditEvaluation, fetchTransactionalCreditEvaluation),
  takeLatest(TransactionalCreditSliceReducer.actions.fetchBorrowerLimit, fetchBorrowerLimit),
]

export default transactionalCreditSaga
