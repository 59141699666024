import axios, { AxiosInstance } from "axios";
import { useMutation } from "react-query";
import { getAxiosInstance } from "../utils";
import { IPatchVerifyOriginationCode, IPostCreateOriginationVerificationBody } from "./dto/PhoneVerificationRecord.dto";
import { QRCodeCreationDto, QRCodeCreationRequest } from "./dto/QRCodeCreation.dto";

export default class OriginationsService {
    private axiosInstance: AxiosInstance
  

    constructor() {
        this.axiosInstance = axios.create()
    }

    _init(baseURL: string, token: string | null): void {
        this.axiosInstance = getAxiosInstance({
            baseURL,
            token
        })
    }

    async createOriginationVerification(body: IPostCreateOriginationVerificationBody): Promise<void> {
        const response = await this.axiosInstance.post<void>(
            `/origination-code`, body
        )

        return response.data
    }

    async verifyOriginationCode(body: IPatchVerifyOriginationCode): Promise<void> {
        const response = await this.axiosInstance.patch<void>(
            `/origination-code/verify`, body
        )

        return response.data
    }

    useQRCodeCreation = () => {
        return useMutation<QRCodeCreationDto, Error, QRCodeCreationRequest>({
            retry: false,
            mutationKey: ['payBRCode'],
            mutationFn: (payload: QRCodeCreationRequest) => {
            return this.axiosInstance
                .post(`payments/qr-code`, {
                    storeId: payload.storeId,
                    value: payload.value
                })
                .then((res) => res.data)
                .catch((_error) => {
                    throw new Error('Ocorreu um erro inesperado, por favor tente novamente.')
                })
            },
        })
    }
}