import styled from 'styled-components';
import { gray, green, white } from '../../ui/utils/_colors';

interface ButtonProps {
    expand?: boolean;
    disabled?: boolean;
}

export const MainContainer = styled.div<ButtonProps>`
    display: flex;
    width: ${(props: ButtonProps) => props.expand ? '100%': '250px'};
    cursor: pointer;
    background-color: ${(props: ButtonProps) => props.disabled ? gray.disabled : green.confirmation};
    border-radius: 4px;
    justify-content: center;
    align-items: center;

    &:hover {
        opacity: 0.8
    }
`

export const Text = styled.p`
    color: ${white.primary};
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
    padding: 0.875rem;
    user-select: none;
`