import { IStoresOriginationRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/stores-ranking'
import { Box } from 'grommet'
import * as React from 'react'
import { Role } from '../../../../common/constants'
import { getAndFormatGraphXLabels } from '../../../../common/FormatName'
import { hasIdentifiedRetailersDashView } from '../../../../common/RoleValidators'
import { BarChart, Loading } from '../../../../legacy-lib/design-kit-ui'
import { getYValues } from '../../../../legacy-lib/design-kit-ui/BarChart/helpers'
import { blue } from '../../../../legacy-lib/design-kit-ui/Palette'
import {
  ApplicationsByStoreBoxStyle,
  BigCardStyle,
  IconCardStyle,
  SectionTitleStyle,
  SortLeftIconStyled,
  SortRightIconStyled,
  TextStyle,
} from './style'

export interface IOriginationsByStoreProps {
  originationsRankingByStore?: IStoresOriginationRankingResponse | Error
  userRole?: Role
}

export interface IOriginationsByStoreState {
  isOnRankingOrder: boolean
}

const initialState: IOriginationsByStoreState = {
  isOnRankingOrder: false,
}

export default class OriginationsByStore extends React.Component<IOriginationsByStoreProps, IOriginationsByStoreState> {
  _isMounted = false

  constructor(props: IOriginationsByStoreProps) {
    super(props)

    this.state = initialState
  }

  orderValues = () => {
    const { isOnRankingOrder } = this.state

    this.setState({
      isOnRankingOrder: !isOnRankingOrder,
    })
  }

  public render() {
    const { isOnRankingOrder } = this.state
    const { originationsRankingByStore } = this.props

    const maxValueData =
      (originationsRankingByStore &&
        !(originationsRankingByStore instanceof Error) &&
        originationsRankingByStore.storesRanking.reduce((a, b) => (a.principalSum > b.principalSum ? a : b))
          .principalSum) ||
      0

    const showBarChart =
      originationsRankingByStore instanceof Error ? (
        <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
      ) : (
        originationsRankingByStore &&
        maxValueData !== undefined && (
          <BarChart
            isCurrency
            boundsLabels={{ y: 'Valor R$' }}
            yAxis={getYValues(
              0,
              originationsRankingByStore.storesRanking.reduce((a, b) => (a.principalSum > b.principalSum ? a : b))
                .principalSum * 1.2
            )}
            averageValue={Number(originationsRankingByStore.averageVolumeOriginations.toFixed())}
            data={originationsRankingByStore.storesRanking
              .sort((a, b) => (isOnRankingOrder ? a.principalSum - b.principalSum : b.principalSum - a.principalSum))
              .map(store => ({
                value: Number(store.principalSum.toFixed()),
                label:
                  this.props.userRole && hasIdentifiedRetailersDashView(this.props.userRole)
                    ? `${getAndFormatGraphXLabels(store.storeName)}`
                    : `Loja ${store.rankingPosition}`,
              }))}
          />
        )
      )

    return (
      <ApplicationsByStoreBoxStyle>
        <Box direction="row" justify="between" align="end">
          <Box>
            <SectionTitleStyle>Compras</SectionTitleStyle>
            <TextStyle>As informações do gráfico abaixo são de acordo com o filtro do calendário.</TextStyle>
          </Box>
          <IconCardStyle onClick={this.orderValues}>
            {isOnRankingOrder ? <SortLeftIconStyled color={blue.vivid} /> : <SortRightIconStyled color={blue.vivid} />}
          </IconCardStyle>
        </Box>
        <BigCardStyle>
          {originationsRankingByStore ? (
            <Box>{showBarChart}</Box>
          ) : (
            <Box fill align="center" justify="center">
              <Loading></Loading>
            </Box>
          )}
        </BigCardStyle>
      </ApplicationsByStoreBoxStyle>
    )
  }
}
