import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class DollarIcon extends React.PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.11931 4.06307C8.93715 0.311274 15.0619 0.31138 18.8806 4.06299C22.7068 7.82192 22.7068 13.8631 18.8806 17.622C18.5851 17.9123 18.1103 17.9081 17.82 17.6126C17.5297 17.3171 17.5339 16.8423 17.8294 16.552C21.0572 13.3809 21.0572 8.30408 17.8294 5.13301C14.5941 1.95462 9.40484 1.95473 6.17068 5.13294C2.94367 8.30412 2.94289 13.381 6.1706 16.552C6.46608 16.8423 6.47029 17.3171 6.18001 17.6126C5.88972 17.9081 5.41487 17.9123 5.11939 17.622C1.2931 13.863 1.29432 7.82188 5.11931 4.06307Z"/>
          <path d="M3.34998 21C3.34998 20.5858 3.68576 20.25 4.09998 20.25H20.1C20.5142 20.25 20.85 20.5858 20.85 21C20.85 21.4142 20.5142 21.75 20.1 21.75H4.09998C3.68576 21.75 3.34998 21.4142 3.34998 21Z"/>
          <path d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V7.25C12.75 7.66421 12.4142 8 12 8C11.5858 8 11.25 7.66421 11.25 7.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z"/>
          <path d="M12 14C12.4142 14 12.75 14.3358 12.75 14.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V14.75C11.25 14.3358 11.5858 14 12 14Z"/>
          <path d="M8.75 9.005C8.75 7.62206 9.87051 6.5 11.255 6.5H12.625C14.0197 6.5 15.1446 7.58799 15.2353 8.95016C15.2629 9.36346 14.9501 9.72082 14.5368 9.74834C14.1235 9.77587 13.7662 9.46314 13.7387 9.04984C13.6994 8.46001 13.2143 8 12.625 8H11.255C10.6995 8 10.25 8.44994 10.25 9.005C10.25 9.46593 10.5639 9.86823 11.0106 9.98056L13.3536 10.5686C14.4684 10.8483 15.249 11.8504 15.249 12.999C15.249 14.3835 14.1269 15.504 12.744 15.504H11.374C9.97643 15.504 8.85186 14.4129 8.7626 13.049C8.73555 12.6356 9.0487 12.2786 9.46202 12.2516C9.87535 12.2246 10.2324 12.5377 10.2594 12.951C10.2981 13.5431 10.7836 14.004 11.374 14.004H12.744C13.2991 14.004 13.749 13.5545 13.749 12.999C13.749 12.5376 13.4356 12.1357 12.9884 12.0234L10.6454 11.4354C9.53217 11.1557 8.75 10.154 8.75 9.005Z"/>
        </svg>
      </Icon>
    )
  }
}
