import React, { useState } from 'react';

import { Icon } from '@blueprintjs/core';

import Button from '../../../../molecules/Button/Button';
import analyticsService from '../../../../services/analytics/analytics.service';
import { AnalyticsEvents } from '../../../../services/analytics/events';
import {
    BackButtonContainer, BackText, ContentContainer, GoToCashierVersionButton, InfoContainer, Input, InputTitle
} from './QRCodeCreation.styles';

interface Props {
    handleGoBack: () => void
    handleGoToCashierVersion: () => void;
    onPaymentCreation: (value: number) => void
}

const QRCodeCreation: React.FC<Props> = (props) => {
    const { handleGoBack, handleGoToCashierVersion, onPaymentCreation } = props;
    const [value, setValue] = useState<string | undefined>(undefined)

    const handleKeyPressLimitVerification = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter')
            handlePaymentCreation()
    }

    const handlePaymentCreation = () => {
        if (!value)
            return

        const amount = Number(value)
        if (amount < 10) {
            analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_invalid_value, { value: amount })
            alert('O valor mínimo de uma compra deve ser de R$ 10,00')
            return
        }

        analyticsService.sendEvent(AnalyticsEvents.qr_code_payment_creation, { value: amount })
        onPaymentCreation(amount)
    }

    return (
        <InfoContainer>
            <ContentContainer>
                <BackButtonContainer onClick={() => handleGoBack()}>
                    <Icon icon="arrow-left"/>
                    <BackText>Voltar</BackText>
                </BackButtonContainer>
                <InputTitle>Informe o valor da compra</InputTitle>

                <Input
                    id="text-input"
                    placeholder="Digite o valor"
                    autoComplete="off"
                    tabIndex={1}
                    value={value}
                    onValueChange={(e: any) => setValue(e.value)}
                    onKeyDown={handleKeyPressLimitVerification}
                    isNumericString={true}
                    decimalScale={2}
                    type={'tel'}
                    prefix={'R$ '}
                    allowNegative={false}
                    thousandSeparator="."
                    decimalSeparator=","
                    autoFocus={true}
                />

                <Button
                    expand={true}
                    disabled={!value || value.length === 0}
                    text='Continuar'
                    onPress={() => handlePaymentCreation()}
                />
            </ContentContainer>
            <GoToCashierVersionButton onClick={() => handleGoToCashierVersion()}>Clique aqui para realizar a venda usando o CPF do cliente</GoToCashierVersionButton>
        </InfoContainer>
        
    )
}

export default QRCodeCreation
	