import React from 'react'
import { formatBRL } from '../../../../cash-register/common/formatBRL'
import { AggregatedTransactionalCreditEvaluation } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import {
  AlertInfoContainer,
  Card,
  CardHeader,
  CardMessage,
  CardTitle,
  CardValue,
  FinalizeText,
  HeaderContainer,
  MainContainer,
  NavigateButton,
  Row,
  StraightLine,
  Subtitle
} from './TransactionalCreditDeniedResult.styles'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
  handleGoToApplication?: () => void
  handleGoBackToHomePage?: () => void
}

export const TransactionalCreditDeniedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart, handleGoToApplication, handleGoBackToHomePage } = props

  return (
    <div>
      <MainContainer>
        <HeaderContainer>
          <FinalizeText onClick={handleGoBackToStart}>Finalizar</FinalizeText>
        </HeaderContainer>

        <Card>
          <CardHeader>
            <CardTitle>Limite Ume pré-aprovado!</CardTitle>
            { evaluationResult.limit?.availableLimit && (<CardValue>{formatBRL(evaluationResult.limit.availableLimit)}</CardValue>) }
          </CardHeader>

          <StraightLine />

          <AlertInfoContainer>
            <Subtitle>Importante:</Subtitle>
            <>
              { !hasCompletedApplication && (
                <Row>
                  {`\u2022` }
                  <CardMessage>
                    Prossiga com o cadastro do cliente para utilizar o limite pré-aprovado.
                  </CardMessage>
                </Row>
              )}
              <Row>
                {`\u2022` }
                <CardMessage>
                  Solicite ao cliente que baixe o aplicativo da Ume para acompanhar o seu limite.
                </CardMessage>
              </Row>
            </>
          </AlertInfoContainer>
        </Card>

        { !hasCompletedApplication && (
          <NavigateButton onClick={handleGoToApplication} backgroundColor="#12aa6a" textColor="#ffffff">
            Cadastrar cliente
          </NavigateButton>
        )}
        <NavigateButton onClick={handleGoBackToHomePage} backgroundColor="#ffffff" textColor="#12aa6a">
          Voltar para a tela inicial
        </NavigateButton>
      </MainContainer>
    </div>
  )
}
