import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import { Currency } from 'grommet-icons'
import React from 'react'
import { Route, Router, Switch } from 'react-router'
import styled from 'styled-components'
import { mobile } from '../../common/assets/utils/_breakpoints'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import { FinancialTab } from '../../molecules/FinancialPayments/FinancialTab'
import PageHeader from '../../molecules/header/components/PageHeader/PageHeader'
import FinancialOriginationsPage from '../../organisms/FinancialOriginations/FinancialOriginationsPage'
import FinancialPaymentsPage from '../../organisms/FinancialPayments/FinancialPaymentsPage'
import { bffParceirosApiService } from '../../services/bff'

interface IFinancialPageProps {
  history: any
  match?: any
  location?: any
}

interface IFinancialPageState {
  selectedTabIndex: number
}

const PAYMENTS_PAGE_INDEX = 0
const ORIGINATIONS_PAGE_INDEX = 1

export default class FinancialPage extends React.Component<IFinancialPageProps, IFinancialPageState> {
  private retailerId?: string
  private user?: ILoginProfileResponse

  constructor(props: IFinancialPageProps) {
    super(props)
    this.state = {
      selectedTabIndex: ORIGINATIONS_PAGE_INDEX,
    }

    // TODO - Handle case with no retailer set
    this.user = bffParceirosApiService.auth.getUser()

    if (this.user) {
      this.retailerId = this.user.retailerId
    }
  }

  // On mounting the page, check which tab is selected by looking at the route name
  componentDidMount = () => {
    if (this.props.location.pathname === '/financial/payments') {
      this.setState({ selectedTabIndex: PAYMENTS_PAGE_INDEX })
    } else if (this.props.location.pathName === '/financial/originations') {
      this.setState({ selectedTabIndex: ORIGINATIONS_PAGE_INDEX })
    }
  }

  // When a tab is clicked, the route name should change, as well as the selected tab
  onTabClicked = (tabIndex: number) => {
    if (tabIndex === ORIGINATIONS_PAGE_INDEX) {
      this.props.history.push(`${this.props.match.path}/originations`)
    } else {
      this.props.history.push(`${this.props.match.path}/payments`)
    }

    this.setState({ selectedTabIndex: tabIndex })
  }

  render() {
    const { selectedTabIndex } = this.state

    return (
      <FinancialPageContainer>
        {!this.retailerId ? <WarningHeader>Você não tem um varejo configurado.</WarningHeader> : <></>}

        {/* BREADCUMB */}
        <FinancialBreadcumb id="financial-breadcumb">
          <span> Home </span>
          <span> / </span>
          <span> Financeiro </span>
          <span> / </span>
          <span> {selectedTabIndex === PAYMENTS_PAGE_INDEX ? 'A Pagar' : 'A Receber'}</span>
        </FinancialBreadcumb>

        <PageHeader identificationUserContainerStyle={{ marginTop: 50, marginLeft: 70 }} />

        <FinancialPageHeader>
          {/* TABS */}
          <UMETabsContainer>
            <FinancialTab
              Icon={<CurrencyIcon color={UMEColors.black.primary} />}
              onClick={() => this.onTabClicked(ORIGINATIONS_PAGE_INDEX)}
              title={'A receber'}
              active={selectedTabIndex !== ORIGINATIONS_PAGE_INDEX}
            />

            <FinancialTab
              Icon={<CurrencyIcon color={UMEColors.black.primary} />}
              onClick={() => this.onTabClicked(PAYMENTS_PAGE_INDEX)}
              title={'A pagar'}
              active={selectedTabIndex !== PAYMENTS_PAGE_INDEX}
            />
          </UMETabsContainer>
        </FinancialPageHeader>
        <Router history={this.props.history}>
          <Switch>
            <Route
              path={`${this.props.match.path}/payments`}
              component={() => <FinancialPaymentsPage history={this.props.history} />}
            />

            <Route
              path={`${this.props.match.path}/originations`}
              component={() => <FinancialOriginationsPage history={this.props.history} />}
            />
          </Switch>
        </Router>
      </FinancialPageContainer>
    )
  }
}

const WarningHeader = styled.div`
  width: 100%;
  background-color: ${UMEColors.orange.amber};
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`

const FinancialPageHeader = styled.div`
  padding: 24px 70px 24px 70px;

  @media (max-width: ${mobile}) {
    padding: 14px 18px 14px 18px;
  }
`

const FinancialPageContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  min-height: 95vh;
  background-color: ${UMEColors.gray.azure};
`

const FinancialBreadcumb = styled.section`
  margin-top: 24px;
  margin-left: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 200px;

  // TODO - Not in design kit
  color: #959595;
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
`

const UMETabsContainer = styled.div`
  margin-top: 4vh;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${mobile}) {
    margin-top: 1vh;
  }
`

const CurrencyIcon = styled(Currency)`
  height: 35px;
  width: 35px;
`
