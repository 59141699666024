import { Box } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../../../common/assets/utils/_breakpoints'
import { Card, Dropdown, Heading, Text } from '../../../../legacy-lib/design-kit-ui'
import { gray } from '../../../../legacy-lib/design-kit-ui/Palette'

export const StoresSelectBoxStyle = styled(Box)`
  margin-top: 18px;
  margin-right: 20px;
`
export const TitleBoxStyle = styled(Box)`
  margin-bottom: 6px;
`
export const TitleHeadingStyle = styled(Heading)`
  font-size: 34px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 38px;
  margin: 8px 0;

  @media (min-width: ${tablet}) {
    margin: 0;
    font-size: 24px;
    letter-spacing: -0.6px;
    line-height: 38px;
  }
`

export const SubTitleStyle = styled(Text)`
  font-size: 14px;
  letter-spacing: -0.45px;
  line-height: 19px;
  margin-bottom: 24px;

  @media (min-width: ${tablet}) {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 0;
  }
`

export const BarSideBoxStyle = styled(Box)`
  margin-top: 32px;
  padding: 4px;
`

export const IconCardStyle = styled(Card)`
  border: none;
  border-radius: 8px;
  height: 56px;
  padding: 12px;
  margin: 0;
`

export const DropdownStyle = styled(Dropdown)`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.54px;
  color: ${gray.eclipse};
  line-height: 16px;
`
