import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NextArrowIcon } from '../../common/assets/images/arrow-right-next.svg'
import { gray } from '../../ui/utils/_colors'

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
}

export const ArrowRightNextButton = (props: Props): JSX.Element => {
  return (
    <Container>
      <Button onClick={props.onClick} disabled={props.disabled}>
        <NextArrowIcon width={'40px'} height={'40px'} />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
`

const Button = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  margin-bottom: 8px;
  cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.2 : 1)};
  pointer-events: ${({ disabled }: { disabled: boolean }) => (disabled ? 'none' : 'auto')};

  &:hover {
    svg rect {
      fill: ${gray.scale200};
    }
  }
`
