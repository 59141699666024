import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { GetStoresQuery, RetailerDto } from './dto/Retailer.dto'
import { GetStoresDto, StoreDto } from './dto/Store.dto'

export default class RetailersService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async fetchStores(params?: GetStoresQuery): Promise<GetStoresDto> {
    const response = await this.axiosInstance.get<GetStoresDto>(`/retailers/stores`, { params })

    return response.data
  }

  async fetchStoreById(storeId: string): Promise<StoreDto> {
    const response = await this.axiosInstance.get<StoreDto>(`retailers/stores/${storeId}`)

    return response.data
  }

  async fetchRetailerById(retailerId: string): Promise<RetailerDto> {
    const response = await this.axiosInstance.get<RetailerDto>(`/retailers/${retailerId}`)

    return response.data
  }
}
