import { Box } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../../../../common/assets/utils/_breakpoints'
import { Card, Heading, Text } from '../../../../../legacy-lib/design-kit-ui'
import { gray, green } from '../../../../../legacy-lib/design-kit-ui/Palette'

export const CardTitleHeadingStyle = styled(Heading)`
  color: ${gray.spanish};
  margin: 0;
  line-height: 22px;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.6px;

  @media (min-width: ${tablet}) {
    font-size: 18px;
    letter-spacing: -0.45px;
  }
`

export const TextStyle = styled(Text)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: ${gray.light};
`

export const NumberBoxMobileStyle = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SmallTextStyle = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
`

export const SmallCardStyle = styled(Card)`
  height: 248px;
  min-width: 128px;
  border: none;
  padding-bottom: 20px;
  margin: 8px 8px 8px 0;

  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    height: 184px;
    width: 164px;
    padding-bottom: 20px;
    margin: 8px;

    > div {
      &.desktop {
        display: flex;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`

export const NumberValueHeadingStyle = styled(Heading)`
  margin: 0 0 8px;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: -0.94px;
  line-height: 47px;

  @media (min-width: ${tablet}) {
    font-size: 40px;
    letter-spacing: -0.94px;
    line-height: 47px;
  }
`

export const NumberHeadingStyle = styled(Heading)`
  margin: 0;
  font-weight: bold;
  font-size: 46px;
  letter-spacing: 1px;
  line-height: 54px;

  @media (min-width: ${tablet}) {
    font-size: 56px;
    letter-spacing: -1.31px;
    line-height: 66px;
  }
`

export const SmallNumberQuantityHeadingStyle = styled(Heading)`
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: -0.8px;
  line-height: 38px;

  @media (min-width: ${tablet}) {
    font-size: 32px;
    letter-spacing: -0.8px;
    line-height: 38px;
  }
`

export const SmallGreenValueHeadingStyle = styled(Heading)`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.57px;
  line-height: 29px;
  color: ${green.darkPastel};

  @media (min-width: ${tablet}) {
    font-size: 24px;
    letter-spacing: -0.55px;
    line-height: 28px;
  }
`
export const GreenCurrencyHeadingStyle = styled(Heading)`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  color: ${green.darkPastel};

  @media (min-width: ${tablet}) {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
`

export const BlackCurrencyHeadingStyle = styled(Heading)`
  margin: 0;
  font-weight: bold;
  font-size: 23px;
  letter-spacing: -0.54px;
  line-height: 27px;

  @media (min-width: ${tablet}) {
    font-size: 23px;
    letter-spacing: -0.54px;
    line-height: 27px;
  }
`
