import React from 'react'
import styled, { css } from 'styled-components'
import { LoadingIcon } from '../../atoms/LoadingIcon/LoadingIcon'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { numberToCurrency } from '../../common/NumberToCurrency'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

interface IFinancialCardProps {
  header: {
    title: string
    subtitle: string
  }
  card: {
    description: string
    quantityText: string
    priceText: string
  }
  data?: {
    quantity: number
    price: number
  }
  red?: boolean
  download?: string
  onDownload?: () => void
}

export const FinancialCard = (props: IFinancialCardProps) => {
  let { data, header, card, red, download, onDownload } = props
  let { title, subtitle } = header
  let { description, quantityText, priceText } = card

  // If no data is given, show loading card
  if (!data) {
    return (
      <Card>
        <Description>
          <BigTitle> {title} </BigTitle>
          <SmallTitle> {subtitle} </SmallTitle>
        </Description>

        <Content>
          <LoadingIcon />
        </Content>

        <DownloadPayments> </DownloadPayments>
      </Card>
    )
  }

  return (
    <Card>
      <Description>
        <BigTitle> {title} </BigTitle>
        <SmallTitle> {subtitle} </SmallTitle>
      </Description>

      <Content>
        <ContentHeader> {description} </ContentHeader>
        <ContentItem>
          <Quantity> {data.quantity} </Quantity>
          <span> {quantityText} </span>
        </ContentItem>
        <ContentItem>
          <Price red={red}>
            <PriceUnit> R$ </PriceUnit>
            {numberToCurrency(data.price)}
          </Price>
          <span> {priceText} </span>
        </ContentItem>
      </Content>
      {download ? (
        <DownloadPayments>
          <span onClick={onDownload}>Baixar</span> {download}
        </DownloadPayments>
      ) : (
        <DownloadPayments> </DownloadPayments>
      )}
    </Card>
  )
}

const DownloadPayments = styled.div`
  margin-left: 15px;
  margin-top: 13px;
  text-align: left;

  width: 170px;
  height: 10px;

  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  align-self: center;

  & > span {
    // TODO - Not in Design Kit
    color: #0037c8;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`

const Card = styled.section`
  flex: 0 0 208px;
  margin: 16px;

  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: ${mobile}) {
    &:nth-child(even) {
      margin-left: 0px;
    }
  }

  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
`

const Description = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
`

const BigTitle = styled.span`
  height: 38px;
  font-size: 24px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
`

const SmallTitle = styled.span`
  font-size: 10px;
  // TODO - Not in Design Kit
  color: #8c8c8c;
  font-weight: 400;
`

const Content = styled.section`
  margin-top: 17px;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  height: 224px;
  width: 208px;
  padding: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const ContentHeader = styled.header`
  // TODO - Not in Design Kit
  color: #a1a1a1;
  font-size: 20px;
  height: 48px;
  font-weight: 500;
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  // TODO - Not in Design Kit
  color: #5b5b5b;
`

const Quantity = styled.span`
  color: ${UMEColors.black.primary};
  font-size: 32px;
  font-weight: bold;
`

const Price = styled.span`
  // TODO - Not in design kit
  color: #00b748;
  font-size: 24px;
  font-weight: 700;

  ${(props: any) =>
    props.red &&
    css`
      // TODO - Not in Design Kit
      color: #d20000;
    `}
`

const PriceUnit = styled.span`
  height: 16px;
  width: 18px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`
