import styled from 'styled-components'
import { black } from '../utils/_colors'

const Icon = styled.div`
  > svg {
    width: ${props => (props.width ? props.width : '24px')};
    height: ${props => (props.height ? props.height : '24px')};
    fill: ${props => (props.color ? props.color : black.primary)};
  }
`

export { Icon }
