import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    height: calc(100% - 56px);
    width: 100%;
    flex-direction: column;
`

export const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const HeaderContainer = styled.div`
    display: flex;
    margin-left: 70px;
    margin-top: 30px;
    @media only screen and (max-width: 770px) {
        margin: 0 auto;
        margin-top: 30px;
    }
`