import React, { Component } from 'react'
import { Container } from './styles'

interface CardProps {
  boxShadow?: boolean
  backgroundColor?: string
  width?: string
  height?: string
  border?: string
}
export default class Card extends Component<CardProps & JSX.IntrinsicElements['div']> {
  render() {
    return (
      <Container boxShadow={true} {...this.props}>
        {this.props.children}
      </Container>
    )
  }
}
