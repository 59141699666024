import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DocumentLabelType,
  ImageLabel,
  ProcessStatus,
} from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import { AxiosError } from 'axios'
import { toaster } from '../../../App'
import { DOCUMENT_BACK_OPTIONS, DOCUMENT_FRONT_OPTIONS } from './biometry.constants'
import { transformHttpBiometryErrorsToString } from './biometry.errors'

export type BiometryState = {
  isCameraOpen: boolean
  documentOptions?: string[]
  selectedDocumentIndex: number
  isCapturingDocumentImage: boolean
  isLoadingDocument: boolean
  isLoadingDocumentEvaluation: boolean
  isDocumentAuthenticated: boolean

  faceImageBase64?: string
  isLoadingFaceImage: boolean
  isFaceAuthenticated: boolean
  isLoadingBiometry: boolean
  biometryStatus?: ProcessStatus

  documentImages?: {
    documentImageBase64: string
    documentType: DocumentLabelType
  }[]

  imageError?: {
    imageBase64?: string
    imageType?: string
    errorMessage?: string
  }
}

const initialState: BiometryState = {
  isCameraOpen: false,
  isCapturingDocumentImage: false,
  selectedDocumentIndex: 0,

  isLoadingFaceImage: false,
  isLoadingBiometry: false,
  isFaceAuthenticated: false,

  isLoadingDocument: false,
  isLoadingDocumentEvaluation: false,
  isDocumentAuthenticated: false,

  imageError: undefined,
}

export type IInsertDocumentPayload = {
  imageBase64: string
  documentType: 'DOCUMENT_FRONT' | 'DOCUMENT_BACK'
}

type IInsertDocumentErrorPayload = {
  imageBase64: string
  documentType: string
  error: AxiosError
}

type IFetchDocumentPayload = {
  missingSide?: DocumentLabelType
  images: {
    documentImageBase64: string
    documentType: DocumentLabelType
  }[]
}

type ICreateBiometryProccessErrorPayload = {
  imageBase64: string
  submitBiometry: boolean
  error: AxiosError
}

const BiometrySliceReducer = createSlice({
  name: 'biometry',
  initialState,
  reducers: {
    onCameraEvent: (state, action: PayloadAction<boolean>) => {
      const isCameraOpen = action.payload
      return { ...state, isCameraOpen }
    },
    insertDocument: (state, action: PayloadAction<IInsertDocumentPayload>) => {
      return { ...state, isDocumentAuthenticated: false }
    },
    fetchDocumentLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingDocument: true }
    },
    startBiometryStatusPooling: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, biometryStatus: undefined }
    },
    stopBiometryStatusPooling: (state, action: PayloadAction<ProcessStatus>) => {
      // Used in Redux Saga
      const biometryStatus = action.payload
      let isFaceAuthenticated = false
      if (biometryStatus === ProcessStatus.COMPLETED) {
        isFaceAuthenticated = true
      }
      return { ...state, biometryStatus, isFaceAuthenticated, isLoadingBiometry: false }
    },
    fetchBiometryStatusLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBiometry: true }
    },

    postponeBiometryStatusResult: (state, action: PayloadAction<undefined>) => {
      return { ...state, biometryStatus: undefined, isFaceAuthenticated: false, isLoadingBiometry: false }
    },
    insertDocumentSuccess: (state, action: PayloadAction<undefined>) => {
      return {
        ...state,
        isCameraOpen: false,
        imageError: undefined,
        isLoadingDocument: false,
      }
    },
    insertDocumentError: (state, action: PayloadAction<IInsertDocumentErrorPayload>) => {
      const { error, imageBase64, documentType } = action.payload

      const errorMessage = transformHttpBiometryErrorsToString(error)
      toaster.showErrorToast(errorMessage)

      return {
        ...state,
        documentImageBase64: undefined,
        isDocumentAuthenticated: false,
        isLoadingDocument: false,
        imageError: {
          imageBase64,
          errorMessage,
          imageType: documentType,
        },
      }
    },
    selectDocument: (state, action: PayloadAction<number>) => {
      const selectedDocumentIndex = action.payload

      return {
        ...state,
        selectedDocumentIndex,
        documentImageBase64: undefined,
        isDocumentAuthenticated: false,
      }
    },
    fetchDocument: (state, action: PayloadAction<undefined>) => {
      // TODO - Redux Saga
      return { ...state, isDocumentAuthenticated: false, selectedDocumentIndex: 0 }
    },
    fetchDocumentSuccess: (state, action: PayloadAction<IFetchDocumentPayload>) => {
      const { images, missingSide } = action.payload

      let selectedDocumentIndex = DOCUMENT_FRONT_OPTIONS.findIndex(doc => doc.type === images[0].documentType)
      if (selectedDocumentIndex === -1) {
        selectedDocumentIndex = DOCUMENT_BACK_OPTIONS.findIndex(doc => doc.type === images[0].documentType)
      }

      return {
        ...state,
        documentImages: images,
        isDocumentAuthenticated: missingSide ? false : true,
        selectedDocumentIndex,
        isLoadingDocument: false,
      }
    },
    fetchDocumentError: (state, action: PayloadAction<AxiosError>) => {
      // const error = action.payload

      // const errorMessage = transformHttpBiometryErrorsToString(error)
      // toaster.showErrorToast(errorMessage)

      return {
        ...state,
        documentImages: undefined,
        isDocumentAuthenticated: false,
        imageError: undefined,
        isLoadingDocument: false,
      }
    },
    fetchBiometryStatus: (state, action: PayloadAction<undefined>) => {
      // TODO - Redux Saga
      return { ...state, isFaceAuthenticated: false, biometryStatus: undefined }
    },
    fetchBiometryStatusError: (state, action: PayloadAction<AxiosError>) => {
      // const error = action.payload

      // const errorMessage = transformHttpBiometryErrorsToString(error)
      // toaster.showErrorToast(errorMessage)

      return {
        ...state,
        imageError: undefined,
        isFaceAuthenticated: false,
        biometryStatus: undefined,
        isLoadingFaceImage: false,
        isLoadingBiometry: false,
      }
    },
    fetchFace: (state, action: PayloadAction<undefined>) => {
      // TODO - Redux Saga
      return {
        ...state,
        isFaceAuthenticated: false,
        faceImageBase64: undefined,
      }
    },
    fetchFaceLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingFaceImage: true }
    },
    fetchFaceStopLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingFaceImage: false }
    },
    fetchFaceSuccess: (state, action: PayloadAction<string>) => {
      const imageBase64 = action.payload
      const faceImageBase64 = `data:image/jpeg;base64,${imageBase64}`

      return {
        ...state,
        faceImageBase64,
        isLoadingFaceImage: false,
        isLoadingBiometry: false,
      }
    },
    fetchFaceError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      if (!error.response || error.response.status !== 404) {
        const errorMessage = transformHttpBiometryErrorsToString(error)
        toaster.showErrorToast(errorMessage)
      }

      return {
        ...state,
        faceImageBase64: undefined,
        isFaceAuthenticated: false,
        biometryStatus: undefined,
        imageError: undefined,
        isLoadingFaceImage: false,
        isLoadingBiometry: false,
      }
    },
    createBiometryProccess: (state, action: PayloadAction<string>) => {
      // Used on Redux-Saga
      return state
    },
    createBiometryProccessSuccess: (state, action: PayloadAction<string>) => {
      const imageBase64 = action.payload

      toaster.showSuccessToast(`Foto submetida, estamos realizando uma análise.`)

      return {
        ...state,
        faceImageBase64: imageBase64,
        isFaceAuthenticated: false,
        biometryStatus: undefined,
        isCameraOpen: false,
        imageError: undefined,
        isLoadingFaceImage: false,
      }
    },
    createBiometryProccessError: (state, action: PayloadAction<ICreateBiometryProccessErrorPayload>) => {
      const { imageBase64, submitBiometry, error } = action.payload

      const errorMessage = transformHttpBiometryErrorsToString(error)
      toaster.showErrorToast(errorMessage)

      return {
        ...state,
        imageError: {
          errorMessage,
          imageBase64,
          submitBiometry,
          imageType: ImageLabel.PORTRAIT,
        },
        isLoadingFaceImage: false,
        isLoadingBiometry: false,
      }
    },
    clearImageErrors: (state, action: PayloadAction<undefined>) => {
      return { ...state, imageError: undefined }
    },
    resetState: (state, action: PayloadAction<undefined>) => {
      return { ...initialState }
    },
  },
})

export default BiometrySliceReducer
