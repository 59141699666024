import { getConfig } from '../../../config/config'

export default class FeatureFlags {
  static isBudgetBlackCampaignActive(): boolean {
    return getConfig().feature_flags.active_budget_black_campaign
  }

  static allowTransactionalCreditEvaluation(): boolean {
    return getConfig().feature_flags.allowTransactionalCreditEvaluation
  }

  static enableTransactionalCreditOnServiceCentral(): boolean {
    return getConfig().feature_flags.enableTransactionalCreditOnServiceCentral
  }
}
