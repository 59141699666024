export enum HttpOriginationsBatchErrors {
    DATE_IN_THE_FUTURE = 'DATE_IN_THE_FUTURE',
    NOT_A_BUSINESS_DAY = 'NOT_A_BUSINESS_DAY',
    BATCH_NOT_FOUND = 'BATCH_NOT_FOUND',
    ALREADY_EXISTING_BATCH_ON_DATE = 'ALREADY_EXISTING_BATCH_ON_DATE',
    NO_ORIGINATIONS_ON_DATE = 'NO_ORIGINATIONS_ON_DATE',
    BATCH_ALREADY_FINISHED = 'BATCH_ALREADY_FINISHED',
    NOT_FIRST_PENDING_STEP = 'NOT_FIRST_PENDING_STEP',
    VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
    EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
    STEP_NOT_FOUND = 'STEP_NOT_FOUND',
    STEP_ALREADY_FINISHED = 'STEP_ALREADY_FINISHED',
}

export enum ORIGINATIONS_BATCH_STEPS_NAME {
    UME_TO_BANKER = 'UME_TO_BANKER',
    BANKER_TO_TRANSITORY = 'BANKER_TO_TRANSITORY',
    TRANSITORY_TO_RETAILER = 'TRANSITORY_TO_RETAILER',
}
  
export enum ORIGINATIONS_BATCH_STATUS {
    AT_UME = 'AT_UME',
    AT_BANKER = 'AT_BANKER',
    AT_TRANSITORY_ACCOUNT = 'AT_TRANSITORY_ACCOUNT',
    AT_RETAILER = 'AT_RETAILER',
}

export enum TRANSFER_STATUS {
    TRANSFERRED = 'TRANSFERRED',
    PENDING = 'PENDING',
}

export interface BatchOriginationDto {
    id: number
    timestamp: string
    borrowerId: string
    mdr: number
    mdrRate: number
    iof: number
    interest: number
    interestRate: number
    principal: number
    financedValue: number
    financialInstitutionId: string
    financialInstitutionName: string
    retailerId: string
    storeId: string
    operatorId: string
    storeName: string
    retailerName: string
    contractId: string
    partnerProposalId: string
    numberOfInstallments: number
    retailerCNPJ: string
    installmentValue: number
    wasRenegotiated: boolean
    renegotiationDiscount: number
    upfrontPayment: number
    firstInstallmentDueDate: string
    upfrontPaymentDueDate: string
    isRenegotiation: boolean
}

export interface RetailerBatchResponse {
    retailerId: string
    principalSum: number
    discountSum: number
    umeFeeSum: number
    iofSum: number
    originationsCount: number
}

export interface StoreBatchResponse {
    retailerId: string
    storeId: string
    principalSum: number
    umeFeeSum: number
    iofSum: number
    originationsCount: number
}

export interface IGetBatchesRetailersTransfers {
    originationsStartDate?: string;
    originationsEndDate?: string;
    storeId?: string;
}

export interface IGetOriginationsForBatchQuery {
    storeId?: string;
    retailerId?: string;
    batchId: string;
}