import styled from "styled-components";
import { white, black } from "../Palette";


const TooltipStyled = styled.div` 

    position: relative;
    display: inline-block;

    /* Tooltip container */    
    .tooltip {

      visibility: hidden;
      width: 120px;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      background-color: ${props => props.background ? props.background : black.primary };
      color: ${white.primary};
      
      /* transition: .50s all; */  /* hover in timer */
      /* transition-delay: 0.3s; */ /* hover off timer */
      position: absolute;
      z-index: 1;

      right: ${props => props.position === 'left' ? '110%' : ''};
      bottom: ${props => props.position === 'top' ? '150%' : ''};
      margin-left: ${props => (props.position === 'top' || props.position === 'bottom' ) ? '-60px' : ''};
      margin-top: ${props => (props.position === 'bottom') ? '8px' : ''};


      top: ${ props => {
        if (props.position === 'right' || props.position === 'left' ){
          return '-5px';
        }
        else if(props.position === 'bottom'){
          return '100%'
        } else {
          return ''
        }
      }
      };

      left: ${ props => {
        if (props.position === 'right' ){
          return '110%';
        }
        else if(props.position === 'top' || props.position === 'bottom' ){
          return '50%'
        } else {
          return ''
        }
      }
      };

    &::after { /* Arrows */
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: ${ props => {
        if (props.position === 'left'){
          return 'transparent transparent transparent black';
        }
        else if(props.position === 'right'){
          return 'transparent black transparent transparent'
        } else if(props.position === 'top'){
          return 'black transparent transparent transparent'
        } else { // bottom
          return 'transparent transparent black transparent'
        } 
      }
      };

      /* OBS: the 'position' property refers to the tooltip, no the arrow. A bottow tooltip has a top arrow, for example. */
      
      bottom: ${props => props.position === 'bottom' ? '100%' : ''};
      right: ${props => props.position === 'right' ? '100%' : ''};
      margin-left: ${props => (props.position === 'top' || props.position === 'bottom' ) ? '-5px' : ''};
      margin-top: ${props => (props.position === 'right' || props.position === 'left') ? '-5px' : ''};

      
      top: ${ props => {
        if (props.position === 'right' || props.position === 'left' ){
          return '50%';
        }
        else if(props.position === 'top'){
          return '100%'
        } else {
          return ''
        }
      }
      };

      left: ${ props => {
        if (props.position === 'left' ){
          return '100%';
        }
        else if(props.position === 'top' || props.position === 'bottom' ){
          return '50%'
        } else {
          return ''
        }
      }
      }; 

    }
  };
    
    /* Show the tooltip text when you mouse over the tooltip container */
    &:hover span {
      visibility: visible;
      /* transition-delay: 0s;*/
    }



`

export { TooltipStyled }
