import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { ApplicationVersionEnum } from '../../../redux/reducers/application/application.types'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import ApplicationLayout from '../ApplicationLayout'

const ApplicationChooseDueDayStep = () => {
  const [secondaryColor, setsecondaryColor] = useState<string>(`#00000`)
  const [selectedDueDay, setSelectedDueDay] = useState<number>()
  const [allowNextBtn, setAllowNextBtn] = useState<boolean>(false)

  const { allowedBillDueDays, enableHighRecurrence, applicationVersion, isDueDaySet } = useTypedSelector(state => ({
    allowedBillDueDays: state.configurations.configurations.borrower.highRecurrenceConfigs.allowedBillDueDays,
    enableHighRecurrence: state.application.borrower?.enableHighRecurrence,
    applicationVersion: state.configurations.configurations.application.version,
    isDueDaySet: state.application.isDueDaySet,
  }))

  const handleDueDayClick = (choseDueDay: number) => {
    setSelectedDueDay(choseDueDay)
    setsecondaryColor(`#C2C2C2`)
    setAllowNextBtn(true)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (isDueDaySet) {
      if (applicationVersion === ApplicationVersionEnum.V1 || applicationVersion === ApplicationVersionEnum.V3) {
        dispatch(ApplicationSliceReducer.actions.startApplication())
      } else if (applicationVersion === ApplicationVersionEnum.V2) {
        dispatch(ApplicationSliceReducer.actions.nextPage())
      }
    }
  }, [isDueDaySet])

  const handleConfirmButton = async () => {
    if (enableHighRecurrence) {
      dispatch(ApplicationSliceReducer.actions.updateBorrowerBillDueDay({ billDueDay: selectedDueDay }))
    }
  }

  const handleColorStyle = (day: number) => {
    const color = selectedDueDay == day ? `#13C450` : `${secondaryColor}`
    const border = selectedDueDay == day ? `#13C450 1px solid` : ''
    return { color, border }
  }

  return (
    <ApplicationLayout
      title={'Data de vencimento'}
      subtitle={'Escolha o dia do mês para o vencimento da fatura do cliente:'}
      isNextButtonEnabled={allowNextBtn}
      labelNextButton={'Finalizar cadastro'}
      overrideNextPage={handleConfirmButton}
    >
      <Container>
        {allowedBillDueDays.map(day => (
          <DayIcon style={handleColorStyle(day)} key={day} onClick={() => handleDueDayClick(day)}>
            {day}
          </DayIcon>
        ))}
      </Container>
    </ApplicationLayout>
  )
}

export default ApplicationChooseDueDayStep

const Container = styled.div`
  margin-top: 2vh;
  margin-bottom: 2vh;

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
`
const DayIcon = styled.div`
  width: 70px;
  height: 70px;
  margin: 32px 14px;

  font-size: 24px;
  font-weight: 700
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 22%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
`
