import { createTheme, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'
import { Loading } from '../../molecules/Budget'
import { SmartphoneSaleEvaluation } from '../../organisms/ProductSale/SmartphoneSaleEvaluation'
import { SmartphoneSaleForm } from '../../organisms/ProductSale/SmartphoneSaleForm'
import { bffParceirosApiService } from '../../services/bff'
import { CreateSmartphoneSaleCreditEvaluationRequest } from '../../services/bff/smartphone-sale/dtos/smartphone-sale-credit-evaluation.dto'
import { SmartphoneSalePages } from './SmartphoneSalePages'

const theme = createTheme({
  palette: {
    primary: {
      main: '#11B048',
    },
  },
})

export const SmartphoneSale: React.FC = () => {
  const [page, setPage] = useState<SmartphoneSalePages>(SmartphoneSalePages.Form)

  const {
    error,
    isLoading,
    mutate,
    data,
  } = bffParceirosApiService.smartphoneSaleCredit.useCreateSmartphoneSaleCreditEvaluation()

  React.useEffect(() => {
    if (!!data) setPage(SmartphoneSalePages.Evaluation)
  }, [data])

  React.useEffect(() => {
    if (!!error) alert(error.message)
  }, [error])

  const handleSmartphoneSaleCreditEvaluationCreation = (request: CreateSmartphoneSaleCreditEvaluationRequest) => {
    mutate({ ...request })
  }

  const renderPage = () => {
    switch (page) {
      case SmartphoneSalePages.Form:
        return <SmartphoneSaleForm onSubmit={handleSmartphoneSaleCreditEvaluationCreation} />
      case SmartphoneSalePages.Evaluation:
        return <SmartphoneSaleEvaluation evaluationResult={data?.evaluation!!} />
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {renderPage()}
      {isLoading && <Loading />}
    </ThemeProvider>
  )
}
