import React from 'react'
import styled, { css } from 'styled-components'
import { formatDate } from '../../common/FormatDate'
import { numberToCurrency } from '../../common/NumberToCurrency'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import { bffParceirosApiService } from '../../services/bff'

export interface IRetailersBatchTableData {
  retailerId: string
  storeId?: string
  status: string
  transferTimestamp: string
  originationsStartDate: string
  originationsEndDate: string
  originationsCount: number
  // principalInStore: number
  transferredValue: number
  discountSum: number
  umeFeeSum: number
  principalSum: number
  batchId: string
  totalSum: number
}

export const FINAL_TRANSFERS_TABLE_HEADER = [
  {
    textAlign: 'center',
    label: 'Status',
    attribute: 'status',
    formatCell: (transfer: IRetailersBatchTableData) => {
      switch (transfer.status) {
        case bffParceirosApiService.backofficeOriginations.TRANSFER_STATUS.PENDING: {
          return <StatusLabel backgroundColor="#FF8B00"> PENDENTE </StatusLabel>
        }
        case bffParceirosApiService.backofficeOriginations.TRANSFER_STATUS.TRANSFERRED: {
          return <StatusLabel backgroundColor="#1F9901"> TRANSFERIDO </StatusLabel>
        }
        default: {
          return <span>{transfer.status}</span>
        }
      }
    },
  },
  {
    textAlign: 'center',
    label: 'Data da transferência',
    attribute: 'transferTimestamp',
    formatCell: (transfer: IRetailersBatchTableData) =>
      transfer.transferTimestamp ? formatDate(transfer.transferTimestamp) : '---',
  },
  {
    textAlign: 'center',
    label: 'Referente a contratos',
    attribute: 'any',
    formatCell: (transfer: IRetailersBatchTableData) => {
      if (transfer.originationsStartDate === transfer.originationsEndDate) {
        return <span>{transfer.originationsStartDate}</span>
      }

      return (
        <span>
          {transfer.originationsStartDate} - {transfer.originationsEndDate}
        </span>
      )
    },
  },
  {
    textAlign: 'center',
    label: 'Qntd Contratos',
    attribute: 'originationsCount',
    formatCell: (transfer: IRetailersBatchTableData) => transfer.originationsCount,
  },
  // {
  //   textAlign: 'center',
  //   label: 'Valor total',
  //   attribute: 'principalInStore',
  //   formatCell: (transfer: IRetailersBatchTableData) => numberToCurrency(transfer.principalSum + transfer.discountSum, true),
  // },
  {
    textAlign: 'center',
    label: 'Valor transferido',
    attribute: 'transferedValue',
    formatCell: (transfer: IRetailersBatchTableData) =>
      numberToCurrency(transfer.principalSum - transfer.umeFeeSum, true),
  },
  {
    textAlign: 'center',
    label: 'Valor transferido descontos',
    attribute: 'discountSum',
    formatCell: (transfer: IRetailersBatchTableData) => numberToCurrency(transfer.discountSum, true),
  },
  {
    textAlign: 'center',
    label: 'Valor total',
    attribute: 'totalSum',
    formatCell: (transfer: IRetailersBatchTableData) => numberToCurrency(transfer.totalSum, true),
  },
  {
    textAlign: 'center',
    label: 'Principal',
    attribute: 'principalSum',
    formatCell: (transfer: IRetailersBatchTableData) => numberToCurrency(transfer.principalSum, true),
  },
  {
    textAlign: 'center',
    label: 'Taxa UME',
    attribute: 'umeFeeSum',
    formatCell: (transfer: IRetailersBatchTableData) => numberToCurrency(transfer.umeFeeSum, true),
  },
]

const StatusLabel = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  height: 50%;
  width: 70%;
  text-align: center;
  // border-radius: 5px;
  padding: 5px;
  font-size: 12px;

  color: ${UMEColors.white.primary};
`
