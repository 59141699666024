import { Calendar as GrommetCalendar } from 'grommet'
import styled from 'styled-components'
import { Button } from '../Button'
import { Card } from '../Card'
import { black, violet } from '../utils/_colors'

const StyledCalendar = styled(GrommetCalendar)`
  margin-left: 18px;
  > div {
    > div:nth-child(2) {
      margin-top: 50px;
    }
  }
`
const StyledButton = styled(Button)`
  height: 30px;
  font-size: 13px;
`

const YearPanel = styled(Card)`
  width: 262px;
  position: absolute;
  border: none;
  margin-top: 90px;
  z-index: 10;
  height: 260px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 8px 0;
`

const MonthComponent = styled(Card)`
  width: 44px;
  height: 44px;
  font-size: 13px;
  border: none;
  cursor: pointer;
  padding: 10px 8px;
  border-radius: 12px;
  box-shadow: none;

  &.jan {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }

  &.fev {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }

  &.mar {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }

  &.abr {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.mai {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.jun {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.jul {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.ago {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.set {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.out {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.nov {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }
  &.dez {
    color: ${(props) => (props.color ? props.color : black.primary)};
    font-weight: ${(props) => (props.color !== black.primary ? 'bold' : 'initial')};
  }

  &:hover {
    border: 1px solid ${violet.eletric};
  }
`

export { MonthComponent, StyledCalendar, YearPanel, StyledButton }
