import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { IGetRetailerFirstPurchase, OriginationFirstPurchaseDto } from './dto/FirstPurchase.dto'
import { IGetOriginationsRanking, StoreOriginationRankingPositionDto } from './dto/OriginationRanking.dto'
import { IGetRetailerRecurrence, OriginationRecurrencePurchaseDto } from './dto/RecurrencePurchase.dto'

export default class TransactionsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getOriginationsFirstPurchase(params: IGetRetailerFirstPurchase): Promise<OriginationFirstPurchaseDto> {
    const response = await this.axiosInstance.get<OriginationFirstPurchaseDto>(
      `transactions/retailers-dash/originations/first-purchase`,
      { params }
    )

    return response.data
  }

  async getOriginationsRecurrencePurchase(params: IGetRetailerRecurrence): Promise<OriginationRecurrencePurchaseDto> {
    const response = await this.axiosInstance.get<OriginationRecurrencePurchaseDto>(
      `transactions/retailers-dash/originations/recurrence`,
      { params }
    )

    return response.data
  }

  async getOriginationsRankingByStore(params: IGetOriginationsRanking): Promise<StoreOriginationRankingPositionDto> {
    const response = await this.axiosInstance.get<StoreOriginationRankingPositionDto>(
      `transactions/retailers-dash/originations/stores/ranking`,
      { params }
    )

    return response.data
  }
}
