import styled from 'styled-components'
import { white, black, gray } from '../Palette'

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`

const HeaderRow = styled.tr`
  height: 56px;
  margin-bottom: 10px;
  background-color: white;
`

const HeaderElement = styled.th`
  color: ${black.light};
  font-family: 'Roboto';
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.textAlign};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  padding-bottom: 20px;
  padding-top: 20px;
  min-width: 130px;
  padding-left: 16px;

  border-bottom: 1px solid rgba(57, 57, 57, 0.2);

  position: sticky;
  top: 0;
  background-color: white;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`

const Select = styled.select`
  width: 100%;
  cursor: pointer;
  padding: 9px 9px 9px 9px;
  margin-bottom: 6px;

  box-sizing: border-box;
  border: 1px solid ${gray.light};
  border-radius: 4px;
  background-color: ${white.primary};
  color: ${black.light};

  font-family: Roboto;
  font-size: ${(props) => props.fontSize};
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
`
const Divider = styled.td`
  border-top: 1px solid rgba(57, 57, 57, 0.2);
  height: 5px;
`

const Data = styled.tbody``

const DataRow = styled.tr`
  height: 40px;
  border-bottom: solid 3px ${white.primary};
  color: ${black.primary};
  background-color: ${white.primary};

  &:nth-child(even) {
    background-color: ${gray.azure};
  }

  &:hover {
    background-color: ${gray.dim};
    color: white;
    transition: 150ms;
    cursor: pointer;
  }
`

const DataRowElement = styled.td`
  font-family: Roboto;
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.textAlign};

  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 16px;

  &:first-child {
    padding-left: 16px;
  }
`

export { Table, HeaderRow, HeaderElement, Select, Divider, Data, DataRow, DataRowElement }
