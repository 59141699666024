import styled from 'styled-components'
import React from 'react'

interface ContainerProps {
  userIsLoggedin?: boolean
}

const PhoneLayout = (props: JSX.IntrinsicElements['div']) => {
  return <Container style={props.style}>{props.children}</Container>
}

const HEADER_HEIGHT = 56
const Container = styled.div`
  padding: 10px 24px 20px 24px;
  display: flex;
  flex-direction: column;

  text-align: left;
  height: calc(100% - ${HEADER_HEIGHT}px);

  // For Desktop
  max-width: 400px;
  margin: auto;
`

export default PhoneLayout
