import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { Role } from '../../common/constants'
import { CaptureMethod } from '../bff/coordinator/interfaces/captureMethods'

export enum CaptureMethodConfig {
  BY_CPF = 'BY_CPF',
  BY_QR_CODE = 'BY_QR_CODE',
}

export interface CaptureMethodStorageConfig {
  captureMethod: CaptureMethodConfig
}

export interface CaptureMethodUIConfig {
  captureMethod: CaptureMethodConfig
  label: string
}

export class CaptureMethodService {
  static allowedOperatorRoles = [Role.ATTENDANT, Role.CASHIER, Role.MANAGER, Role.ANALYST]

  static get(): CaptureMethodUIConfig {
    const captureMethod = LocalStorageWrapper.getItem(LocalStorageItem.CAPTURE_METHOD_CONFIG)
    if (!captureMethod) {
      const defaultCaptureMethod = { captureMethod: CaptureMethodConfig.BY_CPF }
      CaptureMethodService.set({ captureMethod: defaultCaptureMethod.captureMethod })
      return {
        captureMethod: defaultCaptureMethod.captureMethod,
        label: CaptureMethodService.captureMethodConfigToUIText(defaultCaptureMethod.captureMethod),
      }
    }

    return {
      captureMethod: captureMethod.captureMethod,
      label: CaptureMethodService.captureMethodConfigToUIText(captureMethod.captureMethod),
    }
  }

  static set(captureMethod: CaptureMethodStorageConfig): void {
    LocalStorageWrapper.setItem(LocalStorageItem.CAPTURE_METHOD_CONFIG, captureMethod)
  }

  private static captureMethodConfigToUIText(captureMethod: CaptureMethodConfig): string {
    switch (captureMethod) {
      case CaptureMethodConfig.BY_CPF:
        return 'Via CPF'
      case CaptureMethodConfig.BY_QR_CODE:
        return 'Via QR Code UME'
      default:
        return 'Padrão desconhecido'
    }
  }

  static isAllowedToUseQRCodeCaptureMethod = (captureMethod?: number) => {
    return captureMethod && captureMethod === CaptureMethod.QRCode
  }

  static shouldUseQrCodeCaptureMethod = (captureMethod?: number) => {
    const isAllowedToUseQRCodeCaptureMethod = CaptureMethodService.isAllowedToUseQRCodeCaptureMethod(captureMethod)
    const configuredCaptureMethod = CaptureMethodService.get()
    return isAllowedToUseQRCodeCaptureMethod && configuredCaptureMethod.captureMethod === CaptureMethodConfig.BY_QR_CODE
  }

  static allowedCaptureMethods(operatorRole?: Role, storeCaptureMethod?: number): CaptureMethodUIConfig[] {
    if (
      !operatorRole ||
      !storeCaptureMethod ||
      !CaptureMethodService.isAllowedToUseQRCodeCaptureMethod(storeCaptureMethod) ||
      !CaptureMethodService.allowedOperatorRoles.includes(operatorRole)
    ) {
      return []
    }

    return [
      {
        captureMethod: CaptureMethodConfig.BY_CPF,
        label: CaptureMethodService.captureMethodConfigToUIText(CaptureMethodConfig.BY_CPF),
      },
      {
        captureMethod: CaptureMethodConfig.BY_QR_CODE,
        label: CaptureMethodService.captureMethodConfigToUIText(CaptureMethodConfig.BY_QR_CODE),
      },
    ]
  }
}
