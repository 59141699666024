import { Proposal } from '@scudraservicos/coordinator-client/dist/src/services/budgets/interfaces/ChatbotLimitBudgetResponse';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { numberToCurrency } from '../../../common/NumberToCurrency';
import { ReactComponent as BackIcon } from '../../../common/assets/images/arrow-left.svg';
import FeatureFlags from '../../../common/assets/utils/featureFlags';
import { Bold, Text, Title } from '../../../molecules/Budget';
import { BigButton } from '../../../molecules/Budget/BigButton';
import { goToPage } from '../../../molecules/Budget/utils';
import BudgetReducer from '../../../redux/reducers/budget/budget.reducer';
import { useTypedSelector } from '../../../redux/reducers/selectors';
import { BUDGET_PAGES } from './budget-utils';

interface ProcessedProposal {
  numberOfInstallments: string;
  installmentValue: string;
  principalWithTaxes: string;
}

interface PageInfo {
  title?: string;
  subtitle?: JSX.Element;
  oldLimit?: string;
  newLimit?: string;
  purchaseValue: string;
  proposals: ProcessedProposal[];
  mainColor: '#11B048' | '#3350FF';
  valueToComplete?: string;
  simulationValue: string;
}

const BudgetResponse = (): JSX.Element => {
  const dispatch = useDispatch();

  const [pageInfo, setPageInfo] = React.useState<PageInfo>();
  const { budget } = useTypedSelector((state) => state.budget);

  React.useEffect(() => {
    if (budget) {
      switch (budget.action) {
        case 'APPROVED_AUTO':
          setPageInfo({
            title: 'Uhul! 🎉',
            subtitle: FeatureFlags.isBudgetBlackCampaignActive() 
              ? <Title>Conseguimos o aumento de limite</Title> 
              : <Title>Conseguimos o aumento <Bold style={{ color: '#11B048' }}>total</Bold> de limite</Title>,
            newLimit: budget.formatted_new_limit,
            purchaseValue: budget?.formatted_purchase_value as string,
            oldLimit: budget?.formatted_old_limit as string,
            proposals: processInstallments(budget.proposals) as ProcessedProposal[],
            mainColor: '#11B048',
            simulationValue: budget?.formatted_simulation_value as string
          })
          break
        case 'PARTIAL_APPROVED_AUTO':
          setPageInfo({
            title: 'Quase lá! 💰',
            subtitle: <Title>Aumento <Bold style={{ color: '#3350FF' }}>parcial</Bold> do limite foi aprovado</Title>,
            newLimit: budget.formatted_new_limit,
            purchaseValue: budget?.formatted_purchase_value as string,
            proposals: processInstallments(budget.proposals) as ProcessedProposal[],
            valueToComplete: numberToCurrency(Number(budget.purchase_value) - Number(budget.new_limit), true),
            oldLimit: budget?.formatted_old_limit as string,
            mainColor: '#3350FF',
            simulationValue: budget?.formatted_simulation_value as string
          })
          break
        case 'DENIED_AUTO':
          setPageInfo({
            title: 'Poxa vida! 💸',
            subtitle: <Title>Não foi possível aumentar o limite :(</Title>,
            newLimit: budget.formatted_available_limit as string,
            purchaseValue: budget?.formatted_purchase_value as string,
            proposals: processInstallments(budget.proposals) as ProcessedProposal[],
            valueToComplete: numberToCurrency(Number(budget.purchase_value) - Number(budget.available_limit), true),
            mainColor: '#11B048',
            simulationValue: budget?.formatted_simulation_value as string
          })
          break
        case 'SUFFICIENT_LIMIT':
          setPageInfo({
            purchaseValue: budget?.formatted_purchase_value as string,
            proposals: processInstallments(budget.proposals) as ProcessedProposal[],
            oldLimit: budget?.formatted_total_limit as string,
            mainColor: '#11B048',
            simulationValue: budget?.formatted_simulation_value as string
          })
          break
      }
    }
  }, [budget])


  const processInstallments = (proposals?: Proposal[]): ProcessedProposal[] | undefined => {
    return proposals?.map(proposal => ({
      numberOfInstallments: String(proposal.numberOfInstallments),
      installmentValue: numberToCurrency(proposal.installmentValue, true),
      principalWithTaxes: numberToCurrency(
        proposal.installmentValue * proposal.numberOfInstallments,
        true
      )
    }))
  }

  const handleFinishClick = () => dispatch(BudgetReducer.actions.resetRequest());

  const handleBackClick = () => {
    if (
      budget?.action === 'APPROVED_AUTO' ||
      budget?.action === 'PARTIAL_APPROVED_AUTO'
    ){
      goToPage(dispatch, BUDGET_PAGES.INCREASE_LIMIT_ONBOARDING_PAGE)();
    } else {
      dispatch(BudgetReducer.actions.resetRequest());
    }
  }

  return (
    <div>
      {budget?.action === 'DENIED_AUTO' ||  budget?.action === 'SUFFICIENT_LIMIT' ? 
        <NavBar style={{justifyContent: 'flex-end'}}>
          <BackWrapper onClick={handleFinishClick}>
           <Text color='#0E2DE5'>Finalizar</Text>
          </BackWrapper>
        </NavBar>   
        :
        <NavBar>
          <BackWrapper onClick={handleBackClick}>
            <BackIcon style={{ marginRight: 20 }} />
             <Text clickable>Voltar</Text>
          </BackWrapper>

          <Text color='#0E2DE5' onClick={handleFinishClick}>
           Finalizar
          </Text>
        </NavBar>
      }

      {pageInfo?.title &&
        <div style={{ marginBottom: 30 }}>
          <Title>
            <Bold>{pageInfo?.title}</Bold>
          </Title>
          {pageInfo.subtitle}
        </div>}

      {budget?.action !== 'SUFFICIENT_LIMIT' && <BoxesWrapper>
        {budget?.action !== 'DENIED_AUTO' && <Box style={{ background: '#F3F3F3', marginRight: 10 }}>
          <Title style={{ color: '#92929D' }}>De</Title>
          <Title style={{ color: '#92929D' }}>{pageInfo?.oldLimit}</Title>
        </Box>}

        <Box style={{ border: `1px solid ${pageInfo?.mainColor}` }}>
          <Title>
            <Bold style={{ color: pageInfo?.mainColor }}>
              {budget?.action === 'DENIED_AUTO' ? 'Limite disponível' : 'Para'}
            </Bold>
          </Title>
          <Title>
            <Bold style={{ color: pageInfo?.mainColor }}>{pageInfo?.newLimit}</Bold>
          </Title>
        </Box>
      </BoxesWrapper>}

      <Text style={{ marginBottom: 30 }}>{`Valor da compra ${pageInfo?.purchaseValue}`}</Text>

      <NewLimitWrapper>
        <Title><Bold>Orçamento de</Bold></Title>
        <Title>
          <Bold style={{ color: pageInfo?.mainColor }}>{pageInfo?.simulationValue}</Bold>
        </Title>
      </NewLimitWrapper>

      <TableWrapper>
        <Line style={{ borderBottom: '1px solid #C2C2C2' }}>
          <Header style={{ paddingLeft: 5 }}>
            <HeaderTitle>Número de parcelas</HeaderTitle>
          </Header>
          <Header>
            <HeaderTitle>Valor da parcela</HeaderTitle>
          </Header>
          <Header style={{ paddingRight: 5 }}>
            <HeaderTitle>Valor total com juros</HeaderTitle>
          </Header>
        </Line>
        {pageInfo?.proposals?.map((installment, id) => {
          return <TableRow key={`installment-${id}`}>
            <TalbeData>{installment.numberOfInstallments}x</TalbeData>
            <TalbeData>{installment.installmentValue}</TalbeData>
            <TalbeData>
              <Bold>
                {installment.principalWithTaxes}
              </Bold>
            </TalbeData>
          </TableRow>
        })}
      </TableWrapper>

      {pageInfo?.valueToComplete && <Text style={{ marginBottom: 30 }}>
        Para completar o valor da compra faltam: {pageInfo.valueToComplete}
      </Text>}


      <Text><Bold>*Para concluir a compra é preciso ir ao caixa</Bold></Text>

      <Button onClick={handleFinishClick}>
        Finalizar
      </Button>
    </div>
  );
};

const Button = styled(BigButton).attrs({
  backgroundColor: '#3350FF'
})`
  margin-bottom: 40px;
  margin-top: 40px;
  min-height: 60px;
`;

const NewLimitWrapper = styled.div`
  margin-bottom: 30px;
`;

const TalbeData = styled.div`
  width: calc(100% / 3);
`;

const Header = styled.div`
  width: calc(100% / 3);
  justify-content: center;
  display: flex;
`;

const HeaderTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
`;

const Box = styled.div`
  height: 100px;
  flex-grow: 1;
  border-radius: 10px;
  padding: 16px 8px 16px 8px;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TableWrapper = styled.div`
  margin-bottom: 30px;
`;

const TableRow = styled(Line)`
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
`;

const BoxesWrapper = styled(Line)`
  margin-bottom: 30px;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BudgetResponse;
