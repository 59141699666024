import * as React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import StoreSelection from '../manager-config/components/StoreSelection'
import { bffParceirosApiService, startApiServices } from '../services/bff'
import { Role } from './constants'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  authorizedRoles: Role[]
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode

export class PrivateRoute extends Route<PrivateRouteProps> {
  public render() {
    const { component: Component, authorizedRoles, ...rest }: PrivateRouteProps = this.props

    const renderComponent: RenderComponent = props => {
      startApiServices()

      const logged = bffParceirosApiService.auth.isLogged()
      const authorized = bffParceirosApiService.auth.isAuthorized(authorizedRoles)
      const isStoreConfigured = bffParceirosApiService.coordinator.isStoreSetUp()

      if (logged && authorized) {
        if (!isStoreConfigured) {
          return <StoreSelection />
        }
        return <Component {...props} />
      } else if (logged && !authorized) {
        return <Redirect to={{ pathname: '/' }} />
      } else {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    }

    return <Route {...rest} render={renderComponent} />
  }
}
