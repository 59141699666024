import React from 'react'
import { Icon } from './styles'

export default class DollarNewIcon extends React.PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0353 10.8223C44.7192 -3.60759 68.2757 -3.60718 82.963 10.822C97.679 25.2793 97.679 48.5145 82.963 62.9717C81.8264 64.0882 80.0003 64.0721 78.8838 62.9355C77.7672 61.799 77.7834 59.9729 78.9199 58.8563C91.3344 46.6599 91.3344 27.1338 78.9199 14.9374C66.4765 2.71293 46.518 2.71335 34.079 14.9372C21.6675 27.1339 21.6645 46.6603 34.0787 58.8563C35.2151 59.9729 35.2313 61.799 34.1149 62.9355C32.9984 64.0721 31.172 64.0882 30.0356 62.9717C15.3192 48.5141 15.3239 25.2792 30.0353 10.8223Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2302 75.964C23.2302 74.3709 24.5216 73.0794 26.1148 73.0794H87.6529C89.246 73.0794 90.5375 74.3709 90.5375 75.964C90.5375 77.5571 89.246 78.8486 87.6529 78.8486H26.1148C24.5216 78.8486 23.2302 77.5571 23.2302 75.964Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M56.4991 15.3874C58.092 15.3874 59.3834 16.6788 59.3834 18.2717V23.0789C59.3834 24.6719 58.092 25.9633 56.4991 25.9633C54.9062 25.9633 53.6147 24.6719 53.6147 23.0789V18.2717C53.6147 16.6788 54.9062 15.3874 56.4991 15.3874Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M56.4991 49.041C58.092 49.041 59.3834 50.3324 59.3834 51.9253V56.7326C59.3834 58.3255 58.092 59.6169 56.4991 59.6169C54.9062 59.6169 53.6147 58.3255 53.6147 56.7326V51.9253C53.6147 50.3324 54.9062 49.041 56.4991 49.041Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9994 29.8296C43.9994 24.5106 48.309 20.195 53.6339 20.195H58.9031C64.2673 20.195 68.5938 24.3796 68.9427 29.6186C69.0488 31.2082 67.8458 32.5827 66.2562 32.6885C64.6666 32.7944 63.2923 31.5916 63.1866 30.002C63.0354 27.7335 61.1697 25.9642 58.9031 25.9642H53.6339C51.4974 25.9642 49.7686 27.6947 49.7686 29.8296C49.7686 31.6024 50.9759 33.1497 52.6939 33.5817L61.7054 35.8434C65.9931 36.9191 68.9954 40.7733 68.9954 45.191C68.9954 50.516 64.6796 54.8255 59.3608 54.8255H54.0916C48.7164 54.8255 44.3912 50.629 44.0479 45.3833C43.9438 43.7933 45.1482 42.4202 46.7379 42.3164C48.3276 42.2126 49.7009 43.4168 49.8047 45.0064C49.9536 47.2837 51.8209 49.0564 54.0916 49.0564H59.3608C61.4958 49.0564 63.2262 47.3275 63.2262 45.191C63.2262 43.4164 62.0208 41.8706 60.3008 41.4387L51.2893 39.1772C47.0077 38.1014 43.9994 34.2488 43.9994 29.8296Z"/>
          <rect x="22" y="90" width="70" height="22" rx="8" fill="#11B048"/>
          <text font-weight="bold" transform="matrix(0.704356, 0, 0, 0.662493, 5.12189, 37.3505)" text-anchor="start" font-family="'Roboto'" font-size="17" y="102.04632" x="30.38407" stroke-width="0" fill="#ffffff">NOVIDADE!</text>
        </svg>
      </Icon>
    )
  }
}
