import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NotCheck } from '../../../common/assets/images/not-check-circle.svg'
import PhoneLayout from '../../../ui/PhoneLayout/PhoneLayout'
import { green, white } from '../../../ui/utils/_colors'

interface Props {
  handleGoBackToHomePage?: () => void
}

export const SmartphoneSaleDenied: React.FC<Props> = (props: Props) => {
  const { handleGoBackToHomePage } = props

  return (
    <MainContainer>
      <FormAndTopWrapper>
        <Container>
          <NotCheck style={{ width: '48px', height: '48px' }} />
          <Title>Crédito não aprovado</Title>
          <Message>Infelizmente no momento não conseguimos liberar nenhum valor para esse cadastro.</Message>
        </Container>
      </FormAndTopWrapper>

      <Footer>
        <ConfirmButton onClick={handleGoBackToHomePage} backgroundColor={white.primary} textColor={green.dark1}>
          Ok, entendi
        </ConfirmButton>
      </Footer>
    </MainContainer>
  )
}

const MainContainer = styled(PhoneLayout).attrs({
  style: {
    justifyContent: 'space-between',
    padding: 40,
  },
})``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px 18px;
`

const FormAndTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200px;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: black;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  align-items: center;
  padding-top: 15px;
`

const Message = styled.text`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 8px 0px;
  color: #182026;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`
const ConfirmButton = styled.button`
  margin-top: 2vh;
  background: ${(props: any) => (props && props.disabled ? green.light3 : green.dark1)};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`
