import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class Sort extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </Icon>
    )
  }
}
