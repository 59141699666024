import { formatBRL } from '../../../cash-register/common/formatBRL'
import { DateFormat, formatDate, formatNullableDate, TimeZone } from '../../../common/FormatDate'
import { OriginationsReportActions } from '../OriginationsReportActions'
import React from 'react'
import { ContractStatusTag } from '../ContractStatusTag'

const originationsHeader = [
  {
    property: 'originationTimestamp',
    label: 'Data da venda',
    format: (date: string) => formatDate(date, DateFormat.BR_DATE_FORMAT_WITH_HOUR, TimeZone.MANAUS),
  },
  {
    property: 'canceledOn',
    label: 'Status do contrato',
    format: (date?: string) => {
      return <ContractStatusTag canceledOn={date}/>
    },
  },
  {
    property: 'canceledOn',
    label: 'Data do cancelamento',
    format: (date?: string) => formatNullableDate(date, DateFormat.BR_DATE_FORMAT_WITH_HOUR, TimeZone.MANAUS),
  },
  { property: 'contractId', label: 'Identificador do contrato' },
  { property: 'storeName', label: 'Loja' },
  { property: 'borrowerName', label: 'Cliente' },
  { property: 'operatorName', label: 'Operador' },
  { property: 'principal', label: 'Principal', format: formatBRL },
  { property: 'couponPrincipalDiscount', label: 'Desconto', format: formatBRL },
  { property: 'MDR', label: 'Taxa Ume', format: formatBRL },
  { property: 'netValue', label: 'Valor Líquido', format: formatBRL },
  { property: 'IOF', label: 'IOF', format: formatBRL },
  {
    property: 'contractId',
    label: 'Ações',
    format: (contractId: string) => {
      return <OriginationsReportActions contractId={contractId} />
    },
  },
]

export const OriginationsReportConfig = {
  label: 'Vendas',
  route: '/relatorios',
  header: originationsHeader,
}
