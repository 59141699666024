import { Button, Card, Colors, Elevation, H4, H5, Icon } from '@blueprintjs/core'
import * as React from 'react'
import { Redirect } from 'react-router'
import { formItemsContainerStyle } from '../styles'

const spinnerContainerOverrideStyle = {
  ...formItemsContainerStyle,
  width: '60vw',
  height: '55vh',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0D8050',
}

export interface IRenderReceiptsProps {
  renderURL: string
  numberOfInstallments: number
  paidValue: number
}

export interface IRenderReceiptsState {
  redirectToCashierHome: boolean
}

export default class RenderReceipts extends React.Component<IRenderReceiptsProps, IRenderReceiptsState> {
  public readonly state: IRenderReceiptsState = {
    redirectToCashierHome: false,
  }

  public setupRedirect = () => {
    this.setState({
      redirectToCashierHome: true,
    })
  }
  componentDidMount() {
    window.open(this.props.renderURL, '_blank')
  }
  public render() {
    const { redirectToCashierHome } = this.state

    if (redirectToCashierHome) {
      return <Redirect to="/caixa" />
    }

    return (
      <Card style={spinnerContainerOverrideStyle} interactive={false} elevation={Elevation.TWO}>
        <div style={{ padding: '32px' }}>
          <Icon style={{ color: 'white' }} iconSize={75} icon={'tick'} />
        </div>
        <H4 style={{ color: 'white' }}>
          {`Pagamento de ${this.props.numberOfInstallments} parcela${
            this.props.numberOfInstallments > 1 ? 's' : ''
          } no valor de `}
          {this.props.paidValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          {' recebido com sucesso. Se os comprovantes não abrirem, '}
          <a
            style={{ textDecoration: 'underline', color: 'white' }}
            rel="noopener noreferrer"
            href={this.props.renderURL}
            target="_blank"
            tabIndex={1}
          >
            {'CLIQUE AQUI.'}
          </a>
        </H4>

        <Button
          large
          style={{
            marginTop: '32px',
            backgroundColor: Colors.LIGHT_GRAY4,
            backgroundImage: 'none',
          }}
          autoFocus
          icon="arrow-left"
          onClick={this.setupRedirect}
          tabIndex={1}
        >
          <H5 style={{ color: Colors.GRAY1, marginBottom: 0 }}>Voltar à página inicial de Caixa</H5>
        </Button>
      </Card>
    )
  }
}
