export const formItemsContainerStyle = {
  padding: '2.5em',
  display: 'flex',
  flexDirection: 'column' as any,
  width: '60vw',
}

export const formItemStyle = {
  marginTop: '32px',
  alignSelf: 'flex-start',
}

export const formActionsStyle = {
  display: 'flex',
  flexDirection: 'row-reverse' as any,
}

export const centeredButtonGroup = {
  display: 'flex',
  justifyContent: 'space-evenly',
}

export const cellPhoneFormGroupStyle = {
  display: 'flex',
}

export const buttonStyle = {
  width: '10em',
}

export const h5Style = {
  alignSelf: 'flex-start',
  marginLeft: '1.5em',
  marginTop: 0,
  marginBottom: '1.5em',
}

export const formContainerStyle = {
  display: 'flex',
  marginTop: '7em',
  justifyContent: 'center',
  alignItems: 'baseline',
  height: '100vh',
  widht: '100vw',
}
