import cloudDevConfig from '../config.cloud-dev.json'
import devConfig from '../config.dev.json'
import prodConfig from '../config.prod.json'
import stagingConfig from '../config.staging.json'

const deployMode = (process.env.REACT_APP_NODE_ENV as 'prod' | 'staging' | 'cloud-dev' | 'dev') || 'dev'

console.log('STARTING IN MODE', deployMode)
export interface Config {
  bff: {
    url: string
    authorization: string
  }
  biometryConfig: {
    photosMaxSize: number
    retryPhotosMaxSize: number
  }
  access_lists: {
    useNativeCameraOperatorsSafeList: string
    renderNewPurchaseContractOptionsForRetailersSafeList: string
    shouldRunApplicationReavaluationStoresSafeList: string
    exportCcbOnPurchaseRetailersSafeList: string
  }
  feature_flags: {
    active_budget_black_campaign: boolean
    allowTransactionalCreditEvaluation: boolean
    enableTransactionalCreditOnServiceCentral: boolean
  }
  budget_black_campaign: {
    expirationDate: string
  }
  bmp: {
    download_proposal_base_url: string
  }
}

export const getConfig = (): Config => {
  switch (deployMode) {
    case 'cloud-dev':
      return cloudDevConfig
    case 'dev':
      return devConfig
    case 'staging':
      return stagingConfig
    case 'prod':
      return prodConfig
    default:
      return devConfig
  }
}

export const getParceirosBffApiRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'http://localhost:3050/v1',
    // dev: 'https://bff-parceiros-dev-essodihd3q-ue.a.run.app/v1',
    'cloud-dev': 'https://dev-api.ume.com.br/retailers/bff-parceiros/v1',
    staging: 'https://stage-api.ume.com.br/retailers/bff-parceiros/v1',
    prod: 'https://api.ume.com.br/retailers/bff-parceiros/v1',
  }
  return urlsEnv[deployMode]
}

export const getCoordinatorApiRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'http://localhost:3001/v1',
    'cloud-dev': 'https://dev-api.ume.com.br/originations/coordinator/v1',
    staging: 'https://stage-api.ume.com.br/originations/coordinator/v1',
    prod: 'https://api.ume.com.br/originations/coordinator/v1',
  }
  return urlsEnv[deployMode]
}

//TODO: update keycloack enviroments configs
export const getKeycloackRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'https://dev-api.ume.com.br/keycloak',
    'cloud-dev': 'https://dev-api.ume.com.br/keycloak',
    staging: 'https://stage-api.ume.com.br/keycloak',
    prod: 'https://api.ume.com.br/keycloak',
  }
  const realm: { [env: string]: string } = {
    dev: 'analysts',
    'cloud-dev': 'analysts',
    staging: 'analysts',
    prod: 'analysts',
  }
  const clientId: { [env: string]: string } = {
    dev: 'backoffice',
    'cloud-dev': 'backoffice',
    staging: 'backoffice',
    prod: 'backoffice',
  }

  return { url: urlsEnv[deployMode], clientId: clientId[deployMode], realm: realm[deployMode] }
}

export const getGTMId = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'GTM-TG6674C',
    'cloud-dev': 'GTM-TG6674C',
    staging: 'GTM-NW8MLHX',
    prod: 'GTM-M4VWCFB',
  }
  return urlsEnv[deployMode]
}

export const getSentryDSN = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: '',
    'cloud-dev': 'https://ac490eb81aa7448a84d0c417f365ba9f@o489785.ingest.sentry.io/5552629',
    staging: 'https://4991ecc8e4e44901bc93be914252bde4@o489785.ingest.sentry.io/5552665',
    prod: 'https://438ab6a0d61749ed82b111c4293bfda5@o489785.ingest.sentry.io/5552667',
  }
  return urlsEnv[deployMode]
}

export const UME_PHONES = {
  customerService: {
    whatsapp: '+551140201960',
    phoneNumber: '08000312333',
  },
  retailerService: {
    whatsapp: '+5592993104500',
    phoneNumber: '',
  },
  retailerSAC: {
    whatsapp: '551140204025',
    phoneNumber: '1140204025',
  },
}
// --------------- ACQUISITION GATEWAY ---------------

const featureFlags = {
  dev: {
    managerModuleActivate: true,
    operatorsSetupActive: true,
    managerAuthActive: true,
    isResetBiometryAllowed: true,
    isRetailerCreationAllowed: true,
    isZipCodeSearchAllowed: true,
    isNewLoginActive: true,
    isNewRetailerDashActive: true,
    cashier: {
      showLimit: false,
      showLocalCashierSelector: false,
    },
    isReportsPageActive: true,
    application: {
      shouldGenerateApplicationCoupon: true,
    },
  },
  'cloud-dev': {
    managerModuleActivate: true,
    operatorsSetupActive: true,
    managerAuthActive: true,
    isResetBiometryAllowed: true,
    isRetailerCreationAllowed: true,
    isZipCodeSearchAllowed: true,
    isNewLoginActive: true,
    isNewRetailerDashActive: true,
    cashier: {
      showLimit: false,
      showLocalCashierSelector: false,
    },
    isReportsPageActive: true,
    application: {
      shouldGenerateApplicationCoupon: true,
    },
  },
  staging: {
    managerModuleActivate: true,
    operatorsSetupActive: true,
    managerAuthActive: true,
    isResetBiometryAllowed: true,
    isRetailerCreationAllowed: true,
    isZipCodeSearchAllowed: true,
    isNewLoginActive: true,
    isNewRetailerDashActive: true,
    cashier: {
      showLimit: false,
      showLocalCashierSelector: false,
    },
    isReportsPageActive: true,
    application: {
      shouldGenerateApplicationCoupon: true,
    },
  },
  prod: {
    managerModuleActivate: true,
    operatorsSetupActive: true,
    managerAuthActive: true,
    isResetBiometryAllowed: true,
    isRetailerCreationAllowed: true,
    isZipCodeSearchAllowed: true,
    isNewLoginActive: true,
    isNewRetailerDashActive: true,
    cashier: {
      showLimit: false,
      showLocalCashierSelector: false,
    },
    isReportsPageActive: false,
    application: {
      shouldGenerateApplicationCoupon: false,
    },
  },
}

export const managerModuleActivate = featureFlags[deployMode].managerModuleActivate
export const operatorsSetupActive = featureFlags[deployMode].operatorsSetupActive
export const managerAuthActive = featureFlags[deployMode].managerAuthActive
export const isResetBiometryAllowed = featureFlags[deployMode].isResetBiometryAllowed
export const isRetailerCreationAllowed = featureFlags[deployMode].isRetailerCreationAllowed
export const isZipCodeSearchAllowed = featureFlags[deployMode].isZipCodeSearchAllowed
export const isNewLoginActive = featureFlags[deployMode].isNewLoginActive
export const isNewRetailerDashActive = featureFlags[deployMode].isNewRetailerDashActive
export const cashierShowLimit = featureFlags[deployMode].cashier.showLimit
export const showLocalCashierSelector = featureFlags[deployMode].cashier.showLocalCashierSelector
export const isReportsPageActive = featureFlags[deployMode].isReportsPageActive
export const shouldGenerateApplicationCoupon = featureFlags[deployMode].application.shouldGenerateApplicationCoupon
