import { Button, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-animated-css'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { PhoneIcon } from '../../common/assets/images/PhoneIcon'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { APPLICATION_PAGES } from './ApplicationSteps/application-utils'

// TODO - Type as rect element
interface IApplicationLayoutProps {
  title: string | React.ReactElement
  subtitle: string | React.ReactElement
  children: any
  disableFooter?: boolean
  labelNextButton?: string
  labelPreviousButton?: string
  isNextButtonEnabled?: boolean
  isNextButtonLoading?: boolean
  overrideNextPage?: (ok: Function) => void
  overridePreviousPage?: (ok: Function) => void
}

const ApplicationLayout = (props: IApplicationLayoutProps) => {
  const dispatch = useDispatch()
  const {
    title,
    subtitle,
    children,
    disableFooter,
    isNextButtonEnabled,
    isNextButtonLoading,
    overrideNextPage,
    overridePreviousPage,
    labelNextButton,
    labelPreviousButton,
  } = props
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false)

  const {
    hasSendVerificationCode,
    isPhoneVerified,
    hasPhoneVerification,
    pages,
    currentPageNumber,
    isPhoneModalActive,
  } = useTypedSelector(state => ({
    hasSendVerificationCode: state.application.hasSendVerificationCode,
    isPhoneVerified: state.application.isPhoneVerified,
    hasPhoneVerification: state.configurations.configurations.application.hasPhoneVerification,
    pages: state.application.pagesOrder,
    currentPageNumber: state.application.page,
    isPhoneModalActive: state.application.isPhoneModalVisible,
  }))

  const handlePhoneIconOnClick = () => {
    if (hasSendVerificationCode) {
      setTooltipVisible(false)
      dispatch(ApplicationSliceReducer.actions.phoneModalAction(true))
    }
  }

  const handleNextPage = () => {
    if (overrideNextPage) {
      overrideNextPage(() => dispatch(ApplicationSliceReducer.actions.nextPage()))
    } else {
      dispatch(ApplicationSliceReducer.actions.nextPage())
    }
  }

  const handlePreviousPage = () => {
    if (overridePreviousPage) {
      overridePreviousPage(() => dispatch(ApplicationSliceReducer.actions.previousPage()))
    } else {
      dispatch(ApplicationSliceReducer.actions.previousPage())
    }
  }

  const phoneIconColor = isPhoneVerified ? '#11B048' : undefined
  const counter = useRef<number>(0)

  useEffect(() => {
    if (counter.current === 1 && hasSendVerificationCode && !isPhoneVerified) {
      setTimeout(() => {
        setTooltipVisible(true)
      }, 800)
      setTimeout(() => {
        setTooltipVisible(false)
      }, 4000)
      setTimeout(() => {
        dispatch(ApplicationSliceReducer.actions.phoneModalAction(true))
      }, 1500)
    }

    counter.current++
  }, [hasSendVerificationCode])

  useEffect(() => {
    if (!isPhoneModalActive && hasSendVerificationCode && !isPhoneVerified) {
      setTimeout(() => {
        setTooltipVisible(true)
      }, 800)
      setTimeout(() => {
        setTooltipVisible(false)
      }, 4000)
    }
  }, [isPhoneModalActive])

  return (
    <Container disableFooter={disableFooter}>
      <Header>
        <TitleContainer>
          <Title> {title} </Title>
          <Animated
            animationIn="bounceInRight"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={0}
            isVisible={
              hasPhoneVerification &&
              hasSendVerificationCode &&
              pages[currentPageNumber] === APPLICATION_PAGES.PHONE_PAGE
            }
            animateOnMount={false}
          >
            <Tooltip
              visible={isTooltipVisible}
              placement="left"
              color="#11b048"
              // color="orange"
              title={
                <React.Fragment>
                  Clique aqui para <br /> verificar o telefone
                </React.Fragment>
              }
            >
              <PhoneIcon
                color={phoneIconColor}
                // If we remove the item when no verification code was sent, then the layout will change
                // style={{ opacity: hasSendVerificationCode ? 1 : 0 }}
                onClick={handlePhoneIconOnClick}
              />
            </Tooltip>
          </Animated>
        </TitleContainer>
        <SubTitle> {subtitle}</SubTitle>
      </Header>
      <Body>{children}</Body>
      {disableFooter ? (
        <></>
      ) : (
        <Footer>
          <BackButton onClick={handlePreviousPage}>{labelPreviousButton ?? 'Voltar'}</BackButton>
          <NextButton loading={isNextButtonLoading} disabled={!isNextButtonEnabled} onClick={handleNextPage}>
            {labelNextButton ?? 'Próximo'}
          </NextButton>
        </Footer>
      )}
    </Container>
  )
}

export default ApplicationLayout

const Container = styled(PhoneLayout)`
  justify-content: ${(props: any) => (props && props.disableFooter ? 'flex-start' : 'space-between')};
`

const Header = styled.div`
  // height: 15%;

  // margin-bottom: min(30px, 2vh);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;
  align-self: center;

  color: #000000;
`

const SubTitle = styled.span`
  // margin-top: 3vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

const Body = styled.div`
  // height: 77%;
  flex: 1;
  margin-bottom: 10px;
`

const Footer = styled.div`
  // height: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048 !important;

  // BUTTON
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #11b048 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  width: 80% !important;
  height: 100% !important;
`

const NextButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 80% !important;
  height: 100% !important;
  margin-left: 6vw !important;

  // TODO - Add hover
  ${(props: any) =>
    props && props.disabled
      ? 'opacity: 0.3;'
      : ''} // &:hover {
  //   cursor: pointer;
  //   transition: 0.3s;
  //   background-color: #11b048;
  //   opacity: 0.5;
  // }

  // &:focus {
  //   background-color: #11b048;
  // }

  // &:active {
  //   background-color: #11b048;
  // }
`
