import { IOperatorRankingPositionResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-ranking-position'
import { Box } from 'grommet'
import * as React from 'react'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  CardTitleHeadingStyle,
  NumberBoxMobileStyle,
  NumberHeadingStyle,
  RankingPositionBoxStyle,
  RankingPositionTextStyle,
  RankingSizeTextStyle,
  SmallCardStyle,
  SmallTextStyle,
  TextStyle,
} from './style'

export interface IOperatorRankingCardsProps {
  operatorRanking?: IOperatorRankingPositionResponse | Error
}

export interface IOperatorRankingCardsState {}

export default class OperatorRankingCards extends React.PureComponent<
  IOperatorRankingCardsProps,
  IOperatorRankingCardsState
> {
  public render() {
    const { operatorRanking } = this.props

    const totalApplicationsCardBox =
      operatorRanking instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        operatorRanking && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Você fez:</CardTitleHeadingStyle>
              <Box>
                <NumberHeadingStyle>
                  {operatorRanking.numberOfApplications > 9
                    ? operatorRanking.numberOfApplications
                    : '0' + operatorRanking.numberOfApplications}
                </NumberHeadingStyle>
                <TextStyle light>aplicações</TextStyle>
              </Box>
            </Box>
            <Box className="is-mobile" fill justify="between">
              <CardTitleHeadingStyle>Você fez:</CardTitleHeadingStyle>
              <NumberBoxMobileStyle align="center">
                <Box margin={{ right: 'medium' }}>
                  <NumberHeadingStyle>
                    {operatorRanking.numberOfApplications > 9
                      ? operatorRanking.numberOfApplications
                      : '0' + operatorRanking.numberOfApplications}
                  </NumberHeadingStyle>
                </Box>
                <SmallTextStyle>aplicações</SmallTextStyle>
              </NumberBoxMobileStyle>
            </Box>
          </>
        )
      )

    const rankingPosition =
      operatorRanking instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        operatorRanking && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Sua colocação:</CardTitleHeadingStyle>
              <RankingPositionBoxStyle direction="row" align="baseline">
                <NumberHeadingStyle>
                  {operatorRanking.rankingPosition ? ` ${operatorRanking.rankingPosition}º` : '--'}
                </NumberHeadingStyle>
                <RankingSizeTextStyle>
                  {operatorRanking.rankingPosition ? ` /${operatorRanking.rankingSize}` : ' /- -'}
                </RankingSizeTextStyle>
              </RankingPositionBoxStyle>
            </Box>
            <Box className="is-mobile" fill justify="between">
              <CardTitleHeadingStyle>Sua colocação:</CardTitleHeadingStyle>
              <RankingPositionBoxStyle direction="row" align="baseline">
                <RankingPositionTextStyle>
                  {operatorRanking.rankingPosition ? ` ${operatorRanking.rankingPosition}º` : '--'}
                </RankingPositionTextStyle>
                <RankingSizeTextStyle>
                  {operatorRanking.rankingPosition ? ` /${operatorRanking.rankingSize}` : ' /- -'}
                </RankingSizeTextStyle>
              </RankingPositionBoxStyle>
            </Box>
          </>
        )
      )

    return (
      <>
        <SmallCardStyle>
          {operatorRanking ? (
            totalApplicationsCardBox
          ) : (
            <Box fill align="center" justify="center">
              <Loading />
            </Box>
          )}
        </SmallCardStyle>

        <SmallCardStyle>
          {operatorRanking ? (
            rankingPosition
          ) : (
            <Box fill align="center" justify="center">
              <Loading />
            </Box>
          )}
        </SmallCardStyle>
      </>
    )
  }
}
