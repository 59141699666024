import { TextProps } from 'grommet'
import React, { Component } from 'react'
import { StyledText } from './styles'

interface StyledTextProps {
  light?: boolean
}

export default class Text extends Component<StyledTextProps & TextProps & Omit<JSX.IntrinsicElements['a'], 'color'>> {
  render() {
    return <StyledText {...this.props}>{this.props.children}</StyledText>
  }
}
