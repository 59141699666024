import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { toaster } from '../../../App'
import { bffParceirosApiService } from '../../../services/bff'
import {
  CreateBorrowerNextInvoiceError,
  CreateBorrowerNextInvoiceErrorType,
  Invoice,
} from '../../../services/bff/coordinator/dto/Invoices.dto'
import InvoicesSliceReducer from './invoices.reducer'

function* createBorrowerNextInvoice(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload

    yield put(InvoicesSliceReducer.actions.createBorrowerNextInvoiceLoading())

    const response = yield bffParceirosApiService.coordinator.createBorrowerNextInvoice(borrowerId)
    yield put(InvoicesSliceReducer.actions.createBorrowerNextInvoiceSuccess(response))
  } catch (error) {
    yield put(InvoicesSliceReducer.actions.createBorrowerNextInvoiceError(error))
  }
}

function* openInvoice(action: PayloadAction<Invoice>) {
  toaster.showSuccessToast('Boleto criado com sucesso!', 4)
  window.open(action.payload.invoiceUrl + '.pdf', '_blank')
}

function* showInvoiceError(action: PayloadAction<CreateBorrowerNextInvoiceError>) {
  if (action.payload.error == CreateBorrowerNextInvoiceErrorType.NO_PENDING_INSTALLMENTS_FOR_BORROWER) {
    toaster.showErrorToast('Não existem parcelas pendentes.', 4)
  } else {
    toaster.showErrorToast('Erro ao criar boleto. Tente novamente!', 4)
  }
}

const invoicesSaga = [
  takeLatest(InvoicesSliceReducer.actions.createBorrowerNextInvoice, createBorrowerNextInvoice),
  takeLatest(InvoicesSliceReducer.actions.createBorrowerNextInvoiceSuccess, openInvoice),
  takeLatest(InvoicesSliceReducer.actions.createBorrowerNextInvoiceError, showInvoiceError),
]

export default invoicesSaga
