import { Box } from 'grommet'
import * as React from 'react'
import { Text } from '../../legacy-lib/design-kit-ui'
import { red } from '../../legacy-lib/design-kit-ui/Palette'
import { Info } from '../../ui/Icons'
import { IconBox } from './style'

export interface IErrorStringProps {
  text: string
}

export default class ErrorString extends React.Component<IErrorStringProps> {
  public render() {
    return (
      <Box direction="row" align="center">
        <IconBox align="center">
          <Info width="12px" color={red.persian} />
        </IconBox>

        <Text size="xsmall" light color={red.persian}>
          {this.props.text}
        </Text>
      </Box>
    )
  }
}
