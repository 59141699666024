import { Button, Card, Dialog, Elevation, HTMLSelect, Spinner, Switch } from '@blueprintjs/core'
import { IOperatorResponse } from '@scudraservicos/coordinator-client/dist/src/services/operators/interfaces/Operator.res.interface'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import React from 'react'
import { toaster } from '../../App'
import { storeIsNotSetUp } from '../../common/ErrorStrings'
import { bffParceirosApiService } from '../../services/bff'
import {
  buttonStyle,
  cardStyle,
  centeredButtonGroup,
  centeredStyle,
  filtersStyle,
  formContainerStyle,
  formItemsContainerStyle,
  h5Style,
} from './style'

export interface IOperatorsSetupState {
  operators: IOperatorResponse[]
  operatorSelected?: IOperatorResponse
  stores: IStoreResponse[]
  selectedStore?: IStoreResponse
  isLoading: boolean
  isEditing: boolean
  showInactiveOperators: boolean
}

export interface HomeRouterProps {
  title: string
  history?: any
  location?: any
}

export const OperatorRoleToPortuguese = (role: string) => {
  const roles: { [key: string]: string } = {
    representative: 'REPRESENTANTE',
    cashier: 'CAIXA',
    attendant: 'ATENDENTE',
    manager: 'GERENTE',
    analyst: 'ANALISTA',
    promoter: 'PROMOTOR',
  }
  return roles[role]
}

export default class OperatorsSetup extends React.Component<HomeRouterProps, IOperatorsSetupState> {
  state: IOperatorsSetupState = {
    operators: [],
    stores: [],
    isEditing: false,
    operatorSelected: undefined,
    selectedStore: undefined,
    isLoading: true,
    showInactiveOperators: false,
  }

  async componentDidMount() {
    const store = bffParceirosApiService.coordinator.getStore()
    if (!store) {
      toaster.showErrorToast(storeIsNotSetUp)
      this.props.history.push('/configuracao')
    } else {
      const operatorsList = (await bffParceirosApiService.coordinator.fetchOperators(store.id)).operators.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      )

      const storesList = (await bffParceirosApiService.retailers.fetchStores()).stores.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      )
      this.setState({
        operators: operatorsList,
        stores: storesList,
        selectedStore: store,
        isLoading: false,
      })
    }
  }

  public onStoreFilterChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const storeId = e.currentTarget.value
    const store = this.state.stores.filter(s => s.id === storeId)[0]
    this.setState({ selectedStore: store, isLoading: store ? true : false })

    if (store) {
      const operatorsList = (await bffParceirosApiService.coordinator.fetchOperators(store.id)).operators.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      )
      this.setState({ operators: operatorsList, isLoading: false })
    }
  }

  public onActivesFilterChange = () => {
    this.setState({ showInactiveOperators: !this.state.showInactiveOperators })
  }

  public editOperator = (operatorId: string) => {
    const operator = this.state.operators.filter(o => o.id === operatorId)[0]
    this.setState({ operatorSelected: operator })
  }

  public createOperator = () => {
    this.props.history.push('/colaboradores/novo')
  }

  public blockOperator = async (operator: IOperatorResponse) => {
    this.setState({ isEditing: true })

    const formattedCellphone = operator.phoneNumber.startsWith('+55')
      ? operator.phoneNumber.slice(3, 14)
      : operator.phoneNumber

    const body = {
      ...operator,
      phoneNumber: '+55' + formattedCellphone,
      password: undefined,
      active: !operator.active,
    }

    try {
      const savedOperator = await bffParceirosApiService.coordinator.editOperator(operator.id, body)
      this.state.selectedStore &&
        this.setState({
          operators: (
            await bffParceirosApiService.coordinator.fetchOperators(this.state.selectedStore.id)
          ).operators.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
        })
      savedOperator.active
        ? toaster.showSuccessToast('Colaborador desbloqueado com sucesso')
        : toaster.showSuccessToast('Colaborador bloqueado com sucesso')
    } catch (error) {
      toaster.showErrorToast('Falha no sistema. Tente novamente')
    }
    this.setState({ isEditing: false })
  }

  render() {
    const {
      operatorSelected,
      isEditing,
      operators,
      stores,
      isLoading,
      selectedStore,
      showInactiveOperators,
    } = this.state

    if (isLoading) {
      return (
        <div style={{ margin: '64px' }}>
          <Spinner size={75} />
        </div>
      )
    }
    if (isEditing) {
      return (
        <Dialog style={{ width: '25em' }} isOpen={isEditing}>
          <div style={centeredStyle}>
            <p style={{ marginRight: '16px' }}>{' Atualizando colaboradores...'}</p>
            <Spinner size={20} />
          </div>
        </Dialog>
      )
    }

    if (operatorSelected) {
      this.props.history.push({
        pathname: '/colaboradores/editar',
        state: { operatorId: operatorSelected.id },
      })
    }

    return (
      <div style={formContainerStyle}>
        <div>
          <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
            <div style={filtersStyle}>
              <h3 style={h5Style} className="bp3-heading">
                {`Colaboradores`}
              </h3>
            </div>

            <div style={{ ...filtersStyle, marginBottom: '32px' }}>
              <HTMLSelect
                value={selectedStore && selectedStore.id ? selectedStore.id : 0}
                onChange={this.onStoreFilterChange}
              >
                <option value={0}>Selecione uma loja...</option>
                {stores.map((store, idx) => (
                  <option value={store.id} key={idx}>
                    {store.name}
                  </option>
                ))}
              </HTMLSelect>
              <Switch checked={showInactiveOperators} label="Exibir Bloqueados" onChange={this.onActivesFilterChange} />
            </div>

            {operators.map(
              operator =>
                operator.active && (
                  <div key={operator.id} style={filtersStyle}>
                    <Card style={cardStyle} elevation={Elevation.ZERO}>
                      <div style={{ display: 'flex', width: '30vw' }}>
                        <h5>{operator.name.toUpperCase()}</h5>
                      </div>
                      <div style={{ display: 'flex', width: '10vw' }}>
                        <h5>{OperatorRoleToPortuguese(operator.role)}</h5>
                      </div>

                      <div style={centeredButtonGroup}>
                        <Button
                          title={'Editar'}
                          style={buttonStyle}
                          onClick={() => this.editOperator(operator.id)}
                          disabled={!operator.active}
                          intent="success"
                          icon="edit"
                        ></Button>
                        <Button
                          title={'Bloquear'}
                          onClick={() => this.blockOperator(operator)}
                          style={buttonStyle}
                          intent="danger"
                          icon={'blocked-person'}
                        ></Button>
                      </div>
                    </Card>
                  </div>
                )
            )}

            {operators.map(
              operator =>
                showInactiveOperators &&
                !operator.active && (
                  <div key={operator.id} style={filtersStyle}>
                    <Card style={{ ...cardStyle, backgroundColor: 'lightgrey' }} elevation={Elevation.ZERO}>
                      <div style={{ display: 'flex', width: '30vw' }}>
                        <h5>{operator.name.toUpperCase()}</h5>
                      </div>
                      <div style={{ display: 'flex', width: '10vw' }}>
                        <h5>{OperatorRoleToPortuguese(operator.role)}</h5>
                      </div>

                      <div style={centeredButtonGroup}>
                        <Button
                          title={'Desbloqueie para editar'}
                          style={buttonStyle}
                          disabled={!operator.active}
                          intent="success"
                          icon="edit"
                        ></Button>
                        <Button
                          title={'Desbloquear'}
                          onClick={() => this.blockOperator(operator)}
                          style={buttonStyle}
                          intent="danger"
                          icon={'unlock'}
                        ></Button>
                      </div>
                    </Card>
                  </div>
                )
            )}

            <div>
              <Button style={{ marginTop: '32px' }} onClick={this.createOperator} icon="new-person" intent="primary">
                Novo colaborador
              </Button>
            </div>
          </Card>
        </div>
      </div>
    )
  }
}
