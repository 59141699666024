import React from 'react'
import styled from 'styled-components'
import { Loading } from '../../ui'

export const ApplicationLoading = () => {
  return (
    <Container>
      <Loading color="#10A343" height={40} width={40} />
      <Text>Um momento enquanto carregamos as informações e avaliamos o perfil</Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 90vh;
`

const Text = styled.span`
  margin-top: 3vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #000000;

  width: 80%;
  text-align: center;
`
