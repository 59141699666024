import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

const NUMBER_OF_CASHIER_IDS = 20

const cashierIdList = Array.from({ length: NUMBER_OF_CASHIER_IDS }, (_, k) => {
  const id = (k + 1).toString()

  return id.padStart(2, '0')
})

const LocalCashierSelector: React.FC = () => {
  const [selectedLocalCashierId, setSelectedLocalCashierId] = useState<string | null>()

  useEffect(() => {
    let localCashierId = LocalStorageWrapper.getItem(LocalStorageItem.LOCAL_CASHIER_ID)

    if (!localCashierId) setSelectedLocalCashierId(null)
    else setSelectedLocalCashierId(localCashierId.padStart(2, '0'))
  }, [])

  const handleDropDownSelect = (event: any) => {
    let value = event.target.value

    if (value.length === 1) value = value.padStart(2, '0')

    setLocalCashier(value)
  }

  const handleChangeCashier = () => {
    setLocalCashier(null)
  }

  const setLocalCashier = (value: string | null) => {
    setSelectedLocalCashierId(value)
    LocalStorageWrapper.setItem(LocalStorageItem.LOCAL_CASHIER_ID, value ? `${+value}` : null)
  }

  return (
    <Container>
      <Title>Qual caixa você está operando?</Title>
      <Content>
        <SubTitle>Caixa</SubTitle>
        {selectedLocalCashierId && +selectedLocalCashierId > 0 ? (
          <>
            <SubTitle>{selectedLocalCashierId}</SubTitle>
            <ChangeCashier onClick={handleChangeCashier}>Trocar caixa</ChangeCashier>
          </>
        ) : (
          <PickerSelect onChange={handleDropDownSelect} value={selectedLocalCashierId}>
            <option key="default" value="default">
              00
            </option>
            {cashierIdList &&
              cashierIdList.map((localCashierId: string, index: number) => (
                <option key={index} value={index + 1}>
                  {localCashierId}
                </option>
              ))}
          </PickerSelect>
        )}
      </Content>
    </Container>
  )
}

export default LocalCashierSelector

const Container = styled.div`
  height: 38px;
  display: grid;
  gap: 22px;
  justify-items: flex-start;
  margin-left: 80px;
  margin-top: 50px;
`

const Content = styled.div`
  display: flex;
`

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`

const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #363636;
  margin-right: 18px;

  letter-spacing: -0.5px;
`

const ChangeCashier = styled.button`
  border: 0;
  background: transparent;
  color: #0b2eff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  align-self: flex-end;
`

const PickerSelect = styled.select`
  padding: 0px 0px 0px 15px;
  width: 113px;

  border: 0;
  border-bottom: 1px solid #13c450;
  background-color: ${UMEColors.white.primary};

  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #92929d;
`
