import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { DocumentPicker } from '../../../molecules/Application/Biometry/DocumentPicker'
import { DOCUMENT_FRONT_OPTIONS } from '../../../redux/reducers/biometry/biometry.constants'

type Props = {
  isVisible: boolean
  documentIndex: number
  onCancel: () => void
  onOk: () => void
  onDocumentTypeChange: (index: number) => void
}

const BiometryDocumentPickerModal = (props: Props) => {
  const { isVisible, documentIndex, onOk, onCancel, onDocumentTypeChange } = props

  const documentLabels = DOCUMENT_FRONT_OPTIONS.map(doc => doc.label)

  return (
    <Modal
      visible={isVisible}
      centered
      title="Selecione o documento"
      onOk={onOk}
      onCancel={onCancel}
      cancelText="Cancelar"
      cancelButtonProps={{
        style: {
          background: '#fff',
          color: '#11b048',
          borderColor: '#11b048',
          borderRadius: '10px',
          fontWeight: 'bold',
          fontFamily: 'Roboto',
          padding: '0 5vh 0 5vh',
        },
      }}
      okButtonProps={{
        style: {
          background: '#11b048',
          borderRadius: '10px',
          fontWeight: 'bold',
          fontFamily: 'Roboto',
          padding: '0 5vh 0 5vh',
        },
      }}
    >
      <DocumentPicker
        // TODO - Get the available documents list from API
        selectOptions={documentLabels}
        onDropDownSelect={(index?: number) => {
          if (index === null || index === undefined) {
            return
          }

          onDocumentTypeChange(index)
        }}
        selectedIndex={documentIndex}
      />
    </Modal>
  )
}

export default BiometryDocumentPickerModal
