import { Box } from 'grommet'
import styled from 'styled-components'
import { Card, Heading } from '../../../../legacy-lib/design-kit-ui'

export const CalendarBoxStyle = styled(Box)`
  margin-top: 18px;
`
export const TitleBoxStyle = styled(Box)`
  margin-bottom: 6px;
`
export const TitleHeadingStyle = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
  line-height: 38px;
  margin: 0;
`
export const BarSideBoxStyle = styled(Box)``

export const IconCardStyle = styled(Card)`
  border: none;
  border-radius: 8px;
  height: 56px;
  padding: 12px;
  margin: 0;
`
