import { Role } from './constants'

export function hasIdentifiedRetailersDashView(userRole: Role): Boolean {
  const identifiedRetailersDashViewRoles: Role[] = [Role.ANALYST, Role.RETAILER_ANALYST, Role.MANAGER]
  if (identifiedRetailersDashViewRoles.includes(userRole)) {
    return true
  }

  return false
}
