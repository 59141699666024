import React from 'react'
import styled from 'styled-components'
import { Loading } from '../../../ui'

export const ApplicationLoading = () => {
  // const applicationStartTimestamp = useTypedSelector(state => state.application.applicationStartTimestamp)

  return (
    <Container>
      <Loading color="#10A343" height={40} width={40} />
      <BoldGreenText>O cliente está sendo avaliado, aguarde um momento.</BoldGreenText>
      <br />
      <Text>Se algo der errado, não se preocupe. Basta buscar pelo CPF do cliente na tela principal</Text>
    </Container>
  )
}

export default ApplicationLoading

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 90vh;
`

const Text = styled.span`
  margin-top: 3vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #000000;

  width: 80%;
  text-align: center;
`

const BoldGreenText = styled.span`
  margin-top: 3vh;

  color: #11b048;
  font-weight: 800;
`
