import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { getSentryDSN } from './config/config'
import './index.css'
import * as serviceWorker from './serviceWorker'
import analyticsService from './services/analytics/analytics.service'

if (getSentryDSN()) {
  Sentry.init({
    dsn: getSentryDSN(),
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

analyticsService.initalize()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
