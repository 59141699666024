import styled from 'styled-components'

export const NotFoundText = styled.h2`
  color: grey;
  text-align: center;
  padding-bottom: 1%;
`

export const GenericTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;

  span {
    font-size: inherit;
    font-family: inherit;
    line-height: 24px;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: inherit;
    margin: auto;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  thead tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  tr:nth-child(even) {
    background-color: #f0f0f0;
  }

  ${(props: any) =>
    !Boolean(props.disableHover) &&
    `
    tr:hover {
        background-color: #CFE2F3;
        color: black;
        transition: 0.3s;
        cursor: pointer;
    }
  `}

  th {
    margin: 0;
    padding: 0;
    font-weight: 700;
    height: 100%;
    text-align: left;
    border-bottom: solid 1px rgba(0, 0, 0, 0.33);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  td {
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: left;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`
