export function getAndFormatGraphXLabels(name: string) {
  const nameArray = name.split(' ').map(w => (w[0] ? w[0].toUpperCase() + w.substr(1).toLowerCase() : ''))
  const arraySize = nameArray.length

  let firstName, secondNameInitial, lastNameInitial

  firstName =
    nameArray[0] && nameArray[0].length > 9
      ? nameArray[0].substring(0, 9) + ' '
      : nameArray[0]
      ? nameArray[0] + ' '
      : ''
  secondNameInitial = nameArray.length > 2 && nameArray[1] ? nameArray[1].substring(0, 1).toUpperCase() : ''
  lastNameInitial =
    nameArray[arraySize - 1] && arraySize > 1 ? nameArray[arraySize - 1].substring(0, 1).toUpperCase() : ''

  return firstName + secondNameInitial + lastNameInitial
}
