import { ProcessStatus } from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import { Image, Modal } from 'antd'
import { Alert, Checkmark } from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
import {
  AuthenticatedIconContainer,
  AuthenticationImageCardContainer,
  BiometryAnchor,
  ImageBox,
} from '../../../organisms/Application/Biometry/style'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { Loading } from '../../../ui'

interface IAuthenticationImageCardProps {
  imageBase64?: string
  onOpenCamera: () => void
  onUpload: () => void
  isDocument?: boolean
  isLoading?: boolean
  ableToUploadPhoto: boolean
}

export const AuthenticationImageCard = (props: IAuthenticationImageCardProps) => {
  const { imageBase64, onOpenCamera, onUpload, isDocument, isLoading, ableToUploadPhoto } = props

  const { biometryStatus, submitBiometry } = useTypedSelector(state => ({
    biometryStatus: state.biometry.biometryStatus,
    submitBiometry: state.configurations.configurations.application.submitBiometry,
  }))

  const messageCard = (biometryStatus: ProcessStatus) => {
    if (isLoading) {
      return (
        <>
          <AuthenticatedIconContainer>
            <Loading />
          </AuthenticatedIconContainer>
          <BiometryLoadingLegend>Autenticando foto</BiometryLoadingLegend>
        </>
      )
    }

    if (!submitBiometry) {
      return (
        <>
          <AuthenticatedIconContainer>
            <Checkmark color="#11b048" />
          </AuthenticatedIconContainer>
          <BiometryOkLegend>Foto salva</BiometryOkLegend>
        </>
      )
    }

    switch (biometryStatus) {
      case ProcessStatus.COMPLETED:
        return (
          <>
            <AuthenticatedIconContainer>
              <Checkmark color="#11b048" />
            </AuthenticatedIconContainer>
            <BiometryOkLegend>Foto autenticada</BiometryOkLegend>
          </>
        )
      case ProcessStatus.CONFLICT:
        return (
          <>
            <AuthenticatedIconContainer>
              <Alert color="#F7B500" />
            </AuthenticatedIconContainer>
            <BiometryErrorLegend style={{ color: '#F7B500' }}>Biometria em Conflito</BiometryErrorLegend>
            <BiometryAnchor onClick={() => handleConflictModalClick()}>Saiba mais</BiometryAnchor>
          </>
        )
      case ProcessStatus.CANCELED:
        return (
          <>
            <AuthenticatedIconContainer>
              <Alert color="#E10000" />
            </AuthenticatedIconContainer>
            <BiometryErrorLegend>
              Cancelado <br /> Tente novamente{' '}
            </BiometryErrorLegend>
          </>
        )
      default:
        return (
          <>
            <AuthenticatedIconContainer>
              <Alert color="#E10000" />
            </AuthenticatedIconContainer>
            <BiometryErrorLegend>
              Erro <br /> Tente novamente{' '}
            </BiometryErrorLegend>
          </>
        )
    }
  }

  const handleConflictModalClick = () => {
    Modal.info({
      title: 'O que é Conflito?',
      content: (
        <div>
          <StyledText>
            Conflito ocorre quando não conseguimos confirmar se a pessoa da foto é realmente a dona do CPF.
          </StyledText>
          <StyledText>
            <strong>Atualize a página e tente novamente em alguns instantes.</strong> Podemos levar até no máximo 30
            minutos para analisar o caso.
          </StyledText>
        </div>
      ),
      onOk() {},
      centered: true,
      okButtonProps: {
        style: {
          background: '#11b048',
          borderRadius: '10px',
          fontWeight: 'bold',
          fontFamily: 'Roboto',
          padding: '0 5vh 0 5vh',
        },
      },
      okText: 'Entendi',
      maskStyle: {
        width: '100%',
      },
    })
  }

  if (isDocument) {
    return (
      <AuthenticationImageCardContainer gap="large">
        <ImageBox gap="small">
          {imageBase64 ? <Image style={{ borderRadius: 5 }} src={imageBase64} preview={true} /> : undefined}
          <React.Fragment>
            <BiometryAnchor onClick={onOpenCamera}>Tirar nova foto</BiometryAnchor>
            {ableToUploadPhoto ? (
              <React.Fragment>
                <OrText>ou</OrText>
                <BiometryAnchor onClick={onUpload}>Fazer upload da foto</BiometryAnchor>
              </React.Fragment>
            ) : (
              undefined
            )}
          </React.Fragment>
        </ImageBox>
        <ImageBox gap="small">
          <AuthenticatedIconContainer>
            <Checkmark color="#11b048" />
          </AuthenticatedIconContainer>
          <BiometryOkLegend>Foto salva</BiometryOkLegend>
        </ImageBox>
      </AuthenticationImageCardContainer>
    )
  }

  return (
    <AuthenticationImageCardContainer gap="large">
      <ImageBox gap="small">
        {imageBase64 ? <Image style={{ borderRadius: 5 }} src={imageBase64} preview={true} /> : undefined}
        {biometryStatus !== ProcessStatus.CONFLICT ? (
          <React.Fragment>
            <BiometryAnchor onClick={onOpenCamera}>Tirar nova foto</BiometryAnchor>
            {ableToUploadPhoto ? (
              <React.Fragment>
                <OrText>ou</OrText>
                <BiometryAnchor onClick={onUpload}>Fazer upload da foto</BiometryAnchor>
              </React.Fragment>
            ) : (
              undefined
            )}
          </React.Fragment>
        ) : (
          <></>
        )}
      </ImageBox>
      <ImageBox gap="small">{messageCard(biometryStatus as ProcessStatus)}</ImageBox>
    </AuthenticationImageCardContainer>
  )
}

const BiometryOkLegend = styled.p`
  color: #11b048;
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  margin: 1vh;
`

const BiometryLoadingLegend = styled.p`
  color: #80868c;
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  margin: 1vh;
`

const BiometryErrorLegend = styled.p`
  color: #e10000;
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  margin: 1vh;
  text-align: center;
`

const StyledText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`

const OrText = styled.span`
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
`
