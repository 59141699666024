import { Divider } from '@blueprintjs/core'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { black, blue, gray } from '../../ui/utils/_colors'
import { convertToPercentage } from '../../utils/math.utils'
import { ListItem } from './components/ListItem'

interface Props {
  interestRate: number
  iof: number
  firstInstallmentDueDate?: string
  couponDiscount: number
  installmentValue: number
  customerFeeRate?: number
  downPayment?: number
  purchaseValueMinusDownPayment?: number
  customerFeeValue?: number
  principal: number
  productPrice?: number
}

export const SmartphoneSalePurchaseDetails: React.FC<Props> = props => {
  const {
    interestRate,
    iof,
    firstInstallmentDueDate,
    couponDiscount,
    installmentValue,
    customerFeeRate,
    downPayment,
    purchaseValueMinusDownPayment,
    customerFeeValue,
    principal,
    productPrice
  } = props

  return (
    <DetailsSection>
          <DetailsContainer>
            <ListItem 
              label={'Resumo da compra'} 
              labelProps={{ color: black.text2, weight: 'bold' }} 
            />

            <ListItem 
              label={'Valor de compra'}
              labelProps={{color: gray.slate}}
              value={formatBRL(Number(productPrice) - couponDiscount)} 
              valueProps={{ weight: 'normal' }}
            />
            
            <ListItem 
              label={'Entrada'}
              labelProps={{color: gray.slate}}
              value={'- ' + formatBRL(downPayment || 0)} 
              valueProps={{ color: blue.yonder, weight: 'normal' }}
            />

            <ListItem 
              label={'Valor financiado'} 
              labelProps={{ color: gray.scale700, weight: 'bold' }}
              value={formatBRL(purchaseValueMinusDownPayment!)} 
            />

            <Divider />

            <ListItem 
              label={'Taxas e juros aplicados'} 
              labelProps={{ color: black.text2, weight: 'bold' }} 
            />
            
            {customerFeeRate && customerFeeValue ? (
              <ListItem
                label={`Taxa de utilização (${convertToPercentage(customerFeeRate)}%)`}
                labelProps={{color: gray.slate}}
                value={formatBRL(customerFeeValue)}
                valueProps={{ weight: 'normal' }}
              />
            ) : (
              undefined
            )}

            <ListItem 
              label={'IOF'} 
              labelProps={{color: gray.slate}}
              value={formatBRL(iof)} 
              valueProps={{ weight: 'normal' }} 
            />

            <ListItem
              label={'Juros ao mês'}
              labelProps={{color: gray.slate}}
              value={`${convertToPercentage(interestRate)}%`}
              valueProps={{ weight: 'normal' }}
            />

            <ListItem 
              label={'Total financiado com taxas'}
              labelProps={{ color: gray.scale700, weight: 'bold' }} 
              value={formatBRL(principal + iof + (customerFeeValue || 0))} 
            />

            <Divider />

            <ListItem 
              label={'Primeira Parcela'} 
              labelProps={{ color: black.text2, weight: 'bold' }} 
            />
            
            <ListItem 
              label={'Data da 1ª parcela'} 
              labelProps={{color: gray.slate}}
              value={moment(firstInstallmentDueDate).format('L')} 
              valueProps={{ weight: 'normal' }}
            />

            <ListItem 
              label={'Valor da parcela'}
              labelProps={{ color: gray.scale700, weight: 'bold' }}
              value={formatBRL(installmentValue)} 
            />
          </DetailsContainer>
    </DetailsSection>
  )
}

export default SmartphoneSalePurchaseDetails

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px;

  @media (max-width: ${mobile}) {
    padding: 10px 0px 10px 0px;
  }
`

const DetailsContainer = styled.div`
  width: 93%;
  margin: 20px;
  padding: 18px;
  border-radius: 20px;
  line-height: 2;
  background-color: ${gray.light2}; // light blue

  @media (max-width: ${mobile}) {
    padding: 10px 0px 10px 0px;
  }
`
