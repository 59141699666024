import { IOperatorsRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operators-ranking'
import { Box } from 'grommet'
import * as React from 'react'
import { Role } from '../../../../common/constants'
import { getAndFormatGraphXLabels } from '../../../../common/FormatName'
import { hasIdentifiedRetailersDashView } from '../../../../common/RoleValidators'
import { BarChart, Loading, Text } from '../../../../legacy-lib/design-kit-ui'
import { getYValues } from '../../../../legacy-lib/design-kit-ui/BarChart/helpers'
import { blue } from '../../../../legacy-lib/design-kit-ui/Palette'
import { bffParceirosApiService } from '../../../../services/bff'
import {
  ApplicationsByOperatorBoxStyle,
  BigCardStyle,
  IconCardStyle,
  SectionTitleStyle,
  SortLeftIconStyled,
  SortRightIconStyled,
  TextStyle,
} from './style'

export interface IApplicationByOperatorProps {
  applicationsRankingByOperator?: IOperatorsRankingResponse | Error
  userRole?: Role
}

export interface IApplicationByOperatorState {
  isOnRankingOrder: boolean
}

const initialState: IApplicationByOperatorState = {
  isOnRankingOrder: false,
}

export default class ApplicationByOperator extends React.Component<
  IApplicationByOperatorProps,
  IApplicationByOperatorState
> {
  constructor(props: IApplicationByOperatorProps) {
    super(props)

    this.state = initialState
  }

  orderValues = () => {
    const { isOnRankingOrder } = this.state

    this.setState({
      isOnRankingOrder: !isOnRankingOrder,
    })
  }

  public render() {
    const { isOnRankingOrder } = this.state
    const { applicationsRankingByOperator } = this.props

    const maxValueData =
      (applicationsRankingByOperator &&
        !(applicationsRankingByOperator instanceof Error) &&
        applicationsRankingByOperator.operatorsRanking.length > 0 &&
        applicationsRankingByOperator.operatorsRanking.reduce((a, b) =>
          a.numberOfApplications > b.numberOfApplications ? a : b
        ).numberOfApplications) ||
      0

    const showBarChart =
      applicationsRankingByOperator instanceof Error ? (
        <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
      ) : (
        applicationsRankingByOperator &&
        maxValueData !== undefined && (
          <BarChart
            yAxis={getYValues(0, maxValueData)}
            averageValue={Number(applicationsRankingByOperator.averageApplications.toFixed(1))}
            boundsLabels={{ y: 'Quantidade' }}
            data={applicationsRankingByOperator.operatorsRanking
              .sort((a, b) =>
                isOnRankingOrder
                  ? a.numberOfApplications - b.numberOfApplications
                  : b.numberOfApplications - a.numberOfApplications
              )
              .map(operator => ({
                value: operator.numberOfApplications,
                label:
                  bffParceirosApiService.auth.getUser()?.id === operator.operatorId ? (
                    <Text size="xsmall">
                      <b>Sua posição</b>
                    </Text>
                  ) : this.props.userRole && hasIdentifiedRetailersDashView(this.props.userRole) ? (
                    `${getAndFormatGraphXLabels(operator.operatorName)}`
                  ) : (
                    `Operador ${operator.rankingPosition}`
                  ),
              }))}
          />
        )
      )

    return (
      <ApplicationsByOperatorBoxStyle>
        <Box direction="row" justify="between" align="end">
          <Box>
            <SectionTitleStyle>Aplicações por operador</SectionTitleStyle>
            <TextStyle>As informações do gráfico abaixo são de acordo com o filtro do calendário.</TextStyle>
          </Box>
          <IconCardStyle onClick={this.orderValues}>
            {isOnRankingOrder ? <SortLeftIconStyled color={blue.vivid} /> : <SortRightIconStyled color={blue.vivid} />}
          </IconCardStyle>
        </Box>
        <BigCardStyle>
          {applicationsRankingByOperator ? (
            <Box>{showBarChart}</Box>
          ) : (
            <Box fill align="center" justify="center">
              <Loading></Loading>
            </Box>
          )}
        </BigCardStyle>
      </ApplicationsByOperatorBoxStyle>
    )
  }
}
