import { ProcessStatus } from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import React from 'react'
import { AuthenticationImageCard } from '../../../molecules/Application/Biometry/AuthenticationImageCard'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { Loading } from '../../../ui'
import { AuthorizationHelper } from './helpers/verify-operator.helper'
import { BiometryAnchor, ImageContainer, Legend, Subtitle } from './style'
import { TakePhoto } from './TakePhoto'

interface Props {
  handleOpenFaceCamera: () => void
  handleOpenUploadFaceModal: () => void
}

export const FaceImageView = ({ handleOpenFaceCamera, handleOpenUploadFaceModal }: Props) => {
  const {
    isFaceAuthenticated,
    isLoadingBiometry,
    isLoadingFaceImage,
    faceImageBase64,
    biometryStatus,
    submitBiometry,
  } = useTypedSelector(state => ({
    biometryStatus: state.biometry.biometryStatus,
    isLoadingFaceImage: state.biometry.isLoadingFaceImage,
    isLoadingBiometry: state.biometry.isLoadingBiometry,
    isFaceAuthenticated: state.biometry.isFaceAuthenticated,
    faceImageBase64: state.biometry.faceImageBase64,
    submitBiometry: state.configurations.configurations.application.submitBiometry,
  }))

  const isOperatorAllowedToUploadImage = AuthorizationHelper.isOperatorAllowedToUploadImage()

  return (
    <React.Fragment>
      <Subtitle>Foto da Pessoa</Subtitle>
      <Legend>Vamos precisar de uma foto do rosto da pessoa.</Legend>
      <ImageContainer>
        {isLoadingFaceImage ? (
          <Loading />
        ) : isLoadingBiometry ||
          isFaceAuthenticated ||
          (biometryStatus && biometryStatus !== ProcessStatus.SPOOF) ||
          (!submitBiometry && faceImageBase64) ? (
          <AuthenticationImageCard
            onOpenCamera={handleOpenFaceCamera}
            imageBase64={faceImageBase64}
            isLoading={isLoadingBiometry}
            ableToUploadPhoto={isOperatorAllowedToUploadImage}
            onUpload={handleOpenUploadFaceModal}
          />
        ) : (
          <React.Fragment>
            <TakePhoto onClick={handleOpenFaceCamera} />
            {isOperatorAllowedToUploadImage ? (
              <BiometryAnchor style={{ marginTop: '1vh' }} onClick={handleOpenUploadFaceModal}>
                Fazer upload da foto
              </BiometryAnchor>
            ) : (
              undefined
            )}
          </React.Fragment>
        )}
      </ImageContainer>
    </React.Fragment>
  )
}
