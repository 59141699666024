import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  BatchOriginationDto,
  HttpOriginationsBatchErrors,
  IGetBatchesRetailersTransfers,
  IGetOriginationsForBatchQuery,
  ORIGINATIONS_BATCH_STATUS,
  ORIGINATIONS_BATCH_STEPS_NAME,
} from './dto/Batch.dto'
import { GetRetailerTransfersDto, TRANSFER_STATUS } from './dto/RetailersTransfers.dto'

export default class BackofficeOriginationsService {
  private axiosInstance: AxiosInstance
  public readonly HttpBackofficeOriginationsErrors = HttpOriginationsBatchErrors
  public readonly BATCH_STEPS_NAME = ORIGINATIONS_BATCH_STEPS_NAME
  public readonly BATCH_STATUS = ORIGINATIONS_BATCH_STATUS
  public readonly TRANSFER_STATUS = TRANSFER_STATUS

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getRetailersTransfers(
    retailerId: string,
    params: IGetBatchesRetailersTransfers
  ): Promise<GetRetailerTransfersDto> {
    const response = await this.axiosInstance.get<GetRetailerTransfersDto>(
      `/backofficeOriginations/retailers/${retailerId}/transfers`,
      {
        params,
      }
    )

    return response.data
  }

  async getBatchOriginations(params: IGetOriginationsForBatchQuery): Promise<BatchOriginationDto[]> {
    const response = await this.axiosInstance.get<BatchOriginationDto[]>(
      `/backofficeOriginations/batches/${params.batchId}/originations`,
      { params }
    )

    return response.data
  }
}
