import * as React from 'react'
import styled from 'styled-components'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { Card } from '../../legacy-lib/design-kit-ui'
import { black } from '../../legacy-lib/design-kit-ui/utils/_colors'

export const HomeCard = (props: { subtitle: string; icon: any; to: string; onClick: () => void }) => {
  return (
    <SectionContainer onClick={props.onClick}>
      <SectionCard>
        <CardIcon>{props.icon}</CardIcon>
      </SectionCard>
      <div style={{ marginTop: 10 }}>
        <Subtitle>{props.subtitle}</Subtitle>
      </div>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  padding: 16px 10px 40px 0;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${mobile}) {
    max-width: 150px;
    padding: 0;
  }
`
const SectionCard = styled(Card)`
  padding: 35px;

  :hover {
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: ${mobile}) {
    padding: 20px;
  }
`
const Subtitle = styled.span`
  font-size: 20px;
  line-height: 0;
  text-align: center;
  font-weight: bold;
  color: ${black.primary};
`
// TODO(lucas.citolin): there is two definitions of svg so we can maintain the same icons that operators were used to
const CardIcon = styled.span`
  > div > svg {
    width: 100px;
    height: 100px;
  }

  > svg {
    width: 100px;
    height: 100px;
    fill: ${black.primary}
    stroke: ${black.primary}

    @media (max-width: ${mobile}) {
      width: 100px;
      height: 100px;
    }
  }
`
