import React from 'react';
import styled from 'styled-components';

interface Props {
  clickable?: boolean
}

export const Text = (props: JSX.IntrinsicElements['p'] & Props) => {
  return (
    <Content {...props}>{props.children}</Content>
  );
};

const Content = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  margin: 0;

  color: ${(props: any) => (props?.color)};
  cursor: ${(props: any) => (props?.clickable || props?.onClick ? 'pointer' : 'auto')};
`;