import { AxiosError } from 'axios'
import { history, toaster } from '../../../App'
import { OperatorServiceCenterErrors } from '../../../common/ErrorStrings'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'

export class OperatorServiceCenterErrorHandler {
  static handleError(error: AxiosError, document: string): void {
    if (error.response?.data?.message === OperatorServiceCenterErrors.BORROWER_DOES_NOT_HAVE_COMPLETED_APPLICATION) {
      toaster.showWarningToast(`Este cliente não possui cadastro na Ume. Por favor, inicie o processo de cadastro.`, 5)

      return history.push(PagesPathEnum.APPLICATION.replace(':cpf', document))
    }

    toaster.showErrorToast(`Um erro inesperado aconteceu, tente novamente em alguns instantes.`, 3)
    return history.push(PagesPathEnum.HOME_PAGE)
  }
}
