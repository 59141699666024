import { IOriginationFirstPurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-first-purchase'
import { Box } from 'grommet'
import React, { PureComponent } from 'react'
import { numberToCurrency } from '../../../../../common/NumberToCurrency'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  CardTitleHeadingStyle,
  GreenCurrencyHeadingStyle,
  SmallCardStyle,
  SmallGreenValueHeadingStyle,
  SmallNumberQuantityHeadingStyle,
  TextStyle,
} from './style'

interface IFirstPurchaseCardProps {
  originationsFirstPurchase?: IOriginationFirstPurchaseResponse | Error
}

export default class FirstPurchaseCard extends PureComponent<IFirstPurchaseCardProps> {
  render() {
    const { originationsFirstPurchase } = this.props

    const firstPurchaseCardBox =
      originationsFirstPurchase instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        originationsFirstPurchase && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Primeira compra</CardTitleHeadingStyle>
              <Box>
                <SmallNumberQuantityHeadingStyle>
                  {originationsFirstPurchase.firstPurchaseCount > 9
                    ? originationsFirstPurchase.firstPurchaseCount
                    : '0' + originationsFirstPurchase.firstPurchaseCount}
                </SmallNumberQuantityHeadingStyle>
                <TextStyle>Clientes </TextStyle>
              </Box>
              <Box gap="xsmall">
                <Box direction="row" align="end" gap="xsmall">
                  <GreenCurrencyHeadingStyle>{'R$ '}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(originationsFirstPurchase.firstPurchaseSum)}
                  </SmallGreenValueHeadingStyle>
                </Box>
                <TextStyle>Total de créditos </TextStyle>
              </Box>
            </Box>

            <Box className="is-mobile" fill justify="between">
              <CardTitleHeadingStyle>Primeira compra</CardTitleHeadingStyle>
              <Box>
                <SmallNumberQuantityHeadingStyle>
                  {originationsFirstPurchase.firstPurchaseCount > 9
                    ? originationsFirstPurchase.firstPurchaseCount
                    : '0' + originationsFirstPurchase.firstPurchaseCount}
                </SmallNumberQuantityHeadingStyle>
                <TextStyle>Clientes </TextStyle>
              </Box>
              <Box gap="xsmall">
                <Box direction="row" align="end" gap="2px">
                  <GreenCurrencyHeadingStyle>{'R$'}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(originationsFirstPurchase.firstPurchaseSum)}
                  </SmallGreenValueHeadingStyle>
                </Box>
                <TextStyle>Total de créditos </TextStyle>
              </Box>
            </Box>
          </>
        )
      )
    return (
      <SmallCardStyle>
        {originationsFirstPurchase ? (
          firstPurchaseCardBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </SmallCardStyle>
    )
  }
}
