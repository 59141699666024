import moment from 'moment-timezone'
import React from 'react'
import { DatePicker } from '../../atoms/DatePicker/DatePicker'
import { CALENDAR_MIN_BOUND_DATE } from './resources/originations-report-resources'

type DateRangeProps = {
  startDate: string
  endDate: string
  onStartDateChange: (event: { value: string | string[] }) => void
  onEndDateChange: (event: { value: string | string[] }) => void
}

export const DateRangeContainer = (props: DateRangeProps) => {
  const { startDate, endDate } = props
  return (
    <div style={{ display: 'flex', columnGap: '1rem' }}>
      <DatePicker
        date={startDate}
        label={'Data inicial'}
        bounds={[CALENDAR_MIN_BOUND_DATE, new Date().toISOString()]}
        onChange={props.onStartDateChange}
      />
      <DatePicker
        date={endDate}
        label={'Data final'}
        bounds={[startDate, setEndDateCalendarMaxValue(startDate)]}
        onChange={props.onEndDateChange}
      />
    </div>
  )
}

const setEndDateCalendarMaxValue = (startDate: string): string => {
  const today = moment.tz('America/Manaus').toISOString()
  const oneMonthLaterStartDate = moment(startDate)
    .add(1, 'month')
    .toISOString()

  if (moment(oneMonthLaterStartDate).isAfter(today, 'day')) {
    return today
  }

  return oneMonthLaterStartDate
}


