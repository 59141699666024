import { IApplicationsStatus } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/applications.interface'
import { IOriginationFirstPurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-first-purchase'
import { IOriginationRecurrencePurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-recurrence-purchase'
import { Box, Grid } from 'grommet'
import * as React from 'react'
import ApprovedApplicationsCard from '../Cards/ApprovedApplicationsCard/ApprovedApplicationsCard'
import AverageTicketCard from '../Cards/AverageTicketCard/AverageTicketCard'
import FirstPurchaseCard from '../Cards/FirstPurchaseCard/FirstPurchaseCard'
import OriginationsSumCard from '../Cards/OriginationsSumCard/OriginationsSumCard'
import RecurrencePurchaseCard from '../Cards/RecurrencePurchaseCard/RecurrencePurchaseCard'
import TotalApplicationsCard from '../Cards/TotalApplicationsCard/TotalApplicationsCard'
import { CardsBoxStyle, OverviewBoxStyle } from './style'

export interface IManagerOverviewProps {
  applicationsByStatus?: IApplicationsStatus | Error
  originationsFirstPurchase?: IOriginationFirstPurchaseResponse | Error
  originationsRecurrencePurchase?: IOriginationRecurrencePurchaseResponse | Error
  averageTicket?: number | Error
}

export default class ManagerOverview extends React.PureComponent<IManagerOverviewProps> {
  public render() {
    const {
      applicationsByStatus,
      originationsFirstPurchase,
      originationsRecurrencePurchase,
      averageTicket,
    } = this.props

    return (
      <OverviewBoxStyle>
        <CardsBoxStyle className="desktop">
          <TotalApplicationsCard applicationsByStatus={applicationsByStatus} />
          <ApprovedApplicationsCard applicationsByStatus={applicationsByStatus} />
          <FirstPurchaseCard originationsFirstPurchase={originationsFirstPurchase} />
          <RecurrencePurchaseCard originationsRecurrencePurchase={originationsRecurrencePurchase} />
          <Box>
            <AverageTicketCard averageTicket={averageTicket} />
            <OriginationsSumCard
              originationsFirstPurchase={originationsFirstPurchase}
              originationsRecurrencePurchase={originationsRecurrencePurchase}
            />
          </Box>
        </CardsBoxStyle>

        <CardsBoxStyle fill className="is-mobile">
          <Grid columns={['1/2', '1/2']} rows={['auto']} areas={[['left', 'right']]} gap="10px">
            <Box gridArea="left">
              <TotalApplicationsCard applicationsByStatus={applicationsByStatus} />
              <FirstPurchaseCard originationsFirstPurchase={originationsFirstPurchase} />
            </Box>
            <Box gridArea="right">
              <ApprovedApplicationsCard applicationsByStatus={applicationsByStatus} />
              <RecurrencePurchaseCard originationsRecurrencePurchase={originationsRecurrencePurchase} />
            </Box>
          </Grid>
          <OriginationsSumCard
            originationsFirstPurchase={originationsFirstPurchase}
            originationsRecurrencePurchase={originationsRecurrencePurchase}
          />
          <AverageTicketCard averageTicket={averageTicket} />
        </CardsBoxStyle>
      </OverviewBoxStyle>
    )
  }
}
