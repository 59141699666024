import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { ApplicationsByStatusDto, IParamsDateAndRetailer } from './dto/ApplicationsStatus.dto'
import { IOperatorRecordParams, OperatorRecordDto } from './dto/OperatorRecord.dto'
import { IOperatorRankingParams, OperatorsRankingDto } from './dto/OperatorsRanking.dto'
import { OperatorRankingPositionDto } from './dto/OperatorsRankingPosition.dto'
import { IStoreRankingParams, StoresRankingDto } from './dto/StoresRanking.dto'

export default class BackofficeService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getApplicationStatus(params: IParamsDateAndRetailer): Promise<ApplicationsByStatusDto> {
    const response = await this.axiosInstance.get<ApplicationsByStatusDto>(`backoffice/applications/status`, { params })

    return response.data
  }

  async getApplicationsRankingByStores(params: IStoreRankingParams): Promise<StoresRankingDto> {
    const response = await this.axiosInstance.get<StoresRankingDto>(
      `backoffice/retailers-dash/applications/stores/ranking`,
      { params }
    )

    return response.data
  }

  async getApplicationsRankingByOperators(params: IOperatorRankingParams): Promise<OperatorsRankingDto> {
    const response = await this.axiosInstance.get<OperatorsRankingDto>(
      `backoffice/retailers-dash/applications/operators/ranking`,
      { params }
    )

    return response.data
  }

  async getApplicationsRankingForOperator(params: IOperatorRankingParams): Promise<OperatorRankingPositionDto> {
    const response = await this.axiosInstance.get<OperatorRankingPositionDto>(
      `backoffice/retailers-dash/applications/operators/ranking/me`,
      { params }
    )

    return response.data
  }

  async getApplicationRecordForOperator(params: IOperatorRecordParams): Promise<OperatorRecordDto> {
    const response = await this.axiosInstance.get<OperatorRecordDto>(
      `backoffice/retailers-dash/applications/operators/records/me`,
      { params }
    )

    return response.data
  }
}
