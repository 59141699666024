import React from 'react'
import { AuthenticationImageCard } from '../../../molecules/Application/Biometry/AuthenticationImageCard'
import { DocumentsBack, ImageLabelsText } from '../../../redux/reducers/biometry/biometry.constants'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { AuthorizationHelper } from './helpers/verify-operator.helper'
import { BiometryAnchor, ImageContainer, Legend } from './style'
import { TakePhoto } from './TakePhoto'

interface Props {
  handleOpenDocumentCamera: () => void
  handleOpenUploadDocumentModal: () => void
}

export const BackDocumentView = ({ handleOpenDocumentCamera, handleOpenUploadDocumentModal }: Props) => {
  const { isLoadingDocument, documentImages } = useTypedSelector(state => ({
    isLoadingDocument: state.biometry.isLoadingDocument,
    documentImages: state.biometry.documentImages,
  }))

  const isOperatorAllowedToUploadImage = AuthorizationHelper.isOperatorAllowedToUploadImage()

  if (isLoadingDocument || !documentImages) {
    return <React.Fragment />
  }

  let document
  if (DocumentsBack.find(label => label === documentImages[0].documentType)) {
    document = documentImages[0]
  } else {
    document = documentImages.length === 2 ? documentImages[1] : undefined
  }

  if (!document) {
    return (
      <React.Fragment>
        <Legend>Foto do verso.</Legend>
        <ImageContainer>
          <TakePhoto onClick={handleOpenDocumentCamera} />
          {isOperatorAllowedToUploadImage ? (
            <BiometryAnchor style={{ marginTop: '1vh' }} onClick={handleOpenUploadDocumentModal}>
              Fazer upload da foto
            </BiometryAnchor>
          ) : (
            undefined
          )}
        </ImageContainer>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Legend>
        Foto de <strong>{ImageLabelsText[document.documentType]}</strong>
      </Legend>
      <ImageContainer>
        <AuthenticationImageCard
          onOpenCamera={handleOpenDocumentCamera}
          imageBase64={document.documentImageBase64}
          isDocument
          ableToUploadPhoto={isOperatorAllowedToUploadImage}
          onUpload={handleOpenUploadDocumentModal}
        />
      </ImageContainer>
    </React.Fragment>
  )
}
