import styled from 'styled-components';

import { black, gray, green, white } from '../../../../ui/utils/_colors';

export const ContentContainer = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 24px;
    padding: 48px;
    border: solid 1px ${gray.border};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 450px) {
        padding: 24px;
    }
`

export const Title = styled.p`
    color: ${black.text};
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    margin: 0;
    @media only screen and (max-width: 770px) {
        text-align: center;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;
    @media only screen and (max-width: 770px) {
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 770px) {
        justify-content: center;
        align-items: center;
        margin-top: 32px;
    }
`

export const Text = styled.p`
    color: ${gray.text};
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    max-width: 294px;
    @media only screen and (max-width: 770px) {
        text-align: center;
    }
`

export const Value = styled.p`
    color: ${green.confirmation};
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
`

export const GoBackText = styled.p`
    color: ${black.text};
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    text-decoration: underline;
    margin: 0;
    margin-bottom: 38px;
    inline-size: fit-content;
    border: none;
    background-color: ${white.primary};
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
    @media only screen and (max-width: 770px) {
        margin: 28 auto;
        text-align: center;
        inline-size: none;
    }
`

export const SecondaryButton = styled.button`
    border: solid 1px ${black.text};
    border-radius: 4px;
    padding: 6px 16px;
    margin-top: 16px;
    display: inline-block;
    inline-size: fit-content;
    background-color: ${white.primary};
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
`

export const SecondaryButtonText = styled.p`
    color: ${black.text};
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
    user-select: none;
`

export const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const ZoomOrPrintText = styled.p`
    color: ${black.text};
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    margin-top: 20px;
    @media only screen and (max-width: 770px) {
        text-align: center;
    }
`

export const ZoomButton = styled.button`
    text-decoration: underline;
    border: none;
    background-color: ${white.primary};
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
`

export const PrintButton = ZoomButton

export const Modal = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.90);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${white.primary};
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
    &:hover {
        opacity: 0.8;
    }
`