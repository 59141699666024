import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { AnyObject } from 'yup/lib/object';

export interface FormProps {
  isValidationSync?: boolean;
  initialValues?: object;
  schema?: any;
}

// all new field should be inserted here
interface Fields {
  phone?: string;
  cpf?: NumberFormatValues;
  budgetValue?: NumberFormatValues;
}

interface State {
  errors: AnyObject;
  fields: Fields;
  valid: boolean;
}

interface OnSubmitReturn {
  valid: boolean;
  errors?: AnyObject;
}

export interface Form extends State {
  setFormErrors: (params: object) => Promise<boolean>;
  onSubmit: (params: Fields) => Promise<OnSubmitReturn>;
  inputChange: (props: InputChangeProps) => void;
  clearField: (field: keyof Fields) => void;
  setValues: (params: Fields) => void;
}

interface InputChangeProps {
  field: string;
  value: unknown;
  noValidate?: boolean;
}

const withForm = (Component: any) => ({
  isValidationSync = false,
  initialValues = {},
  schema
}: FormProps): React.ComponentClass => class withForm extends React.Component<FormProps, State> {
    state = {
      errors: {},
      fields: {},
      valid: false
    };

    componentDidMount() {
      if (isValidationSync && Object.keys(initialValues).length === 0) {
        throw 'Sync Validation requires initial values, use setInitialValues props';
      } else {
        this.setState({ fields: { ...initialValues } });
      }
    }

    private async onSubmit(params: object): Promise<OnSubmitReturn> {
      if (schema) {
        const data = JSON.stringify(params, null, 2);

        return await schema
          .validate(data, { abortEarly: false })
          .then((valid: boolean) => {
            this.setFormErrors({
              errors: {}
            });
            return { valid };
          })
          .catch((err: any) => {
            const errors: { [key: string]: string } = {};
            err.inner.forEach((element: { path: string; errors: string; }) => {
              errors[element.path] = element.errors;
            });

            // console.log('withForm onSubmit ', { errors });
            this.setFormErrors(errors);
            return { valid: false, errors };
          });
      }
      return { valid: true };
    }

    private setFormErrors(errors: { [key: string]: string } | {}) {
      this.setState({ errors });
    }

    private handleValidationSync = async (field: string, params: object) => await schema
      .validateAt(field, params)
      .then(() => {
        const data = JSON.stringify(params, null, 2);
        const valid = schema.isValidSync(data);
        this.setState({ valid });
        this.setFormErrors({
          errors: {}
        });
      })
      .catch((err: any) => {
        const data = JSON.stringify(params, null, 2);
        const valid = schema.isValidSync(data);
        const errors = { [field]: err.errors };

        this.setState({ valid, errors });
      });


    private setChangeOnState(
      field: string,
      value: unknown,
      noValidate = false
    ) {
      this.setState(
        { fields: { ...this.state.fields, [field]: value } },
        async () => {
          if (isValidationSync && !noValidate) {
            this.handleValidationSync(field, this.state.fields);
          }
        }
      );
    }

    private handleInputChange = ({
      field,
      value,
      noValidate = false
    }: InputChangeProps) => {
      this.setChangeOnState(field, value, noValidate);
    };

    private clearField = (field: string) => {
      setTimeout(() => this.setChangeOnState(field, ''), 0);
    };

    private handleSetValues = (params: object) => {
      this.setState({
        fields: {
          ...this.state.fields,
          ...params
        }
      });

      const data = JSON.stringify(params, null, 2);
      const valid = schema.isValidSync(data);

      this.setState({ valid });
    };

    render() {
      return (
        <Component
          errors={this.state.errors}
          setFormErrors={this.setFormErrors.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          inputChange={this.handleInputChange.bind(this)}
          clearField={this.clearField.bind(this)}
          fields={this.state.fields}
          valid={this.state.valid}
          // use setValues inside useEffect
          setValues={this.handleSetValues.bind(this)}
          {...this.props}
        />
      );
    }
};

export default withForm;
