import styled from 'styled-components'
import { FormClose } from 'grommet-icons'
import { Label } from '../Tag/styles'
import { gray, black } from '../Palette'
import { Box, Layer } from 'grommet'

export const ModalLayer = styled(Layer)`
  border-radius: 10px;
  background-color: ${gray.light1};
  width: 90%;
  height: 90%;
`
export const ModalContainer = styled(Box)`
  color: ${black.primary};
  padding: 20px;
  font-family: Roboto;
`
export const ModalHeader = styled.div`
  border-radius: 10px;
  background-color: ${gray.light1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const Title = styled.h1`
  border-radius: 10px;
  background-color: ${gray.light1};
  margin: 10px 0 0 0;
`
export const CloseButton = styled.button`
  background-color: ${gray.light1};
  border: none;
  float: right;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CloseIcon = styled(FormClose)`
  height: 1em;
  width: 1em;
  border: 2px solid ${gray.eclipse};
  border-radius: 100%;
  stroke: ${black.primary};
`
export const CloseLabel = styled(Label)`
  font-weight: bold;
  font-family: Roboto;
  color: ${gray.eclipse}
  font-size: 14px;
  margin-right: 15px;
`
