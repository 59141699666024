import { IOperatorRankingPositionResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-ranking-position'
import { IOperatorRecordResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-record'
import { Box, Grid } from 'grommet'
import * as React from 'react'
import FixedMonthRecordCard from '../Cards/FixedMonthRecordCard/FixedMonthRecordCard'
import IntervalRecordCard from '../Cards/IntervalRecordCard/IntervalRecordCard'
import OperatorRankingCards from '../Cards/OperatorRankingCards/OperatorRankingCards'
import { CardsBoxStyle, OverviewBoxStyle } from './style'

export interface IRepresentativeOverviewProps {
  operatorRanking?: IOperatorRankingPositionResponse | Error
  intervalRecord?: IOperatorRecordResponse | Error
  fixedMonthRecord?: number | Error
  totalApplicationsToday: number
}

export interface IRepresentativeOverviewState {}

export default class RepresentativeOverview extends React.PureComponent<
  IRepresentativeOverviewProps,
  IRepresentativeOverviewState
> {
  public render() {
    const { operatorRanking, fixedMonthRecord, intervalRecord, totalApplicationsToday } = this.props

    return (
      <OverviewBoxStyle>
        <CardsBoxStyle className="desktop">
          <OperatorRankingCards operatorRanking={operatorRanking} />
          <IntervalRecordCard totalApplicationsToday={totalApplicationsToday} intervalRecord={intervalRecord} />
          <FixedMonthRecordCard fixedMonthRecord={fixedMonthRecord} />
        </CardsBoxStyle>
        <CardsBoxStyle fill className="is-mobile">
          <Grid columns={['1/2', '1/2']} rows={['auto']} areas={[['ranking', 'record']]}>
            <Box gridArea="ranking">
              <OperatorRankingCards operatorRanking={operatorRanking} />
            </Box>
            <Box gridArea="record">
              <IntervalRecordCard totalApplicationsToday={totalApplicationsToday} intervalRecord={intervalRecord} />
            </Box>
          </Grid>
          <FixedMonthRecordCard fixedMonthRecord={fixedMonthRecord} />
        </CardsBoxStyle>
      </OverviewBoxStyle>
    )
  }
}
