import React from 'react'

// TODO - How to type ...rest ?
export const PhoneIcon = (props: any) => {
  let { color, ...rest } = props

  color = color ? color : '#C2C2C2'

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="22.5" cy="22.5" r="18.5" fill="white" />
        <circle cx="22.5" cy="22.5" r="18" stroke={color} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0258 20.9912C20.8748 20.8323 20.8472 20.4632 21.0422 20.025C21.7735 18.0705 21.8727 17.7078 21.4041 17.2145L18.3963 14.0484C17.8187 13.4404 16.9009 13.5952 16.3334 14.1926C14.1112 16.5317 14.1112 20.3073 16.3334 22.6465L21.952 28.5608C24.1972 30.9242 27.8559 30.9242 30.101 28.5608C30.6499 27.9831 30.7858 27.09 30.238 26.5133L27.2302 23.3473C26.7261 22.8166 26.3836 22.9204 24.5047 23.6975C24.0715 23.9091 23.7744 23.8844 23.6424 23.7455L21.0258 20.9912ZM28.8428 27.4646C27.2515 29.0844 24.7319 29.0672 23.1604 27.4129L17.5417 21.4986C15.9669 19.8409 15.9315 17.1604 17.4353 15.4567L19.9401 18.0933L19.9343 18.1146C19.8557 18.402 19.7105 18.8314 19.5002 19.3948C19.0716 20.3538 19.1529 21.4396 19.8175 22.1391L22.434 24.8934C23.1175 25.6128 24.2025 25.7029 25.1918 25.2149C25.6505 25.0271 26.0394 24.8806 26.308 24.7964L28.8428 27.4646Z"
        fill={color}
      />
      <path
        d="M24.4167 20.5C24.4167 20.1318 24.1183 19.8333 23.7501 19.8333C23.2898 19.8333 22.9167 19.4602 22.9167 19C22.9167 18.5398 23.2898 18.1667 23.7501 18.1667C25.0387 18.1667 26.0834 19.2113 26.0834 20.5C26.0834 20.9602 25.7103 21.3333 25.2501 21.3333C24.7898 21.3333 24.4167 20.9602 24.4167 20.5Z"
        fill={color}
      />
      <path
        d="M23.7501 17.5833C25.3609 17.5833 26.6667 18.8892 26.6667 20.5C26.6667 20.9602 27.0398 21.3333 27.5001 21.3333C27.9603 21.3333 28.3334 20.9602 28.3334 20.5C28.3334 17.9687 26.2814 15.9167 23.7501 15.9167C23.2898 15.9167 22.9167 16.2898 22.9167 16.75C22.9167 17.2102 23.2898 17.5833 23.7501 17.5833Z"
        fill={color}
      />
      <path
        d="M28.9167 20.5C28.9167 17.6465 26.6036 15.3333 23.7501 15.3333C23.2898 15.3333 22.9167 14.9602 22.9167 14.5C22.9167 14.0398 23.2898 13.6667 23.7501 13.6667C27.524 13.6667 30.5834 16.7261 30.5834 20.5C30.5834 20.9602 30.2103 21.3333 29.7501 21.3333C29.2898 21.3333 28.9167 20.9602 28.9167 20.5Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="45"
          height="45"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
