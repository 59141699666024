import { CSSProperties } from 'react'
import styled from 'styled-components'
import { ReactComponent as Info } from '../../common/assets/images/info_icon.svg'
import { ReactComponent as Warning } from '../../common/assets/images/warning_icon.svg'
import { blue, gray, green, yellow } from '../../ui/utils/_colors'

export const purchseValueGroup = {
  display: 'flex',
  marginBottom: '16px',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}

export const redSpan: CSSProperties = {
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#F61E61',
  width: '50%',
}

export const orangeSpan: CSSProperties = {
  color: 'white',
  textAlign: 'center',
  backgroundColor: '#FFA500',
  width: '50%',
}

export const spanContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'center'
}

export const DownloadSmartphoneSaleConfigurationManualButton = styled.a`
  color: ${green.primary};
  text-align: left;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
  background: ${(props: any) => props.type == 'INFO' ? blue.alert50 : yellow.alert50};
  border: 1px solid #E4ECF1; 
  padding: 16px;
  border-radius: 8px;
  position: relative;

  &:nth-child(odd):last-child {
    grid-column: span 2;
  }
`

export const InfoIcon = styled(Info)`
  padding-right: 5px;
  width: 40px;
`

export const WarningIcon = styled(Warning)`
  padding-right: 5px;
  width: 35px;
`

export const InfoCardText = styled.div`
  color: ${gray.scale800};
  text-align: left;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props: any) => props.bold == true ? 600 : 400};
  line-height: 16px;
`