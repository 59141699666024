import moment from 'moment-timezone'

export const CALENDAR_MIN_BOUND_DATE = moment('2023-01-01')
  .tz('America/Manaus')
  .toISOString()

export const MAXIMUM_DATE_RANGE_ERROR_MESSAGE = 'Selecione um intervalo entre as datas de até 1 mês'

export enum ContractStatus {
  CANCELED = 'Cancelado',
  APPROVED = 'Aprovado',
}
