import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toaster } from '../../App'
import { Dropdown } from '../../atoms/Dropdown/Dropdown'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { bffParceirosApiService } from '../../services/bff'
import { StoreDto, StoreStatus } from '../../services/bff/retailers/dto/Store.dto'
import { sleep } from '../../utils/sleep'
import LoggedUserSaverService from '../LoggedUserInfoCard/LoggedUserSaverService'

export const StoreSelection: React.FC = () => {
  const [stores, setStores] = useState<Map<string, StoreDto> | undefined>(undefined)
  const [selectedStore, setSelectedStore] = useState<StoreDto | undefined>(undefined)

  const [isNewStore, setIsNewStore] = useState<boolean>(false)
  const [isUpdatingStore, setIsUpdatingStore] = useState<boolean>(false)

  useEffect(() => {
    const fetchStoresData = async () => {
      const currentStore = bffParceirosApiService.coordinator.getStore()
      setSelectedStore(currentStore)

      const stores = new Map<string, StoreDto>(
        (await bffParceirosApiService.retailers.fetchStores({ status: StoreStatus.ACTIVE })).stores
          .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
          .map(store => [store.id, store])
      )

      setStores(stores)
    }

    fetchStoresData()
  }, [])

  const getStoreSettings = async (storeId?: string) => {
    const storeWithSettings = storeId ? await bffParceirosApiService.retailers.fetchStoreById(storeId) : undefined
    return { settings: storeWithSettings?.settings }
  }

  const onStoreChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStoreId = event.target.value

    if (!selectedStoreId) {
      toaster.showErrorToast('Selecione uma loja')
      return
    }

    setIsUpdatingStore(true)

    const selectedStore = Array.from(stores!.values()).find(store => store.id === selectedStoreId)

    const { settings } = await getStoreSettings(selectedStore!.id)

    bffParceirosApiService.coordinator.setStore({ ...selectedStore!, settings })

    await LoggedUserSaverService.fetchAndSaveUser()

    setSelectedStore(bffParceirosApiService.coordinator.getStore())

    await handleStoreUpdate()
  }

  const handleStoreUpdate = async () => {
    setIsNewStore(true)
    setIsUpdatingStore(false)
    await sleep(3000)
    setIsNewStore(false)
  }

  if (!stores) return <LoadingSpinner />
  if (isUpdatingStore) return <LoadingSpinner text={'Alterando a loja...'} />

  return (
    <div>
      <Dropdown value={selectedStore?.name} onChange={onStoreChange} options={Array.from(stores!.values())} />
      {isNewStore && <SuccessMessage>Loja alterada com sucesso!</SuccessMessage>}
    </div>
  )
}

const SuccessMessage = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #448017;
`
