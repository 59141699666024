import { ButtonType } from 'grommet'
import React, { Component } from 'react'
import { StyledButton } from './styles'

interface ButtonProps extends ButtonType {
  width?: string
  border?: string
  borderRadius?: string
  color?: string
  backgroundColor?: string
}

export default class Button extends Component<ButtonProps> {
  render() {
    return <StyledButton {...this.props}>{this.props.children}</StyledButton>
  }
}
