import * as React from 'react'
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { BigButton } from '../../../molecules/Budget/BigButton';
import { BUDGET_PAGES } from './budget-utils';
import { Bold, Card, Title } from '../../../molecules/Budget';
import { BodyText } from '../../../molecules/Budget/BodyText';
import { ReactComponent as CloseCard } from '../../../common/assets/images/close-card.svg';
import { goToPage } from '../../../molecules/Budget/utils';

const BudgetHowItWorks = (): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Header>
        <Title>Como funciona?</Title>

        <CloseCard
          onClick={goToPage(dispatch, BUDGET_PAGES.ONBOARDING_PAGE)}
          style={{ cursor: 'pointer' }}
        />
      </Header>

      <BodyText>
        <Bold>Fazemos uma análise e, se for possível,</Bold> aumentaremos o limite para concluir a compra.
      </BodyText>

      <BodyText>
        <Bold>*Lembre-se o aumento de limite não é garantido.</Bold>
      </BodyText>

      <BigButton
        backgroundColor='#3350FF'
        onClick={goToPage(dispatch, BUDGET_PAGES.ONBOARDING_PAGE)}
      >
        Fechar
      </BigButton>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled(Card)`
  flex: 1;
  justify-content: space-between;
  padding-bottom: 20%;
`;

export default BudgetHowItWorks;
