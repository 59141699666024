import { Button } from 'antd'
import styled from 'styled-components'
import { blue, gray, green, white } from '../../../../ui/utils/_colors'

interface NavigateButtonProps {
  backgroundColor: string
  textColor: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 0px;
  justify-content: space-between;
  height: 70%;
  // For Desktop
  max-width: 400px;
  margin: auto;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: end;
`

export const FinalizeText = styled.p`
  color: ${blue.dark};
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-decoration: underline;
  margin: 0;
  inline-size: fit-content;
  border: none;
  background-color: ${white.primary};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:focus {
    outline: ${blue} auto 1px;
  }
  @media only screen and (max-width: 770px) {
    margin: 28 auto;
    text-align: center;
    inline-size: none;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 65%;
  padding: 16px 0px 16px 0px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #f2f2f2;
  background: #ffffff;
  margin-bottom: 100px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 20px 0px;
`

export const CardTitle = styled.span`
  font-size: 18px;
  color: #999999;
`

export const BoldText = styled.span`
  font-weight: bold;
  color: black;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardMessage = styled.text`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 4px;
  color: #999999;
`

export const StraightLine = styled.div`
  width: 100%;
  margin: 10px 0px;
  border-block: inherit;
  border-width: 1px;
  border-color: #f2f2f2;
`

export const AlertInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  margin: 20px 0px;
`

export const Subtitle = styled.text`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 10px;
`

export const CardValue = styled.text`
  font-size: 28px;
  font-weight: 550;
  text-align-vertical: center;
  color: ${green.dark1};
`

export const CardInfo = styled.text`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${gray.text};
  margin-top 30px;
`

export const NavigateButton = styled(Button)<NavigateButtonProps>`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: ${(props: NavigateButtonProps) => props.textColor} !important;
  // BUTTON
  height: 80px !important;
  padding: 20px !important;
  border: 1px solid ${green.dark1} !important;
  background: ${(props: NavigateButtonProps) => props.backgroundColor} !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin: 0px;
  align-self: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
