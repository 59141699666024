import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class FilePlusIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M6,22h12c1.104,0,2-0.896,2-2V8l-6-6H6C4.896,2,4,2.896,4,4v16C4,21.104,4.896,22,6,22z M13,4l5,5h-4c-0.553,0-1,0-1,0V4z M8,14h3v-3h2v3h3v2h-3v3h-2v-3H8V14z" />
        </svg>
      </Icon>
    )
  }
}
