import { Button, Image } from 'antd'
import { Box, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { IBiometryImageError } from '../../../redux/reducers/biometry/biometry.constants'

export interface IErrorImageModalProps {
  previous: () => void
  imageError?: IBiometryImageError
}

export const InvalidImageView = (props: IErrorImageModalProps) => {
  const { imageError, previous } = props

  const handlePrevious = () => {
    previous()
  }

  if (!imageError) {
    return (
      <Container>
        <h1> Nenhum erro por aqui </h1>
      </Container>
    )
  }

  const { errorMessage, imageBase64 } = imageError

  const message = errorMessage

  return (
    <Container>
      <ImageBox>
        <Image src={imageBase64} preview={true} />
        <ErrorText>{message}</ErrorText>
      </ImageBox>
      <TryAgainButton onClick={handlePrevious}>Tirar nova foto</TryAgainButton>
    </Container>
  )
}

const ImageBox = styled.div`
  max-width: 90vh;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const Container = styled(Box)`
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`

const ErrorText = styled(Text)`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 1vh;
  color: #e10000;
`

export const TryAgainButton = styled(Button)`
  margin: 0 2vh 0 2vh;
  width: 90%;
  height: 40px !important;

  letter-spacing: 0;
  font-family: Roboto;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #fff !important;

  background-color: #11b048 !important;
  border-color: #11b048 !important;
  border-radius: 10px !important;
`
