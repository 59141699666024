import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '../../../common/assets/images/arrow-left.svg';
import { ReactComponent as Money } from '../../../common/assets/images/brl.svg';
import { ReactComponent as Profile } from '../../../common/assets/images/profile.svg';
import { ReactComponent as VerticalLine } from '../../../common/assets/images/vertical-line.svg';
import FeatureFlags from '../../../common/assets/utils/featureFlags';
import { Bold, Card, Title } from '../../../molecules/Budget';
import { Text } from '../../../molecules/Budget/';
import { BigButton } from '../../../molecules/Budget/BigButton';
import { goToPage } from '../../../molecules/Budget/utils';
import { useTypedSelector } from '../../../redux/reducers/selectors';
import { BUDGET_PAGES } from './budget-utils';
import BudgetPhone from './BudgetPhone';

const  BudgetOnboarding = (): JSX.Element => {
  const dispatch = useDispatch();

  const { phone } = useTypedSelector((state) => state.budget);

  if (!phone) {
    return <BudgetPhone />
  }

  const currentUrl = window.location.href;

  const renderCardLine = (
    Icon: React.FunctionComponent,
    title?: string,
    subtitle?: string
  ) => {
    return <Line>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <InfoWrapper>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </InfoWrapper>
    </Line>;
  };

  return (
    <>
      <NavBar>
        <BackWrapper onClick={goToPage(dispatch, BUDGET_PAGES.PHONE_PAGE)}>
          <BackIcon style={{ marginRight: 20 }} />
          <Text color='#0E2DE6' clickable>Trocar telefone</Text>
        </BackWrapper>

        <Text color='#92929D'>{phone}</Text>
      </NavBar>

      <Title style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {FeatureFlags.isBudgetBlackCampaignActive() && currentUrl.includes('/calculadora') 
          ? "Aumento em apenas" 
          : "Faça uma análise em apenas"} 
        <br /> 
        <Bold>2 etapas</Bold>
      </Title>

      
      <Card onClick={goToPage(dispatch, BUDGET_PAGES.BUDGET_REQUEST_FORM_PAGE)}>
        {renderCardLine(Profile, 'CPF', 'Digite o CPF')}
        {renderCardLine(VerticalLine)}
        {renderCardLine(Money, 'Valor da compra', 'Digite o valor da compra')}
      </Card>

      <BigButton onClick={goToPage(dispatch, BUDGET_PAGES.BUDGET_REQUEST_FORM_PAGE)}>
        Começar
      </BigButton>
    </>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 0.2;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const CardTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const CardSubtitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #92929D;
  margin: 0;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BudgetOnboarding;
