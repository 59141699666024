import { Button, Text } from 'grommet'
import styled, { css } from 'styled-components'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

export const formItemsContainerStyle = {
  padding: '32px',
  display: 'flex',
  flexDirection: 'column' as any,
  width: '60vw',
  marginBottom: '64px',
}

export const centeredStyle = {
  padding: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
}

export const cardStyle = {
  display: 'flex',
  padding: '0',
  width: '50vw',
  paddingRight: '16px',
  paddingLeft: '16px',
  justifyContent: 'space-between',
}

export const ssnDialogStyle = {
  padding: '8px',
  display: 'flex',
  flexDirection: 'column' as any,
  scrollBehavior: 'smooth' as any,
  width: '100%',
}

export const formItemStyle = {
  marginTop: '8px',
  marginBottom: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'flex-start',
}

export const formActionsStyle = {
  display: 'flex',
  flexDirection: 'row-reverse' as any,
}

export const textInputGroupWithLabelStyle = {
  display: 'flex',
  marginTop: '16px',
  marginRight: '32px',
  marginLeft: '32px',
  flexDirection: 'column' as any,
  justifyContent: 'flex-end',
}
export const textInputGroupStyle = {
  display: 'flex',
  alignItems: 'flex-end',
}

export const centeredButtonGroup = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
}

export const passwordFormGroupStyle = {
  display: 'flex',
  marginTop: '16px',
  marginRight: '32px',
  marginLeft: '32px',
  flexDirection: 'column' as any,
  alignItems: 'flex-start',
}

export const buttonStyle = {
  width: '32px',
  marginLeft: '16px',
}

export const h5Style = {
  alignSelf: 'flex-start',
  marginLeft: '32px',
  marginTop: 0,
  marginBottom: '16px',
}
export const titleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginRight: '32px',
}

export const buttonsSubmitStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: 0,
  marginRight: '32px',
  marginLeft: '32px',
  justifyContent: 'space-between',
  marginBottom: '16px',
}

export const filtersStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  marginLeft: '32px',
  marginRight: '32px',
  marginTop: '0',
  justifyContent: 'space-between',
  marginBottom: '8px',
}

export const formContainerStyle = {
  display: 'flex',
  marginTop: '7em',
  justifyContent: 'center',
  alignItems: 'baseline',
  height: '100vh',
  widht: '100vw',
}

export const StyledSubtitle = styled(Text)`
  font-size: 14px;
  text-align: start;
  padding-left: 50px;
`

export const StyledErrorLabel = styled(Text)`
  color: ${UMEColors.red.light};
`

export const StyledConfirmationText = styled(Text)`
  font-size: 22px;
  margin-left: 25px;
`

export const FileUpload = styled.input`
  outline: none;
  margin-left: 15px;
  padding: 5px;
`
export const DownloadTableTemplateButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  padding: 5px;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.gray.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.gray.primary};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.gray.primary};
  }

  &:active {
    background-color: ${UMEColors.gray.primary};
  }
`
export const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${UMEColors.gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${UMEColors.gray.eclipse};
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${UMEColors.gray.nobel};
  }
`
