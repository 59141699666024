import { DueDateRule } from '../services/bff/coordinator/dto/Contracts.dto'

const DAYS_IN_MONTH = 30

/**
 * Converts a given fee to another unit, examples:
 *
 * (x, 360, 30) converts the fee x from yearly to montly fee.
 * (x, 7, 30) converts the fee x from weekly to montly fee.
 * (x, 30,14) converts the fee x from monthly to biweekly fee.
 * (x, 30, 7) converts the fee x from monthly to weekly fee.
 *
 * @param {number} fee
 * @param {number} targetUnit
 * @param {number} feeUnit
 * @returns {number}
 */

const mapDueDateRuleToDays = new Map<DueDateRule, number>([
  [DueDateRule.BIWEEKLY, 14],
  [DueDateRule.WEEKLY, 7],
  [DueDateRule.MONTHLY, DAYS_IN_MONTH],
])

export const convertFee = (fee: number, feeUnit: number, targetUnit: number): number => {
  return Math.pow(1 + fee, targetUnit / feeUnit) - 1
}

export const resolveFeeByDueDateRule = (fee: number, targetDueDateRule?: DueDateRule): number => {
  if (!targetDueDateRule || targetDueDateRule === DueDateRule.MONTHLY) return fee

  const days = mapDueDateRuleToDays.get(targetDueDateRule) ?? DAYS_IN_MONTH

  return convertFee(fee, DAYS_IN_MONTH, days)
}

export const convertToPercentage = (value: number): string => {
  return Number(value * 100).toFixed(2)
}
