import React from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import BudgetReducer from '../../redux/reducers/budget/budget.reducer';
import { Text } from './index';

interface Props {
  dispatch: Dispatch<any>;
  text?: string;
}

export const CancelButton = (
  props: JSX.IntrinsicElements['p'] & Props
): JSX.Element => {

  const handleClick = () => {
    props.dispatch(BudgetReducer.actions.resetRequest());
  }

  return (
    <BackWrapper onClick={handleClick} {...props}>
      <Text color='#0E2DE6' clickable>{props.text || 'Cancelar'}</Text>
    </BackWrapper>
  );
};

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
`;
