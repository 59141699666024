import { Button } from 'antd'
import { Text, TextProps } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { DateFormat, TimeZone, formatDate } from '../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../common/TransactionalCreditHelper'
import { ReactComponent as ApprovedCheckSvg } from '../../common/assets/images/approved-check-circle.svg'
import { TransactionalCreditEvaluation } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { Loading } from '../../ui'
import { gray, green } from '../../ui/utils/_colors'

interface TransactionalCreditEvaluationProps {
  evaluation: TransactionalCreditEvaluation
  isFetchingBorrowerLimit: boolean
  borrowerLimit?: number
  availableLimit?: number
}

const ApplicationWithTransactionalCreditEvaluation: React.FC<TransactionalCreditEvaluationProps> = (props: TransactionalCreditEvaluationProps) => {
  const { evaluation, isFetchingBorrowerLimit, availableLimit } = props

  const handleBackToHomePage = () => {
    window.location.href = '/'
  }

  const totalAmount = calculateTxCreditTotalAmount(evaluation.policy!!.maxAmount, (availableLimit || 0))

  return (
    <Container>
      <HeaderContainer>
        <Title>Cadastro realizado com sucesso</Title>
        <SubTitle>O cadastro do cliente foi realizado e a Super Compra Ume está ativa para esta loja!</SubTitle>
      </HeaderContainer>

      {isFetchingBorrowerLimit ? (
        <LoadingContainer>
          <LoadingText>Estamos calculando o limite do cliente</LoadingText>
          <Loading color="#10A343" height={20} width={20} />
        </LoadingContainer>
      ) : (
        <Card>
          <CardHeader>
            <ApprovedCheckSvg />
            <CardTitle>Super Compra Ume ativa</CardTitle>
            <CardValue>{formatBRL(totalAmount)}</CardValue>
          </CardHeader>

          <StraightLine />

          <CardInfoContainer>
            <InfoDescription>Validade</InfoDescription>
            <InfoValue>
              {formatDate(evaluation.expirationTimestamp, DateFormat.BR_DATE_FORMAT, TimeZone.MANAUS)}
            </InfoValue>
          </CardInfoContainer>

          <StraightLine />

          <AlertInfoContainer>
            <Subtitle>Importante:</Subtitle>
            <>
              <Row>
                {`\u2022`}
                <AlertInfoMessage>
                  A Super Compra é um limite extra válido para uma única compra em sua loja.
                </AlertInfoMessage>
              </Row>
              <Row>
                {`\u2022`}
                <AlertInfoMessage>
                  A proposta para este cliente já se encontra ativa no Caixa.
                </AlertInfoMessage>
              </Row>
            </>
          </AlertInfoContainer>
        </Card>
      )}

      <BackButton onClick={handleBackToHomePage}>Voltar para a tela inicial</BackButton>
    </Container>
  )
}

export default ApplicationWithTransactionalCreditEvaluation

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 32px 24px 32px 24px;
  justify-content: space-evenly;

  height: 80%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;

  letter-spacing: 0.18px;

  color: ${green.dark1};
`

const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 0.1px;

  color: #000000;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  min-height: 70%;
  padding: 16px 0px 16px 0px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #f2f2f2;

  background: #ffffff;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 10px;
`

const CardTitle = styled.span`
  font-size: 16px;
  font-weight: 500;;
  line-height: 16px;
  color: ${gray.text};
  
  margin-top 14px;
`

const CardValue = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${green.dark1};
`

const StraightLine = styled.div`
  width: 100%;
  margin: 10px 0px;
  border-block: inherit;
  border-width: 1px;
  border-color: #f2f2f2;
`

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`

export const InfoDescription = styled.text`
  font-size: 14px;
  font-weight: 400;
  text-align-vertical: center;
  color: #999999;
`

export const InfoValue = styled(Text) <TextProps>`
  font-size: 14px;
  font-weight: 550;
  text-align-vertical: center;
  color: ${(props: TextProps) => props.color ?? gray.text};
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.p`
  font-size: 12px;
  color: #11b048;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const AlertInfoMessage = styled.text`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 4px;
  color: #999999;
`

export const AlertInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  margin: 20px 0px;
`

export const Subtitle = styled.text`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 10px;
`

const BoldText = styled.span`
  font-weight: 525;
  color: black;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  height: 40px !important;
  padding: 20px !important;
  border: 1px solid ${green.dark1} !important;
  background: ${green.dark1} !important;
  border-radius: 4px !important;

  width: 100% !important;
  margin-top: 22px;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
