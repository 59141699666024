import { PayloadAction } from '@reduxjs/toolkit'
import { ChatbotLimitBudgetResponse } from '@scudraservicos/coordinator-client/dist/src/services/budgets/interfaces/ChatbotLimitBudgetResponse'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import { set as setOnLocalStorage } from '../../../common/assets/utils/localStorage'
import { BUDGET_PAGES } from '../../../organisms/Budget/BudgetSteps/budget-utils'
import { bffParceirosApiService } from '../../../services/bff'
import { ChatbotError } from '../../../services/bff/coordinator/dto/Budgets.dto'
import { selectState } from '../selectors'
import { BUDGET_ASYNC_STORAGE_KEYS } from './budget.constants'
import { processBudgetRequestError } from './budget.errors'
import BudgetSliceReducer, { IFecthBudgetPayload, ISetPhonePayload } from './budget.reducer'

function* setPhone(action: PayloadAction<ISetPhonePayload>) {
  yield put(BudgetSliceReducer.actions.setPage(BUDGET_PAGES.ONBOARDING_PAGE))
  const { phone } = action.payload

  setOnLocalStorage(BUDGET_ASYNC_STORAGE_KEYS.phone, phone)
}

function* createBudget(action: PayloadAction<IFecthBudgetPayload>) {
  const { cpf, value } = action.payload
  try {
    let phone: string = yield selectState(state => state.budget.phone)
    phone = `+55${phone.replace(/\D/g, '')}`

    const response: ChatbotLimitBudgetResponse | ChatbotError = yield bffParceirosApiService.budgets.createBudget(
      String(cpf?.value),
      Number(value?.floatValue),
      phone
    )
    yield put(BudgetSliceReducer.actions.createBudgetSuccess(response as ChatbotLimitBudgetResponse))

    const { action } = response as ChatbotLimitBudgetResponse
    if (action === 'PARTIAL_APPROVED_AUTO' || action === 'APPROVED_AUTO') {
      yield put(BudgetSliceReducer.actions.setPage(BUDGET_PAGES.INCREASE_LIMIT_ONBOARDING_PAGE))
    } else if (action === 'DENIED_AUTO' || action === 'SUFFICIENT_LIMIT') {
      yield put(BudgetSliceReducer.actions.setPage(BUDGET_PAGES.BUDGET_RESPONSE_PAGE))
    } else {
      throw response
    }
  } catch (error) {
    console.log({ error })
    const budgetError = processBudgetRequestError(error as AxiosError)

    if (
      budgetError === 'UNKNOWN_ERROR' ||
      (budgetError !== 'REQUEST_ERROR' &&
        budgetError !== 'CONNECTION_ERROR' &&
        budgetError !== 'CPF_NOT_FOUND' &&
        budgetError !== 'OPEN_RENEGOTIATIONS' &&
        budgetError !== 'OVERDUE_INSTALLMENTS' &&
        budgetError !== 'LIMIT_IS_BLOCKED' &&
        budgetError !== 'MANUAL_EVALUATION' &&
        budgetError !== 'LIMIT_NOT_FOUND' &&
        budgetError !== 'NOT_APPROVED')
    ) {
      Sentry.captureException(error, { extra: { method: 'createBudget' } })
    }

    yield put(BudgetSliceReducer.actions.createBudgetError(budgetError))
  }
}

const budgetSaga = [
  takeLatest(BudgetSliceReducer.actions.setPhone, setPhone),
  takeLatest(BudgetSliceReducer.actions.createBudget, createBudget),
]

export default budgetSaga
