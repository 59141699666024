import React from 'react'
import styled from 'styled-components'
import { black, gray } from '../../../ui/utils/_colors'
import AvailableProductButton, { IAvailableProductButtonProps } from './AvailableProductButton'

export interface IAvailableProductsCardProps {
  userAvailableProducts: IAvailableProductButtonProps[]
}

const AvailableProductsSection: React.FC<IAvailableProductsCardProps> = props => {
  const { userAvailableProducts } = props

  return (
    <MainContainer>
      <AvailableProductsContainer>
        <Header>
          <Title>O que deseja fazer agora?</Title>
        </Header>

        <AvailableProductsCardContainer>
          {userAvailableProducts.map(
            (item, index) =>
              item.shouldRender && (
                <AvailableProductButton key={index} title={item.title} icon={item.icon} onButtonPress={item.onButtonPress} isLoading={item.isLoading} shouldRender={item.shouldRender} />
              )
          )}
        </AvailableProductsCardContainer>
      </AvailableProductsContainer>
    </MainContainer>
  )
}

export default AvailableProductsSection

const MainContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${gray.light2};
`

const AvailableProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 0px 24px 16px 24px;
`

const Header = styled.div`
  padding: 24px 0px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: black;
  line-height: 22px;
  text-align: left;
  color: ${black.primary};
`

const AvailableProductsCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
