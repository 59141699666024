import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { BorrowerIdRetailerIdStoreIdParam, CouponDto } from './dto/Coupons.dto'

export default class CouponsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async fetchActiveNextPurchaseCoupons(params: BorrowerIdRetailerIdStoreIdParam): Promise<CouponDto> {
    const response = await this.axiosInstance.get<CouponDto>(`/coupons/next-purchase`, { params: params })

    return response.data
  }
}
