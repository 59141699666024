import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as RemoveBaseIcon } from '../../common/assets/images/bx-x-circle.svg'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

interface ITagProps {
  color?: string
  backgroundColor?: string
  label: string
  index: number
  onClick?: (label: string, index: number) => void
}

export const Tag = (props: ITagProps) => {
  let { color, backgroundColor, label } = props

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.label, props.index)
    }
  }

  return (
    <TagContainer backgroundColor={backgroundColor} color={color} onClick={onClick}>
      <Label>{label}</Label>
      <RemoveIcon />
    </TagContainer>
  )
}

const TagContainer = styled.div`
  // margin: 10px;
  padding: 5px 10px 5px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${UMEColors.white.primary};
  border: 1px solid black;

  fill: ${UMEColors.black.primary};
  border-radius: 10px;
  font-size: 14px;

  ${(props: any) =>
    css`
      background-color: ${props.backgroundColor ? props.backgroundColor : UMEColors.black.primary};
      border: none;
      color: ${props.color ? props.color : UMEColors.white.primary};
      fill: ${props.color ? props.color : UMEColors.white.primary};
    `};

  &:hover {
    opacity: 0.4;
    transition: 0.3s;
    cursor: pointer;
  }
`

const RemoveIcon = styled(RemoveBaseIcon)`
  height: 22px;
  width: 22px;
  margin-left: 5px;
`

const Label = styled.span`
  height: 100%;
  line-height: 0;
`
