import React, { PureComponent } from 'react';
import { Icon } from './styles';

export default class HouseNewIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="92" height="92" viewBox="-10 4 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M15.1934 32.666V79.7653H75.9667V32.666" stroke="#252927" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
          <path fill="none" d="M7.59668 37.9829L45.58 11.3945L83.5634 37.9829" stroke="#252927" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
          <path fill="none" d="M56.975 79.7646V56.9746C56.975 52.7774 53.5755 49.3779 49.3783 49.3779H41.7817C37.5845 49.3779 34.185 52.7774 34.185 56.9746V79.7646" stroke="#252927" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
          <rect x="11" y="92" width="70" height="22" rx="8" fill="#11B048"/>
          <text fontWeight="bold" transform="matrix(0.704356, 0, 0, 0.662493, 5.12189, 37.3505)" textAnchor="start" fontFamily="'Roboto'" fontSize="17" y="106" x="15" strokeWidth="0" fill="#ffffff">NOVIDADE!</text>
        </svg>
      </Icon>
    );
  }
}
