import React from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../App'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { APPLICATION_STATUS } from '../../redux/reducers/application/application.types'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import ApplicationApprovedComponent from '../Application/ProcessEndPages/ApplicationApprovedComponent'
import ApplicationDeniedComponent from '../Application/ProcessEndPages/ApplicationDeniedComponent'

const OperatorServiceCenterReevaluationResultPage = () => {
  const dispatch = useDispatch()

  const { limit, applicationReevaluationStatus, document } = useTypedSelector(state => ({
    limit: state.operatorServiceCenter.borrower?.credit.limit,
    applicationReevaluationStatus: state.operatorServiceCenter.applicationReevaluationStatus,
    document: state.operatorServiceCenter.borrower?.borrower.document,
  }))

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    return history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  const wasApproved = applicationReevaluationStatus === APPLICATION_STATUS.APPROVED

  return (
    <>
      {wasApproved ? (
        <ApplicationApprovedComponent
          handleReset={handleBackButton}
          isFetchingBorrowerLimit={false}
          transactionalCreditEvaluation={undefined}
          applicationCoupon={undefined}
          availableLimit={undefined}
          borrowerLimit={limit}
          document={document}
        />
      ) : (
        <ApplicationDeniedComponent handleReset={handleBackButton} />
      )}
    </>
  )
}

export default OperatorServiceCenterReevaluationResultPage
