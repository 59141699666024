import { DateInput } from 'grommet'
import React from 'react'
import { gray } from '../../legacy-lib/design-kit-ui/utils/_colors'

interface DatePickerProps {
  label: string
  date: string
  bounds?: string[]
  onChange: (event: { value: string | string[] }) => void
}
export const DatePicker = (props: DatePickerProps) => {
  const { date, label, onChange, bounds } = props

  return (
    <div>
      <p style={{ fontSize: '1.2rem' }}>
        <strong>{label}</strong>
      </p>
      <DateInput
        style={{ ...dateInputStyle, backgroundColor: date === '' ? '#f5696b4d' : '' }}
        format="dd/mm/yyyy"
        value={date}
        calendarProps={{
          locale: 'pt-BR',
          bounds: bounds,
        }}
        onChange={onChange}
      />
    </div>
  )
}

const dateInputStyle: React.CSSProperties = {
  width: '13rem',
  fontSize: '1rem',
  fontFamily: 'Roboto',
  boxSizing: 'border-box',
  border: `1px solid ${gray.light}`,
  borderRadius: '4px',
  fontWeight: 700,
  textAlign: 'center',
}
