import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { ReactComponent as BronzeIncentiveIcon } from '../../../common/assets/images/bronze-incentive-icon.svg'
import { ReactComponent as DiamondIncentiveIcon } from '../../../common/assets/images/diamond-incentive-icon.svg'
import { ReactComponent as GoldIncentiveIcon } from '../../../common/assets/images/gold-incentive-icon.svg'
import { ReactComponent as SilverIncentiveIcon } from '../../../common/assets/images/silver-incentive-icon.svg'
import { IncentiveCategories } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-incentives.enum'
import { gray } from '../../../ui/utils/_colors'

interface IOperatorIncentive {
  incentiveCategory?: string
  incentiveValue?: number
}

const OperatorServiceCenterIncentive: React.FC<IOperatorIncentive> = props => {
  const { incentiveCategory, incentiveValue } = props

  const handleIncentiveIcon = () => {
    switch (incentiveCategory) {
      case IncentiveCategories.BRONZE:
        return <BronzeIncentiveIcon />
      case IncentiveCategories.PRATA:
        return <SilverIncentiveIcon />
      case IncentiveCategories.OURO:
        return <GoldIncentiveIcon />
      case IncentiveCategories.DIAMANTE:
        return <DiamondIncentiveIcon />
    }
  }

  const incentiveIcon = handleIncentiveIcon()

  return (
    <>
      <Container>
        {incentiveIcon}
        <IncentiveContainer>
          <Title>{`Incentivo ${incentiveCategory}`}</Title>
          {incentiveValue && <Text>{`Você ganha ${formatBRL(incentiveValue)} na conversão`}</Text>}
        </IncentiveContainer>
      </Container>
      <Line />
    </>
  )
}

export default OperatorServiceCenterIncentive

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

const IncentiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: auto;
  height: auto;
  padding: 16px 0px 16px 16px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: black;
  line-height: 22px;
  text-align: left;
`

const Text = styled.text`
  font-family: Inter;
  font-size: 14px;
  color: ${gray.scale700};
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding-top: 8px;
`

const Line = styled.hr`
  color: ${gray.scale400};
  border-top: 1px solid ${gray.scale400};
  width: 100%;
  margin: 24px 0px;
`
