import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { Reducers, rootSaga } from '../reducers'

const sagaMiddleware = createSagaMiddleware()

// Note: this API requires redux@>=3.1.0
const Store = createStore(Reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default Store
