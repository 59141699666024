export const isImeiValid = (imei: string): boolean => {
  const etal = /^[0-9]{15}$/
  if (!etal.test(imei)) return false
  let sum = 0
  let multiplier = 2
  const lastIndex = 14
  for (let i = 0; i < lastIndex; i++) {
    const digit = imei.substring(lastIndex - i - 1, lastIndex - i)
    const tp = parseInt(digit, 10) * multiplier
    if (tp >= 10) sum += (tp % 10) + 1
    else sum += tp
    if (multiplier === 1) multiplier++
    else multiplier--
  }
  const checkNumber = (10 - (sum % 10)) % 10
  if (checkNumber !== parseInt(imei.substring(14, 15), 10)) return false
  return true
}
