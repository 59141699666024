import { Anchor, Box, Heading, Text } from 'grommet'
import styled from 'styled-components'
import * as UMEColors from '../../../legacy-lib/design-kit-ui/utils/_colors'

export const Subtitle = styled(Heading)`
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
  margin: 0;
`

export const ImageContainer = styled(Box)`
  min-height: 20vh;
  margin-top: 1vh;
  margin-bottom: 3vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
`

export const AuthenticationImageCardContainer = styled(Box)`
  width: 100%;
  flex-direction: row;
  align-items: center;
`

export const ImageBox = styled(Box)`
  width: 50%;
  flex-direction: column;
  align-items: center;
`

export const AuthenticatedIconContainer = styled(Box)`
  align-items: center;
  padding: 15px;
  border: 1px solid #f1f1f5;
  width: 60px;
  border-radius: 100%;
`

export const BiometryLegend = styled(Text)`
  color: #171725;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
`

export const BiometryAnchor = styled(Anchor)`
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  text-decoration: underline;
`

export const PreviousContainer = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
`

export const PreviousTitle = styled(Text)`
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 16px;
  margin-left: 1vh;
`
export const Legend = styled(Text)`
  color: ${UMEColors.gray.aluminium};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  text-align: start;
`
