import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BackIcon } from '../../common/assets/images/arrow-left.svg'

interface Props {
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>
}

export const ArrowBackButton = (props: Props): JSX.Element => {
  return (
    <Container>
      <BackButton onClick={props.onBackButtonClick}>
        <BackIcon width={'24px'} height={'24px'} />
      </BackButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const BackButton = styled.div`
  cursor: pointer;
`
