import { Button } from 'antd'
import styled from 'styled-components'
import { gray, green } from '../../../../ui/utils/_colors'

interface NavigateButtonProps {
  backgroundColor: string
  textColor: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 0px;
  justify-content: space-around;
  height: 93%;
  // For Desktop
  max-width: 400px;
  margin: auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: start;
  text-align: start;
  margin: 30px 18px;
`

export const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: black;
`

export const BoldText = styled.span`
  font-weight: bold;
`

export const Message = styled.text`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  align-items: start;
  text-align: start;
  padding: 20px 0px;
  color: ${gray.text};
`

export const NavigateButton = styled(Button)<NavigateButtonProps>`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: ${(props: NavigateButtonProps) => props.textColor} !important;
  // BUTTON
  height: 40px !important;
  padding: 20px !important;
  border: 1px solid ${green.dark1} !important;
  background: ${(props: NavigateButtonProps) => props.backgroundColor} !important;
  border-radius: 4px !important;
  width: 90% !important;
  margin-top: 50px;
  align-self: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
