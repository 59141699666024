import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js'

export default class KeycloakService {
  private keycloakInstance: Keycloak | undefined

  async init(keycloakConfig: KeycloakConfig): Promise<Keycloak> {
    const keycloakInitOptions: KeycloakInitOptions = {
      onLoad: 'check-sso',
    }

    const keycloak = new Keycloak(keycloakConfig)

    await keycloak.init(keycloakInitOptions)

    this.keycloakInstance = keycloak

    return keycloak
  }

  async getLoginToken(): Promise<string> {
    try {
      const keycloakInitOptions: KeycloakInitOptions = {
        onLoad: 'check-sso',
      }

      if (this.keycloakInstance?.authenticated) {
        return this.keycloakInstance.token!
      }

      const isKeycloakInitialized = await this.keycloakInstance?.init(keycloakInitOptions)

      if (!isKeycloakInitialized) {
        await this.keycloakInstance?.login()
      }

      return this.keycloakInstance?.token!
    } catch (error) {
      return ''
    }
  }

  async logout(): Promise<void> {
    if (this.keycloakInstance?.authenticated) {
      this.keycloakInstance?.clearToken()
      await this.keycloakInstance?.logout()
    }
  }
}
