import React, { PureComponent } from 'react'
import Loading from '../../atoms/Loading/Loading'
import { ITableColumns, ITableProps } from './interfaces'
import { GenericTableContainer, NotFoundText } from './styles'

export class GenericTable extends PureComponent<ITableProps, {}> {
  renderRow(datum: any, index: number) {
    if (!this.props.header) {
      return <></>
    }

    return (
      <tr
        onClick={this.onClick.bind(this, index, datum)}
        key={index}
      >
        {this.props.header.map((c: any, index: number) => (
          <td key={index} align="center">
            <span>{this.renderCellText(c, datum)}</span>
          </td>
        ))}
      </tr>
    )
  }

  renderCellText(columnDefinition: ITableColumns, datum: any) {
    if (columnDefinition.formatObject) return columnDefinition.formatObject(datum)
    else if (columnDefinition.format) return columnDefinition.format(datum[columnDefinition.property])
    else return datum[columnDefinition.property]
  }

  onClick(index: number, content: any) {
    if (this.props.onClick) {
      this.props.onClick(index, content)
    }
  }

  render() {
    const { header, data, notFoundText } = this.props

    if (!header) return <></>

    if (!data) {
      return <Loading />
    }

    if (Array.isArray(data) && data.length === 0) {
      return <NotFoundText style={{ textAlign: 'center' }}> {notFoundText || 'Nenhum dado encontrado'} </NotFoundText>
    }

    return (
      <GenericTableContainer disableHover={this.props.disableHover}>
        <table>
          <thead>
            <tr>
              {header.map((c: ITableColumns, index: number) => (
                <th key={index} scope="col" align="center">
                  <span>
                    <strong>{c.label}</strong>
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{data.map(this.renderRow.bind(this))}</tbody>
        </table>
      </GenericTableContainer>
    )
  }
}
