import { IStoresRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/stores-ranking'
import { Box } from 'grommet'
import * as React from 'react'
import { Role } from '../../../../common/constants'
import { getAndFormatGraphXLabels } from '../../../../common/FormatName'
import { hasIdentifiedRetailersDashView } from '../../../../common/RoleValidators'
import { BarChart, Loading } from '../../../../legacy-lib/design-kit-ui'
import { getYValues } from '../../../../legacy-lib/design-kit-ui/BarChart/helpers'
import { blue } from '../../../../legacy-lib/design-kit-ui/Palette'
import {
  ApplicationsByStoreBoxStyle,
  BigCardStyle,
  IconCardStyle,
  SectionTitleStyle,
  SortLeftIconStyled,
  SortRightIconStyled,
  TextStyle,
} from './style'

export interface IApplicationByStoreProps {
  applicationsRankingByStore?: IStoresRankingResponse | Error
  userRole?: Role
}

export interface IApplicationByStoreState {
  isOnRankingOrder: boolean
}

const initialState: IApplicationByStoreState = {
  isOnRankingOrder: false,
}

export default class ApplicationByStore extends React.Component<IApplicationByStoreProps, IApplicationByStoreState> {
  _isMounted = false

  constructor(props: IApplicationByStoreProps) {
    super(props)

    this.state = initialState
  }

  orderValues = () => {
    const { isOnRankingOrder } = this.state

    this.setState({
      isOnRankingOrder: !isOnRankingOrder,
    })
  }

  public render() {
    const { isOnRankingOrder } = this.state
    const { applicationsRankingByStore } = this.props

    const showBarChart =
      applicationsRankingByStore instanceof Error ? (
        <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
      ) : (
        applicationsRankingByStore && (
          <BarChart
            boundsLabels={{ y: 'Quantidade' }}
            yAxis={getYValues(
              0,
              applicationsRankingByStore.storesRanking.reduce(
                (a: any, b) => (a.countApplications > b.countApplications ? a : b),
                getYValues
              ).countApplications
            )}
            averageValue={Number(applicationsRankingByStore.averageApplications.toFixed(1))}
            data={applicationsRankingByStore.storesRanking
              .sort((a, b) =>
                isOnRankingOrder ? a.countApplications - b.countApplications : b.countApplications - a.countApplications
              )
              .map(store => ({
                value: store.countApplications,
                label:
                  this.props.userRole && hasIdentifiedRetailersDashView(this.props.userRole)
                    ? `${getAndFormatGraphXLabels(store.storeName)}`
                    : `Loja ${store.rankingPosition}`,
              }))}
          />
        )
      )

    return (
      <ApplicationsByStoreBoxStyle>
        <Box direction="row" justify="between" align="end">
          <Box>
            <SectionTitleStyle>Aplicações por loja</SectionTitleStyle>
            <TextStyle>As informações do gráfico abaixo são de acordo com o filtro do calendário.</TextStyle>
          </Box>
          <IconCardStyle onClick={this.orderValues}>
            {isOnRankingOrder ? <SortLeftIconStyled color={blue.vivid} /> : <SortRightIconStyled color={blue.vivid} />}
          </IconCardStyle>
        </Box>
        <BigCardStyle>
          {applicationsRankingByStore ? (
            <Box>{showBarChart}</Box>
          ) : (
            <Box fill align="center" justify="center">
              <Loading></Loading>
            </Box>
          )}
        </BigCardStyle>
      </ApplicationsByStoreBoxStyle>
    )
  }
}
