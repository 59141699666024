import { Grommet, SelectProps } from 'grommet'
import React from 'react'
import { blue } from '../utils/_colors'
import { Container, StyledDropdown } from './styles'

const Dropdown = (props: SelectProps) => {
  return (
    <Container>
      <Grommet theme={theme}>
        <StyledDropdown {...props} />
      </Grommet>
    </Container>
  )
}

const theme = {
  global: {
    font: {
      family: 'Roboto',
    },
    colors: {
      brand: blue.primary,
      focus: blue.primary,
      border: {
        light: 'none',
      },
    },
  },
}

export default Dropdown
