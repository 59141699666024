import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  position: relative;
  margin: 0em 15px;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  label::after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  input[type='checkbox']:checked + label {
    background-color: #219653;
    border-color: #219653;

    transition: border-color 350ms cubic-bezier(0.4, 0, 0.23, 1);
    transition: background-color 350ms cubic-bezier(0.4, 0, 0.23, 1);
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`

interface Props {
  checked: boolean
}

export const RoundCheckBox = (props: Props) => {
  return (
    <CheckboxContainer>
      <input disabled readOnly checked={props.checked} type="checkbox" id="checkbox" />
      <label htmlFor="checkbox"></label>
    </CheckboxContainer>
  )
}
