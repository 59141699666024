import React from 'react'
import { Loading } from '../Loading'
import { Table, Data, DataRow, DataRowElement, Divider, HeaderElement, HeaderRow, Select } from './styles'

const RESET_STATE = 'Todos'

interface IDataTableProps {
  data?: any[]
  originalData?: any[]
  fontSize?: string
  header: IHeader[]
  onRowElementClick?: (element: any, index: number) => void
  onFilterSelected?: (attribute: string, selectedElement: string, data?: any[]) => void
  selectedIndex?: number
}

interface IDropDownValue {
  label: string
  value: string
}

interface IHeader {
  label: string
  attribute: string
  dropDownValues?: IDropDownValue[]
  formatCell?: (rowElement: any) => any
  textAlign?: string
}

export default class DataTable extends React.Component<IDataTableProps> {
  public RESET_STATE = RESET_STATE

  onDropDownSelect = (head: IHeader, event: React.ChangeEvent<HTMLSelectElement>, data?: any[]) => {
    if (this.props.onFilterSelected) {
      this.props.onFilterSelected(head.attribute, event.target.value, data)
    }
  }

  onRowElementClick = (element: any, index: number) => {
    // If callback
    if (this.props.onRowElementClick) {
      this.props.onRowElementClick(element, index)
    }
  }

  renderHeaderElement = (head: IHeader, index: number, data?: any[]) => {
    if (head.dropDownValues) {
      return (
        <HeaderElement
          fontSize={this.props.fontSize ? this.props.fontSize : '14px'}
          textAlign={head.textAlign ? head.textAlign : 'left'}
          key={index}
        >
          <Select
            fontSize={this.props.fontSize ? this.props.fontSize : '14px'}
            name="Header-Select"
            onChange={(event: any) => this.onDropDownSelect(head, event, data)}
          >
            <option key={index} value={RESET_STATE}>
              {head.label}
            </option>
            {head.dropDownValues.map((dropDownValue, index) => (
              <option key={index} value={dropDownValue.value}>
                {dropDownValue.label}
              </option>
            ))}
          </Select>
        </HeaderElement>
      )
    }
    return (
      <HeaderElement
        fontSize={this.props.fontSize ? this.props.fontSize : '20px'}
        textAlign={head.textAlign ? head.textAlign : 'left'}
        key={index}
      >
        {head.label}
      </HeaderElement>
    )
  }

  renderCellElement = (headElement: IHeader, dataElement: any, index: number) => {
    if (headElement.formatCell) {
      return (
        <DataRowElement
          fontSize={this.props.fontSize ? this.props.fontSize : '20px'}
          textAlign={headElement.textAlign ? headElement.textAlign : 'left'}
          key={index}
        >
          {headElement.formatCell(dataElement)}
        </DataRowElement>
      )
    }

    return (
      <DataRowElement
        fontSize={this.props.fontSize ? this.props.fontSize : '20px'}
        textAlign={headElement.textAlign ? headElement.textAlign : 'left'}
        key={index}
      >
        {dataElement[headElement.attribute]}
      </DataRowElement>
    )
  }

  render() {
    let { data, header, selectedIndex, originalData } = this.props
    if (!data) {
      return <Loading />
    }

    return (
      <Table>
        <thead>
          <HeaderRow>{header.map((head, index) => this.renderHeaderElement(head, index, originalData))}</HeaderRow>
        </thead>

        <Data>
          <tr>
            {header.map((head, index) => (
              <Divider key={index}></Divider>
            ))}
          </tr>

          {data.map((unit, index) => (
            <DataRow
              key={index}
              isSelected={selectedIndex === index}
              onClick={(event: any) => this.onRowElementClick(unit, index)}
            >
              {header.map((head, index) => this.renderCellElement(head, unit, index))}
            </DataRow>
          ))}
        </Data>
      </Table>
    )
  }
}
