import { TextFieldProps } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import EventNoteIcon from '@material-ui/icons/EventNote'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import WorkIcon from '@material-ui/icons/Work'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { AutoCompleteProps } from 'antd/lib/auto-complete'
import moment from 'moment'
import React, { ChangeEvent, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../../App'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import {
  applicationProfessionsFilterOptions,
  APPLICATION_PROFESSIONS,
  IApplicationProfession,
} from './application-professions'
import { ApplicationInput } from '../../../molecules/Application/ApplicationInput'
import ApplicationLayout from '../ApplicationLayout'
import { MaskDate, validateEmail, validateFullName, validateCep, validateBirthDate } from './application-utils'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import NumberField from '../../../molecules/Application/NumberField'
import { NumberFormatValues } from 'react-number-format'
import ApplicationZipCodeSearchDialog from '../ApplicationZipCodeSearch'

const ApplicationBorrowerStep = () => {
  const dispatch = useDispatch()

  const { borrower, birthDate, cep, email, isUpdatingBorrower, name, profession } = useTypedSelector(state => ({
    borrower: state.application.borrower,

    name: state.application.name,
    birthDate: state.application.birthDate,
    email: state.application.email,
    profession: state.application.profession,
    cep: state.application.cep,

    isUpdatingBorrower: state.application.isUpdatingBorrower,
  }))

  const [formName, setFormName] = useState<string>(name)
  const nameRef = useRef<HTMLInputElement>(null)

  const [formEmail, setFormEmail] = useState<string>(email)
  const emailRef = useRef<HTMLInputElement>(null)

  const [formBirthDate, setFormBirthDate] = useState<string>(birthDate)
  const birthDateRef = useRef<HTMLInputElement>(null)

  const [formCep, setFormCep] = useState<string>(cep)
  const cepRef = useRef<HTMLInputElement>(null)

  const professionRef = useRef<HTMLInputElement>(null)

  // --- NAME
  const handleNameChange = (event: ChangeEvent<TextFieldProps>) => {
    let data = event.target.value as string
    data = data.replace(/[&/\\#,+()$~%.'":*?<>{}\\-\\_=;|0-9]/g, '')

    setFormName(data)
  }

  const handleNameSubmit = () => {
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'name', formValue: formName }))
  }

  const handleNameEnter = () => {
    handleNameSubmit()
    if (isNameValid) {
      birthDateRef.current?.focus()
    }
  }

  // --- BIRTH DATE
  const handleBirthDateChange = (event: ChangeEvent<TextFieldProps>) => {
    const data = event.target.value as string

    setFormBirthDate(data)
  }

  const handleBirthDateSubmit = () => {
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'birthDate', formValue: formBirthDate }))
  }

  const handleBirthDateEnter = () => {
    handleBirthDateSubmit()
    if (isBirthDateValid) {
      professionRef.current?.focus()
    }
  }

  // --- PROFESSION
  const handleProfessionOnChange = (event: ChangeEvent<AutoCompleteProps>, newValue: IApplicationProfession | null) => {
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'profession', formValue: newValue }))
    emailRef.current?.focus()
  }

  // --- EMAIL
  const handleEmailChange = (event: ChangeEvent<TextFieldProps>) => {
    const data = event.target.value as string
    setFormEmail(data)
  }

  const handleEmailSubmit = () => {
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'email', formValue: formEmail }))
  }

  const handleEmailEnter = () => {
    handleEmailSubmit()
    if (isEmailValid) {
      cepRef.current?.focus()
    }
  }

  // --- CEP
  const handleCepChange = (event: NumberFormatValues) => {
    setFormCep(event.value)
  }

  const handleCepSubmit = () => {
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'cep', formValue: formCep }))
  }

  const handleCepEnter = () => {
    handleCepSubmit()
    if (isCepValid) {
      handleFormSubmit(() => {})
    }
  }

  const handleCepSelection = (event: ChangeEvent<HTMLSelectElement>) => {
    let data = event.target.value as string
    data = data.replace(/[^0-9]/g, '')

    setFormCep(data)
    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'cep', formValue: data }))
  }

  const handleFormSubmit = (defaultAction: Function) => {
    if (!isNextButtonEnabled) {
      return toaster.showErrorToast(`Preencha todos os campos obrigatórios do formulário`)
    }

    if (!borrower) {
      return dispatch(ApplicationSliceReducer.actions.updateBorrower())
    }

    if (checkIfFormHasAlteredDefaultValues()) {
      return dispatch(ApplicationSliceReducer.actions.updateBorrower())
    } else {
      return defaultAction()
    }
  }

  const checkIfFormHasAlteredDefaultValues = () => {
    // ---- Check if values have changed since last API fetch -- It can be risky
    if (!borrower) {
      return true
    }

    if (!profession) {
      return false
    }

    let formBirthDateInYYYYMMDDFormat = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    let borrowerBirthDateInYYYYMMDDFormat = moment(borrower.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    // If any element of the borrower has changed since the last API fetch, then a PATCH is required
    // Else, nothing changed, no element was editted, go to the next page
    if (
      borrower.name !== name ||
      borrower.email !== email ||
      borrower.cep !== cep ||
      borrowerBirthDateInYYYYMMDDFormat !== formBirthDateInYYYYMMDDFormat ||
      borrower.professionId !== String(profession.id)
    ) {
      return true
    } else {
      return false
    }
  }

  const isNameValid = validateFullName(formName)
  const isEmailValid = validateEmail(formEmail)
  const isCepValid = validateCep(formCep)
  const isProfessionValid = profession ? true : false
  const { isBirthDateValid, errorText } = validateBirthDate(formBirthDate)

  const isNextButtonEnabled =
    isNameValid && (formEmail.length === 0 || isEmailValid) && isCepValid && isProfessionValid && isBirthDateValid

  return (
    <ApplicationLayout
      title="Cadastro do cliente"
      subtitle={'Precisamos de algumas informações do cliente para realizarmos a avaliação de crédito'}
      isNextButtonEnabled={isNextButtonEnabled}
      isNextButtonLoading={isUpdatingBorrower}
      overrideNextPage={handleFormSubmit}
    >
      <Container>
        <ApplicationInput
          autoFocus={!isNextButtonEnabled}
          inputRef={nameRef}
          value={formName}
          error={formName.length !== 0 && !isNameValid}
          Icon={PersonIcon}
          onChange={handleNameChange}
          onBlur={handleNameSubmit}
          onEnterPressed={handleNameEnter}
          label="Nome completo"
          placeholder="Nome Sobrenome"
          isValidated={isNameValid}
          style={{ width: '100%', marginTop: '2vh' }}
        />

        <ApplicationInput
          inputRef={birthDateRef}
          value={formBirthDate}
          error={formBirthDate.length !== 0 && !isBirthDateValid}
          errorText={errorText}
          Mask={MaskDate}
          type="tel"
          onBlur={handleBirthDateSubmit}
          onChange={handleBirthDateChange}
          onEnterPressed={handleBirthDateEnter}
          label="Data de nascimento"
          placeholder="DD/MM/AAAA"
          isValidated={isBirthDateValid}
          style={{ width: '100%', marginTop: '2vh' }}
        />

        <Autocomplete
          options={APPLICATION_PROFESSIONS}
          onChange={handleProfessionOnChange}
          value={profession}
          filterOptions={applicationProfessionsFilterOptions}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <ApplicationInput
              params={params}
              inputRef={professionRef}
              label="Profissão"
              isValidated={isProfessionValid}
              style={{ width: '100%', marginTop: '2vh' }}
              Icon={WorkIcon}
              placeholder="PROFISSÃO"
              adornmentStyle={{ paddingRight: 0 }}
            />
          )}
        />

        <ApplicationInput
          inputRef={emailRef}
          value={formEmail}
          Icon={EmailIcon}
          label="Email (opcional)"
          type="email"
          error={formEmail.length !== 0 && !isEmailValid}
          placeholder="cliente@gmail.com"
          style={{ width: '100%', marginTop: '2vh' }}
          isValidated={isEmailValid}
          onBlur={handleEmailSubmit}
          onEnterPressed={handleEmailEnter}
          onChange={handleEmailChange}
        />

        <NumberField
          inputRef={cepRef}
          value={formCep}
          Icon={HomeIcon}
          onValueChange={handleCepChange}
          onBlur={handleCepSubmit}
          onEnterPressed={handleCepEnter}
          error={!isCepValid && formCep.length ? true : false}
          type="tel"
          label="CEP"
          format="#####-###"
          mask="_"
          placeholder="00000-000"
          isValidated={isCepValid}
          style={{ width: '100%', marginTop: '2vh' }}
        />

        <ApplicationZipCodeSearchDialog zipCodeSelected={cep} onZipCodeSelect={handleCepSelection} />
      </Container>
    </ApplicationLayout>
  )
}

export default ApplicationBorrowerStep

const Container = styled.div`
  margin-top: 1vh;
  margin-bottom: 1vh;

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`
