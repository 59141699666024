import { InboxOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal'
import Dragger from 'antd/lib/upload/Dragger'
import imageCompression from 'browser-image-compression'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import React from 'react'
import styled from 'styled-components'
import { getConfig } from '../../../config/config'
import { DocumentPicker } from '../../../molecules/Application/Biometry/DocumentPicker'
import { DOCUMENT_FRONT_OPTIONS } from '../../../redux/reducers/biometry/biometry.constants'
import { Loading } from '../../../ui'

type Props = {
  type: 'FACE' | 'DOCUMENT_FRONT' | 'DOCUMENT_BACK'
  isVisible: boolean
  documentIndex: number
  onCancel: () => void
  isLoading: boolean
  handleImage: (imageBase64: string) => void
  onDocumentTypeChange: (index: number) => void
}

const BiometryUploadModal = (props: Props) => {
  const { type, isVisible, documentIndex, isLoading, onCancel, handleImage, onDocumentTypeChange } = props

  const handleCustomRequest = async (options: UploadRequestOption) => {
    if (options.file) {
      const compressOptions = {
        maxSizeMB: getConfig().biometryConfig.photosMaxSize,
      }

      const file = options.file as File

      const compressedFile = await imageCompression(file, compressOptions)

      const abc = await compressedFile.arrayBuffer()
      const rawBase64 = btoa(new Uint8Array(abc).reduce((options, byte) => options + String.fromCharCode(byte), ''))
      const base64 = `data:${file.type};base64,${rawBase64}`
      handleImage(base64)
    }
  }

  // TODO - Get the available documents list from API
  const documentLabels = DOCUMENT_FRONT_OPTIONS.map(doc => doc.label)
  const isFace = type === 'FACE' ? true : false

  if (isLoading) {
    return (
      <Modal
        title={
          <span>
            Upload da foto do <strong>{isFace ? 'ROSTO' : 'DOCUMENTO'}</strong>
          </span>
        }
        visible={isVisible}
        footer={null}
        onCancel={onCancel}
      >
        <Container>
          <Loading />
        </Container>
      </Modal>
    )
  }

  return (
    <Modal
      title={
        <span>
          Upload da foto do <strong>{isFace ? 'ROSTO' : 'DOCUMENTO'}</strong>
        </span>
      }
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
    >
      <Container>
        {type === 'DOCUMENT_FRONT' ? (
          <DocumentPicker
            style={{ width: '100%', marginRight: 0 }}
            selectOptions={documentLabels}
            onDropDownSelect={(index?: number) => {
              if (index === null || index === undefined) {
                return
              }

              onDocumentTypeChange(index)
            }}
            selectedIndex={documentIndex}
          />
        ) : (
          undefined
        )}

        <Dragger
          customRequest={handleCustomRequest}
          accept={'.jpg,.jpeg,.png'}
          showUploadList={false}
          multiple={false}
          style={{ marginTop: '2vh' }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Clique ou arraste um arquivo</p>
          <p className="ant-upload-hint" style={{ maxWidth: '80%', margin: 'auto' }}>
            Carregue apenas uma imagem para realizar o upload
          </p>
        </Dragger>
      </Container>
    </Modal>
  )
}

export default BiometryUploadModal

const Container = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
