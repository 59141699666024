import { DateFormat, formatDate, formatNullableDate, TimeZone } from '../../../common/FormatDate'
import { IOriginationSummaryView } from '../../../services/bff/coordinator/dto/OriginationSummaries.dto'
import { OriginationReportCsvTableRow } from '../interfaces/OriginationReportCsvTableRow.interface'
import { ContractStatus } from '../resources/originations-report-resources'

export const mapCsvTableRows = (originationSummaries: IOriginationSummaryView[]): OriginationReportCsvTableRow[] => {
  return originationSummaries.map(os => {
    return {
      data_venda: formatDate(os.originationTimestamp, DateFormat.BR_DATE_FORMAT_WITH_HOUR),
      status_do_contrato: getContractStatus(os.canceledOn),
      data_cancelamento: formatNullableDate(os.canceledOn, DateFormat.BR_DATE_FORMAT_WITH_HOUR, TimeZone.MANAUS),
      loja: os.storeName,
      identificador_do_contrato: os.contractId,
      cliente: os.borrowerName,
      operador: os.operatorName,
      valor_principal: os.principal,
      desconto: os.couponPrincipalDiscount,
      taxa_ume: os.MDR,
      valor_liquido: os.netValue,
      iof: os.IOF,
    }
  })
}

export const getContractStatus = (canceledOn?: string): ContractStatus => {
  return canceledOn ? ContractStatus.CANCELED : ContractStatus.APPROVED
}
