import styled from 'styled-components'
import { Anchor } from '../../../../legacy-lib/design-kit-ui'

export const LogoImg = styled.img`
  alt: ume-logo;
  height: 51px;
`
export const TopAnchorStyle = styled(Anchor)`
  line-height: 35px;
  font-size: 20px;
`
