import React from 'react'
import { useTypedSelector } from '../../../../redux/reducers/selectors'
import { ApplicationPhoneStepV1 } from './ApplicationPhoneStepV1'
import { ApplicationPhoneStepV2 } from './ApplicationPhoneStepV2'

const ApplicationPhoneStep = () => {
  const { applicationVersion } = useTypedSelector(state => ({
    applicationVersion: state.configurations.configurations.application.version,
  }))

  return applicationVersion === 'v1' || applicationVersion === 'v3' ? (
    <ApplicationPhoneStepV1 />
  ) : (
    <ApplicationPhoneStepV2 />
  )
}

export default ApplicationPhoneStep
