import { InputAdornment, TextField, createStyles, makeStyles } from '@material-ui/core';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import React, { useState } from 'react';
import styled from 'styled-components';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { keyframes } from 'styled-components';

const ENTER_KEYCODE = 13;

const useStyles = makeStyles(theme =>
  createStyles({
    default: {
      '& label.Mui-focused': {
        fontWeight: 'bold',
      },
    },
    onError: {
      '& label.Mui-focused': {
        color: theme.palette.error.main,
        fontWeight: 'bold',
      },
      '& label': {
        color: theme.palette.error.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.error.main} !important`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.error.main} !important`,
      },
    },
    onSuccess: {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& label': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
    },
  })
);

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

interface INumberField extends NumberFormatProps {
  Icon?: React.ElementType
  isLoading?: boolean
  isValidated?: boolean
  disabled?: boolean
  onEnterPressed?: () => void
  style?: React.CSSProperties
  error?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  isAnimating?: boolean
  helperText?: string
}

const NumberField = (props: INumberField) => {
  const classes = useStyles();

  const [isAnimationRunning, setAnimationRunning] = useState<boolean>(false);

  const {
    Icon,
    isLoading,
    error,
    isValidated,
    disabled,
    isAnimating,
    adornmentStyle,
    onChange,
    onEnterPressed,
    helperText,
    ...rest
  } = props;

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER_KEYCODE && onEnterPressed) {
      if (error) {
        setAnimationRunning(true);
        // TODO - Is this anti-pattern?
        setTimeout(() => setAnimationRunning(false), 500);
      }

      onEnterPressed();
    }
  };

  const handleAdornmentClick = () => {
    if (onEnterPressed && !disabled) {
      onEnterPressed();
    }
  };

  const currentClass = error ? classes.onError : isValidated ? classes.onSuccess : classes.default;

  return (
    <StyledNumberFormat
      {...rest}
      // TODO - This is required because react says animate is not a boolean field??
      animate={isAnimationRunning || isAnimating ? 1 : 0}
      disabled={disabled}
      className={currentClass}
      onKeyDown={handleOnKeyDown}
      customInput={TextField}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ color: error ? 'red' : isValidated ? '#11B048' : '#9A9A9A' }} position='end'>
            {isLoading ? <LoadingIcon /> : Icon ? <Icon onClick={handleAdornmentClick} /> : <></>}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default NumberField;

// TODO - How to not loose the NumberFormat type
const StyledNumberFormat = styled(NumberFormat)<{ animate: boolean }>`
  animation: 1s ${shakeAnimation} ${(props: any) => (props && props.animate ? 'infinite' : 'block')};
`;

const LoadingIcon = styled(DonutLargeIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s linear infinite;
`;
