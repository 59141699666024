import styled from 'styled-components'
import { tablet } from '../../../../../common/assets/utils/_breakpoints'
import { Card, Heading, Text } from '../../../../../legacy-lib/design-kit-ui'
import { gray, orange } from '../../../../../legacy-lib/design-kit-ui/Palette'

export const CardTitleHeadingStyle = styled(Heading)`
  color: ${gray.spanish};
  font-size: 18px;
  margin: 0 0 8px;
  font-weight: 500;
  letter-spacing: -0.45px;
  line-height: 22px;

  @media (min-width: ${tablet}) {
    margin: 0;
  }
`

export const TextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '12px')};
  font-weight: ${(props: any) => (props.bold ? '500' : 'normal')};
  letter-spacing: 0;
  line-height: 16px;

  @media (min-width: ${tablet}) {
    font-weight: ${(props: any) => (props.bold ? '500' : '300')};
    font-size: ${(props: any) => (props.size ? props.size : '14px')};
  }
`

export const SmallTextStyle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 10px;
  color: ${gray.eclipse};
  margin-right: 16px;
`

export const MediumCardStyle = styled(Card)`
  height: 272px;
  min-width: 128px;
  border: none;
  margin: 8px 0 8px 8px;
  padding-bottom: 20px;

  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    height: 184px;
    width: 330px;
    margin: 8px;
    padding-bottom: 20px;

    > div {
      &.desktop {
        display: flex;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`

export const OrangeNumberHeadingStyle = styled(Heading)`
  color: ${orange.amber};
  margin: 0;
  font-weight: bold;
  font-size: 56px;
  letter-spacing: -1.4px;
  line-height: 66px;

  @media (min-width: ${tablet}) {
    margin: 4px;
    font-size: 80px;
    letter-spacing: -2px;
    line-height: 94px;
  }
`

export const NumberHeadingStyle = styled(Heading)`
  margin: 0;
  letter-spacing: -1.5px;
  line-height: 75px;
  font-size: ${(props: any) => (props.size ? props.size : '64px')};
`
