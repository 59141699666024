import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatbotLimitBudgetResponse } from '@scudraservicos/coordinator-client/dist/src/services/budgets/interfaces/ChatbotLimitBudgetResponse';
import { NumberFormatValues } from 'react-number-format';
import { BUDGET_PAGES } from '../../../organisms/Budget/BudgetSteps/budget-utils';

export type BudgetState = {
  page: BUDGET_PAGES

  phone?: string
  initialCpf?: string
  cpf?: NumberFormatValues
  value?: NumberFormatValues

  isLoadingBudget?: boolean
  budgetError?: string //todo: tipar
  budget?: ChatbotLimitBudgetResponse
}

const initialState: BudgetState = {
  page: BUDGET_PAGES.PHONE_PAGE,
}

export type ISetInitialCpfPayload = {
  initialCpf: string
}

export type ISetPhonePayload = {
  phone: string
}

export interface IFecthBudgetPayload {
  cpf?: NumberFormatValues
  value?: NumberFormatValues
}

export interface ISetBudgetRequestParamsPayload {
  cpf: NumberFormatValues
  value: NumberFormatValues
  phone: string
}

const BudgetSliceReducer = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setInitialCpf: (state, action: PayloadAction<ISetInitialCpfPayload>) => {
      const initialCpf = action.payload.initialCpf
      return {
        ...state,
        initialCpf,
      }
    },
    setPhone: (state, action: PayloadAction<ISetPhonePayload>) => {
      const phone = action.payload.phone;
      return {
        ...state,
        phone,
        cpf: undefined,
        value: undefined
      };
    },
    setPage: (state, action: PayloadAction<BUDGET_PAGES>) => {
      const page = action.payload;
      return { ...state, page };
    },
    createBudget: (state, action: PayloadAction<IFecthBudgetPayload>) => {
      const { cpf, value } = action.payload;
      return {
        ...state,
        page: BUDGET_PAGES.BUDGET_REQUEST_FORM_PAGE,
        cpf,
        value,
        isLoadingBudget: true,
        budgetError: undefined
      };
    },
    createBudgetSuccess: (state, action: PayloadAction<ChatbotLimitBudgetResponse>) => {
      const budget = action.payload;
      return {
        ...state,
        budget,
        isLoadingBudget: false,
      };
    },
    createBudgetError: (state, action: PayloadAction<string>) => { //todo: tipar error
      const budgetError = action.payload;
      return {
        ...state,
        isLoadingBudget: false,
        budgetError
      };
    },
    resetRequest: (state, action: PayloadAction<undefined>) => {
      return {
        ...state,
        page: BUDGET_PAGES.ONBOARDING_PAGE,
        initialCpf: undefined,
        cpf: undefined,
        value: undefined,
        budgetError: undefined,
        budget: undefined
      };
    },
  },
});

export default BudgetSliceReducer;
