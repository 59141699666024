import styled from 'styled-components'
import { large } from '../../common/assets/utils/_sizes'

export const cashierOptionsContainer = {
  padding: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '32px',
}

export const cashierOptions = {
  display: 'flex',
  width: '95vw',
  padding: '48px',
  flexWrap: 'wrap' as any,
  justifyContent: 'space-evenly',
}

export const CashierOption = styled.div`
  outline: none;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${large};
  width: 40vw;
  height: 40vh;
`

export const cellPhoneFormGroupStyle = {
  display: 'flex',
  marginTop: '16px',
}

export const cashierOptionIcon = {
  marginTop: '16px',
  color: '#218bb5',
}

export const formItemsContainerStyle = {
  scrollBehavior: 'smooth' as any,
  padding: '48px',
  display: 'flex',
  flexDirection: 'column' as any,
  width: '95vw',
  borderRadius: '8px',
}

export const textInputGroupWithLabelStyle = {
  display: 'flex',
  flexDirection: 'column' as any,
  justifyContent: 'flex-start',
}

export const h5Style = {
  alignSelf: 'flex-start',
  marginRight: '20px',
  marginLeft: '20px',
}

export const textInputGroupStyle = {
  padding: 0,
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '22px',
}

export const formItemStyle = {
  alignSelf: 'flex-start',
}

export const buttonStyle = {
  width: '10em',
}

export const formContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  height: 'auto',
  widht: '100vw',
  marginTop: '32px',
}

export const contractInfomationGridStyle = {
  display: 'inline-grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center',
  margin: '32px 32px 16px',
}

export const borrowerInfomationGridStyle = {
  display: 'inline-grid',
  gridTemplateColumns: '45% 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center',
  margin: '16px 48px',
}

export const infoContentItemStyle = {
  gridRow: '1',
  justifySelf: 'start',
  alignSelf: 'center',
  marginBottom: '2px',
}

export const infoLabelItemStyle = {
  gridRow: '2',
  justifySelf: 'start',
  alignSelf: 'center',
  marginBottom: '32px',
}

export const applicationContentItemStyle = {
  gridRow: '3',
  justifySelf: 'center',
  alignSelf: 'center',
  marginBottom: '2px',
}

export const applicationLabelItemStyle = {
  gridRow: '4',
  justifySelf: 'center',
  alignSelf: 'center',
}

export const formActionsStyle = {
  display: 'flex',
  flexDirection: 'row-reverse' as any,
}

export const tableStyleHeaderCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
}

export const tableStyleHeaderWithoutDiscountCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
  color: '#92929D',
}

export const tableStyleHeaderDiscountCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
  color: '#0E8C39',
}

export const tableStyleRowCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
  fontSize: '20px',
}

export const tableStyleRowWithoutDiscountCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
  fontSize: '20px',
  color: '#92929D',
  textDecorationLine: 'line-through',
}

export const tableStyleRowDiscountCell = {
  textAlign: 'left' as 'left',
  padding: '0.5em',
  fontSize: '20px',
  color: '#0E8C39',
}

export const contractTableStyleHeaderCell = {
  textAlign: 'center' as any,
  padding: '0.5em',
}

export const contractTableStyleRowCell = {
  textAlign: 'center' as any,
  padding: '0.5em',
  fontSize: '20px',
}
