import { Box, ChartProps, Grommet, Stack } from 'grommet'
import React, { Component } from 'react'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { gray } from '../utils/_colors'
import {
  AverageValueHeadingStyle,
  BarChartBoxStyle,
  BlackLineBoxStyle,
  StyledBarChart,
  TopLabelTextStyle,
  ValueTextStyle,
  YAxisLabelsBoxStyle,
  YLabelHeadingStyle,
} from './styles'

type GrommetChartProps = Omit<ChartProps, 'values'>

interface BarChartProps extends GrommetChartProps {
  isCurrency?: boolean
  yAxis: number[]
  data: {
    value: number
    label?: string | number | React.ReactNode
    topLabel?: string
  }[]
  comparisonData?: {
    value: number
    topLabel?: string
  }[]
  boundsLabels?: {
    y: string
    x?: string
  }
  averageValue?: number
}

export default class BarChart extends Component<BarChartProps> {
  render() {
    const yAxisOrderDescendent = this.props.yAxis.slice().sort((a, b) => b - a)
    const barColors = ['#28C264', '#752CD0', '#0184FF', '#EF3E63', '#F7B500', '#1392AB', '#1F13AB', '#C603CC']
    const lastIndex = this.props.yAxis.length - 1
    const lastYAxisValue = this.props.yAxis[lastIndex]
    const firstYAxisValue = this.props.yAxis[0]

    return (
      <Grommet theme={theme}>
        <BarChartBoxStyle>
          {/* Label on Y axis if exists */}
          {this.props.boundsLabels && <YLabelHeadingStyle level="4">{this.props.boundsLabels.y}</YLabelHeadingStyle>}

          <Stack guidingChild={2}>
            {/* Axis Y values */}
            <YAxisLabelsBoxStyle fill justify="between" flex={false}>
              {yAxisOrderDescendent.map((yValue, idx) => {
                return (
                  <Box key={idx}>
                    <Text color={gray.nobel} size="small">
                      {yValue}
                    </Text>
                  </Box>
                )
              })}
            </YAxisLabelsBoxStyle>

            {/* Average dashed line */}
            <Box pad={{ bottom: '29px', top: '10px' }} fill justify="between" flex={false}>
              <StyledBarChart
                bounds={[
                  [0, this.props.data.length + 1],
                  [firstYAxisValue, lastYAxisValue],
                ]}
                dash
                overflow
                values={[
                  { value: [1, this.props.averageValue || firstYAxisValue] },
                  {
                    value: [this.props.data.length + 1, this.props.averageValue || lastYAxisValue],
                  },
                ]}
                type="line"
                color={{
                  color: this.props.averageValue ? 'black' : 'transparent',
                  opacity: 'weak',
                }}
                thickness="hair"
                size={{ height: 'medium', width: 'full' }}
              ></StyledBarChart>
            </Box>

            <Box direction="row">
              {/* vertical black line */}
              <BlackLineBoxStyle fill flex={false} align="center" basis="xxsmall">
                <StyledBarChart
                  bounds={[
                    [0, 1],
                    [firstYAxisValue, lastYAxisValue],
                  ]}
                  values={[{ value: [1, lastYAxisValue] }]}
                  type="bar"
                  color="black"
                  thickness="hair"
                  size={{ height: 'medium', width: 'full' }}
                ></StyledBarChart>
              </BlackLineBoxStyle>

              {/* Bar chart */}
              {this.props.data.map((item, idx, items) => (
                <Box key={idx} flex={false} align="center" basis={this.props.comparisonData ? 'small' : 'xsmall'}>
                  {/* Label on top if exists*/}
                  {item.topLabel && (
                    <TopLabelTextStyle
                      light
                      positionY={(item.value * 85) / lastYAxisValue}
                      color={gray.dim}
                      size="small"
                    >
                      {item.topLabel}
                    </TopLabelTextStyle>
                  )}

                  {/* Value on top */}
                  <ValueTextStyle
                    positionY={(item.value * 85) / lastYAxisValue}
                    color={gray.dim}
                    size={this.props.comparisonData ? '10px' : 'small'}
                  >
                    {item.value}
                  </ValueTextStyle>

                  {/* Single bar chart */}
                  <StyledBarChart
                    bounds={[
                      [0, 2],
                      [firstYAxisValue, lastYAxisValue],
                    ]}
                    type="bar"
                    key={idx}
                    values={[{ value: [1, item.value] }]}
                    color={{ color: barColors[idx % barColors.length] }}
                    thickness="medium"
                    size={{ height: 'medium', width: 'full' }}
                    {...this.props}
                  ></StyledBarChart>

                  {/* Value label */}
                  <Box>
                    <Text color={gray.nobel} size="xsmall">
                      {item.label || ''}
                    </Text>
                  </Box>
                </Box>
              ))}
              {/* Average value Text */}
              {this.props.averageValue !== undefined && (
                <AverageValueHeadingStyle level="4">
                  {this.props.isCurrency ? `Média: R$${this.props.averageValue}` : `Média: ${this.props.averageValue}`}
                </AverageValueHeadingStyle>
              )}
            </Box>

            <Box direction="row">
              {/* vertical black line */}
              <BlackLineBoxStyle fill flex={false} align="center" basis="xxsmall">
                <StyledBarChart
                  bounds={[
                    [0, 1],
                    [firstYAxisValue, lastYAxisValue],
                  ]}
                  values={[{ value: [1, lastYAxisValue] }]}
                  type="bar"
                  color="black"
                  thickness="hair"
                  size={{ height: 'medium', width: 'full' }}
                ></StyledBarChart>
              </BlackLineBoxStyle>

              {/* Bar chart with bound on averageValue with opacity*/}
              {this.props.averageValue !== undefined &&
                this.props.data.map((item, idx, items) => (
                  <Box key={idx} flex={false} align="center" basis={this.props.comparisonData ? 'small' : 'xsmall'}>
                    <StyledBarChart
                      bounds={[
                        [0, 2],
                        [firstYAxisValue, lastYAxisValue],
                      ]}
                      type="bar"
                      key={idx}
                      values={[{ value: [1, this.props.averageValue] }]}
                      color={{
                        color: barColors[idx % barColors.length],
                        opacity: 'weak',
                      }}
                      thickness="medium"
                      size={{ height: 'medium', width: 'full' }}
                      {...this.props}
                    ></StyledBarChart>

                    {/* Value label */}
                    <Box align="center">
                      <Text color={gray.nobel} size="small"></Text>
                    </Box>
                  </Box>
                ))}
            </Box>

            <Box direction="row">
              {/* vertical black line */}
              <Box fill flex={false} align="center" basis="36px"></Box>

              {/* Bar chart with data for comparison*/}
              {this.props.comparisonData &&
                this.props.comparisonData.map((item, idx, items) => (
                  <Box key={idx} flex={false} align="center" basis="small">
                    {/* Label on top if exists*/}
                    {item.topLabel && (
                      <TopLabelTextStyle
                        light
                        positionY={(item.value * 85) / lastYAxisValue}
                        color={gray.dim}
                        size="small"
                      >
                        {item.topLabel}
                      </TopLabelTextStyle>
                    )}

                    {/* Value on top */}
                    <ValueTextStyle positionY={(item.value * 85) / lastYAxisValue} color={gray.dim} size="10px">
                      {item.value}
                    </ValueTextStyle>

                    <StyledBarChart
                      bounds={[
                        [0, 2],
                        [firstYAxisValue, lastYAxisValue],
                      ]}
                      type="bar"
                      key={idx}
                      values={[{ value: [1, item.value] }]}
                      color={{ color: gray.gainsboro }}
                      thickness="medium"
                      size={{ height: 'medium', width: 'full' }}
                      {...this.props}
                    ></StyledBarChart>

                    {/* Value label */}
                    <Box align="center">
                      <Text size="small"></Text>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Stack>

          {/* Label on X axis if exists */}
          <Box align="center">
            {this.props.boundsLabels && this.props.boundsLabels.x && (
              <Heading margin="medium" level="5">
                {this.props.boundsLabels.x}
              </Heading>
            )}
          </Box>
        </BarChartBoxStyle>
      </Grommet>
    )
  }
}

const theme = {
  global: {
    size: {
      xxsmall: '50px',
      xsmall: '80px',
      small: '90px',
      medium: '250px',
      large: '768px',
      xlarge: '1152px',
      xxlarge: '1536px',
      full: '100%',
    },
    opacity: {
      strong: 0.8,
      medium: 0.5,
      weak: 0.25,
    },
  },
}
