import { APPLICATION_PAGES } from '../../../organisms/Application/ApplicationSteps/application-utils'
import {
  ApplicationVersion,
  ApplicationVersionEnum,
  APPLICATION_STATUS,
  BiometryStatus,
  BorrowerStatus,
  PhoneStatus,
  PRE_APPLICATION_STATUS,
} from './application.types'

export class ApplicationHelper {
  static definePagesOrderFromApplicationInfo(
    status: APPLICATION_STATUS,
    isPhoneVerified: boolean,
    hasBorrowerBillDueDay: boolean,
    applicationVersion: ApplicationVersion,
    biometryStatus?: BiometryStatus,
    enableHighRecurrence?: boolean,
    isBorrowerDisabledToPurchase?: boolean
  ): APPLICATION_PAGES[] {
    switch (status) {
      case APPLICATION_STATUS.APPROVED: {
        const pages = []
        if (!isPhoneVerified) {
          pages.push(APPLICATION_PAGES.SEND_VERIFICATION_CODE_PAGE, APPLICATION_PAGES.PHONE_VERIFICATION_PAGE)
        }
        if (!hasBorrowerBillDueDay && enableHighRecurrence && applicationVersion === ApplicationVersionEnum.V2) {
          pages.push(APPLICATION_PAGES.CHOOSE_BILL_DUE_DAY_PAGE)
        }
        if (!!isBorrowerDisabledToPurchase) {
          return [APPLICATION_PAGES.BORROWER_DISABLED_TO_PURCHASE_PAGE]
        }
        pages.push(APPLICATION_PAGES.APPROVED_PAGE)
        return pages
      }
      case APPLICATION_STATUS.PRE_APPROVED: {
        if (biometryStatus == BiometryStatus.CONFLICT) {
          return [APPLICATION_PAGES.BIOMETRY_CONFLICT_PAGE]
        }
        if (biometryStatus) {
          return [APPLICATION_PAGES.IN_PROGRESS_PAGE]
        }
        return [APPLICATION_PAGES.CPF_PAGE]
      }
      case APPLICATION_STATUS.FAILURE:
      case APPLICATION_STATUS.DENIED:
        return [APPLICATION_PAGES.DENIED_PAGE]
      case APPLICATION_STATUS.WAITING_MANUAL_APPROVAL:
        return [APPLICATION_PAGES.WAITING_MANUAL_APPROVAL_PAGE]
      default:
        return [APPLICATION_PAGES.CPF_PAGE]
    }
  }

  static definePagesOrderFromBorrowerInfo(
    borrowerStatus: BorrowerStatus,
    phoneStatus: PhoneStatus,
    enableHighRecurrence: boolean,
    applicationVersion: ApplicationVersion,
    hasBorrowerBillDueDay: boolean,
    preApplicationStatus: PRE_APPLICATION_STATUS,
    biometryStatus?: BiometryStatus,
    isBorrowerDisabledToPurchase?: boolean
  ): APPLICATION_PAGES[] {
    if (
      preApplicationStatus === PRE_APPLICATION_STATUS.PRE_APPLICATION_DENIED &&
      phoneStatus === PhoneStatus.VerifiedPhone
    ) {
      return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.PRE_DENIED_PAGE]
    }

    switch (borrowerStatus) {
      case BorrowerStatus.Approved: {
        const pages = [APPLICATION_PAGES.CPF_PAGE]
        if (phoneStatus !== PhoneStatus.VerifiedPhone) {
          pages.push(APPLICATION_PAGES.SEND_VERIFICATION_CODE_PAGE, APPLICATION_PAGES.PHONE_VERIFICATION_PAGE)
        }
        if (applicationVersion === ApplicationVersionEnum.V2 && enableHighRecurrence && !hasBorrowerBillDueDay) {
          pages.push(APPLICATION_PAGES.CHOOSE_BILL_DUE_DAY_PAGE)
        }

        if (!!isBorrowerDisabledToPurchase) {
          pages.push(APPLICATION_PAGES.BORROWER_DISABLED_TO_PURCHASE_PAGE)
          return pages
        }

        pages.push(APPLICATION_PAGES.APPROVED_PAGE)
        return pages
      }
      case BorrowerStatus.Incomplete:
        let pages = [
          APPLICATION_PAGES.CPF_PAGE,
          APPLICATION_PAGES.PHONE_PAGE,
          APPLICATION_PAGES.BORROWER_PAGE,
          APPLICATION_PAGES.BIOMETRY_PAGE,
        ]
        if (
          preApplicationStatus === PRE_APPLICATION_STATUS.PRE_APPLICATION_APPROVED &&
          phoneStatus === PhoneStatus.VerifiedPhone
        ) {
          pages = [
            APPLICATION_PAGES.CPF_PAGE,
            APPLICATION_PAGES.PHONE_PAGE,
            APPLICATION_PAGES.PRE_APPROVED_PAGE,
            APPLICATION_PAGES.BORROWER_PAGE,
            APPLICATION_PAGES.BIOMETRY_PAGE,
          ]
        }
        if (
          (applicationVersion === ApplicationVersionEnum.V1 || applicationVersion === ApplicationVersionEnum.V3) &&
          enableHighRecurrence
        ) {
          pages = [...pages, APPLICATION_PAGES.CHOOSE_BILL_DUE_DAY_PAGE]
        }
        return [...pages, APPLICATION_PAGES.IN_PROGRESS_PAGE]

      case BorrowerStatus.ManualAnalysis:
        return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.WAITING_MANUAL_APPROVAL_PAGE]
      case BorrowerStatus.UnderAnalysis:
        return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.IN_PROGRESS_PAGE]
      case BorrowerStatus.PreApproved:
        if (biometryStatus === BiometryStatus.CONFLICT) {
          return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.BIOMETRY_CONFLICT_PAGE]
        }
        return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.IN_PROGRESS_PAGE]
      case BorrowerStatus.Denied:
      default:
        return [APPLICATION_PAGES.CPF_PAGE, APPLICATION_PAGES.DENIED_PAGE]
    }
  }
}
