import { Box, Grommet } from 'grommet'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { PureComponent } from 'react'
import { black, orange, violet, white } from '../utils/_colors'
import { IntervalType } from './helpers'
import { MonthComponent, StyledButton, StyledCalendar, YearPanel } from './styles'

const MONTHS = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
interface CalendarProps {
  singleMonthOnly?: boolean
  singleWeekOnly?: boolean // to be implemented
  singleDayOnly?: boolean // to be implemented
  defaultMode?: IntervalType // to be implemented
  onIntervalSelected?: (interval: IntervalType) => void // 'range' mode to be implemented
  onDateIntervalChange?: (dateStart: string, dateEnd: string) => void
}
interface CalendarState {
  activeMode: IntervalType
  startDateRange: string
  endDateRange: string
  months: Array<{
    name: string
    active: boolean
  }>
}

const theme = {
  global: {
    size: {
      medium: '238px',
    },
    font: {
      family: 'Roboto',
    },
    colors: {
      brand: violet.eletric,
      focus: violet.eletric,
      border: {
        light: 'none',
      },
    },
  },
  calendar: {
    heading: {
      level: '5',
    },
    medium: {
      daySize: '34px',
      fontSize: '14px',
    },
  },
}

const initialState = {
  activeMode: 'month',
  startDateRange: moment().startOf('month').format('YYYY-MM-DD'),
  endDateRange: moment().endOf('month').format('YYYY-MM-DD'),
  months: MONTHS.map((m, idx) => ({
    name: m,
    active: idx === moment().month() ? true : false,
  })),
} as CalendarState

export default class Calendar extends PureComponent<CalendarProps, CalendarState> {
  constructor(props: CalendarProps) {
    super(props)
    this.state = initialState
  }

  onClickDay = () => {
    const { onDateIntervalChange, onIntervalSelected } = this.props
    const startDate = moment().format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    if (onIntervalSelected) {
      onIntervalSelected('day')
    }

    if (onDateIntervalChange) {
      onDateIntervalChange(startDate, endDate)
    }

    this.setState({
      ...initialState,
      startDateRange: startDate,
      endDateRange: endDate,
      activeMode: 'day',
    })
  }

  onClickWeek = () => {
    const { onDateIntervalChange, onIntervalSelected } = this.props
    const startDate = moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD')
    const endDate = moment().endOf('week').subtract(1, 'day').format('YYYY-MM-DD')

    if (onIntervalSelected) {
      onIntervalSelected('week')
    }

    if (onDateIntervalChange) {
      onDateIntervalChange(startDate, endDate)
    }

    this.setState({
      ...initialState,
      startDateRange: startDate,
      endDateRange: endDate,
      activeMode: 'week',
    })
  }

  onSelectMonth = () => {
    const { onDateIntervalChange, onIntervalSelected } = this.props

    if (onIntervalSelected) {
      onIntervalSelected('month')
    }

    if (onDateIntervalChange) {
      onDateIntervalChange(initialState.startDateRange, initialState.endDateRange)
    }

    this.setState({
      ...initialState,
      activeMode: 'month',
    })
  }

  onSelectDate = (date: string[][] | string) => {
    const { startDateRange, endDateRange } = this.state
    const { onDateIntervalChange, onIntervalSelected } = this.props
    const { activeMode } = this.state

    let startDate: string,
      endDate: string | null = null

    if (Array.isArray(date)) {
      startDate = date[0][0]
      endDate = date[0][1]
    } else {
      startDate = date
    }

    if (activeMode === 'week') {
      if (moment(startDate).weekday() === 6) {
        // saturday
        startDate = moment(startDate).format('YYYY-MM-DD')
      } else {
        startDate = moment(startDate).startOf('week').subtract(1, 'day').format('YYYY-MM-DD')
      }

      endDate = moment(startDate).add(6, 'day').format('YYYY-MM-DD')

      if (onIntervalSelected) {
        onIntervalSelected('week')
      }

      if (endDate === endDateRange && startDate === startDateRange) {
        // dont change state if the dates are the same
        return
      }
    } else if (activeMode === 'day') {
      startDate = moment(startDate).format('YYYY-MM-DD')
      endDate = moment(startDate).format('YYYY-MM-DD')

      if (onIntervalSelected) {
        onIntervalSelected('day')
      }
    } else {
      // TODO: arbitrary mode could be implemented here
      return // do nothing if mode is month is selected
    }

    if (onDateIntervalChange) {
      onDateIntervalChange(startDate, endDate)
    }

    this.setState({
      startDateRange: startDate,
      endDateRange: endDate,
    })
  }

  onClickMonth = async (monthSelected: number) => {
    const { singleMonthOnly, onIntervalSelected, onDateIntervalChange } = this.props

    this.setState({
      months: this.state.months.map((m, i) => {
        // clicked, not active, will turn on
        if (i === monthSelected && !m.active) {
          return {
            active: true,
            name: m.name,
          }
          // clicked, active, will deactivate
        } else if (i === monthSelected && m.active) {
          return {
            active: false,
            name: m.name,
          }
        } else if (i !== monthSelected && singleMonthOnly) {
          // not clicked, only single month can be selected, will turn off
          return {
            active: false,
            name: m.name,
          }
        } else {
          // not clicked, multiple months can be selected, keep state
          return m
        }
      }),
    })

    if (onDateIntervalChange && singleMonthOnly) {
      const monthStart = moment().set('month', monthSelected).startOf('month')
      const monthEnd = moment().set('month', monthSelected).endOf('month')

      onDateIntervalChange(monthStart.format('YYYY-MM-DD'), monthEnd.format('YYYY-MM-DD'))
    }

    if (onIntervalSelected) {
      onIntervalSelected('month')
    }
  }

  render() {
    const { activeMode, startDateRange, endDateRange } = this.state

    return (
      <Grommet theme={theme}>
        <Box width={'auto'}>
          <div style={{ margin: '50px auto 0 0', display: 'flex', position: 'absolute' }}>
            <StyledButton
              width={'69px'}
              label={'Mês'}
              color={activeMode === 'month' ? white.primary : black.primary}
              border={activeMode === 'month' ? 'none' : `solid 0.5px ${black.primary}`}
              backgroundColor={activeMode === 'month' ? orange.amber : white.primary}
              borderRadius={'25px'}
              onClick={this.onSelectMonth}
            />
            <StyledButton
              width={'92px'}
              label={'Semana'}
              color={activeMode === 'week' ? white.primary : black.primary}
              border={activeMode === 'week' ? 'none' : `solid 0.5px ${black.primary}`}
              backgroundColor={activeMode === 'week' ? orange.amber : white.primary}
              borderRadius={'25px'}
              onClick={this.onClickWeek}
            />

            <StyledButton
              width={'66px'}
              label={'Dia'}
              color={activeMode === 'day' ? white.primary : black.primary}
              border={activeMode === 'day' ? 'none' : `solid 0.5px ${black.primary}`}
              backgroundColor={activeMode === 'day' ? orange.amber : white.primary}
              borderRadius={'25px'}
              onClick={this.onClickDay}
            />
          </div>
          {activeMode === 'month' && (
            <YearPanel boxShadow={false}>
              {this.state.months.map((m, i) => (
                <MonthComponent
                  className={m.name}
                  key={m.name}
                  color={m.active ? white.primary : black.primary}
                  backgroundColor={m.active ? violet.eletric : white.primary}
                  onClick={() => this.onClickMonth(i)}
                >
                  {m.name.toUpperCase()}
                </MonthComponent>
              ))}
            </YearPanel>
          )}
          <StyledCalendar
            dates={
              !endDateRange || startDateRange === endDateRange
                ? [moment(startDateRange).toISOString()] // toISO() is needed because https://github.com/grommet/grommet/issues/75
                : [[moment(startDateRange).toISOString(), moment(endDateRange).toISOString()]]
            }
            onSelect={this.onSelectDate}
            daysOfWeek={true}
            locale={'pt-BR'}
          />
        </Box>
      </Grommet>
    )
  }
}
