import { IBilling } from '@scudraservicos/coordinator-client/dist/src/services/billings/interfaces/billings.res.interface'
import { AxiosError } from 'axios'
import React from 'react'
import styled, { css } from 'styled-components'
import { formatDate } from '../../common/FormatDate'
import { numberToCurrency } from '../../common/NumberToCurrency'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import { bffParceirosApiService } from '../../services/bff'

export const getErrorStringForBillingsHttpErrors = (errorCode: string, error: AxiosError) => {
  if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.NO_PAYMENTS_AVAILABLE) {
    return `Nenhum pagamento disponível neste intervalo`
  } else if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.BILLING_NOT_FOUND) {
    return `Cobrança não encontrada`
  } else if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.INVOICE_STILL_VALID) {
    return `Cobrança ainda esta válida. Espere o último boleto expirar para criar um novo`
  } else if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.INVALID_INVOICE_DUE_DATE) {
    return `Data de vencimento do boleto inválida`
  } else if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.DATE_RANGE_OVERLAP) {
    return error.response ? error.response.data.message : `Já existe uma cobrança com essas datas`
  } else if (errorCode === bffParceirosApiService.billings.HttpBillingsErrors.INVALID_DATE_RANGE) {
    return `Data de ínicio não pode estar depois da data de fim`
  } else {
    return `Erro não identificado. Entrar em contato com a engenharia`
  }
}

export const financialPendingPaymentsCard = {
  header: { title: 'A Pagar', subtitle: 'Boletos enviados' },
  card: {
    description: 'Boletos pendentes de pagamentos',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
}

export const financialOverduePaymentsCard = {
  header: { title: 'Vencido', subtitle: 'Em atraso' },
  card: {
    description: 'Boletos vencidos',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
}

export const financialIncomingPaymentsCard = {
  header: { title: 'Em Aberto', subtitle: 'Está sendo pago' },
  card: {
    description: 'Valor que está entrando',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
  download: `pagamentos em aberto.`,
}

export const UMEDataTableHeader = [
  { label: 'Emissão do boleto:', attribute: 'createdOn', formatCell: (row: any) => formatDate(row.createdOn) },
  {
    label: 'Referente a pagamentos de:',
    attribute: 'startDate',
    formatCell: (row: any) => `${formatDate(row.startDate)} - ${formatDate(row.endDate)}`,
  },
  {
    label: 'Código de Barras',
    attribute: 'any',
    formatCell: (row: any) => {
      if (row.invoice) return row.invoice.barcode
      else return '---'
    },
  },
  {
    label: 'Link do Boleto',
    attribute: 'any',
    formatCell: (row: any) => {
      if (row.invoice) {
        return (
          <InvoiceLink color="inherit" target="_blank" rel="noopener noreferrer" href={row.invoice.url}>
            Link para boleto
          </InvoiceLink>
        )
      }
    },
  },
  { label: 'Loja', attribute: 'storesName' },
  {
    label: 'Valor do boleto',
    attribute: 'paymentsSum',
    formatCell: (row: any) => {
      if (row.invoice) return `R$ ${numberToCurrency(row.invoice.value)}`
      else return '---'
    },
  },
  {
    label: 'Qntd de Pagamentos',
    attribute: 'paymentsCount',
  },
  {
    label: 'Venc. Boleto',
    // TODO - When formatCell is given, attribute is not used
    attribute: 'invoice.dueDate',
    formatCell: (row: any) => {
      if (row.invoice) return formatDate(row.invoice.dueDate)
      else return '---'
    },
  },
  {
    label: 'Status',
    attribute: 'status',
    dropDownValues: [
      { label: 'A pagar', value: 'pending' },
      { label: 'Pago', value: 'paid' },
      { label: 'Vencido', value: 'expired' },
      { label: 'Segunda Via', value: 're-issuance' },
    ],
    formatCell: (row: IBilling) => {
      // TODO - None of theses colors are in the design kit
      if (row.status !== 'paid' && row.numberOfInvoices > 1) {
        return <BillingStatusText backgroundColor="#FF8B00">Segunda Via</BillingStatusText>
      }
      switch (row.status) {
        case 'pending':
          return <BillingStatusText backgroundColor="#0048D6">A Pagar</BillingStatusText>
        case 'paid':
          return <BillingStatusText backgroundColor="#1F9901">Pago</BillingStatusText>
        case 'expired':
          return <BillingStatusText backgroundColor="#FF2601">Vencido</BillingStatusText>
        default:
          return row.status
      }
    },
  },
]

const BillingStatusText = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  height: 100%;
  width: 80%;
  text-align: center;
  border-radius: 2;
  color: ${UMEColors.white.primary};
`

const InvoiceLink = styled.a`
  color: inherit;
  text-decoration: underline;

  &:hover {
    opacity: 0.4;
    color: inherit;
  }
`
