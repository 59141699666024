import styled from 'styled-components'

export const TableOutlineWhenSelected = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px;
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 0.5em;
  }
`

export const TableRowOutlineWhenSelected = styled.tr`
  margin-bottom: 16px;
  outline: none;
  cursor: pointer;

  td {
    border: solid 1px #ffffff;
    border-style: solid none;
    padding: 10px;
    text-align: left;
    font-size: 16px;
    background-color: ${(props: any) => (props.isSelected ? '#3f3f3f' : '#ffffff')};
    transition: border-color 500ms cubic-bezier(0.4, 0, 0.23, 1);
    transition: background-color 350ms cubic-bezier(0.4, 0, 0.23, 1);
    height: 2em;
    white-space: nowrap;
    color: ${(props: any) => (props.isSelected ? '#ffffff' : '#000')};
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`
