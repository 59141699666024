import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'
import { IOperator } from '../../../services/bff/coordinator/dto/Operators.dto'
import { OperatorPerformanceInfoResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import operatorSliceReducer, { IFetchOperatorPayload } from './operator.reducer'

function* fetchOperator(action: PayloadAction<IFetchOperatorPayload>) {
  const { operatorId } = action.payload

  try {
    const response: IOperator = yield bffParceirosApiService.coordinator.fetchOperatorById(operatorId)

    yield put(operatorSliceReducer.actions.fetchOperatorSuccess(response))
  } catch (error) {
    yield put(operatorSliceReducer.actions.fetchOperatorError(error))
  }
}

function* fetchOperatorPerformanceInfo(action: PayloadAction<undefined>) {
  try {
    const response: OperatorPerformanceInfoResponse = yield bffParceirosApiService.operatorServiceCenter.fetchOperatorPerformance()
    yield put(operatorSliceReducer.actions.fetchOperatorPerformanceInfoSuccess(response))
  } catch (error) {
    yield put(operatorSliceReducer.actions.fetchOperatorPerformanceInfoError(error))
  }
}

const operatorSaga = [takeLatest(operatorSliceReducer.actions.fetchOperator, fetchOperator), takeLatest(operatorSliceReducer.actions.fetchOperatorPerformanceInfo, fetchOperatorPerformanceInfo)]

export default operatorSaga
