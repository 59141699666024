import axios, { AxiosInstance } from 'axios'
import { getParceirosBffApiRootUrl } from '../../../config/config'
import { ChatbotError, ChatbotLimitBudgetResponse } from '../coordinator/dto/Budgets.dto'
import { getAxiosInstance } from '../utils'

export default class BudgetsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async createBudget(
    cpf: string,
    purchaseAmount: number,
    phoneNumber: string
  ): Promise<ChatbotLimitBudgetResponse | ChatbotError> {
    const response = await this.axiosInstance.post<ChatbotLimitBudgetResponse | ChatbotError>(
      `${getParceirosBffApiRootUrl()}/budgets/chat`,
      {
        text: `orcamento ${cpf} ${purchaseAmount}`,
        phone_number: phoneNumber,
      }
    )
    return response.data
  }
}
