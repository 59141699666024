import React from 'react'
import styled from 'styled-components'

export const Title = (props: JSX.IntrinsicElements['p']) => {
  return (
    <Text {...props}>{props.children}</Text>
  )
}

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin: 0;
`