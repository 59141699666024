import React, { Component } from 'react'
import ReactLoading, { LoadingProps } from 'react-loading'
import { gray } from '../utils/_colors'

export default class Loading extends Component<LoadingProps> {
  render() {
    return (
      <ReactLoading type="spokes" color={gray.aluminium} height={25} width={25} {...this.props}>
        {this.props.children}
      </ReactLoading>
    )
  }
}
