import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import AvailableProductsSection from '../../molecules/OperatorServiceCenter/availableProducts/AvailableProductsSection'
import OperatorServiceCenterBorrowerInfo from '../../molecules/OperatorServiceCenter/borrowerData/OperatorServiceCenterBorrowerInfo'
import OperatorServiceCenterIncentive from '../../molecules/OperatorServiceCenter/borrowerData/OperatorServiceCenterIncentive'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { gray } from '../../ui/utils/_colors'
import CouponDetails from './CouponDetails'
import { UserAvailableProducts } from './DataLists/UserAvailableProducts'

const OperatorServiceCenterBorrowerData: React.FC = () => {
  const { borrowerData } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    }
  }, [dispatch])

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
  }

  return (
    <MainContainer>
      <BorrowerContainer>
        <CloseButtonContainer>
          <CloseButton onCloseButtonClick={handleBackButton} />
        </CloseButtonContainer>

        {borrowerData?.credit?.incentiveCategory && (
          <OperatorServiceCenterIncentive incentiveCategory={borrowerData?.credit?.incentiveCategory} incentiveValue={borrowerData?.operator?.incentiveValue} />
        )}
        <OperatorServiceCenterBorrowerInfo borrower={borrowerData} />
        {borrowerData?.coupon && <CouponDetails coupon={borrowerData?.coupon!} showStoreAlert={false} />}

        <AvailableProducts>
          <AvailableProductsSection
            userAvailableProducts={UserAvailableProducts({
              document: borrowerData!.borrower!.document,
              borrowerId: borrowerData?.borrower?.id,
            })}
          />
        </AvailableProducts>
      </BorrowerContainer>
    </MainContainer>
  )
}

export default OperatorServiceCenterBorrowerData

const CloseButtonContainer = styled.div`
  padding-bottom: 16px;
`

const MainContainer = styled.div`
  height: 100vh;
`

const BorrowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 24px 24px 0px 24px;
`

const AvailableProducts = styled.div`
  flex-grow: 1;
  margin: 24px -24px 0 -24px;
  background: ${gray.light2};
  overflow-y: auto;
`
