import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import * as React from 'react'
import { Redirect } from 'react-router'
import { Role } from '../../../../common/constants'
import { formatPhoneNumber } from '../../../../common/FormatPhone'
import { storeConfirmation } from '../../../../common/StoreConfirmation'
import { emailIsValid } from '../../../../common/Validators'
import * as config from '../../../../config/config'
import { operatorsSetupActive } from '../../../../config/config'
import { LoadingScreen } from '../../../../legacy-lib/design-kit-ui'
import LoggedUserSaverService from '../../../../molecules/LoggedUserInfoCard/LoggedUserSaverService'
import { PagesPathEnum } from '../../../../pages/enums/pages-path.enum'
import {
  bffParceirosApiService,
  keyCloackService,
  startApiServices,
  startSSOAuthService,
} from '../../../../services/bff'
import WhatsAppUtils from '../../../../utils/whatsApp.utils'
import EmailStep from '../EmailStep/EmailStep'
import KeycloackLogin from '../Keycloack/KeycloackLogin'
import PasswordStep from '../PasswordStep/PasswordStep'
import { ForgotPassword, Link, LoginBox, LoginCard, Message, MessageIcon, Row } from './style'

export enum LoginFormStep {
  EMAIL_STEP,
  PASSWORD_STEP,
}

export interface ILoginFormProps {
  title?: string
  history?: any
  location?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export interface ILoginFormState {
  formStep: LoginFormStep
  username: string
  password: string
  showPassword: boolean
  loading: boolean
  passwordAuthenticationError: string
  ssoAuthenticationError: string
}

export default class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  constructor(props: ILoginFormProps) {
    super(props)

    this.state = {
      formStep: LoginFormStep.EMAIL_STEP,
      username: '',
      password: '',
      passwordAuthenticationError: '',
      ssoAuthenticationError: '',
      loading: false,
      showPassword: false,
    }
  }

  public onStepChange = (step: LoginFormStep) => {
    if (!this.state.username) {
      this.setState({ passwordAuthenticationError: 'Preencha o campo com seu e-mail' })
    } else if (!emailIsValid(this.state.username)) {
      this.setState({ passwordAuthenticationError: 'Por favor, insira um e-mail válido' })
    } else {
      this.setState({ passwordAuthenticationError: '' })
      this.setState({ formStep: step })
    }
  }

  public handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      this.handlePasswordAuthentication()
    }
  }

  public onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ passwordAuthenticationError: '', username: e.target.value })
  }

  public onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({ passwordAuthenticationError: '', password: e.target.value })
  }

  public handlePasswordAuthentication = async () => {
    const { username, password } = this.state

    // stop here if form is invalid
    if (!username) {
      this.setState({ passwordAuthenticationError: 'Preencha o campo com seu e-mail' })
      return
    }

    // stop here if form is invalid
    if (!password) {
      this.setState({ passwordAuthenticationError: 'Preencha o campo com sua senha' })
      return
    }

    this.setState({ loading: true })

    try {
      await bffParceirosApiService.auth.login({ username, password })
      startApiServices()

      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)

      if (operatorsSetupActive) {
        if (!operator.active) {
          this.setState({
            loading: false,
            passwordAuthenticationError: 'Usuário bloqueado',
          })
          bffParceirosApiService.auth.logout()
        }
      }

      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (!isStoreSetUp) {
        const storeId = operator.storeId
        const store = await bffParceirosApiService.retailers.fetchStoreById(storeId)

        storeConfirmation(store)
      }

      await LoggedUserSaverService.fetchAndSaveUser()

      this.setState({ loading: false })
      this.props.handleUserLogged(user)
    } catch (error) {
      this.setState({
        loading: false,
        passwordAuthenticationError: error.message,
      })

      if (error.message === 'UNAUTHORIZED') {
        this.setState({
          formStep: LoginFormStep.EMAIL_STEP,
          password: '',
          passwordAuthenticationError: 'Usuário não cadastrado ou senha incorreta',
        })
      }
    }
  }

  public handleSSOAuthentication = async () => {
    this.setState({ loading: true })

    try {
      const keycloakLoginToken = await keyCloackService.getLoginToken()

      if (!keycloakLoginToken) {
        return this.setState({
          loading: false,
          ssoAuthenticationError: 'Erro ao autenticar, tente novamente.',
        })
      }

      await startSSOAuthService(keycloakLoginToken)

      startApiServices()

      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)

      if (operatorsSetupActive) {
        if (!operator.active) {
          this.setState({
            loading: false,
            ssoAuthenticationError: 'Usuário bloqueado',
          })
          bffParceirosApiService.auth.logout()
        }
      }

      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (!isStoreSetUp) {
        const storeId = operator.storeId ? operator.storeId : user.storeId!
        const store = await bffParceirosApiService.retailers.fetchStoreById(storeId)

        storeConfirmation(store)
      }

      await LoggedUserSaverService.fetchAndSaveUser()

      this.setState({ loading: false })
      this.props.handleUserLogged(user)
    } catch (error) {
      this.setState({
        loading: false,
        ssoAuthenticationError: error.message,
      })

      if (error.message === 'UNAUTHORIZED') {
        this.setState({
          ssoAuthenticationError: 'Usuário sem autorização',
        })
      }
    }
  }

  public render() {
    const user = bffParceirosApiService.auth.getUser()

    if (bffParceirosApiService.auth.isLogged() && user) {
      let pathname = PagesPathEnum.HOME_PAGE

      if (user.role === Role.CASHIER) {
        pathname = PagesPathEnum.CASH_REGISTER
      } else {
        pathname = PagesPathEnum.HOME_PAGE
      }
      const { from } = { from: { pathname } }

      return <Redirect to={from} />
    }

    const steps = {
      0: (
        <EmailStep
          onUsernameChange={this.onUsernameChange}
          username={this.state.username}
          onStepChange={this.onStepChange}
          error={this.state.passwordAuthenticationError}
        />
      ),
      1: (
        <PasswordStep
          onPasswordChange={this.onPasswordChange}
          password={this.state.password}
          onStepChange={this.onStepChange}
          onSubmit={this.handlePasswordAuthentication}
          error={this.state.passwordAuthenticationError}
        />
      ),
    }

    return (
      <LoginBox align="center" justify="center">
        <LoginCard boxShadow={false}>
          {steps[this.state.formStep]}

          <KeycloackLogin
            handleLoginButtonPress={this.handleSSOAuthentication}
            error={this.state.ssoAuthenticationError}
          />

          <ForgotPassword>
            <Row>
              <MessageIcon icon={'chat'} size={20} />
              <Message color={'#252927'}>Esqueceu seus dados de acesso?</Message>
            </Row>

            <Message color={'#757E79'}>
              Fale conosco pelo Atendimento ao Varejo Ume pelo{' '}
              <Link href={WhatsAppUtils.buildUrl(config.UME_PHONES.retailerSAC.whatsapp)} target="_blank">
                WhatsApp {formatPhoneNumber(config.UME_PHONES.retailerSAC.phoneNumber)}.
              </Link>
            </Message>
          </ForgotPassword>
        </LoginCard>
        {this.state.loading && <LoadingScreen />}
      </LoginBox>
    )
  }
}
