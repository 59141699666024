export const sortBy = (fields: Array<string>) => (a: any, b: any) =>
  fields
    .map((o: any) => {
      let dir = 1
      if (o[0] === '-') {
        dir = -1
        o = o.substring(1)
      }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0
    })
    .reduce((p: any, n: any) => (p ? p : n), 0)

export const groupBy = (key: string) => (list: Array<any>) =>
  list.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

export const insertAt = (array: any, index: number, element: any) => {
  return [...array.slice(0, index), element, ...array.slice(index)]
}
