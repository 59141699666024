import React from 'react'
import styled from 'styled-components'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { bffParceirosApiService } from '../../services/bff'
import CustomButton from '../../ui/Button/CustomButton'
import { black, gray, green, white } from '../../ui/utils/_colors'
import CashierSendLimitButtonText from './CashierSendLimitButtonText'
import CashierSendLimitTimeoutHandler, { COUNTDOWN_TIMEOUT } from './CashierSendLimitTimeoutHandler'

type Infos = {
  operatorId: string
  borrowerId: string
}

interface IButtonProps {
  payload: Infos
  onSuccess(): void
  onError(): void
}

export default function SendNotifyLimit({ payload, onSuccess, onError }: IButtonProps) {
  const [countdown, setCountdown] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [storeId, setStoreId] = React.useState('')

  React.useEffect(() => {
    getStoreFromLocalStorage()
    getTimestampFromLocalStorage()
  }, [])

  const getStoreFromLocalStorage = () => {
    const store = bffParceirosApiService.coordinator.getStore()

    if (store) {
      setStoreId(store.id)
    }
  }

  const getTimestampFromLocalStorage = () => {
    const countdownWithSeconds = CashierSendLimitTimeoutHandler.getCashierSendLimitTimeout(payload.borrowerId)

    if (countdownWithSeconds) {
      const { seconds } = countdownWithSeconds

      if (seconds > 0) updateCountdownTimer(seconds)
    }
  }

  const handleOnClick = async () => {
    try {
      analyticsService.sendEvent(AnalyticsEvents.purchase_send_borrower_limit)
      const { borrowerId, operatorId } = payload

      setIsLoading(true)

      await bffParceirosApiService.coordinator.fetchBorrowerNotifyLimit(borrowerId, storeId, operatorId)

      onSuccess()
    } catch (error) {
      onError()
    } finally {
      setIsLoading(false)
      updateCountdownTimer(COUNTDOWN_TIMEOUT)
      CashierSendLimitTimeoutHandler.addCashierSendLimitTimeout(payload.borrowerId)
    }
  }

  const updateCountdownTimer = async (seconds: number) => {
    var timeLeft = seconds

    setCountdown(seconds)

    const downloadTimer = setInterval(function() {
      if (timeLeft <= 0) {
        clearInterval(downloadTimer)
        setCountdown(0)
      } else {
        setCountdown(timeLeft)
      }
      timeLeft -= 1
    }, 1000)
  }

  return (
    <Container>
      <Title>Enviar limite do cliente por SMS</Title>
      <SendLimitButton
        onClick={handleOnClick}
        disabled={isLoading || countdown > 0}
        color={green.primary}
        borderRadius="8px"
        border="0px"
        height="67px"
        width="241px"
        isLoading={isLoading || countdown > 0}
      >
        <TextButton isLoading={isLoading || countdown > 0}>Enviar SMS</TextButton>
      </SendLimitButton>
      <CashierSendLimitButtonText countdown={countdown} isLoading={isLoading} />
      <Divider />
      <GroupInfo>
        <GroupInfoTitle>Limite disponível</GroupInfoTitle>
        <GroupInfoText>
          Clientes UME podem verificar o limite pelo <strong>aplicativo</strong>, com um <strong>promotor</strong> ou na{' '}
          <strong>central de atendimento</strong>.
        </GroupInfoText>
      </GroupInfo>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  row-gap: 14px;
  justify-items: center;
`

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: ${black.primary};
`

interface ISendLimitButtonProps {
  readonly isLoading: boolean
}

const SendLimitButton = styled(CustomButton)<ISendLimitButtonProps>`
  border-color: ${green.primary};
  background-color: ${green.primary};
  color: ${white.primary};
  opacity: ${(props: ISendLimitButtonProps) => (props.isLoading ? '0.5' : '1')};
  cursor: ${(props: ISendLimitButtonProps) => (props.isLoading ? 'default' : 'pointer')};

  &:hover {
    box-shadow: ${(props: ISendLimitButtonProps) => (props.isLoading ? 'none' : `0px 0px 0px 1px ${green.laurel}`)};
    background-color: ${(props: ISendLimitButtonProps) => !props.isLoading && green.laurel};
    opacity: ${(props: ISendLimitButtonProps) => (props.isLoading ? '0.5' : '1')};
  }
`

const TextButton = styled.label<ISendLimitButtonProps>`
  font-size: 16px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  font-family: 'Roboto', 'Times New Roman', Times, serif;
  cursor: ${(props: ISendLimitButtonProps) => (props.isLoading ? 'default' : 'pointer')};
`

const Divider = styled.div`
  height: 0.01px;
  width: 18rem;
  border: 0.01px solid ${gray.nobel};
  opacity: 0.25;
`

const GroupInfo = styled.div`
  width: 320px;
  height: 124px;
  background-color: #f4f4f4; // lightgray
  border-radius: 10px;
  text-align: start;
  padding: 10px 12px;

  span,
  strong {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    color: #757e79; // lightgray
  }
`

const GroupInfoTitle = styled.div`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  color: ${black.primary};
`

const GroupInfoText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  padding-top: 4px;
  color: #757e79; // lightgray
`
