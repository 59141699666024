import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

interface Props {
  nextButtonDisabled?: boolean;
  onNextButtonClick?: React.MouseEventHandler<HTMLElement>;
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>;
}

export const FooterButtons = (props: Props): JSX.Element => {
  return (
    <Footer>
      <ComponentButton onClick={props.onBackButtonClick}>
        Voltar
      </ComponentButton>
      <NextButton
        disabled={props.nextButtonDisabled}
        onClick={props.onNextButtonClick}
      >
        Próximo
      </NextButton>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

const ComponentButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048 !important;

  // BUTTON
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #11b048 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  width: 80% !important;
  height: 60px !important;

  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6);
`;

const NextButton = styled(ComponentButton)`
  margin-left: 8px;
  
  border: 1px solid ${(props: JSX.IntrinsicElements['button']) => props.disabled ? '#C2C2C2' : '#11b048'} !important;
  background: ${(props: JSX.IntrinsicElements['button']) => props.disabled ? '#C2C2C2' : '#11b048'} !important;

  color: white !important;
`;
