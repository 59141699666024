import { Tab as GrommetTab } from 'grommet'
import styled from 'styled-components'
import { blue } from '../utils/_colors'

const StyledTab = styled(GrommetTab)`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  letter-spacing: 0.61px;
  line-height: 16px;
  opacity: 0.3;

  & button,
  div,
  span {
    color: ${props => (props.color ? props.color : blue.navy)};
  }

  &:nth-child(n) {
    > div {
      border-bottom: solid 2px ${props => (props.color ? props.color : blue.navy)};
    }
  }

  &:first-child:focus {
    opacity: 1;
  }

  &:nth-child(n) {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
    opacity: 1;
  }

  &:not(element):focus {
    opacity: 1;
  }
`

export { StyledTab }
