import {
  Button,
  Card,
  Colors,
  Elevation,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  Label,
  Spinner,
  Switch,
} from '@blueprintjs/core'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { Box } from 'grommet'
import { Validate } from 'grommet-icons'
import React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { RouteProps } from 'react-router'
import * as XLSX from 'xlsx'
import { toaster } from '../../App'
import { OperatorsRolesToAnalyst, OperatorsRolesToManager, Role } from '../../common/constants'
import { operatorsErrorResponse, storeIsNotSetUp } from '../../common/ErrorStrings'
import { get_header_row } from '../../common/GetHeaderRow'
import { emailIsValid, validateBr } from '../../common/Validators'
import { DataTable } from '../../legacy-lib/design-kit-ui'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import { bffParceirosApiService } from '../../services/bff'
import { Loading } from '../../ui'
import { OperatorRoleToPortuguese } from './OperatorsSetup'
import {
  buttonsSubmitStyle,
  DownloadTableTemplateButton,
  FileUpload,
  formContainerStyle,
  formItemsContainerStyle,
  formItemStyle,
  h5Style,
  passwordFormGroupStyle,
  StyledConfirmationText,
  StyledErrorLabel,
  StyledSubtitle,
  TableContainer,
  textInputGroupWithLabelStyle,
  titleStyle,
} from './style'

export interface IOperatorsFormState {
  stores: IStoreResponse[]
  role: string
  operatorId?: string
  name: string
  cpf: string
  cellphone: string
  email: string
  password: string
  showPassword: boolean
  resetPassword: boolean
  confirmPassword: string
  isActive: boolean
  selectedStore?: IStoreResponse
  isLoading: boolean
  isSaving: boolean
  isLoadingManyOperatorsCreation: boolean
  isCreatingManyOperators: boolean
  isManyOperatorsCreated: boolean
  retailerId?: string
  createManyOperatorsErrors?: any
}

export interface IOperatorsFormProps extends RouteProps {
  title?: string
  history?: any
  location?: any
}

const regexNumber = /(?=.*?[0-9])/

const ERRORS_TABLE_HEADER = [
  { attribute: 'line', label: 'Linha', textAlign: 'center' },
  {
    attribute: 'reason',
    label: 'Erro',
    textAlign: 'center',
    formatCell: (row: any) => {
      return <StyledErrorLabel>{operatorsErrorResponse[row.reason]}</StyledErrorLabel>
    },
  },
]

export default class OperatorsForm extends React.Component<IOperatorsFormProps, IOperatorsFormState> {
  state: IOperatorsFormState = {
    selectedStore: undefined,
    isLoading: true,
    isSaving: false,
    operatorId: undefined,
    stores: [],
    role: '',
    name: '',
    cpf: '',
    cellphone: '',
    email: '',
    password: '',
    showPassword: false,
    resetPassword: false,
    confirmPassword: '',
    isActive: true,
    isCreatingManyOperators: false,
    isLoadingManyOperatorsCreation: false,
    isManyOperatorsCreated: false,
    createManyOperatorsErrors: undefined,
  }

  async componentDidMount() {
    const operatorId = this.props.history.location.state && this.props.history.location.state.operatorId
    const storesList = (await bffParceirosApiService.retailers.fetchStores()).stores.sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    )

    const store = bffParceirosApiService.coordinator.getStore()
    if (!store) {
      toaster.showErrorToast(storeIsNotSetUp)
      this.props.history.push('/configuracao')
      return
    }
    if (operatorId) {
      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(operatorId)

      const formattedCellphone = operator.phoneNumber.startsWith('+55')
        ? operator.phoneNumber.slice(3, 14)
        : operator.phoneNumber

      this.setState({
        retailerId: operator.retailerId,
        operatorId: operator.id,
        name: operator.name,
        cpf: operator.cpf,
        cellphone: formattedCellphone,
        email: operator.email,
        role: operator.role,
        isActive: operator.active,
        isLoading: false,
        stores: storesList,
        selectedStore: operator.storeId ? storesList.filter(s => s.id === operator.storeId)[0] : store,
      })
    } else {
      this.setState({
        stores: storesList,
        selectedStore: store,
        isLoading: false,
      })
    }
  }
  public onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.currentTarget.value.toUpperCase() })
  }

  public onCPFChange = (values: NumberFormatValues) => {
    this.setState({ cpf: values.value })
  }

  public onCellphoneChange = (values: NumberFormatValues) => {
    this.setState({ cellphone: values.value })
  }

  public onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value.toLowerCase() })
  }

  public onStoreChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const storeId = e.currentTarget.value
    const store = this.state.stores.filter(s => s.id === storeId)[0]
    this.setState({ selectedStore: store })
  }

  public onRoleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const role = e.currentTarget.value
    this.setState({ role })
  }

  public onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value })
  }

  public onPasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: e.currentTarget.value })
  }

  public onActiveChange = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  public onPasswordResetChange = () => {
    this.setState({ resetPassword: !this.state.resetPassword })
  }

  public saveOperator = async () => {
    const {
      name,
      cpf,
      email,
      cellphone,
      selectedStore,
      role,
      password,
      isActive,
      confirmPassword,
      resetPassword,
    } = this.state
    this.setState({ isSaving: true })

    const formattedCellphone = cellphone.startsWith('+55') ? cellphone.slice(3, 14) : cellphone
    const operatorId = this.props.history.location.state && this.props.history.location.state.operatorId

    // if operatorId exists then has to edit, if it's not then create one

    if (operatorId) {
      if (
        !emailIsValid(email) ||
        !this.cellphoneIsValid(formattedCellphone) ||
        !selectedStore ||
        !role ||
        (resetPassword && !this.passwordIsValid(password)) ||
        (resetPassword && !this.passwordIsCorrect(confirmPassword))
      ) {
        this.setState({ isSaving: false })
        toaster.showWarningToast('Há campos obrigatórios incompletos')
        return
      }

      try {
        await bffParceirosApiService.coordinator.editOperator(operatorId, {
          role,
          email,
          password,
          active: isActive,
          phoneNumber: '+55' + formattedCellphone,
          storeId: selectedStore && selectedStore.id,
        })
        toaster.showSuccessToast('Colaborador foi alterado com sucesso!')
        this.props.history.push('/colaboradores')
      } catch (error) {
        toaster.showErrorToast(
          operatorsErrorResponse[error.response.data.error] || 'Falha no sistema. Tente novamente.'
        )
      }
    } else {
      if (
        !name ||
        !this.cpfIsValid(cpf) ||
        !emailIsValid(email) ||
        !this.cellphoneIsValid(formattedCellphone) ||
        !selectedStore ||
        !role ||
        !this.passwordIsValid(password) ||
        !this.passwordIsCorrect(confirmPassword)
      ) {
        this.setState({ isSaving: false })
        toaster.showWarningToast('Há campos obrigatórios incompletos')
        return
      }

      const store = bffParceirosApiService.coordinator.getStore()

      if (!store) {
        toaster.showErrorToast(storeIsNotSetUp)
        this.props.history.push('/configuracao')
        return
      }

      try {
        await bffParceirosApiService.coordinator.createOperator({
          cpf,
          name,
          role,
          email,
          password,
          active: isActive,
          phoneNumber: '+55' + formattedCellphone,
          retailerId: selectedStore && selectedStore.retailerId,
          storeId: (selectedStore && selectedStore.id) || store.id,
        })
        toaster.showSuccessToast('Colaborador foi criado com sucesso!')
        this.props.history.push('/colaboradores')
      } catch (error) {
        toaster.showErrorToast(
          operatorsErrorResponse[error.response.data.error] || 'Falha no sistema. Tente novamente.'
        )
      }
    }
    this.setState({ isSaving: false })
  }

  public cancelOperation = () => {
    this.props.history.push('/colaboradores')
  }

  public cpfIsValid(cpf: string) {
    return validateBr.cpf(cpf)
  }

  public passwordIsValid(password: string) {
    return password.length > 5 && regexNumber.test(password)
  }

  public passwordIsCorrect(passwordConfirm: string) {
    return passwordConfirm === this.state.password
  }

  public cellphoneIsValid(cellphone: string) {
    return validateBr.celular(cellphone)
  }

  // Creates a template table and sends to user
  handleDownloadTableTemplate = () => {
    let template = this.state.stores.map(store => ({
      Lojas: store.name,
      Funções: '',
    }))

    const roles = ['Caixa', 'Representante', 'Atendente', 'Gerente', 'Promotor']
    let i = 0
    for (const role of roles) {
      if (template[i]) {
        template[i]['Funções'] = role
      } else {
        template.push({ Lojas: '', Funções: role })
      }
      i++
    }

    const sheet = XLSX.utils.json_to_sheet(template, {
      header: ['Lojas', 'Funções', 'Nome', 'CPF', 'Email', 'Telefone', 'Função', 'Loja'],
    })
    XLSX.utils.sheet_add_json(sheet, [], { origin: 'A3' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet)
    XLSX.writeFile(wb, 'colaboradores_template.xlsx')
  }

  // Gets the inputted file from the user
  // - Validates it's format (header rows)
  // - Creates a json array
  // - Sends to server
  handleSelectedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isLoadingManyOperatorsCreation: true, createManyOperatorsErrors: undefined })
    const files = e.target.files
    if (!files || files.length === 0) {
      this.setState({ isLoadingManyOperatorsCreation: false })
      return toaster.showErrorToast(`Selecione um arquivo`)
    }
    const file = files[0]
    const bufferData = await file.arrayBuffer()
    // Read the .xlsx file
    const data = XLSX.read(bufferData, { type: 'buffer' })
    // Get all sheet's names
    const sheetNames = data.SheetNames
    // No sheet or multiple sheets error
    if (sheetNames.length !== 1) {
      this.setState({ isLoadingManyOperatorsCreation: false })
      return toaster.showErrorToast(`Planilha deve ter uma Sheet!`)
    }
    // Get the worksheet
    const worksheet = data.Sheets[sheetNames[0]]
    // Get the header row
    const headerRow = get_header_row(worksheet)
    // Check if the header row is as expected
    const expectedHeadersArray = ['Nome', 'CPF', 'Email', 'Telefone', 'Função', 'Loja']
    for (const expectedHeader of expectedHeadersArray) {
      if (!headerRow.find(element => element === expectedHeader)) {
        this.setState({ isLoadingManyOperatorsCreation: false })
        return toaster.showErrorToast(`Coluna ${expectedHeader} faltando na tabela.`)
      }
    }
    this.setState({ isCreatingManyOperators: true })

    let jsonFromTable: any[] = XLSX.utils.sheet_to_json(data.Sheets[sheetNames[0]])
    const jsonBody: any[] = []

    // Treat json from table into expected json for API
    for (let element of jsonFromTable) {
      // Skip the rows with just store names or roles
      if (
        element['Nome'] === undefined &&
        element['CPF'] === undefined &&
        element['Email'] === undefined &&
        element['Telefone'] === undefined &&
        element['Função'] === undefined &&
        element['Loja'] === undefined
      ) {
        continue
      }

      const name = element['Nome'] ? String(element['Nome']) : undefined
      // If a CPF is given, remove all NaN
      const cpf = element['CPF']
        ? String(element['CPF'])
            .replace('.', '')
            .replace('.', '')
            .replace('-', '')
            .replace("'", '')
        : undefined
      const email = element['Email'] ? String(element['Email']) : undefined
      // If a phone number is given, remove all NaN
      const phoneNumber = element['Telefone']
        ? String(element['Telefone'])
            .replace('-', '')
            .replace('(', '')
            .replace(')', '')
            .replace("'", '')
            .replace(' ', '')
        : undefined
      const role = element['Função'] ? String(element['Função']) : undefined
      const storeName = element['Loja'] ? String(element['Loja']) : undefined

      jsonBody.push({
        name,
        cpf,
        email,
        phoneNumber,
        role,
        storeName,
        active: true,
      })
    }

    // Verify if the sheet is empty
    if (jsonBody.length === 0) {
      this.setState({
        createManyOperatorsErrors: undefined,
        isLoadingManyOperatorsCreation: false,
        isCreatingManyOperators: false,
      })
      return toaster.showErrorToast(`Preencha a planilha com os operadores a serem cadastrados.`)
    }

    bffParceirosApiService.coordinator
      .createManyOperators({ operators: jsonBody })
      .then(result => {
        this.setState({ createManyOperatorsErrors: undefined, isLoadingManyOperatorsCreation: false })
      })
      .catch(error => {
        if (error.response?.data.statusCode === 400) {
          toaster.showErrorToast(`Erro na validação da planilha.`)
          const errors = error.response.data.message
          this.setState({
            createManyOperatorsErrors: errors,
            isLoadingManyOperatorsCreation: false,
          })
        } else {
          toaster.showErrorToast(`Erro no servidor.`)
          this.setState({
            createManyOperatorsErrors: undefined,
            isLoadingManyOperatorsCreation: false,
            isCreatingManyOperators: false,
          })
        }
      })
  }

  // Changes the event value to default, so the onChange event is triggered even if
  //   the same file is uploaded.
  handleClickInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = ''
  }

  render() {
    const operatorIdEdit = this.props.history.location.state && this.props.history.location.state.operatorId

    const {
      isLoading,
      stores,
      operatorId,
      selectedStore,
      name,
      cpf,
      cellphone,
      email,
      password,
      showPassword,
      resetPassword,
      isActive,
      confirmPassword,
      role,
      isSaving,
    } = this.state

    const user = bffParceirosApiService.auth.getUser()

    if (isLoading) {
      return (
        <div style={{ margin: '64px' }}>
          <Spinner size={75} />
        </div>
      )
    }

    return (
      <div style={formContainerStyle}>
        <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
          <div style={titleStyle}>
            <h5 style={h5Style} className="bp3-heading">
              Cadastro em massa
            </h5>
          </div>
          {this.state.isLoadingManyOperatorsCreation ? (
            <Box width="100%" align="center" justify="center" height="300px">
              <Loading />
            </Box>
          ) : (
            <>
              {this.state.isCreatingManyOperators ? (
                <>
                  {this.state.createManyOperatorsErrors ? (
                    <>
                      <StyledSubtitle>
                        Foram encontrados erros de validação na planilha. Por favor, corrija-os e suba uma nova. Confira
                        os erros na tabela abaixo:
                      </StyledSubtitle>
                      <TableContainer>
                        <DataTable
                          header={ERRORS_TABLE_HEADER}
                          data={this.state.createManyOperatorsErrors}
                          fontSize="14px"
                        />
                      </TableContainer>
                      <Box direction="row" margin={{ top: '15px', left: '50px', bottom: '25px' }}>
                        <DownloadTableTemplateButton
                          type="primary"
                          size="middle"
                          onClick={this.handleDownloadTableTemplate}
                        >
                          Download template da planilha
                        </DownloadTableTemplateButton>
                        <FileUpload
                          type="file"
                          accept=".xlsx"
                          onChange={this.handleSelectedFile}
                          onClick={this.handleClickInputFile}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        direction="row"
                        align="center"
                        alignSelf="center"
                        margin={{ top: '15px', left: '50px', bottom: '50px' }}
                      >
                        <Validate size="xlarge" color={UMEColors.green.laurel} />
                        <StyledConfirmationText>Cadastro realizado com sucesso!</StyledConfirmationText>
                      </Box>
                      <StyledSubtitle>
                        Cadastro de operadores cuncluído. A senha padrão é a composição do primeiro nome seguido do
                        último nome e dos dois últimos dígitos do telefone.
                      </StyledSubtitle>
                    </>
                  )}
                </>
              ) : (
                <>
                  <StyledSubtitle>
                    Faça upload de uma planilha XLSX com os operadores a serem cadastrado seguindo o modelo.
                  </StyledSubtitle>
                  <Box direction="row" margin={{ top: '15px', left: '50px', bottom: '25px' }}>
                    <DownloadTableTemplateButton
                      type="primary"
                      size="middle"
                      onClick={this.handleDownloadTableTemplate}
                    >
                      Download template da planilha
                    </DownloadTableTemplateButton>
                    <FileUpload
                      type="file"
                      accept=".xlsx"
                      onChange={this.handleSelectedFile}
                      onClick={this.handleClickInputFile}
                    />
                  </Box>

                  <div style={textInputGroupWithLabelStyle}></div>

                  <div style={titleStyle}>
                    <h5 style={h5Style} className="bp3-heading">
                      Dados cadastrais
                    </h5>
                    <p>
                      <b style={{ color: Colors.RED3 }}>*</b>
                      Campos obrigatórios
                    </p>
                  </div>

                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      Nome Completo
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <InputGroup
                      disabled={!!operatorId}
                      placeholder="Nome igual ao do RG"
                      type={'text'}
                      tabIndex={1}
                      autoComplete="off"
                      value={name}
                      onChange={this.onNameChange}
                    />
                  </div>
                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      CPF
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <NumberFormat
                      disabled={!!operatorId}
                      placeholder="000.000.000-00"
                      tabIndex={1}
                      type={'tel'}
                      autoComplete="off"
                      format="###.###.###-##"
                      mask="_"
                      isNumericString={true}
                      value={cpf}
                      className={`bp3-input-group bp3-input ${
                        cpf && !this.cpfIsValid(cpf) ? 'bp3-intent-danger' : 'bp3-intent-none'
                      } `}
                      onValueChange={this.onCPFChange}
                    />
                  </div>
                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      E-mail
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <InputGroup
                      placeholder="exemplo@email.com"
                      type="email"
                      tabIndex={1}
                      autoComplete="off"
                      value={email}
                      intent={email && !emailIsValid(email) ? Intent.DANGER : Intent.NONE}
                      onChange={this.onEmailChange}
                    />
                  </div>
                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      Telefone Celular
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <NumberFormat
                      autoComplete="off"
                      isNumericString={true}
                      tabIndex={1}
                      placeholder="Celular com DDD"
                      className={`bp3-input-group bp3-input ${
                        cellphone && !this.cellphoneIsValid(cellphone) ? 'bp3-intent-danger' : 'bp3-intent-none'
                      } `}
                      type="tel"
                      value={cellphone}
                      format="(##) ##### ####"
                      mask="_"
                      onValueChange={this.onCellphoneChange}
                    />
                  </div>

                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      Loja
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <HTMLSelect
                      value={selectedStore && selectedStore.id ? selectedStore.id : 0}
                      onChange={this.onStoreChange}
                    >
                      <option value={0}>Selecione uma loja...</option>
                      {stores.map((store, idx) => (
                        <option value={store.id} key={idx}>
                          {store.name}
                        </option>
                      ))}
                    </HTMLSelect>
                  </div>

                  <div style={textInputGroupWithLabelStyle}>
                    <Label style={formItemStyle} className="bp3-label">
                      Função
                      <b style={{ color: Colors.RED3 }}>*</b>
                    </Label>
                    <HTMLSelect value={role} onChange={this.onRoleChange}>
                      <option value={0}>Selecione uma função...</option>

                      {user &&
                        user.role === Role.MANAGER &&
                        OperatorsRolesToManager.map((role, idx) => (
                          <option value={role} key={idx}>
                            {OperatorRoleToPortuguese(role)}
                          </option>
                        ))}

                      {user &&
                        user.role === Role.ANALYST &&
                        OperatorsRolesToAnalyst.map((role, idx) => (
                          <option value={role} key={idx}>
                            {OperatorRoleToPortuguese(role)}
                          </option>
                        ))}
                    </HTMLSelect>
                  </div>

                  {operatorId && (
                    <div style={{ ...passwordFormGroupStyle, marginTop: '32px' }}>
                      <Switch checked={resetPassword} label="Alterar Senha" onChange={this.onPasswordResetChange} />
                    </div>
                  )}

                  {((operatorId && resetPassword) || !operatorId) && (
                    <div style={passwordFormGroupStyle}>
                      <Label style={formItemStyle} className="bp3-label">
                        Senha
                        <b style={{ color: Colors.RED3 }}>*</b>
                      </Label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputGroup
                          placeholder="Senha"
                          tabIndex={1}
                          type="password"
                          autoComplete="off"
                          value={password}
                          intent={password && !this.passwordIsValid(password) ? Intent.DANGER : Intent.NONE}
                          onChange={this.onPasswordChange}
                        />
                        <div style={{ ...passwordFormGroupStyle, marginTop: 0, marginLeft: '8px' }}>
                          <p style={{ marginBottom: 0, color: password.length > 5 ? Colors.GREEN5 : Colors.RED5 }}>
                            Mínimo de 6 caracteres
                          </p>
                          <p
                            style={{ marginBottom: 0, color: regexNumber.test(password) ? Colors.GREEN5 : Colors.RED5 }}
                          >
                            Pelo menos 1 número
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {((operatorId && resetPassword) || !operatorId) && (
                    <div style={passwordFormGroupStyle}>
                      <Label style={formItemStyle} className="bp3-label">
                        Confirme a Senha
                        <b style={{ color: Colors.RED3 }}>*</b>
                      </Label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputGroup
                          placeholder="Repita a senha"
                          type={showPassword ? 'text' : 'password'}
                          tabIndex={1}
                          autoComplete="off"
                          value={confirmPassword}
                          intent={
                            confirmPassword && !this.passwordIsCorrect(confirmPassword) ? Intent.DANGER : Intent.NONE
                          }
                          onChange={this.onPasswordConfirmChange}
                        />

                        {confirmPassword && this.passwordIsCorrect(confirmPassword) && (
                          <Icon style={{ marginLeft: '8px' }} icon="tick-circle" intent="success"></Icon>
                        )}
                      </div>
                    </div>
                  )}

                  {operatorIdEdit && (
                    <div style={{ ...passwordFormGroupStyle, marginTop: '32px' }}>
                      <Switch checked={isActive} label="Ativo" onChange={this.onActiveChange} />
                    </div>
                  )}

                  <div style={buttonsSubmitStyle}>
                    <Button style={{ marginTop: '32px' }} onClick={this.cancelOperation} icon="cross" intent="danger">
                      Cancelar
                    </Button>
                    <Button
                      style={{ marginTop: '32px' }}
                      loading={isSaving}
                      onClick={this.saveOperator}
                      icon="tick"
                      intent="success"
                    >
                      Salvar
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </div>
    )
  }
}
