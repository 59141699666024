import moment from 'moment'
import React from 'react'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const CEP_LENGTH = 8

export enum APPLICATION_PAGES {
  CPF_PAGE,
  PHONE_PAGE,
  BORROWER_PAGE,
  BIOMETRY_PAGE,
  PRE_APPROVED_PAGE,
  ALREADY_APPROVED_PAGE,
  DENIED_PAGE,
  IN_PROGRESS_PAGE,
  APPROVED_PAGE,
  WAITING_MANUAL_APPROVAL_PAGE,
  PRE_DENIED_PAGE,
  SEND_VERIFICATION_CODE_PAGE,
  PHONE_VERIFICATION_PAGE,
  CHOOSE_BILL_DUE_DAY_PAGE,
  BIOMETRY_CONFLICT_PAGE,
  BORROWER_DISABLED_TO_PURCHASE_PAGE
}

export const validateBirthDate = (birthDate?: string) => {
  if (!birthDate) {
    return {
      isBirthDateValid: false,
      errorText: '',
    }
  }

  let isBirthDateValid = moment(birthDate, 'DD/MM/YYYY', true).isValid()

  if (isBirthDateValid) {
    const formattedDate = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const date = moment(formattedDate, 'YYYY-MM-DD').toDate()

    if (moment(date).isAfter()) {
      return {
        isBirthDateValid: false,
        errorText: 'Idade mínima 18 anos de idade',
      }
    }

    const diffYear = moment().diff(date, 'year')

    if (diffYear < 18) {
      return {
        isBirthDateValid: false,
        errorText: 'Idade mínima 18 anos de idade',
      }
    }

    if (diffYear >= 100) {
      return {
        isBirthDateValid: false,
        errorText: 'Idade máxima 100 anos de idade',
      }
    }
  }

  return {
    isBirthDateValid,
    errorText: '',
  }
}

export const validatePhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return false
  }

  const re = /^(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])(?:[2-8]|9[1-9])[0-9]{3}?[0-9]{4}$/
  return re.test(phoneNumber)
}

export const validateCep = (cep?: string) => {
  if (!cep) {
    return false
  }

  cep = cep.replace(/[^0-9]/g, '')

  return cep.length === CEP_LENGTH
}

export const validateFullName = (name?: string) => {
  if (!name) {
    return false
  }
  const re = /.*\s.+/
  return re.test(name)
}

export const validateEmail = (email?: string) => {
  if (!email) {
    return false
  }
  email = email.replace(/\s/g, '')
  if (email.length === 0) {
    return true
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const MaskCep = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
    />
  )
}

export const MaskTextOnly = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/^[a-zA-Z]+$/]}
    />
  )
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy')
export const MaskDate = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      keepCharPositions={true}
      pipe={autoCorrectedDatePipe}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  )
}
