export const getYValues = (firstValue, lastValue, quantityLabels = 5) => {
  const firstBound = Math.floor(firstValue / 10) * 10
  let lastBound = Math.ceil(lastValue / 1000) * 1000

  if (lastValue < 50) {
    lastBound = Math.ceil(lastValue / 10) * 10 || 10
  } else if (lastValue < 500) {
    lastBound = Math.ceil(lastValue / 100) * 100
  } else {
    lastBound = Math.ceil(lastValue / 1000) * 1000
  }

  const stepValue = lastBound / quantityLabels

  let yValues = []
  for (let value = firstBound; value <= lastBound; value += stepValue) {
    yValues.push(value)
  }

  return yValues
}
