import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  textColor: string
  backgroundColor: string
  border?: string
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  onClick?: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
  disabledBackgroundColor?: string
}

export const Button = (props: Props): JSX.Element => {
  const Icon = props.icon

  return (
    <Container>
      <StyledButton textColor={props.textColor} backgroundColor={props.backgroundColor} border={props.border} disabled={props.disabled} onClick={props.onClick}>
        {props.text}
        {Icon && (
          <IconComponent>
            <Icon />
          </IconComponent>
        )}
      </StyledButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledButton = styled.button<Props>`
  width: 100%;
  height: 60px;

  margin-top: 2vh;
  padding: 12px;

  display: flex; /* Torna o botão um contêiner flex */
  flex-direction: row;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  align-items: center; /* Centraliza o conteúdo verticalmente */

  background: ${(props: Props) => (props && props.disabled ? '#a9e1bc' : props.backgroundColor)};
  border-radius: 48px;
  border: ${(props: Props) => (props && props.border ? props.border : '0px')};

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: ${(props: Props) => props.textColor};

  line-height: 16px;
  text-align: center;
`
const IconComponent = styled.div`
  margin-left: 8px;
  margin-bottom: 2px;
`
