import styled from 'styled-components'
import { gray, black } from '../utils/_colors'
import { Anchor } from 'grommet'

const StyledBreadcrumbs = styled.section`
  width: ${props => (props.width ? props.width : '100%')};
  font-size: ${props => (props.fontSize ? props.fontSize : '11px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '11px')};

  font-family: Roboto;
  letter-spacing: 0;
  display: inline-block;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`
const StyledItem = styled(Anchor)`
  color: ${props => (props.color ? props.color : gray.nobel)};
  font-weight: ${props => (props.bold ? '500' : 'normal')};
  pointer-events: none;
  &::after {
    content: " / ";
  }

  &:last-child {
    color: ${black.primary};
    &::after {
      content: none;
    }
  }
`

export { StyledBreadcrumbs, StyledItem }
