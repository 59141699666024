import { Card, Elevation, H4, Spinner } from '@blueprintjs/core'
import * as React from 'react'
import { formItemsContainerStyle } from '../styles'

const spinnerContainerOverrideStyle = {
  ...formItemsContainerStyle,
  width: '60vw',
  height: '55vh',
  alignItems: 'center',
  justifyContent: 'center',
}

export const PaymentInProgress = () => (
  <Card style={spinnerContainerOverrideStyle} interactive={false} elevation={Elevation.TWO}>
    <div style={{ padding: '32px' }}>
      <Spinner size={75} />
    </div>
    <H4>Gerando comprovantes...</H4>
  </Card>
)
