import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class StoreIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19.148,2.971C18.789,2.372,18.132,2,17.434,2H6.566C5.868,2,5.211,2.372,4.852,2.971L2.143,7.485 C2.049,7.641,2,7.818,2,8c0,1.006,0.386,1.914,1,2.618V19c0,1.103,0.897,2,2,2h4h6h4c1.103,0,2-0.897,2-2v-8.382 C21.614,9.914,22,9.006,22,8c0-0.182-0.049-0.359-0.143-0.515L19.148,2.971z M19.984,8.251C19.86,9.235,19.018,10,18,10 c-1.103,0-2-0.897-2-2c0-0.068-0.025-0.128-0.039-0.192l0.02-0.004L15.22,4h2.214L19.984,8.251z M10.819,4h2.361l0.813,4.065 C13.958,9.137,13.08,10,12,10s-1.958-0.863-1.993-1.935L10.819,4z M6.566,4H8.78L8.02,7.804l0.02,0.004C8.025,7.872,8,7.932,8,8 c0,1.103-0.897,2-2,2c-1.018,0-1.86-0.765-1.984-1.749L6.566,4z M10,19v-3h4v3H10z M16,19v-3c0-1.103-0.897-2-2-2h-4 c-1.103,0-2,0.897-2,2v3H5v-7.142C5.321,11.941,5.652,12,6,12c1.193,0,2.267-0.525,3-1.357C9.733,11.475,10.807,12,12,12 s2.267-0.525,3-1.357C15.733,11.475,16.807,12,18,12c0.348,0,0.679-0.059,1-0.142V19H16z" />
        </svg>
      </Icon>
    )
  }
}
