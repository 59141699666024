import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { IUserPayloadDto, IUserPayloadQuery } from './dto/users.payload.dto'

export class UsersService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async fetchCurrentUser(params?: IUserPayloadQuery): Promise<IUserPayloadDto> {
    const response = await this.axiosInstance.get<IUserPayloadDto>('users/me', { params })

    return response.data
  }
}
