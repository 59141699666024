import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { Box } from 'grommet'
import * as React from 'react'
import { rose } from '../../../../legacy-lib/design-kit-ui/Palette'
import { Search, Store } from '../../../../ui/Icons'
import {
  BarSideBoxStyle,
  DropdownStyle,
  StoresSelectBoxStyle,
  SubTitleStyle,
  TitleBoxStyle,
  TitleHeadingStyle,
} from './style'

export interface IStoresSelectSidebarProps {
  stores: IStoreResponse[]
  selectedStore?: string
  onStoreSelect: (store: { value: IStoreResponse }) => void
}

export interface IStoresSelectSidebarState {}

export default class StoresSelectSidebar extends React.PureComponent<
  IStoresSelectSidebarProps,
  IStoresSelectSidebarState
> {
  public render() {
    const { stores, selectedStore, onStoreSelect } = this.props

    return (
      <BarSideBoxStyle>
        <Box>
          <TitleBoxStyle direction="row" gap="small" align="center">
            <Store width="30px" height="30px" color={rose.primary} />
            <TitleHeadingStyle>Escolha a loja</TitleHeadingStyle>
          </TitleBoxStyle>
          <Box width="200px">
            <SubTitleStyle>Seleciona uma loja específica para poder ver seus dados.</SubTitleStyle>
          </Box>
        </Box>
        <StoresSelectBoxStyle>
          <DropdownStyle
            icon={<Search />}
            placeholder="Procure pela loja"
            labelKey="name"
            valueKey="id"
            value={selectedStore ? stores.find(s => s.id === selectedStore) : stores[0]}
            options={stores}
            onChange={onStoreSelect}
          ></DropdownStyle>
        </StoresSelectBoxStyle>
      </BarSideBoxStyle>
    )
  }
}
