import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class DashboardIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg width="800px" height="800px" viewBox="0 0 50.8 50.8" xmlns="http://www.w3.org/2000/svg">
          <g style={{ display: 'inline' }}>
            <path
              d="M7.854 33.546 16 22.893l7.52 16.293 6.267-27.572 3.76 8.773 5.64-6.893 3.76 8.146"
              style={{
                fill: 'none',
                stroke: this.props.color ? this.props.color : 'black',
                strokeWidth: '2.50658',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
            />
          </g>
        </svg>
      </Icon>
    )
  }
}
