import React from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../../App'
import { ReactComponent as BarCodeIcon } from '../../../common/assets/images/barcode-icon.svg'
import { ReactComponent as TrendingUpIcon } from '../../../common/assets/images/trending-up-icon.svg'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import BudgetSliceReducer from '../../../redux/reducers/budget/budget.reducer'
import InvoicesSliceReducer from '../../../redux/reducers/invoice/invoices.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../../services/bff'

interface Props {
  document: string
  borrowerId?: string
}

export const UserAvailableProducts = (props: Props) => {
  const { isCreatingBorrowerNextInvoice } = useTypedSelector(state => ({
    isCreatingBorrowerNextInvoice: state.invoices.isCreatingBorrowerNextInvoice,
  }))

  const dispatch = useDispatch()

  const store = bffParceirosApiService.coordinator.getStore()

  const handleBudgetButton = () => {
    dispatch(BudgetSliceReducer.actions.resetRequest())
    dispatch(
      BudgetSliceReducer.actions.setInitialCpf({
        initialCpf: props.document,
      })
    )

    history.push(PagesPathEnum.BUDGET_PAGE)
  }

  const handleGenerateBorrowerNextInvoiceButton = (borrowerId: string) => {
    dispatch(InvoicesSliceReducer.actions.createBorrowerNextInvoice(borrowerId))
  }

  const shouldRenderGenerateBorrowerNextInvoiceButton = !!store?.settings?.enableToGenerateBorrowerInvoice && !!props.borrowerId

  return [
    {
      shouldRender: true,
      title: 'Solicitar aumento de limite',
      icon: <TrendingUpIcon />,
      onButtonPress: handleBudgetButton,
      isLoading: false,
    },
    {
      shouldRender: shouldRenderGenerateBorrowerNextInvoiceButton,
      title: 'Imprimir próximo boleto',
      icon: <BarCodeIcon />,
      onButtonPress: () => handleGenerateBorrowerNextInvoiceButton(props.borrowerId!),
      isLoading: isCreatingBorrowerNextInvoice,
    },
  ]
}
