import React from 'react';
import styled from 'styled-components';

export const BodyText = (props: JSX.IntrinsicElements['p']): JSX.Element => {
  return (
    <Text {...props}>{props.children}</Text>
  );
};

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  margin: 0;
`;