// ApplicationWaitingManualApprovalPage

import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../../App'
import { ReactComponent as WaitingManualApprovalSvg } from '../../../common/assets/images/waiting_manual_approval.svg'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'

const ApplicationWaitingManualApprovalPage = () => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch(ApplicationSliceReducer.actions.resetState())
    history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  // Side Effect: Component Did Mount load borrower limit
  useEffect(() => {
    dispatch(ApplicationSliceReducer.actions.fetchBorrowerLimit())
  }, [dispatch])

  return (
    <Container>
      <Title>Análise direcionada para a banca de avaliação</Title>
      <WaitingManualApprovalSvg width={'100%'} />
      <Subtitle>
        Esta análise será avaliada em tempo real. O cliente receberá o resultado por mensagem SMS em breve.
      </Subtitle>
      <BackButton onClick={handleReset}> Voltar para o cadastro </BackButton>
    </Container>
  )
}

export default ApplicationWaitingManualApprovalPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  justify-content: space-between;

  text-align: left;
  height: 93%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.18px;

  color: #000000;
`

const Subtitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* or 147% */

  /* Cinza escuro */

  color: #92929d;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 20px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 90% !important;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
