import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isCompletedApplication } from '../../common/ApplicationHelper'
import { Loading } from '../../molecules/Budget'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import BudgetReducer from '../../redux/reducers/budget/budget.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import {
  AggregatedTransactionalCreditEvaluation,
  ApplicationSetupStatusEnum,
  EvaluationResultEnum,
} from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { TransactionalCreditApplicationDeniedResult } from './components/TransactionalCreditApplicationDeniedResult/TransactionalCreditApplicationDeniedResult'
import { TransactionalCreditApprovedResult } from './components/TransactionalCreditApprovedResult/TransactionalCreditApprovedResult'
import { TransactionalCreditDeniedResult } from './components/TransactionalCreditDeniedResult/TransactionalCreditDeniedResult'

enum Result {
  AllApproved,
  ApplicationDenied,
  TransactionalCreditDenied,
}

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
}

const TransactionalCreditResult: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const { evaluationResult } = props

  const { isLoadingPreApplication, preApplicationStatus } = useTypedSelector(
    state => {
      return {
        isLoadingPreApplication: state.application.isLoadingPreApplication,
        preApplicationStatus: state.application?.preApplicationResponse?.status,
      }
    }
  )

  useEffect(() => {
    dispatch(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: evaluationResult.evaluation.document }));
  }, []);

  const defineResult = (evaluationResult: AggregatedTransactionalCreditEvaluation) => {
    if (
      evaluationResult.evaluation.result == EvaluationResultEnum.APPROVED ||
      evaluationResult.evaluation.result == EvaluationResultEnum.PARTIALLY_APPROVED
    ) {
      return Result.AllApproved
    }
    return evaluationResult.applicationSetup?.status == ApplicationSetupStatusEnum.DENIED
      ? Result.ApplicationDenied
      : Result.TransactionalCreditDenied
  }

  const handleGoBackToHomePage = () => {
    window.location.href = '/'
  }

  const handleGoBackToStart = () => {
    dispatch(BudgetReducer.actions.resetRequest())
    window.location.href = '/super-compra'
  }

  const handleGoToApplication = (cpf: string) => {
    window.location.href = `/applications/${cpf}`
  }

  if (isLoadingPreApplication) {
    return <Loading />
  }

  const hasCompletedApplication = !!preApplicationStatus && isCompletedApplication(preApplicationStatus)

  const result = defineResult(evaluationResult)

  switch (result) {
    case Result.AllApproved: {
      return (
        <TransactionalCreditApprovedResult
          evaluationResult={evaluationResult}
          hasCompletedApplication={hasCompletedApplication}
          handleGoBackToHomePage={handleGoBackToHomePage}
          handleGoToApplication={() => handleGoToApplication(evaluationResult.evaluation.document)}
          handleGoBackToStart={handleGoBackToStart}
        />
      )
    }
    case Result.ApplicationDenied: {
      return <TransactionalCreditApplicationDeniedResult handleGoBackToHomePage={handleGoBackToHomePage} />
    }
    case Result.TransactionalCreditDenied: {
      return (
        <TransactionalCreditDeniedResult
          evaluationResult={evaluationResult}
          hasCompletedApplication={hasCompletedApplication}
          handleGoBackToHomePage={handleGoBackToHomePage}
          handleGoToApplication={() => handleGoToApplication(evaluationResult.evaluation.document)}
          handleGoBackToStart={handleGoBackToStart}
        />
      )
    }
  }
}

export default TransactionalCreditResult
