import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../common/assets/images/close-icon.svg'

interface Props {
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
}

export const CloseButton = (props: Props): JSX.Element => {
  return (
    <Container>
      <BackButton onClick={props.onCloseButtonClick}>
        <CloseIcon width={'16px'} height={'16px'} />
      </BackButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const BackButton = styled.div`
  cursor: pointer;
`
