import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { Button } from '../../atoms/Buttons/Button'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { formatBRL } from '../../cash-register/common/formatBRL'
import NumberField from '../../molecules/Application/NumberField'
import { bffParceirosApiService } from '../../services/bff'
import { IStoreResponse } from '../../services/bff/coordinator/dto/Stores.dto'
import { CreateTransactionalCreditEvaluationReq } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { black, gray, green, red, white } from '../../ui/utils/_colors'

interface TransactionalCreditFormProps {
  document?: string
  onConfirmValue: (request: CreateTransactionalCreditEvaluationReq) => void
  onRedo: () => void
}

const MINIMUM_AMOUNT = 400
const MAXIMUM_AMOUNT = 4000
const amountErrorMessage = `Digite um valor entre ${formatBRL(MINIMUM_AMOUNT)} e ${formatBRL(MAXIMUM_AMOUNT)}.`

const TransactionalCreditForm: React.FC<TransactionalCreditFormProps> = (props: TransactionalCreditFormProps) => {
  const { document, onConfirmValue, onRedo } = props

  const [amount, setAmount] = React.useState<number | undefined>(undefined)
  const [store, setLocalStorageStore] = useState<IStoreResponse>()

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
  }, [store])

  const handleAmountChange = (values: NumberFormatValues) => {
    setAmount(Number(values.value))
  }

  const isValidAmount = !!amount && amount >= MINIMUM_AMOUNT && amount <= MAXIMUM_AMOUNT

  return (
    <>
      <MainContainer>
        <CloseButtonContainer>
          <CloseButton onCloseButtonClick={onRedo} />
        </CloseButtonContainer>

        {!store && <WarningHeader>Loja não configurada</WarningHeader>}

        <Title>Digite o valor do produto</Title>
        <Subtitle>Informe o valor do produto para consulta.</Subtitle>

        <FormField
          label={'Insira o valor da compra'}
          value={amount}
          placeholder="R$ 1.000,00"
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          onValueChange={handleAmountChange}
          error={!!amount && !isValidAmount}
        />
        <HelperText color={amount! > 0 && !isValidAmount ? red.rubi : gray.scale600}>{amountErrorMessage}</HelperText>
      </MainContainer>

      <Footer>
        <Button
          text={'Próximo'}
          textColor={white.primary}
          backgroundColor={green.dark2}
          disabled={!isValidAmount}
          onClick={() => onConfirmValue({ document: document!, requestedAmount: amount!!, storeId: store?.id })}
        />
      </Footer>
    </>
  )
}

interface ColorProps {
  color: string
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 40px;
`

const CloseButtonContainer = styled.div`
  padding-bottom: 16px;
`

const WarningHeader = styled.div`
  width: 100%;
  background-color: #fa6400;
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  color: ${black.primary};
  line-height: 24px;
  text-align: left;
`

const Subtitle = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: ${gray.scale800};
  line-height: 20px;
  text-align: left;
  margin-top: 8px;
`

const FormField = styled(NumberField).attrs({
  type: 'tel',
  mask: '_',
  style: { width: '100%', marginTop: '25%' },
  autoFocus: true,
})``

const HelperText = styled.text<ColorProps>`
  margin-top: 8px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${(props: ColorProps) => props.color};
`

const Footer = styled.div`
  width: 100%;
  padding: 0px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  bottom: 16px;
`

export default TransactionalCreditForm
