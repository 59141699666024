import { IOperatorRankingPositionResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-ranking-position'
import { IOperatorRecordResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operator-record'
import { IOperatorsRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/operators-ranking'
import { IStoresRankingResponse } from '@scudraservicos/coordinator-client/dist/src/services/backoffice/interfaces/stores-ranking'
import { Box, Grid } from 'grommet'
import moment from 'moment'
import * as React from 'react'
import { IntervalType } from '../../../../legacy-lib/design-kit-ui/Calendar/helpers'
import ApplicationByOperator from '../../../../molecules/dashView/components/ApplicationsByOperator/ApplicationsByOperator'
import ApplicationByStore from '../../../../molecules/dashView/components/ApplicationsByStore/ApplicationsByStore'
import CalendarSidebar from '../../../../molecules/dashView/components/CalendarSidebar/CalendarSidebar'
import CalendarSidebarMobile from '../../../../molecules/dashView/components/CalendarSidebar/CalendarSidebarMobile'
import RepresentativeOverview from '../../../../molecules/dashView/components/Overview/RepresentativeOverview'
import OverviewHeading from '../../../../molecules/dashView/components/Overview/RepresentativeOverviewHeading'
import { bffParceirosApiService } from '../../../../services/bff'
import { MainBoxStyle, MainMobileBoxStyle, RepresentativeViewBoxStyle, SidebarBoxStyle } from './style'

export interface IRepresentativeDashViewProps {}

export interface IRepresentativeDashViewState {
  startDate: string
  endDate: string
  interval: IntervalType
  applicationsRankingByOperator?: IOperatorsRankingResponse | Error
  applicationsRankingByStore?: IStoresRankingResponse | Error
  operatorRanking?: IOperatorRankingPositionResponse | Error
  totalApplicationsToday: number
  intervalRecord?: IOperatorRecordResponse | Error
  fixedMonthRecord?: number | Error
}

const initialState: IRepresentativeDashViewState = {
  startDate: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
  interval: 'month',
  totalApplicationsToday: 0,
  applicationsRankingByStore: undefined,
  applicationsRankingByOperator: undefined,
  operatorRanking: undefined,
  intervalRecord: undefined,
  fixedMonthRecord: undefined,
}
export default class RepresentativeDashView extends React.Component<
  IRepresentativeDashViewProps,
  IRepresentativeDashViewState
> {
  _isMounted = false

  constructor(props: IRepresentativeDashViewProps) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    this._isMounted = true
    this.getRankingPosition()
    this.getOperatorsRanking()
    this.getStoresRanking()
    this.getMonthRecord()
    this.getTotalApplicationsToday()
  }

  componentDidUpdate(prevProps: IRepresentativeDashViewProps, prevState: IRepresentativeDashViewState) {
    if (this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate) {
      this.setState({
        operatorRanking: initialState.operatorRanking,
        applicationsRankingByOperator: initialState.applicationsRankingByOperator,
        applicationsRankingByStore: initialState.applicationsRankingByStore,
      })
      this.getRankingPosition()
      this.getOperatorsRanking()
      this.getStoresRanking()
    }
    if (this.state.interval !== prevState.interval) {
      this.setState({ intervalRecord: initialState.intervalRecord })
      this.getRecordForInterval()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  intervalSelected = (interval: IntervalType) => {
    this.setState({ interval })
  }

  dateRangeSelected = (startDate: string, endDate: string) => {
    this.setState({ startDate, endDate })
  }

  getRankingPosition = () => {
    const { startDate, endDate } = this.state

    bffParceirosApiService.backoffice
      .getApplicationsRankingForOperator({ startDate, endDate })
      .then(data => {
        this._isMounted && this.setState({ operatorRanking: data })
      })
      .catch(error => this._isMounted && this.setState({ operatorRanking: error }))
  }

  getStoresRanking = () => {
    const { startDate, endDate } = this.state

    bffParceirosApiService.backoffice
      .getApplicationsRankingByStores({ startDate, endDate })
      .then(data => {
        this._isMounted && this.setState({ applicationsRankingByStore: data })
      })
      .catch(error => this._isMounted && this.setState({ applicationsRankingByStore: error }))
  }

  getOperatorsRanking = () => {
    const { startDate, endDate } = this.state

    bffParceirosApiService.backoffice
      .getApplicationsRankingByOperators({ startDate, endDate })
      .then(data => {
        this._isMounted && this.setState({ applicationsRankingByOperator: data })
      })
      .catch(error => this._isMounted && this.setState({ applicationsRankingByOperator: error }))
  }

  getMonthRecord = () => {
    bffParceirosApiService.backoffice
      .getApplicationRecordForOperator({ interval: 'month' })
      .then(data => {
        this._isMounted &&
          this.setState({
            fixedMonthRecord: data.numberOfApplications,
            intervalRecord: data,
          })
      })
      .catch(error => this._isMounted && this.setState({ fixedMonthRecord: error, intervalRecord: error }))
  }

  getRecordForInterval = () => {
    const { interval } = this.state

    bffParceirosApiService.backoffice
      .getApplicationRecordForOperator({ interval: interval === 'range' ? 'month' : interval })
      .then(data => {
        this._isMounted && this.setState({ intervalRecord: data })
      })
      .catch(error => this._isMounted && this.setState({ intervalRecord: error }))
  }

  getTotalApplicationsToday = () => {
    const today = moment
      .utc()
      .toISOString()
      .split('T')[0]

    bffParceirosApiService.backoffice
      .getApplicationsRankingForOperator({ startDate: today, endDate: today })
      .then(data => this._isMounted && this.setState({ totalApplicationsToday: data.numberOfApplications }))
  }

  public render() {
    const {
      applicationsRankingByStore,
      applicationsRankingByOperator,
      operatorRanking,
      intervalRecord,
      fixedMonthRecord,
      totalApplicationsToday,
    } = this.state

    return (
      <RepresentativeViewBoxStyle>
        <Grid className="desktop" fill rows={['full']} columns={['340px', 'auto']} areas={[['sidebar', 'main']]}>
          <SidebarBoxStyle gridArea="sidebar">
            <CalendarSidebar intervalSelected={this.intervalSelected} dateRangeSelected={this.dateRangeSelected} />
          </SidebarBoxStyle>
          <MainBoxStyle gridArea="main" gap="small">
            <OverviewHeading />
            <RepresentativeOverview
              operatorRanking={operatorRanking}
              intervalRecord={intervalRecord}
              fixedMonthRecord={fixedMonthRecord}
              totalApplicationsToday={totalApplicationsToday}
            />
            <ApplicationByStore applicationsRankingByStore={applicationsRankingByStore} />
            <ApplicationByOperator applicationsRankingByOperator={applicationsRankingByOperator} />
          </MainBoxStyle>
        </Grid>
        <Box className="is-mobile">
          <MainMobileBoxStyle gap="medium">
            <Box direction="row" justify="between" align="end">
              <OverviewHeading />
              <CalendarSidebarMobile
                intervalSelected={this.intervalSelected}
                dateRangeSelected={this.dateRangeSelected}
              />
            </Box>
            <RepresentativeOverview
              totalApplicationsToday={totalApplicationsToday}
              operatorRanking={operatorRanking}
              intervalRecord={intervalRecord}
              fixedMonthRecord={fixedMonthRecord}
            />
            <ApplicationByStore applicationsRankingByStore={applicationsRankingByStore} />
            <ApplicationByOperator applicationsRankingByOperator={applicationsRankingByOperator} />
          </MainMobileBoxStyle>
        </Box>
      </RepresentativeViewBoxStyle>
    )
  }
}
