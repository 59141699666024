export const getRandomColor = () => {
  const randomColors = [
    { backgroundColor: '#E53935', textColor: '#FFF' },
    { backgroundColor: '#9C27B0', textColor: '#FFF' },
    { backgroundColor: '#4527A0', textColor: '#FFF' },
    { backgroundColor: '#536DFE', textColor: '#FFF' },
    { backgroundColor: '#1976D2', textColor: '#FFF' },
    { backgroundColor: '#039BE5', textColor: '#FFF' },
    { backgroundColor: '#006064', textColor: '#FFF' },
    { backgroundColor: '#00695C', textColor: '#FFF' },
    { backgroundColor: '#1B5E20', textColor: '#FFF' },
    { backgroundColor: '#388E3C', textColor: '#FFF' },
    { backgroundColor: '#7CB342', textColor: '#FFF' },
    { backgroundColor: '#AFB42B', textColor: '#FFF' },
    { backgroundColor: '#F9A825', textColor: '#FFF' },
    { backgroundColor: '#FDD835', textColor: '#FFF' },
    { backgroundColor: '#EF6C00', textColor: '#FFF' },
    { backgroundColor: '#F4511E', textColor: '#FFF' },
    { backgroundColor: '#6D4C41', textColor: '#FFF' },
    { backgroundColor: '#455A64', textColor: '#FFF' },
  ]

  return randomColors[Math.floor(Math.random() * randomColors.length)]
}
