import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { OperatorsDataValidationLinkRequest, OperatorsDataValidationLinkResponse } from './dto/retailers-onboarding.dto'

export default class RetailersOnboardingService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getOperatorsDataValidationLink(params: OperatorsDataValidationLinkRequest): Promise<OperatorsDataValidationLinkResponse> {
    const response = await this.axiosInstance.get<OperatorsDataValidationLinkResponse>(`/retailers-onboarding/operators/data-validation-link`, { params })
    return response.data
  }
}
