import * as React from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import { NumberFormatValues } from 'react-number-format';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import FeatureFlags from '../../../common/assets/utils/featureFlags';
import withErrors from '../../../hoc/budget/withErrors';
import withForm, { Form, FormProps } from '../../../hoc/withForm';
import NumberField from '../../../molecules/Application/NumberField';
import { Loading } from '../../../molecules/Budget';
import { CancelButton } from '../../../molecules/Budget/CancelButton';
import { FooterButtons } from '../../../molecules/Budget/FooterButtons';
import { goToPage } from '../../../molecules/Budget/utils';
import BudgetReducer from '../../../redux/reducers/budget/budget.reducer';
import { useTypedSelector } from '../../../redux/reducers/selectors';
import { cpfSchema, minValueSchema } from '../../../schemas/defaultSchemas';
import { BUDGET_PAGES } from './budget-utils';

const BudgetRequest = ({
  inputChange,
  fields,
  valid,
  errors,
  onSubmit,
  setFormErrors,
  setValues
}: Form): JSX.Element => {
  const dispatch = useDispatch();

  const CPF_STEP = 0;
  const VALUE_STEP = 1;
  const [activeIndex, setActiveIndex] = React.useState<number>(CPF_STEP);

  const { isLoadingBudget, initialCpf, cpf, value } = useTypedSelector(state => state.budget)

  React.useEffect(() => {
    if (initialCpf) {
      setValues({ cpf: { value: initialCpf, formattedValue: initialCpf, floatValue: Number(initialCpf) } })
    }

    if (cpf && value) {
      setValues({ cpf, budgetValue: value });
    }
  }, [cpf, value]);

  const handleNext = () => {
    if (activeIndex === CPF_STEP && !errors.cpf) {
      setActiveIndex(VALUE_STEP);
    } else if (activeIndex === VALUE_STEP && valid) {
      dispatch(BudgetReducer.actions.createBudget({ cpf: fields.cpf, value: fields.budgetValue }));
    }
  };

  const handleBack = () => {
    if (activeIndex === CPF_STEP) {
      goToPage(dispatch, BUDGET_PAGES.ONBOARDING_PAGE)();
    } else if (activeIndex === VALUE_STEP) {
      setActiveIndex(CPF_STEP);
    }
  };

  const handleInputChange = (field: 'cpf' | 'budgetValue') => (value: NumberFormatValues) => {
    inputChange({ field, value });
  };

  const setNewIndex = async (newIndex: number): Promise<void> => {
    if (newIndex > activeIndex) {
      const res = await onSubmit(fields);

      if (activeIndex === CPF_STEP && !res.errors?.cpf) {
        setFormErrors({});
        setActiveIndex(newIndex);
      }
    } else {
      setActiveIndex(newIndex);
    }
  };

  const handleEnterPressed = (newIndex: number) => async (): Promise<void> => {
    setNewIndex(newIndex);
  };

  const isNextButtonDisabled = (activeIndex === CPF_STEP && (errors.cpf || !fields.cpf?.formattedValue)) ||
    activeIndex === VALUE_STEP && !valid;

    return (
    <>
      <FormAndTopWrapper>
        <CancelButton dispatch={dispatch} />

        <Carousel activeIndex={activeIndex}>
          <Carousel.Item>
            <FormTitle>
              Preencha o CPF
            </FormTitle>

            <BudgetField
              label='CPF'
              value={fields.cpf?.formattedValue}
              placeholder='000.000.000-00'
              format='###.###.###-##'
              onValueChange={handleInputChange('cpf')}
              onEnterPressed={handleEnterPressed(VALUE_STEP)}
              error={!!errors.cpf}
              helperText={errors.cpf ? errors.cpf[0] : ''}
            />
          </Carousel.Item>
          <Carousel.Item>
            <FormTitle>
            {FeatureFlags.isBudgetBlackCampaignActive() ? "Digite o valor do limite desejado" : "Digite o valor da compra"}
            </FormTitle>

            <BudgetField
              label= {FeatureFlags.isBudgetBlackCampaignActive() ? "Valor do limite" : "Valor da compra"}
              value={fields.budgetValue?.formattedValue}
              placeholder='R$ 2.000,00'
              prefix='R$ '
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              onValueChange={handleInputChange('budgetValue')}
              onEnterPressed={handleNext}
              error={!!errors.budgetValue}
              helperText={errors.budgetValue ? errors.budgetValue[0] : ''}
            />
          </Carousel.Item>
        </Carousel>
      </FormAndTopWrapper>

      <FooterButtons
        nextButtonDisabled={isNextButtonDisabled}
        onNextButtonClick={handleNext}
        onBackButtonClick={handleBack}
      />

      {isLoadingBudget && <Loading />}
    </>
  );
};

const Carousel = styled(BootstrapCarousel).attrs({
  interval: null,
  controls: false,
  slide: false
})`
  flex-grow: 1;
  justify-content: space-between;
`;

const BudgetField = styled(NumberField).attrs({
  type: 'tel',
  mask: '_',
  style: { width: '100%' },
  autoFocus: true
})``;

const FormTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

const FormAndTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 200px;
`;

const formProps: FormProps = {
  isValidationSync: true,
  schema: yup.object().shape({
    cpf: cpfSchema,
    budgetValue: minValueSchema(10, 'budgetValue', 'Digite um valor maior que R$ 10,00')
  }),
  initialValues: {
    cpf: { formattedValue: '', floatValue: undefined },
    budgetValue: { formattedValue: '', floatValue: undefined },
  }
};

 export default withForm(withErrors(BudgetRequest as React.ComponentType<object>))(formProps);
