import { IOriginationRecurrencePurchaseResponse } from '@scudraservicos/coordinator-client/dist/src/services/transactions/interfaces/origination-recurrence-purchase'
import { Box } from 'grommet'
import React, { PureComponent } from 'react'
import { numberToCurrency } from '../../../../../common/NumberToCurrency'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  CardTitleHeadingStyle,
  GreenCurrencyHeadingStyle,
  SmallCardStyle,
  SmallGreenValueHeadingStyle,
  SmallNumberQuantityHeadingStyle,
  TextStyle,
} from './style'

interface IRecurrencePurchaseCardProps {
  originationsRecurrencePurchase?: IOriginationRecurrencePurchaseResponse | Error
}

export default class RecurrencePurchaseCard extends PureComponent<IRecurrencePurchaseCardProps> {
  render() {
    const { originationsRecurrencePurchase } = this.props

    const recurrencePurchaseCardBox =
      originationsRecurrencePurchase instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        originationsRecurrencePurchase && (
          <>
            <Box className="desktop" fill justify="between">
              <CardTitleHeadingStyle>Recorrência</CardTitleHeadingStyle>
              <Box>
                <SmallNumberQuantityHeadingStyle>
                  {originationsRecurrencePurchase.recurrenceCount > 9
                    ? originationsRecurrencePurchase.recurrenceCount
                    : '0' + originationsRecurrencePurchase.recurrenceCount}
                </SmallNumberQuantityHeadingStyle>
                <TextStyle>Clientes </TextStyle>
              </Box>
              <Box gap="xsmall">
                <Box direction="row" align="end" gap="xsmall">
                  <GreenCurrencyHeadingStyle>{'R$ '}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(originationsRecurrencePurchase.recurrenceSum)}
                  </SmallGreenValueHeadingStyle>
                </Box>
                <TextStyle>Total de créditos </TextStyle>
              </Box>
            </Box>

            <Box className="is-mobile" fill justify="between">
              <CardTitleHeadingStyle>Recorrência</CardTitleHeadingStyle>
              <Box>
                <SmallNumberQuantityHeadingStyle>
                  {originationsRecurrencePurchase.recurrenceCount > 9
                    ? originationsRecurrencePurchase.recurrenceCount
                    : '0' + originationsRecurrencePurchase.recurrenceCount}
                </SmallNumberQuantityHeadingStyle>
                <TextStyle>Clientes </TextStyle>
              </Box>
              <Box gap="xsmall">
                <Box direction="row" align="end" gap="2px">
                  <GreenCurrencyHeadingStyle>{'R$'}</GreenCurrencyHeadingStyle>
                  <SmallGreenValueHeadingStyle>
                    {numberToCurrency(originationsRecurrencePurchase.recurrenceSum)}
                  </SmallGreenValueHeadingStyle>
                </Box>
                <TextStyle>Total de créditos </TextStyle>
              </Box>
            </Box>
          </>
        )
      )
    return (
      <SmallCardStyle>
        {originationsRecurrencePurchase ? (
          recurrencePurchaseCardBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </SmallCardStyle>
    )
  }
}
