import React from 'react'
import { green, red, white } from '../../legacy-lib/design-kit-ui/utils/_colors'
import { ContractStatus } from './resources/originations-report-resources'
import { getContractStatus } from './utils/originations-report-helpers'

export const ContractStatusTag = (props: { canceledOn?: string }) => {
  const status = getContractStatus(props.canceledOn)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%',
        minWidth: 'fit-content',
        backgroundColor: status === ContractStatus.CANCELED ? red.primary : green.primary,
        color: white.primary,
        textAlign: 'center',
        borderRadius: '0.5rem',
        boxSizing: 'border-box',
      }}
    >
      {status}
    </div>
  )
}
