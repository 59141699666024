import axios, { AxiosInstance } from 'axios'
import { useMutation } from 'react-query'
import { getAxiosInstance } from '../utils'
import { CreateTransactionalCreditEvaluationReq, CreateTransactionalCreditEvaluationResponse, TransactionalCreditEvaluation } from './dtos/transactional-credit-evaluation.dto'
import { TransactionalCreditEvaluationResult } from './enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from './enums/transactional-credit-evaluation-status.enum'

export default class TransactionalCreditService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getAvailableEvaluation(
    cpf: string,
    storeId: string,
    retailerId: string,
    status: TransactionalCreditEvaluationStatus,
    results: TransactionalCreditEvaluationResult[]
  ): Promise<TransactionalCreditEvaluation | undefined> {
    const response = await this.axiosInstance.get<TransactionalCreditEvaluation>(`/tx-credit/evaluations`, {
      params: {
        document: cpf,
        storeId,
        storeIdShouldIncludeNull: true,
        retailerId,
        retailerIdShouldIncludeNull: true,
        status,
        results
      },
    })

    return response.data
  }

  async createEvaluation(body: CreateTransactionalCreditEvaluationReq): Promise<CreateTransactionalCreditEvaluationResponse> {
    const response = await this.axiosInstance.post<CreateTransactionalCreditEvaluationResponse>(`/tx-credit/evaluations`, body)
    return response.data
  }

  useCreateTransactionalCreditEvaluation = () => {
      return useMutation<CreateTransactionalCreditEvaluationResponse, Error, CreateTransactionalCreditEvaluationReq>({
          retry: false,
          mutationKey: ['createTxCreditEvaluation'],
          mutationFn: (payload: CreateTransactionalCreditEvaluationReq) => {
          return this.axiosInstance
              .post(`/tx-credit/evaluations`, payload)
              .then((res) => res.data)
              .catch((_error) => {
                  throw new Error(_error?.message ?? 'UNEXPECTED_ERROR')
              })
          },
      })
  }
}
