import moment from 'moment'
import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { toaster } from '../../../App'

interface Props {
  onPhoto: (file: File) => void
  manualUpload: boolean
}

const OpenNativeCamera = ({ onPhoto, manualUpload }: Props) => {
  const handleChange = async () => {
    const cameraFileInput = document.getElementById('cameraFileInput') as HTMLInputElement
    const pictureFromCamera = document.getElementById('pictureFromCamera') as HTMLImageElement
    if (!cameraFileInput.files?.[0]) {
      return toaster.showErrorToast(`Erro em carregar a foto. Tente novamente.`)
    }
    if (!manualUpload)
      if (moment(cameraFileInput?.files![0]?.lastModified) < moment().add(-30, 'seconds')) {
        return toaster.showErrorToast(`Não é permitido fazer upload de fotos.`)
      }

    pictureFromCamera.setAttribute('src', window.URL.createObjectURL(cameraFileInput.files![0]))
    onPhoto(cameraFileInput.files![0])
  }

  useLayoutEffect(() => {
    const cameraFileInput = document.getElementById('cameraFileInput') as HTMLInputElement
    cameraFileInput.addEventListener('change', handleChange)
    cameraFileInput.click()
    return () => {
      cameraFileInput.removeEventListener('change', handleChange)
    }
  }, [])

  return (
    <>
      <CameraFileInput id="cameraFileInput" type="file" accept="image/*" capture="user" />
      <TakenImg id="pictureFromCamera" />
    </>
  )
}

export default OpenNativeCamera

const TakenImg = styled.img`
  width: 100%;
  height: auto;
  margin-top: 16px;
`
const CameraFileInput = styled.input`
  display: none;
`
