import React from 'react'
import styled from 'styled-components'
import { history } from '../../App'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { black, gray, green, white } from '../../ui/utils/_colors'

interface ApplicationWithSmartphoneSaleProps {
  cpf?: string
}

const ApplicationWithSmartphoneSale: React.FC<ApplicationWithSmartphoneSaleProps> = (props: ApplicationWithSmartphoneSaleProps) => {
  const { cpf } = props

  const handleRedirect = () => {
    return history.push({
      pathname: PagesPathEnum.PURCHASE,
      state: { cpf },
    })
  }

  return (
    <Container>
      <HeaderContainer>
        <Title>PARABÉNS!</Title>
        <SubTitle>Você já pode aproveitar seu crédito para comprar o celular da sua preferência!</SubTitle>
      </HeaderContainer>

      <ConfirmButton onClick={handleRedirect} backgroundColor={white.primary} textColor={green.dark1}>
        Ir para o caixa
      </ConfirmButton>
    </Container>
  )
}

export default ApplicationWithSmartphoneSale

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 24px;
  justify-content: space-between;

  height: calc(100% - 56px);

  // Desktop
  max-width: 400px;
  margin: auto;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  letter-spacing: 0.18px;

  color: ${black.text2};
`

const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.1px;

  color: ${gray.light6};
`

const ConfirmButton = styled.button`
  margin-top: 1vh;
  background: ${(props: any) => (props && props.disabled ? green.light3 : green.dark1)};
  border-radius: 90px;
  border: 0px;
  padding: 12px;
  height: 48px;
  width: 100%;
  max-width: 328px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${white.primary};
`
