import React from 'react'
import styled from 'styled-components'
import { green, red } from '../../../ui/utils/_colors'

interface Props {
  handleReset: () => void
}

const ApplicationDeniedComponent: React.FC<Props> = props => {
  return (
    <>
      <MainContainer>
        <h1>
          <RedText>Infelizmente não podemos ceder crédito</RedText> a este cliente no momento :(
        </h1>
      </MainContainer>

      <Footer>
        <BackButton onClick={props.handleReset}>Voltar</BackButton>
      </Footer>
    </>
  )
}

export default ApplicationDeniedComponent

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

const RedText = styled.span`
  color: ${red.dark};
`
const BackButton = styled.button`
  margin-top: 2vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid ${green.dark1};
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${green.dark1};
`
