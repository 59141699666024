import { Box } from 'grommet'
import styled from 'styled-components'
import { Clone } from 'grommet-icons'
import { blue } from '../Palette'

const ClipboardContainer = styled(Box)`
  flex-direction: row;
  align-items: center;
`
const Copy = styled(Clone)`
  margin-left: 5px;
  stroke: ${props => props.color ? props.color : blue.navy};
  width: 2vh;
  padding: 1px;

  :hover{
    cursor: pointer;
  }
`
export { ClipboardContainer, Copy }
