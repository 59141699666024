import { RetailerBatchResponse, StoreBatchResponse } from "./Batch.dto";

export enum TRANSFER_STATUS {
    TRANSFERRED = 'TRANSFERRED',
    PENDING = 'PENDING',
}
export type ITransferStatus = 'TRANSFERRED' | 'PENDING'

export interface RetailerTransfersDto {
    batchId: string
    status: ITransferStatus
    transferTimestamp: string
    originationsStartDate: string
    originationsEndDate: string
    retailerBatch: RetailerBatchResponse
    storesBatch: StoreBatchResponse[]
}

export interface GetRetailerTransfersDto {
    data: RetailerTransfersDto[]
}