import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as ProfileIcon } from '../../common/assets/images/profile.svg'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'

import { IUserPayloadDto } from '../../services/bff/users/dto/users.payload.dto'

interface ILoggedUserInfoCardProps {
  containerStyle?: React.CSSProperties
}

const LoggedUserInfoCard: React.FC<ILoggedUserInfoCardProps> = ({ containerStyle }) => {
  const [currentUser, setCurrentUser] = useState<IUserPayloadDto>()

  useEffect(() => {
    const logged_user = LocalStorageWrapper.getItem(LocalStorageItem.LOGGED_USER) as IUserPayloadDto | undefined

    if (logged_user)
      setCurrentUser({
        ...logged_user,
        store: logged_user.store
          ? {
              id: logged_user?.store?.id,
              name: logged_user?.store?.name,
            }
          : null,
        retailer: logged_user.retailer
          ? {
              id: logged_user?.retailer?.id,
              fantasyName: logged_user?.retailer?.fantasyName,
            }
          : null,
      })
  }, [])

  return (
    <Container style={containerStyle}>
      <ProfileIcon />
      <Content>
        <Name>{currentUser?.user.name}</Name>
        <RetailerStore>
          {currentUser?.retailer && currentUser.store
            ? `${currentUser?.retailer?.fantasyName} | ${currentUser?.store?.name}`
            : 'Loja não configurada'}
        </RetailerStore>
      </Content>
    </Container>
  )
}

export default LoggedUserInfoCard

const Container = styled.div`
  width: 302px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const Content = styled.div`
  display: grid;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

const Name = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: start;

  color: #363636;
`

const RetailerStore = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: -0.5px;

  color: #363636;
`
