import { Anchor as GrommetAnchor } from 'grommet'
import styled from 'styled-components'
import { blue } from '../utils/_colors'

const StyledAnchor = styled(GrommetAnchor)`
  color: ${props => (props.color ? props.color : blue.navy)};
  font-family: Roboto;
  font-weight: ${props => (props.bold ? '500' : 'normal')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  letter-spacing: 0;
  line-height: 16px;

  &:nth-child(n) {
    box-shadow: none;
  }

  &:hover {
    color: ${props => (props.color ? props.color : blue.navy)};
  }

  > div {
    > svg {
      fill: ${props => (props.color ? props.color : blue.navy)};
    }
  }
`

export { StyledAnchor }
