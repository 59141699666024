import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import CouponImg from '../../common/assets/images/coupon-img.jpeg'
import { DateFormat, formatDate, TimeZone } from '../../common/FormatDate'
import { CouponDto } from '../../services/bff/coupons/dto/Coupons.dto'
import { black, gray, green } from '../../ui/utils/_colors'

export interface CouponDetailsProps {
  coupon: CouponDto
  showStoreAlert: boolean
}

const CouponDetails = (props: CouponDetailsProps) => {
  const { coupon, showStoreAlert } = props

  const couponDiscountValue = coupon.percentValue ? `${(coupon.percentValue * 100).toFixed(0)}%` : formatBRL(coupon.monetaryValue!)

  const couponValidUntilDate = formatDate(coupon?.validUntil!, DateFormat.DAY_MONTH_BR_DATE_FORMAT, TimeZone.SAO_PAULO)

  const couponValidity = coupon.validityInDays === 0 ? `hoje às 23:59` : `em ${couponValidUntilDate}`

  return (
    <MainContainer>
      <Card>
        <CouponContainer>
          <Title>{couponDiscountValue} de desconto</Title>
          <Text>Compras a partir de {formatBRL(coupon.minimumPurchaseValue)}.</Text>
          {coupon.maximumDiscountValue && <Text>Valor máximo aplicado de {formatBRL(coupon.maximumDiscountValue)}.</Text>}
          <Text>Expira {couponValidity}.</Text>
        </CouponContainer>
        <CouponImage src={CouponImg} />
      </Card>
    </MainContainer>
  )
}

export default CouponDetails

const MainContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 24px;
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  height: 120px;
  border-radius: 8px;
  align-items: start;
  justify-content: space-between;
  background: ${green.tea};
`

const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 16px 2px 16px 16px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: ${black.primary};
  line-height: 20px;
  padding-bottom: 4px;
`

const Text = styled.text`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: ${gray.scale800};
  line-height: normal;
`

const CouponImage = styled.img`
  width: 96px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`
