export const formItemsContainerStyle = {
  padding: '2.5em',
  display: 'flex',
  flexDirection: 'column' as any,
  width: '60vw',
}

export const ssnDialogStyle = {
  padding: '8px',
  display: 'flex',
  flexDirection: 'column' as any,
  scrollBehavior: 'smooth' as any,
  width: '100%',
}

export const formItemStyle = {
  marginTop: '32px',
  alignSelf: 'flex-start',
}

export const formActionsStyle = {
  display: 'flex',
  flexDirection: 'row-reverse' as any,
}

export const textInputGroupWithLabelStyle = {
  display: 'flex',
  flexDirection: 'column' as any,
  justifyContent: 'flex-end',
}
export const textInputGroupStyle = {
  display: 'flex',
  alignItems: 'flex-end',
}

export const centeredButtonGroup = {
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '16px',
  marginTop: '32px',
}

export const cellPhoneFormGroupStyle = {
  display: 'flex',
  marginTop: '32px',
  alignItems: 'flex-end',
}

export const buttonStyle = {
  width: '10em',
}

export const h5Style = {
  alignSelf: 'flex-start',
  marginLeft: '32px',
  marginTop: 0,
  marginBottom: '1.5em',
}

export const formContainerStyle = {
  display: 'flex',
  marginTop: '7em',
  justifyContent: 'center',
  alignItems: 'baseline',
  height: '100vh',
  widht: '100vw',
}
