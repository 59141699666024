import { Button } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'

export const DownloadSpecificContractsButton = (props: any) => {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingButton
      loading={loading}
      type="primary"
      onClick={async () => {
        setLoading(true)
        await props.onDownloadContracts(props.c)
        setLoading(false)
      }}
    >
      Baixar contratos
    </LoadingButton>
  )
}

const LoadingButton = styled(Button)`
  position: inherit !important;

  background-color: ${UMEColors.blue.dark};
  color: white;
  width: 150px;

  border-radius: 10px !important;

  z-index: 0;

  &:hover {
    opacity: 0.5;
  }
`
