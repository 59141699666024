import { Select } from 'grommet'
import styled from 'styled-components'
import { gray } from '../utils/_colors'

const StyledDropdown = styled(Select)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.61px;
  line-height: 16px;
  background-color: ${gray.ivory};

  > * {
    border: none;
  }

  &:nth-child(n) {
    box-shadow: none;
  }
`

const Container = styled.div`
  background-color: ${gray.ivory};
  border-radius: 8px;
`

export { Container, StyledDropdown }
