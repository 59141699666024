import { Button, Card, Colors, Elevation, H4, H5, Icon, Spinner } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import styled from 'styled-components'
import { getContractAcceptedProposal } from '../../common/ContractHelper'
import SafeListService from '../../common/assets/utils/SafeListService'
import { resolveCcbDownloadUrl, resolvePurchaseReceiptRoute } from '../../pages/Receipts/Utils'
import InvoicesSliceReducer from '../../redux/reducers/invoice/invoices.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { IContract, SourceProduct } from '../../services/bff/coordinator/dto/Contracts.dto'
import { Loading } from '../../ui'
import { green, white } from '../../ui/utils/_colors'
import { formContainerStyle, formItemsContainerStyle } from '../styles'

const containerStyle = (backgroundColor: string) => {
  const styles = {
    ...formItemsContainerStyle,
    width: '60vw',
    height: '55vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: backgroundColor,
    padding: '30px',
  }
  if (window.matchMedia('(max-width: 768px)').matches) {
    styles.height = '80vh'
    styles.padding = '20px'
  }
  return styles
}

interface IApprovedPurchaseParams {
  id: string
}

const getCcbUrl = (contract?: IContract): string | undefined => {
  if (!contract) return 

  const acceptedProposal = getContractAcceptedProposal(contract)
  if (!acceptedProposal?.partnerProposalId) return

  return resolveCcbDownloadUrl(acceptedProposal.partnerProposalId)
}

const ApprovedPurchase: React.FC = () => {
  const params = useParams<IApprovedPurchaseParams>()
  
  const dispatch = useDispatch()
  const [contract, setContract] = useState<IContract | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [redirectToCashierHome, setRedirectToCashierHome] = useState(false)
  const { isCreatingBorrowerNextInvoice } = useTypedSelector(state => ({
    isCreatingBorrowerNextInvoice: state.invoices.isCreatingBorrowerNextInvoice,
  }))

  const fetchContract = async () => {
    if (!params.id ) return

    const contract = await bffParceirosApiService.coordinator.fetchContract(params.id)
    setIsLoading(false)
    setContract(contract)
  }

  const setupRedirect = () => {
    setRedirectToCashierHome(true)
  }

  const createInvoice = (borrowerId: string) => {
    dispatch(InvoicesSliceReducer.actions.createBorrowerNextInvoice(borrowerId))
  }

  const exportCcb = (ccbUrl: string) => {
    window.open(ccbUrl, '_blank')
  }

  const openSmartphoneSaleTerm = () => {
    window.open("https://storage.googleapis.com/prd-static-files-api/85bfbf7cadcafcd57e530820db123e8f.pdf", '_blank')
  }

  const renderContainer = (content: React.ReactNode) => {
    return (
      <div style={formContainerStyle}>
        {content}
      </div>
    )
  }

  const renderGoBackButton = () => {
    return (<CustomButton
      large
      style={{
        marginTop: '32px',
        backgroundColor: Colors.LIGHT_GRAY4,
        backgroundImage: 'none',
      }}
      autoFocus
      icon="arrow-left"
      onClick={setupRedirect}
      tabIndex={1}
    >
      <H5 style={{ color: Colors.GRAY1, marginBottom: 0 }}>Voltar à página inicial de Caixa</H5>
    </CustomButton>)
  }

  const renderLoading = renderContainer(
    <Card style={containerStyle(white.primary)} interactive={false} elevation={Elevation.TWO}>
      <div style={{ padding: '32px' }}>
        <Spinner size={75} className='text-white' />
      </div>
      <H4>Buscando contrato...</H4>
    </Card>
  )

  const renderContractNotFound = renderContainer(
    <Card style={containerStyle(green.background3)} interactive={false} elevation={Elevation.TWO}>
      <div style={{ padding: '32px' }}>
        <Icon style={{ color: white.primary }} iconSize={75} icon={'help'} />
      </div>
      <H4 style={{ color: white.primary }}>Contrato não encontrado...</H4>
      {renderGoBackButton()}
    </Card>
  )

  useEffect(() => {
    fetchContract()
  }, [])

  if (redirectToCashierHome) {
    return <Redirect to="/caixa" />
  }
  if (isLoading) return renderLoading
  if (!contract) return renderContractNotFound

  const store = bffParceirosApiService.coordinator.getStore()
  const sourceProduct = contract.sourceProduct
  const borrowerId = contract.borrowerId

  const contractReceiptUri = resolvePurchaseReceiptRoute(params.id)
  const ccbUrl = getCcbUrl(contract)
  const isAllowedToExportCcb = store?.retailerId ? SafeListService.shouldAllowExportCcbOnPurchase(store?.retailerId) : false

  return renderContainer(
    <Card style={containerStyle(green.background3)} interactive={false} elevation={Elevation.TWO}>
      <div style={{ padding: '32px' }}>
        <Icon style={{ color: white.primary }} iconSize={75} icon={'tick'} />
      </div>
      <H4 style={{ color: white.primary, fontFamily: 'Inter' }}>
        Compra Finalizada! Se o comprovante não abriu automaticamente,{' '}
        <a
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: white.primary }}
          href={contractReceiptUri}
          target="_blank"
          tabIndex={1}
        >
          CLIQUE AQUI
        </a>
        .
      </H4>
      {isAllowedToExportCcb && ccbUrl && (
        <CustomButton
          large
          style={{
            marginTop: '32px',
            backgroundColor: Colors.LIGHT_GRAY4,
            backgroundImage: 'none',
          }}
          autoFocus
          icon="document"
          onClick={() => exportCcb(ccbUrl)}
          tabIndex={2}
        >
          <H5 style={{ color: Colors.GRAY1, marginBottom: 0, fontFamily: 'Inter' }}>Imprimir CCB</H5>
        </CustomButton>
      )}
      {store?.settings?.enableToGenerateBorrowerInvoice && borrowerId && (
        <CustomButton
          large
          style={{
            marginTop: '32px',
            backgroundColor: Colors.LIGHT_GRAY4,
            backgroundImage: 'none',
          }}
          autoFocus
          icon="barcode"
          onClick={() => createInvoice(borrowerId)}
          tabIndex={1}
          disabled={isCreatingBorrowerNextInvoice}
        >
          {isCreatingBorrowerNextInvoice ? (
            <Loading color="#5C7080" height={20} width={20} />
          ) : (
            <H5 style={{ color: Colors.GRAY1, marginBottom: 0, fontFamily: 'Inter' }}>Imprimir próximo boleto</H5>
          )}
        </CustomButton>
      )}
      {sourceProduct == SourceProduct.SMARTPHONE_SALE && (
        <CustomButton
          large
          style={{
            marginTop: '32px',
            backgroundColor: Colors.LIGHT_GRAY4,
            backgroundImage: 'none',
          }}
          autoFocus
          onClick={() => openSmartphoneSaleTerm()}
          icon="document"
          tabIndex={1}
          disabled={false}
        >
          <H5 style={{ color: Colors.GRAY1, marginBottom: 0, fontFamily: 'Inter' }}>Imprimir termo de ciência</H5>
        </CustomButton>
      )}
      {renderGoBackButton()}
    </Card>
  )
}

export default ApprovedPurchase

const CustomButton = styled(Button)`
  width: 40%;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 70%;
  }
`
