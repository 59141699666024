import { PRE_APPLICATION_STATUS } from "../redux/reducers/application/application.types";

const completedApplicationStatusesMap = new Set([
    PRE_APPLICATION_STATUS.APPROVED,
    PRE_APPLICATION_STATUS.DENIED,
    PRE_APPLICATION_STATUS.UNDER_ANALYSIS,
    PRE_APPLICATION_STATUS.WAITING_MANUAL_APPROVAL,
    PRE_APPLICATION_STATUS.PRE_APPROVED,
])

export const isCompletedApplication = (preApplicationStatus: PRE_APPLICATION_STATUS) => {
    return completedApplicationStatusesMap.has(preApplicationStatus)
}