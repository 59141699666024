import { Intent, Tag } from '@blueprintjs/core'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { Text } from '../../ui'
import { green, white } from '../../ui/utils/_colors'
import { formatBRL } from '../common/formatBRL'
import { getPayableAdditions, getPayableDiscounts } from '../common/installment-computed-values'
import { RoundCheckBox } from '../common/RoundCheckBox'
import { TableRowOutlineWhenSelected } from '../common/TableOutlineWhenSelected'

export interface InstallmentUI {
  id: string
  contractId: string
  installmentOrder: number
  dueDate: string
  installmentValue: number
  earlyPaymentDiscount: number
  monetaryCorrection: number
  defaultInterest: number
  defaultFine: number
  status: string
  isRenegotiation: boolean
  paymentDue: number
  couponDiscount: number
  isFromRenegotiation: boolean
}

interface Props {
  isSelected: boolean
  i: InstallmentUI
  onInstallmentSelected: () => void
}

const installmentStatus = (key: 'PENDING' | 'PAYMENT_OVERDUE' | 'PAID') =>
  ({
    PENDING: 'A vencer',
    PAYMENT_OVERDUE: 'Em atraso',
    PAID: 'Pago',
  }[key])

const installmentStatusToTagIntent = (key: 'PENDING' | 'PAYMENT_OVERDUE' | 'PAID') =>
  ({
    PENDING: Intent.PRIMARY,
    PAYMENT_OVERDUE: Intent.DANGER,
    PAID: Intent.SUCCESS,
  }[key])

export const InstallmentRow = ({ isSelected, i, onInstallmentSelected }: Props) => {
  return (
    <TableRowOutlineWhenSelected isSelected={isSelected} key={i.id} onClick={onInstallmentSelected}>
      <td>{i.contractId}</td>
      <td>{i.installmentOrder}</td>
      <td>
        <Text color={isSelected ? white.primary : green.dark} style={{ fontWeight: 'bold' }}>
          {moment(i.dueDate).format('L')}
        </Text>
      </td>
      <td>{formatBRL(i.installmentValue)}</td>
      <td>
        {formatBRL(
          getPayableDiscounts({
            paymentDue: i.paymentDue,
            value: i.installmentValue,
          })
        )}
      </td>
      <td>
        {formatBRL(
          getPayableAdditions({
            paymentDue: i.paymentDue,
            value: i.installmentValue,
          })
        )}
      </td>
      <td>
        <Text color={isSelected ? white.primary : green.dark} style={{ fontWeight: 'bold' }}>
          {formatBRL(i.paymentDue)}
        </Text>
      </td>
      <td>
        <Tag large round intent={installmentStatusToTagIntent(i.status as any)}>
          {installmentStatus(i.status as any)}
        </Tag>
      </td>
      <td>
        <RoundCheckBox checked={isSelected} />
      </td>
    </TableRowOutlineWhenSelected>
  )
}
