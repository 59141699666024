import axios, { AxiosInstance } from 'axios'
import { keyCloackService } from '..'
import { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { getAxiosInstance } from '../utils'
import { ILoginProfileResponse, ILoginResponse, IOperatorLogin } from './dto/Login.dto'

export default class AuthService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async login(body: IOperatorLogin) {
    const response = await this.axiosInstance.post<ILoginResponse>('/auth/operators', body)
    const authToken = response.data.accessToken
    localStorage.setItem('id_token', authToken)
  }

  async decodeJwt(authorizationToken?: string): Promise<ILoginProfileResponse> {
    let response

    if (authorizationToken) {
      response = await this.axiosInstance.get<ILoginProfileResponse>(`/auth`, {
        headers: {
          authorization: `Bearer ${authorizationToken}`,
        },
      })
    } else {
      response = await this.axiosInstance.get<ILoginProfileResponse>(`/auth`)
    }

    this.setUser(response.data)
    return response.data
  }

  async exchangeTokens(authorizationToken: string): Promise<string> {
    const response = await this.axiosInstance.get<ILoginResponse>(`/auth/exchange-tokens`, {
      headers: {
        authorization: `Bearer ${authorizationToken}`,
      },
    })

    localStorage.setItem(LocalStorageItem.ID_TOKEN, response.data.accessToken)
    return response.data.accessToken
  }

  async logout() {
    localStorage.removeItem(LocalStorageItem.ID_TOKEN)
    localStorage.removeItem(LocalStorageItem.USER)
    localStorage.removeItem(LocalStorageItem.LOGGED_USER)
    localStorage.removeItem(LocalStorageItem.LOCAL_CASHIER_ID)
    localStorage.removeItem(LocalStorageItem.STORE)
    await keyCloackService.logout()
  }

  isAuthorized(authorizedRoles: string[]): boolean {
    const user = this.getUser()

    if (user && authorizedRoles.indexOf(user.role) !== -1) {
      return true
    } else {
      return false
    }
  }

  setUser(user: ILoginProfileResponse) {
    localStorage.setItem(LocalStorageItem.USER, JSON.stringify(user))
  }

  getToken(): string | null {
    return localStorage.getItem(LocalStorageItem.ID_TOKEN)
  }

  isLogged(): boolean {
    // Checks if there is a saved token
    const token = this.getToken()
    const user = this.getUser()
    return !!token && !!user
  }

  getUser(): ILoginProfileResponse | undefined {
    const user = localStorage.getItem(LocalStorageItem.USER)
    const userParsed = JSON.parse(user || '[]')
    if (userParsed.length === 0) {
      return undefined
    } else if (userParsed.exp < Date.now() / 1000) {
      return undefined
    } else {
      return userParsed
    }
  }
}
