import { PayloadAction } from '@reduxjs/toolkit'
import {
  DocumentImageResponse,
  ImageResponse,
  ProcessStatusResponse,
} from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.res'
import {
  DocumentLabelType,
  ProcessStatus,
} from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import { call, delay, fork, put, race, take, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'
import { selectState } from '../selectors'
import { DOCUMENT_BACK_OPTIONS, DOCUMENT_FRONT_OPTIONS } from './biometry.constants'
import BiometrySliceReducer, { IInsertDocumentPayload } from './biometry.reducer'

function* insertDocument(action: PayloadAction<IInsertDocumentPayload>) {
  // TODO - What if this throws an error?
  let imageBase64 = action.payload.imageBase64
  let selectedDocumentType = action.payload.documentType
  const selectedDocumentIndex: number = yield selectState(state => state.biometry.selectedDocumentIndex)
  const borrowerId: string = yield selectState(state => state.application.borrowerId)
  if (imageBase64.startsWith('data:image')) {
    imageBase64 = imageBase64.split(',')[1]
  }

  // TODO - Get correctly from coordinator-client
  let documentType: DocumentLabelType
  if (selectedDocumentType === 'DOCUMENT_FRONT') {
    documentType = DOCUMENT_FRONT_OPTIONS[selectedDocumentIndex].type as DocumentLabelType
  } else {
    documentType = DOCUMENT_BACK_OPTIONS[selectedDocumentIndex].type as DocumentLabelType
  }

  try {
    yield put(BiometrySliceReducer.actions.fetchDocumentLoading())

    yield bffParceirosApiService.datalakeAcesso.insertDocumentSide(borrowerId, documentType, { imageBase64 })

    yield put(BiometrySliceReducer.actions.insertDocumentSuccess())
    yield put(BiometrySliceReducer.actions.fetchDocument())
  } catch (error) {
    yield put(
      BiometrySliceReducer.actions.insertDocumentError({
        imageBase64: `data:image/jpeg;base64,${imageBase64}`,
        documentType,
        error,
      })
    )
  }
}

function* fetchDocument(action: PayloadAction<undefined>) {
  try {
    const borrowerId: string = yield selectState(state => state.application.borrowerId)

    yield put(BiometrySliceReducer.actions.fetchDocumentLoading())

    // TODO - Losing type due to generator
    const response: DocumentImageResponse = yield bffParceirosApiService.datalakeAcesso.getDocumentImages(borrowerId)

    yield put(
      BiometrySliceReducer.actions.fetchDocumentSuccess({
        missingSide: response.missingSide,
        images: response.images.map(image => ({
          documentImageBase64: `data:image/jpeg;base64,${image.imagebase64}`,
          documentType: image.label as DocumentLabelType,
        })),
      })
    )
  } catch (error) {
    yield put(BiometrySliceReducer.actions.fetchDocumentError(error))
  }
}

function* fetchBiometryStatus(action: PayloadAction<undefined>) {
  try {
    const { borrowerId, submitBiometry } = yield selectState(state => ({
      borrowerId: state.application.borrowerId,
      submitBiometry: state.configurations.configurations.application.submitBiometry,
    }))

    yield put(BiometrySliceReducer.actions.fetchFaceLoading())
    yield put(BiometrySliceReducer.actions.fetchBiometryStatusLoading())

    if (submitBiometry) {
      const biometryProccess: ProcessStatusResponse = yield bffParceirosApiService.datalakeAcesso.getBiometryStatus(
        borrowerId
      )

      if (biometryProccess.status === ProcessStatus.IN_PROGRESS) {
        yield put(BiometrySliceReducer.actions.startBiometryStatusPooling())
      } else if (biometryProccess.status !== ProcessStatus.SPOOF) {
        yield put(BiometrySliceReducer.actions.fetchFace())
        yield put(BiometrySliceReducer.actions.stopBiometryStatusPooling(biometryProccess.status))
      } else {
        yield put(BiometrySliceReducer.actions.fetchFaceStopLoading())
        yield put(BiometrySliceReducer.actions.stopBiometryStatusPooling(biometryProccess.status))
      }
    } else {
      yield put(BiometrySliceReducer.actions.fetchFace())
    }
  } catch (error) {
    yield put(BiometrySliceReducer.actions.fetchBiometryStatusError(error))
  }
}

function* fetchFace(action: PayloadAction<undefined>) {
  try {
    const borrowerId: string = yield selectState(state => state.application.borrowerId)

    yield put(BiometrySliceReducer.actions.fetchFaceLoading())
    yield put(BiometrySliceReducer.actions.fetchBiometryStatusLoading())

    const response: ImageResponse = yield bffParceirosApiService.datalakeAcesso.getFaceImage(borrowerId)

    // TODO - Typo on API
    yield put(BiometrySliceReducer.actions.fetchFaceSuccess(response.imagebase64))
  } catch (error) {
    yield put(BiometrySliceReducer.actions.fetchFaceError(error))
  }
}

function* createBiometryProccess(action: PayloadAction<string>) {
  const { borrowerId, submitBiometry } = yield selectState(state => ({
    borrowerId: state.application.borrowerId,
    submitBiometry: state.configurations.configurations.application.submitBiometry,
  }))
  let imageBase64 = action.payload
  if (imageBase64.startsWith('data:image')) {
    imageBase64 = imageBase64.split(',')[1]
  }

  try {
    yield put(BiometrySliceReducer.actions.fetchFaceLoading())
    yield put(BiometrySliceReducer.actions.fetchBiometryStatusLoading())

    yield bffParceirosApiService.datalakeAcesso.executeBiometry(borrowerId, { imageBase64, submitBiometry })

    yield put(BiometrySliceReducer.actions.createBiometryProccessSuccess(`data:image/jpeg;base64,${imageBase64}`))
    if (submitBiometry) {
      yield put(BiometrySliceReducer.actions.startBiometryStatusPooling())
    } else {
      yield put(BiometrySliceReducer.actions.postponeBiometryStatusResult())
    }
  } catch (error) {
    yield put(
      BiometrySliceReducer.actions.createBiometryProccessError({
        imageBase64: `data:image/jpeg;base64,${imageBase64}`,
        submitBiometry,
        error,
      })
    )
  }
}

function* poolBiometryStatus() {
  while (true) {
    try {
      const borrowerId: string = yield selectState(state => state.application.borrowerId)

      const biometryProccess: ProcessStatusResponse = yield bffParceirosApiService.datalakeAcesso.getBiometryStatus(
        borrowerId
      )

      if (biometryProccess.status !== ProcessStatus.IN_PROGRESS) {
        yield put(BiometrySliceReducer.actions.stopBiometryStatusPooling(biometryProccess.status))
      }

      yield delay(1000)
    } catch (error) {
      yield put(BiometrySliceReducer.actions.fetchBiometryStatusError(error))
    }
  }
}

function* poolBiometryStatusWatcher() {
  while (true) {
    yield take(BiometrySliceReducer.actions.startBiometryStatusPooling)
    yield race([call(poolBiometryStatus), take(BiometrySliceReducer.actions.stopBiometryStatusPooling)])
  }
}

const biometrySaga = [
  takeLatest(BiometrySliceReducer.actions.insertDocument, insertDocument),
  takeLatest(BiometrySliceReducer.actions.fetchDocument, fetchDocument),
  takeLatest(BiometrySliceReducer.actions.fetchBiometryStatus, fetchBiometryStatus),
  takeLatest(BiometrySliceReducer.actions.fetchFace, fetchFace),
  takeLatest(BiometrySliceReducer.actions.createBiometryProccess, createBiometryProccess),
  fork(poolBiometryStatusWatcher),
]

export default biometrySaga
