import { HeadingProps } from 'grommet'
import React, { Component } from 'react'
import { StyledHeading } from './styles'

export default class Heading extends Component<
  HeadingProps &
    (
      | Omit<JSX.IntrinsicElements['h1'], 'color'>
      | Omit<JSX.IntrinsicElements['h2'], 'color'>
      | Omit<JSX.IntrinsicElements['h3'], 'color'>
      | Omit<JSX.IntrinsicElements['h4'], 'color'>
      | Omit<JSX.IntrinsicElements['h5'], 'color'>
      | Omit<JSX.IntrinsicElements['h6'], 'color'>
    )
> {
  render() {
    return (
      <StyledHeading level={this.props.level ? this.props.level : '3'} {...this.props}>
        {this.props.children}
      </StyledHeading>
    )
  }
}
