import SafeListService from '../../common/assets/utils/SafeListService'
import { bffParceirosApiService } from '../../services/bff'
import { ApplicationStatus } from '../../services/bff/applicationEngine/dto/Application.dto'

export default class TransactionalCreditHelper {
  static REEVALUATION_INTERVAL_CHECK = 1000

  static async runPreChecks(document: string, storeId: string) {
    if (!SafeListService.shouldRunApplicationReavaluationStoresSafeList(storeId)) {
      return
    }

    const preApplication = await bffParceirosApiService.applicationEngine.preApplication({
      cpf: document,
      storeId,
    })

    const borrowerId = preApplication?.borrower?.id
    if (!borrowerId) {
      return
    }

    if (preApplication.shouldReevaluate) {
      await bffParceirosApiService.applicationEngine.handleReevaluation({ borrowerId, storeId })
    }

    if (preApplication.application?.status === ApplicationStatus.UNDER_ANALYSIS) {
      await bffParceirosApiService.applicationEngine.handleApplicationInProgress(borrowerId)
    }
  }
}
