import { Box, BoxProps } from 'grommet'
import React, { Component } from 'react'
import { Anchor } from '../Anchor'
import { white } from '../Palette'
import { StyledHeader } from './styles'
import { Menu } from 'grommet-icons'

interface HeaderProps {
  logo?: JSX.Element
  mobile?: boolean
  sideBar?: JSX.Element
  isSideBarOpen?: boolean
  openSideBar?: () => void
}

interface HeaderState {
  open: boolean
}

export default class Header extends Component<HeaderProps & BoxProps, HeaderState> {
  render() {
    return (
      <StyledHeader {...this.props}>
        {this.props.mobile ? (
          <>
            <Anchor
              margin="small"
              key="menu"
              color={white.primary}
              icon={<Menu />}
              onClick={this.props.openSideBar}
            />
            <Box margin="small">{this.props.logo}</Box>
            {this.props.children && <Box margin="small">{this.props.children}</Box>}
            {this.props.isSideBarOpen && this.props.sideBar}
          </>
        ) : (
          <>
            <Box margin={{ left: '22px' }}>{this.props.logo}</Box>
            <Box margin={{ right: '60px' }} direction="row" gap="medium">
              {this.props.children}
            </Box>
          </>
        )}
      </StyledHeader>
    )
  }
}
