import { Box, Text } from 'grommet'
import React from 'react'

export interface PropsRichTab {
  icon: any
  label: string
}

const RichTabTitle = (props: PropsRichTab) => (
  <Box direction="row" align="center" gap="xsmall" margin="xsmall">
    {props.icon}
    <Text size="small">
      <span>{props.label}</span>
    </Text>
  </Box>
)

export default RichTabTitle
