import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SpinnerIcon } from '../../common/assets/images/spinner.svg'

interface Props {
  clickable?: boolean
  loadingText?: string
}

export const Loading = (props: JSX.IntrinsicElements['p'] & Props) => {
  return (
    <LoadingContainer>
      <SpinnerWrapper>
        <SpinnerIcon />
      </SpinnerWrapper>
      <LoadingText>{props.loadingText || 'Carregando...'}</LoadingText>
    </LoadingContainer>
  )
}

const LoadingText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #10a343;

  margin: 5vw;
  text-align: center;
`

const SpinnerWrapper = styled.div`
  margin: 20px;
  width: 90px;
  height: 90px;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2147483647;
  flex-direction: column;
  display: flex;
`
