import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import analyticsService from '../../services/analytics/analytics.service'
import { DueDateRule, SourceProduct } from '../../services/bff/coordinator/dto/Contracts.dto'
import { black, green } from '../../ui/utils/_colors'

export interface PurchaseConditions {
  proposalId: string
  installments: number
  cet: number
  interestRate: number
  iof: number
  installmentValue: number
  installmentValueWithoutInterest?: number
  isFocused: boolean
  isSelected: boolean
  partnerProposalId: string
  dueDateRule?: DueDateRule
  customerFeeRate?: number
  firstInstallmentDueDate: string
  customerFeeValue?: number
}
interface Props {
  togglePurchaseConditionSelected: (purchaseConditionIdx: number) => void
  onKeyPressTableRow: (index: number, event: React.KeyboardEvent) => void
  purchaseConditions: PurchaseConditions[]
  sourceProduct?: string
}

export const PurchaseInstallmentsOptionsCard: React.FC<Props> = props => {
  const { togglePurchaseConditionSelected, onKeyPressTableRow, purchaseConditions, sourceProduct } = props

  const isBiweeklyDueDate = () => {
    return purchaseConditions.some(p => p.dueDateRule === 'BIWEEKLY')
  }

  const handlePurchaseConditionSelected = (
    idx: number,
    proposalId: string,
    selectedNumberOfInstallment: number,
    selectedInstallmentValue: number
  ) => {
    togglePurchaseConditionSelected(idx)

    analyticsService.sendEvent(
      `proposal_${proposalId}_${selectedNumberOfInstallment}x_R$${selectedInstallmentValue.toFixed(2)}`.substring(0, 40)
    )
  }

  return (
    <PurchaseInstallmentsOptionsContainer>
      <Title>{isBiweeklyDueDate() ? 'Parcelamento quinzenal' : 'Opções de parcelamento'} </Title>

      <InstallmentsOptions>
        <div style={{ padding: '25px' }}>
          {purchaseConditions.map((i, idx) => (
            <InstallmentsOptionsList
              key={idx}
              onClick={() => handlePurchaseConditionSelected(idx, i.proposalId, i.installments, i.installmentValue)}
              onKeyDown={(event: React.KeyboardEvent) => onKeyPressTableRow(idx, event)}
            >
              {sourceProduct === SourceProduct.INTEREST_PAID_BY_RETAILER && i.installmentValueWithoutInterest ? (
                <ListContainer>
                  <InputRadio
                    type="radio"
                    id="selectPurchaseConditions"
                    name="conditions"
                    checked={i.isSelected && i.isFocused}
                  />
                  <LineThroughValue>
                    {i.installments}x de {formatBRL(i.installmentValue)}
                  </LineThroughValue>
                  <ColumnView>
                    <DiscountValue>
                      {i.installments}x de {formatBRL(i.installmentValueWithoutInterest)}
                    </DiscountValue>
                    <DiscountDetailSubText>*Se pago até o vencimento</DiscountDetailSubText>
                  </ColumnView>
                </ListContainer>
              ) : (
                <ListContainer>
                  <InputRadio
                    type="radio"
                    id="selectPurchaseConditions"
                    name="conditions"
                    checked={i.isSelected && i.isFocused}
                  />
                  <Value>
                    {i.installments}x de {formatBRL(i.installmentValue)}
                  </Value>
                </ListContainer>
              )}
            </InstallmentsOptionsList>
          ))}
        </div>
      </InstallmentsOptions>
    </PurchaseInstallmentsOptionsContainer>
  )
}

export default PurchaseInstallmentsOptionsCard

const PurchaseInstallmentsOptionsContainer = styled.div`
  width: 90%;
  margin: 20px;
  border-radius: 20px;
  background-color: white;
`

const Title = styled.div`
  width: 100%;
  padding-top: 25px;
  font-size: 18px;
  font-weight: bold;
`

const InstallmentsOptions = styled.ul`
  padding: 0px;
`

const InstallmentsOptionsList = styled.li`
  width: 100%;
  padding: 4px;
  margin-bottom: 16px;
  list-style: none;
  outline: none;
  border: 1px solid #eff4f0;
  border-radius: 10px;
  cursor: pointer;
`

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right as right;
  padding: 0.2em 1em 0.2em 1em;
  font-size: 20px;
`

const InputRadio = styled.input`
  width: 18px;
  height: 18px;
  accent-color: green;
`

const Value = styled.div`
  font-weight: bold;
`

const LineThroughValue = styled.text`
  font-weight: normal;
  font-size: 19px;
  color: ${black.primary};
  text-decoration: line-through;
`

const ColumnView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DiscountValue = styled.text`
  font-weight: bold;
  color: ${green.primary};
`

const DiscountDetailSubText = styled.text`
  font-weight: normal;
  color: ${green.primary};
  font-size: 14px;
`
