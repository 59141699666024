import * as React from 'react'
import { useEffect, useRef } from 'react'

export const AutoScrollToView = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return <div ref={ref} />
}
