import axios, { AxiosInstance } from "axios";
import { Configurations } from "../../../redux/reducers/configurations/configurations.reducer";
import { getAxiosInstance } from "../utils";

export default class ConfigurationsService {
    private axiosInstance: AxiosInstance = axios.create()

    _init(baseURL: string, token: string | null): void {
        this.axiosInstance = getAxiosInstance({
            baseURL,
            token
        })
    }

    async fetchConfigurations(storeId: string, retailerId: string): Promise<Configurations> {
        const response = await this.axiosInstance.get<Configurations>(
            `/configurations`,  { params: { storeId, retailerId } }
        )
        return response.data
    }
}