import React from 'react';
import styled from 'styled-components';

export const Card = (props: JSX.IntrinsicElements['div']) => {
  return (
    <Container {...props}>{props.children}</Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  padding-top: 30px
  padding-bottom: 30px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;