import { ICompanySettings } from '../../coordinator/dto/CompanySettings.dto'

export enum StoreStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface StoreDto {
  id: string
  name: string
  zipcode: string
  retailerId: string
  address?: string
  googlePlaceId?: string
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
  deactivatedOn?: string
  status?: StoreStatus
  settings?: ICompanySettings
}

export interface GetStoresDto {
  stores: StoreDto[]
}

export enum BankAccountType {
  'POUPANCA' = '1',
  'CORRENTE' = '2',
}
