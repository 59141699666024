import React from 'react'
import styled from 'styled-components'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { green, white } from '../../../ui/utils/_colors'
import { simplifyProducts } from './ProductsHelper'
import { ProductsList } from './ProductsList'

interface Props {
  evaluationResult: TransactionalCreditEvaluation
  handleGoToApplication?: () => void
}

export const SmartphoneSaleApproved: React.FC<Props> = (props: Props) => {
  const { evaluationResult, handleGoToApplication } = props

  return (
    <MainContainer>
      <Title>Celulares disponíveis para esse cliente</Title>
      <Scroll>
        <ProductsList
          products={simplifyProducts(evaluationResult?.products)}
          canSelectItems={false}
          layout={'single-column'}
        ></ProductsList>
      </Scroll>
      <Footer>
        <ConfirmButton onClick={handleGoToApplication} backgroundColor={white.primary} textColor={green.dark1}>
          Cadastrar cliente
        </ConfirmButton>
      </Footer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100% - 56px);

  @media (max-width: 480px) {
    margin: 0px 8px 0px 8px;
    padding: 10px 8px 20px 8px;
  }
  justify-content: space-between;

  max-width: 400px;
  margin: auto;
`

const Title = styled.h1`
  font-family: Roboto;
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
`

const Scroll = styled.div`
  max-height: 100vh;
  overflow-y: auto; /* Adiciona o scroll interno */
  height: calc(100%);

  @media (min-width: 480px) {
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
      border: 3px solid #f1f1f1;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
`
const ConfirmButton = styled.button`
  margin-top: 1vh;
  background: ${(props: any) => (props && props.disabled ? green.light3 : green.dark1)};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 48px;
  width: 100%;
  max-width: 328px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${white.primary};
`
