import { Box } from 'grommet'
import * as React from 'react'
import styled from 'styled-components'
import ErrorString from '../../../../atoms/ErrorString/ErrorString'
import { Text } from '../../../../legacy-lib/design-kit-ui'
import { Button } from '../../../../ui'

export interface IKeycloackLoginProps {
  handleLoginButtonPress: () => Promise<any>
  error?: string
}

export default class KeycloackLogin extends React.Component<IKeycloackLoginProps> {
  constructor(props: IKeycloackLoginProps) {
    super(props)
  }

  public render() {
    return (
      <Box margin="medium">
        <Box align="center" justify="center">
          <Text size="small">Colaborador Ume, faça login com sua conta Google</Text>

          <ButtonBox>
            <TextButton
              style={{ width: '100%', fontWeight: 'normal' }}
              onClick={() => {
                this.props.handleLoginButtonPress()
              }}
            >
              Entrar com e-mail corporativo
            </TextButton>
          </ButtonBox>
        </Box>

        <Box align="center" justify="center">
          {<Box>{this.props.error && <ErrorString text={this.props.error} />}</Box>}
        </Box>
      </Box>
    )
  }
}

export const ButtonBox = styled(Box)`
  align-items: center;
`

export const TextButton = styled(Button)`
  font-size: 14px;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  outline: none;
  box-shadow: none;

  &:hover {
    outline: none;
    box-shadow: none;
  }
`
