export enum OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE {
  BORROWER_APPLICATION_DENIED = 'Cliente não aprovado',
  // BORROWER_APPLICATION_INCOMPLETE = 'Cliente com pendências cadastrais.',
  BORROWER_APPLICATION_UNDER_ANALYSIS = 'Cliente com avaliação cadastral em andamento',
  BORROWER_APPLICATION_MANUAL_ANALYSIS = 'Cliente com avaliação cadastral em andamento',
  BORROWER_APPLICATION_PRE_APPROVED = 'Cliente com avaliação cadastral em andamento',
  BORROWER_BLOCKED_TO_PURCHASE = 'Cliente bloqueado para compras',
  BORROWER_DISABLE_TO_PURCHASE = 'Cliente bloqueado para compras',
  OVERDUE_INSTALLMENTS = 'Cliente com limite bloqueado',
}
