import { Button as GrommetButton } from 'grommet'
import styled from 'styled-components'
import { blue, white } from '../utils/_colors'

const StyledButton = styled(GrommetButton)`
  width: ${props => (props.width ? props.width : '94px')};
  height: ${props => (props.height ? props.height : '38px')};
  color: ${props => (props.color ? props.color : white.primary)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : blue.navy)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  border: ${props => (props.border ? props.border : `solid 0.5px ${blue.navy}`)};
  margin: ${props => (props.margin ? props.margin : '4px 8px')};

  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.61px;
  line-height: 16px;

  &:nth-child(n) {
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => (props.color ? props.color : blue.navy)};
  }
`

export { StyledButton }
