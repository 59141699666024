import React from 'react'
import styled, { css } from 'styled-components'
import { LoadingIcon } from '../../atoms/LoadingIcon/LoadingIcon'

const RESET_STATE = 'Todos'

interface IDataTableProps {
  data?: any[]
  header: IHeader[]
  onRowElementClick?: (element: any, index: number) => void
  onFilterSelected?: (attribute: string, selectedElement: string) => void
  keepSelected?: boolean
  selectedIndex?: number
}

interface IDataTableState {
  originalData?: any[]
}

interface IDropDownValue {
  label: string
  value: string
}

interface IHeader {
  label: string
  attribute: string
  dropDownValues?: IDropDownValue[]
  formatCell?: (rowElement: any) => any
}

export default class UMEDataTable extends React.Component<IDataTableProps, IDataTableState> {
  public RESET_STATE = RESET_STATE

  constructor(props: IDataTableProps) {
    super(props)

    this.state = {
      originalData: props.data,
    }
  }

  onDropDownSelect = (head: IHeader, event: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.props.onFilterSelected) {
      this.props.onFilterSelected(head.attribute, event.target.value)
    }
  }

  onHeaderElementClick = (element: any) => {}

  onRowElementClick = (element: any, index: number) => {
    // If callback
    if (this.props.onRowElementClick) {
      this.props.onRowElementClick(element, index)
    }
  }

  renderHeaderElement = (head: IHeader, index: number) => {
    if (head.dropDownValues) {
      return (
        <HeaderElement key={index}>
          <Select name="Header-Select" onChange={(event: any) => this.onDropDownSelect(head, event)}>
            <option key={index} value={RESET_STATE}>
              Todos
            </option>
            {head.dropDownValues.map((dropDownValue, index) => (
              <option key={index} value={dropDownValue.value}>
                {dropDownValue.label}
              </option>
            ))}
          </Select>
        </HeaderElement>
      )
    }
    return <HeaderElement key={index}>{head.label}</HeaderElement>
  }

  renderCellElement = (headElement: IHeader, dataElement: any, index: number) => {
    if (headElement.formatCell) {
      return <DataRowElement key={index}>{headElement.formatCell(dataElement)}</DataRowElement>
    }

    return <DataRowElement key={index}>{dataElement[headElement.attribute]}</DataRowElement>
  }

  render() {
    let { data, header, selectedIndex } = this.props

    if (!data) {
      return <LoadingIcon />
    }

    return (
      <Table>
        <thead>
          <HeaderRow>{header.map((head, index) => this.renderHeaderElement(head, index))}</HeaderRow>
        </thead>

        <Data>
          <tr>
            {header.map((head, index) => (
              <Divider key={index}></Divider>
            ))}
          </tr>

          {data.map((unit, index) => (
            <DataRow
              key={index}
              isSelected={selectedIndex === index}
              onClick={(event: any) => this.onRowElementClick(unit, index)}
            >
              {header.map((head, index) => this.renderCellElement(head, unit, index))}
            </DataRow>
          ))}
        </Data>
      </Table>
    )
  }
}

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`

const HeaderRow = styled.tr`
  height: 56px;
  margin-bottom: 10px;
  background-color: white;
`

const HeaderElement = styled.th`
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  padding-top: 20px;
  min-width: 130px;

  border-bottom: 1px solid rgba(57, 57, 57, 0.2);

  position: sticky;
  top: 0;
  background-color: white;

  &:first-child {
    padding-left: 32px;
  }
`

const Select = styled.select`
  width: 80%;
  cursor: pointer;
  padding: 9px 9px 9px 9px;
  margin-bottom: 6px;

  box-sizing: border-box;
  /* height: 36px; */
  border: 1px solid #bebebe;
  border-radius: 4px;
  background-color: #ffffff;

  /* height: 14px; */
  /* width: 84px; */
  color: #3b3b3b;

  text-align: left;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
`
const Divider = styled.td`
  border-top: 1px solid rgba(57, 57, 57, 0.2);
  height: 5px;
`

const Data = styled.tbody``

type DataRowProps = {
  isSelected?: boolean
}

const DataRow = styled.tr<DataRowProps>`
  height: 40px;
  border-bottom: solid 3px #fff;
  color: #000000;
  background-color: #fff;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #616161;
    color: white;
    transition: 150ms;
    cursor: pointer;
  }

  ${(props: any) =>
    props.isSelected &&
    css`
      background-color: #616161 !important;
      color: white;
    `}
`

const DataRowElement = styled.td`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;

  &:first-child {
    padding-left: 32px;
  }
`
