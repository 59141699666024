import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../../App'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'

const ApplicationAlreadyApprovedPage = () => {
  const dispatch = useDispatch()
  const preApplicationResponse = useTypedSelector(state => state.application.preApplicationResponse)

  const handleReset = () => {
    dispatch(ApplicationSliceReducer.actions.resetState())
    history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  if (!preApplicationResponse) {
    return <></>
  }

  const { limit } = preApplicationResponse

  return (
    <Container>
      <HeaderContainer>
        <Title>Bem vindo de volta!</Title>
        <SubTitle> Estamos felizes em ter o cliente aqui de novo. </SubTitle>
      </HeaderContainer>

      <LimitContainer>
        <LimitTitle> Limite total: </LimitTitle>
        <LimitValue> {formatBRL(limit)} </LimitValue>
      </LimitContainer>

      <BackButton onClick={handleReset}> Voltar para o cadastro </BackButton>
    </Container>
  )
}

export default ApplicationAlreadyApprovedPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  justify-content: space-between;

  text-align: left;
  height: 93%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;

  color: #11b048;
`

const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.1px;

  color: #000000;
`

const LimitContainer = styled.div`
  // margin-left: 5vw;

  display: flex;
  flex-direction: column;
`

const LimitTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`

const LimitValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #11b048;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 20px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 90% !important;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
