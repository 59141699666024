import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { LoginContainerStyle } from '.'
import { Role } from '../../common/constants'
import LoginForm from '../../organisms/login/components/LoginForm/LoginForm'
import { bffParceirosApiService } from '../../services/bff'
import Background from '../Background/Background'

export interface HomeRouterProps {
  title?: string
  history?: any
  location?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export default class LoginMultiStep extends Component<HomeRouterProps> {
  public render() {
    const user = bffParceirosApiService.auth.getUser()

    if (bffParceirosApiService.auth.isLogged() && user) {
      let pathname = '/'
      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (user.role === Role.MANAGER && !isStoreSetUp) {
        pathname = '/configuracao'
      } else if ((user.role === Role.CASHIER || user.role === Role.MANAGER) && isStoreSetUp) {
        pathname = '/caixa'
      } else if (user.role === Role.REPRESENTATIVE || user.role === Role.ATTENDANT) {
        pathname = '/aquisicao'
      } else {
        pathname = '/'
      }
      const { from } = { from: { pathname } }

      return <Redirect to={from} />
    }

    return (
      <LoginContainerStyle>
        <Background>
          <LoginForm {...this.props} />
        </Background>
      </LoginContainerStyle>
    )
  }
}
