import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { GetBillingsDto, IFetchBilling, PaymentsAggDto } from './dto/Billings.dto'
import { IFetchBillingsPayments, IFetchBillingsPaymentsForRetailers, RetailerPaymentDto } from './dto/Payments.dto'

export default class BillingsService {
  private axiosInstance: AxiosInstance
  public readonly HttpBillingsErrors: any

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async fetchBillings(params: IFetchBilling): Promise<GetBillingsDto[]> {
    const response = await this.axiosInstance.get<GetBillingsDto[]>('/billings', { params })
    return response.data
  }

  async fetchBillingsPaymentsAgg(params: IFetchBillingsPayments): Promise<PaymentsAggDto> {
    const response = await this.axiosInstance.get<PaymentsAggDto>('billings/payments/agg', { params })
    return response.data
  }

  async fetchBillingsPaymentsForRetailers(params: IFetchBillingsPaymentsForRetailers): Promise<RetailerPaymentDto[]> {
    const response = await this.axiosInstance.get<RetailerPaymentDto[]>('billings/payments/retailer', { params })

    return response.data
  }
}
