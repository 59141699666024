import { Button } from 'grommet'
import React from 'react'
import { Spinner } from '../../atoms/Spinner/Spinner'

export interface ILoadingButtonState {}

export interface ILoadingButtonProps {
  isLoading: boolean
  label: string
  style?: any
  icon?: any
  onClick?: Function
}

export class LoadingButton extends React.Component<ILoadingButtonProps, ILoadingButtonState> {
  render() {
    return (
      <Button
        {...this.props}
        icon={this.props.isLoading ? <Spinner /> : this.props.icon ? this.props.icon : <></>}
        label={this.props.label}
        onClick={(event: any) => (this.props.onClick ? this.props.onClick(event) : undefined)}
      />
    )
  }
}
