import { Divider } from '@blueprintjs/core'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { ReactComponent as ArrowDownIcon } from '../../common/assets/images/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../common/assets/images/arrow-up.svg'
import { mobile } from '../../common/assets/utils/_breakpoints'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { DueDateRule } from '../../services/bff/coordinator/dto/Contracts.dto'
import { convertToPercentage, resolveFeeByDueDateRule } from '../../utils/math.utils'
import { ListItem } from './components/ListItem'

interface Props {
  purchaseValue: string
  interestRate: number
  iof: number
  firstInstallmentDueDate?: string
  couponDiscount: number
  installmentValue: number
  installmentValueWithoutInterest?: number
  customerFeeRate?: number
  dueDateRule?: DueDateRule
}

export const PurchaseDetailsDropdown: React.FC<Props> = props => {
  const {
    purchaseValue,
    interestRate,
    iof,
    firstInstallmentDueDate,
    couponDiscount,
    installmentValue,
    installmentValueWithoutInterest,
    customerFeeRate,
    dueDateRule,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    analyticsService.sendEvent(AnalyticsEvents.purchase_proposal_details_dropdown)
    setIsOpen(!isOpen)
  }

  return (
    <DropdownButtonSection>
      <DropdownButtonContainer onClick={toggleDropdown}>
        <ButtonText>Ver detalhes</ButtonText>
        {isOpen ? <ArrowUpIcon style={{ marginLeft: 10 }} /> : <ArrowDownIcon style={{ marginLeft: 10 }} />}
      </DropdownButtonContainer>

      {isOpen && (
        <DropdownContainer>
          <ListItem label={'Valor da compra'} value={formatBRL(Number(purchaseValue) - couponDiscount)} />

          {installmentValueWithoutInterest && (
            <ListItem label={'Valor da parcela até o vencimento'} value={formatBRL(installmentValueWithoutInterest)} />
          )}

          <ListItem label={'Valor da parcela'} value={formatBRL(installmentValue)} />

          <ListItem
            label={'Juros'}
            value={`${convertToPercentage(resolveFeeByDueDateRule(interestRate, dueDateRule))}%`}
          />

          {customerFeeRate ? (
            <ListItem label={'Taxa de utilização'} value={`${convertToPercentage(customerFeeRate)}%`} />
          ) : (
            undefined
          )}

          <ListItem label={'IOF'} value={formatBRL(iof)} />

          <Divider />

          <ListItem label={'1ª Parcela'} value={moment(firstInstallmentDueDate).format('L')} />
        </DropdownContainer>
      )}
    </DropdownButtonSection>
  )
}

export default PurchaseDetailsDropdown

const DropdownButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px;

  @media (max-width: ${mobile}) {
    padding: 10px 0px 10px 0px;
  }
`

const DropdownButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  cursor: pointer;
`

const ButtonText = styled.text`
  font-size: 16px;
  font-weight: bold;
`

const DropdownContainer = styled.div`
  width: 93%;
  margin: 20px;
  padding: 18px;
  border-radius: 20px;
  line-height: 2;
  background-color: #eff4f0; // light grey

  @media (max-width: ${mobile}) {
    padding: 10px 0px 10px 0px;
  }
`
