import Lottie from "lottie-react";
import styled from 'styled-components';
import { black, gray, green, white } from '../../../../ui/utils/_colors';

export const ContentContainer = styled.div`
    width: 100%;
    max-width: 700px;
    margin: 24px;
    padding: 48px;
    border: solid 1px ${gray.border};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 450px) {
        padding: 24px;
    }
`

export const Title = styled.p`
    color: ${black.text};
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    margin: 0;
    @media only screen and (max-width: 770px) {
        text-align: center;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;
    @media only screen and (max-width: 770px) {
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 770px) {
        justify-content: center;
        align-items: center;
        margin-top: 32px;
    }
`

export const Text = styled.p`
    color: ${gray.text};
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    max-width: 294px;
`

export const Value = styled.p`
    color: ${green.confirmation};
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 48px;
`

interface ButtonProps {
    disabled: boolean
}

export const PrimaryButton = styled.button`
    border: 1px solid ${(props: ButtonProps) => props.disabled ? gray.darkDisabled : green.confirmation};
    border-radius: 4px;
    padding: 6px 10px;
    margin-top: 16px;
    min-width: 200px;
    display: inline-block;
    inline-size: fit-content;
    background-color: ${(props: ButtonProps) => props.disabled ? gray.darkDisabled : green.confirmation};
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
`

export const PrimaryButtonText = styled.p`
    color: ${white.primary};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 0.875rem;
    margin: 0;
    user-select: none;
`

export const LeftCard = styled.div`
    width: 100%;
    max-width: 267px;
    height: 267px;
    border: solid 1px ${gray.border};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 450px) {
        padding: 24px;
    }
`

export const Animation = styled(Lottie)`
    width: 100px;
    heigth: 100px;
`

export const SecondaryButton = styled.button`
    border: 1px solid ${(props: ButtonProps) => props.disabled ? gray.darkDisabled : green.confirmation};
    border-radius: 4px;
    padding: 6px 10px;
    margin-top: 16px;
    min-width: 200px;
    display: inline-block;
    inline-size: fit-content;
    background-color: ${white.primary};
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: ${green.confirmation} auto 1px;
    }
`

export const SecondaryButtonText = styled.p`
    color: ${(props: ButtonProps ) => props.disabled ? gray.darkDisabled : green.confirmation};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 0.875rem;
    margin: 0;
    user-select: none;
`