import React from 'react'
import LoggedUserInfoCard from '../../../LoggedUserInfoCard/LoggedUserInfoCard'

interface IPageHeaderProps {
  identificationUserContainerStyle?: React.CSSProperties
}

const PageHeader: React.FC<IPageHeaderProps> = ({ identificationUserContainerStyle }) => {
  return (
    <div>
      <LoggedUserInfoCard containerStyle={identificationUserContainerStyle} />
    </div>
  )
}

export default PageHeader
