import { Button, Image } from 'antd'
import { Box } from 'grommet'
import React from 'react'
import Webcam from 'react-webcam'
import styled from 'styled-components'
import * as localStorage from '../../../common/assets/utils/localStorage'
import SafeListService from '../../../common/assets/utils/SafeListService'
import { getConfig } from '../../../config/config'
import { Loading } from '../../../ui'
import BrowserInfoUtils from '../../../utils/browser-info.utils'
import FileUtils, { ImageCompressionOptions } from '../../../utils/file.utils'
import OpenNativeCamera from './NativeCameraView'

const IMAGE_WIDTH = 1440
const IMAGE_HEIGHT = 1080
const IMAGE_BASE64_MAX_SIZE = 790000

export interface ICameraViewProps {
  onCapture: (imageBase64: string) => void
  onCloseCamera: () => void
  isLoading?: boolean
}

export interface ICameraViewState {}

const initialState: ICameraViewState = {}
export default class CameraView extends React.Component<ICameraViewProps, ICameraViewState> {
  private webcam: any
  private imageBase64: string = ''

  constructor(props: ICameraViewProps) {
    super(props)
    this.state = initialState
  }

  handleTakenPhoto = async (file: File) => {
    const { onCapture } = this.props
    const compressOptions: ImageCompressionOptions = {
      maxSizeMB: getConfig().biometryConfig.photosMaxSize,
    }
    const compressedFile = await FileUtils.compressImage(file, compressOptions)
    this.imageBase64 = await FileUtils.toBase64(compressedFile)

    if (this.imageBase64.length > IMAGE_BASE64_MAX_SIZE) {
      const compressOptions: ImageCompressionOptions = {
        maxSizeMB: getConfig().biometryConfig.retryPhotosMaxSize,
      }
      const compressedFile = await FileUtils.compressImage(file, compressOptions)
      this.imageBase64 = await FileUtils.toBase64(compressedFile)
    }

    onCapture(this.imageBase64)
    this.setState({ isLoading: true })
  }

  setVideoRef = (webcam: any) => {
    if (!webcam) return

    this.webcam = webcam
  }

  public capture = () => {
    const { onCapture } = this.props
    this.imageBase64 = this.webcam.getScreenshot()
    if (this.imageBase64) {
      onCapture(this.imageBase64)
      this.setState({ isLoading: true })
    }
  }

  handleCloseCamera = () => {
    this.props.onCloseCamera()
  }

  render() {
    const operatorId = localStorage.get('user')?.id
    const { isLoading } = this.props
    return (
      <Container>
        <Box justify="center" align="center" width="100%" height="100%">
          {isLoading ? (
            <>
              <Image style={{ borderRadius: 5 }} src={this.imageBase64} preview={false} />
              <LoadContainer>
                <Loading width="50px" />
              </LoadContainer>
            </>
          ) : BrowserInfoUtils.isMobile() && SafeListService.shouldUseNativeCameraSafeList(operatorId) ? (
            <OpenNativeCamera onPhoto={this.handleTakenPhoto} manualUpload={false} />
          ) : (
            <>
              <Webcam
                width="100%"
                height="100%"
                audio={false}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                ref={this.setVideoRef}
                videoConstraints={{
                  width: IMAGE_WIDTH,
                  height: IMAGE_HEIGHT,
                  facingMode: { ideal: 'environment' },
                }}
              />
              <Box align="center" justify="center" height="20%">
                <CaptureImageButton onClick={this.capture} disabled={isLoading}>
                  1
                </CaptureImageButton>
              </Box>
            </>
          )}
        </Box>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const CaptureImageButton = styled(Button)`
  width: 10vh !important;
  height: 10vh !important;
  border-radius: 100% !important;
  background: #c2c2c2 !important;
  border-width: 8px !important;
  border-style: double !important;
  border-color: white !important;
  color: #c2c2c2 !important;

  &:hover {
    border-color: #c2c2c2;
  }
`

const LoadContainer = styled.div`
  position: absolute;
  z-index: 5;
`
