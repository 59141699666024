import React from 'react'
import QRCode from 'react-qr-code'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import { ArrowBackButton } from '../../atoms/Buttons/ArrowBackButton'
import { Button } from '../../atoms/Buttons/Button'
import UmeLinks from '../../common/UmeLinks'
import { Loading } from '../../molecules/Budget/Loading'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { black, gray, green, white } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'
import PhoneUtils from '../../utils/phone.utils'

export const OperatorServiceCenterBorrowerReevaluation: React.FC = () => {
  const { borrowerData, isReevaluatingBorrower } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
    isReevaluatingBorrower: state.operatorServiceCenter.isReevaluatingBorrower,
  }))

  const store = bffParceirosApiService.coordinator.getStore()

  const dispatch = useDispatch()

  const handleConfirmButton = async () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.reevaluateBorrower({ document: borrowerData?.borrower.document!!, borrowerId: borrowerData?.borrower.id!!, storeId: store?.id!! }))
  }

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
    history.push(PagesPathEnum.HOME_PAGE)
  }

  return (
    <>
      <BackButtonContainer>
        <ArrowBackButton onBackButtonClick={handleBackButton} />
      </BackButtonContainer>

      <MainContainer>
        <Card>
          <CardContainer>
            <CardHeader>
              <Title color={green.dark1}>Reavaliação de cadastro disponível</Title>
            </CardHeader>
            <CardSectionInfo>
              <Title color={black.primary}>{borrowerData?.borrower?.name}</Title>
            </CardSectionInfo>
            <CardSectionInfo>
              <BoldText>CPF</BoldText>
              <BoldText color={gray.text}>{DocumentUtils.maskCpf(borrowerData?.borrower?.document)}</BoldText>
            </CardSectionInfo>
            <CardSectionInfo>
              <BoldText>Telefone</BoldText>
              <BoldText color={gray.text}>{PhoneUtils.maskPhone(borrowerData?.borrower?.phoneNumber)}</BoldText>
            </CardSectionInfo>
            <TextContainer>
              <Text>
                Seu telefone mudou? Solicite a alteração pelo atendimento nesse link:{' '}
                <Link href="https://customers.ume.com.br/atualizacao-dados" target="_blank" rel="noopener noreferrer">
                  https://customers.ume.com.br/atualizacao-dados
                </Link>
              </Text>
            </TextContainer>
            <QRCodeContainer>
              <QRCode style={{ height: '100%', width: '40%' }} value={UmeLinks.UPDATE_BORROWER_DATA} />
            </QRCodeContainer>
          </CardContainer>
        </Card>
      </MainContainer>
      <Footer>
        <Button text={'Reavaliar cadastro'} textColor={white.primary} backgroundColor={green.dark1} disabled={isReevaluatingBorrower} onClick={handleConfirmButton} />
        <Button text={'Sair'} textColor={green.dark2} backgroundColor={white.primary} border={`1px solid ${green.dark2}`} onClick={handleBackButton} />
      </Footer>
      {isReevaluatingBorrower && <Loading loadingText={'Aguarde, essa avaliação pode demorar um pouco. Permaneça na tela...'} />}
    </>
  )
}

interface ColorProps {
  color: string
}

const BackButtonContainer = styled.div`
  margin: 16px 0px;
`

const Title = styled.p<ColorProps>`
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
`

const Link = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const TextContainer = styled.div`
  padding: 10px 0px;
`

const Text = styled.text`
  font-family: Roboto;
  font-size: 16px;
  color: ${black.primary};
  text-align: left;
`
const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1vh;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  background: #ffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 10px 16px;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 16px 0px;
`

const CardSectionInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 6px 0px;
`

const BoldText = styled.text<ColorProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: ${(props: ColorProps) => props.color};
  text-align: left;
`
