import { Box, Grommet, Layer, LayerProps } from 'grommet'
import React, { Component } from 'react'
import { Text } from '../Text'
import animatedLogo from './animated-logo.gif'

export default class LoadingScreen extends Component<LayerProps & JSX.IntrinsicElements['div']> {
  render() {
    return (
      <Grommet theme={theme}>
        <Layer animation="fadeIn" full>
          <Box fill background={{ opacity: 'strong' }} align="center" justify="center">
            <img src={animatedLogo} alt="UME" width="180" />
            <Text light size="small">
              Carregando...
            </Text>
          </Box>
        </Layer>
      </Grommet>
    )
  }
}

const theme = {
  layer: {
    background: 'rgba(255, 255, 255, 0.9)',
    overlay: {
      background: 'rgba(white, 0.5)',
    },
  },
}
