import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as localStorage from '../../../common/assets/utils/localStorage'
import * as config from '../../../config/config'
import NumberField from '../../../molecules/Application/NumberField'
import { formatPhoneNumber } from '../../../molecules/Application/PhoneCard'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import WhatsAppUtils from '../../../utils/whatsApp.utils'
import ApplicationLayout from '../ApplicationLayout'

const CHANGE_UNVERIFIED_NUMBER_WPP_TEXT_BUILDER = (
  operatorId: string,
  borrowerId: string,
  currentPhoneNumber: string
) => {
  return `Olá! Solicito a troca do número não verificado. Os dados: ${WhatsAppUtils.WPP_BREAKLINE}
    - operador: ${operatorId} ${WhatsAppUtils.WPP_BREAKLINE}
    - cliente: ${borrowerId} ${WhatsAppUtils.WPP_BREAKLINE}
    - número atual: ${formatPhoneNumber(currentPhoneNumber)} ${WhatsAppUtils.WPP_BREAKLINE}
    ${WhatsAppUtils.WPP_BREAKLINE}
    Vou enviar o novo número na próxima mensagem :)
  `
}

const SendVerificationCodeStep = () => {
  const dispatch = useDispatch()

  const {
    isSubmittingPhoneNumber,
    hasSendVerificationCode,
    isPhoneVerified,
    phoneNumber,
    borrowerId,
  } = useTypedSelector(state => ({
    hasSendVerificationCode: state.application.hasSendVerificationCode,
    isPhoneVerified: state.application.isPhoneVerified,
    phoneNumber: state.application.borrower?.unverifiedPhoneNumber ?? state.application.phoneNumber,
    isSubmittingPhoneNumber: state.application.isSubmittingPhoneNumber,
    borrowerId: state.application.borrowerId,
  }))

  // Uncontrolled component: primary phone input
  const [formPhoneNumber] = useState<string>(phoneNumber)

  const handlePhoneNumberSubmit = () => {
    dispatch(ApplicationSliceReducer.actions.sendVerificationCode({ phoneNumber: formPhoneNumber }))
  }

  useEffect(() => {
    if (hasSendVerificationCode) {
      dispatch(ApplicationSliceReducer.actions.nextPage())
    }
  }, [hasSendVerificationCode])

  // TODO(lucas.citolin): create class LocalStorageUtils and type items on local storage
  const operatorId = localStorage.get('user')?.id

  return (
    <ApplicationLayout
      title="Confira o número do cliente"
      subtitle={<SmallText>Vamos enviar um código de verificação para esse número:</SmallText>}
      disableFooter={true}
    >
      <Container>
        <NumberField
          autoFocus
          Icon={isPhoneVerified ? CheckCircleIcon : PhoneInTalkIcon}
          defaultValue={phoneNumber}
          value={formPhoneNumber}
          label="Celular Principal"
          placeholder="(92) 90000-0000"
          format="(##) #####-####"
          type="tel"
          mask="_"
          onEnterPressed={handlePhoneNumberSubmit}
          isLoading={isSubmittingPhoneNumber}
          isValidated={true}
          disabled={true}
          error={false}
          style={{ marginTop: '20px' }}
        />
        {hasSendVerificationCode && !isPhoneVerified ? (
          <SmallText>
            <BoldGreenText>Ótimo! Um SMS foi enviado.</BoldGreenText> Para digitar o código de verificação ou alterar o
            número do celular, clique no ícone de telefone no canto superior direito da tela
          </SmallText>
        ) : isPhoneVerified ? (
          <SmallText>
            <BoldGreenText>Este telefone já foi verificado</BoldGreenText>
          </SmallText>
        ) : (
          <ButtonContainer>
            <SmallText>Caso o número esteja incorreto, a troca de número será feita apenas pelo atendimento.</SmallText>
            <CustomLink
              href={WhatsAppUtils.buildUrlWithText(
                config.UME_PHONES.retailerService.whatsapp,
                CHANGE_UNVERIFIED_NUMBER_WPP_TEXT_BUILDER(operatorId, borrowerId as string, phoneNumber)
              )}
            >
              Ir para o atendimento
            </CustomLink>

            <ConfirmButton disabled={formPhoneNumber.length === 0} onClick={handlePhoneNumberSubmit}>
              Enviar código SMS
            </ConfirmButton>
          </ButtonContainer>
        )}
      </Container>
    </ApplicationLayout>
  )
}

export default SendVerificationCodeStep

const CustomLink = styled.a`
  color: blue;

  &:hover {
    color: gray;
  }
`

const Container = styled.div`
  margin-top: 2vh;
  margin-bottom: 6vh;

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  margin-top: 2vh;
  margin-bottom: 6vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SmallText = styled.span`
  margin-top: 1vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props: any) => (props && props.bold ? '500' : '')};
  font-size: 14px;
  line-height: 24px;

  color: #000000;
`

const BoldGreenText = styled.span`
  color: #11b048;
  font-weight: 800;
`

const ConfirmButton = styled.button`
  margin-top: 30%;
  outline: none;
  transition: 0.5s;

  // BUTTON
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#10A343')};
  border-radius: 10px;
  border: 1px;
  height: 70px;
  padding: 7px;
  bottom: 52px;
  left: 11.11%;
  right: 11.11%;

  // TEXT
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;

  :focus {
    outline: none;
  }
`
