import { Button, Card, Elevation, HTMLSelect } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { toaster } from '../../App'
import { Role } from '../../common/constants'
import { storeIsNotSetUp } from '../../common/ErrorStrings'
import { Loading } from '../../legacy-lib/design-kit-ui'
import LoggedUserSaverService from '../../molecules/LoggedUserInfoCard/LoggedUserSaverService'
import { bffParceirosApiService } from '../../services/bff'
import { StoreDto, StoreStatus } from '../../services/bff/retailers/dto/Store.dto'
import {
  CaptureMethodConfig,
  CaptureMethodService,
  CaptureMethodUIConfig,
} from '../../services/captureMethodService/CaptureMethodService'
import { formItemsContainerStyle } from './style'

export interface IStoreSelectionState {
  stores: Map<string, StoreDto>
  selectedStore?: StoreDto

  selectedCaptureMethod?: CaptureMethodConfig
  allowedCaptureMethods?: CaptureMethodUIConfig[]
}

export interface HomeRouterProps {
  history?: any
  location?: any
}
export default class StoreSelection extends React.Component<HomeRouterProps, IStoreSelectionState> {
  state: IStoreSelectionState = {
    stores: new Map<string, StoreDto>(),
    selectedStore: undefined,
  }

  async componentDidMount() {
    // TODO(lucas.citolin): fetchStores doesn't return the settings property of the store
    const stores = new Map<string, StoreDto>(
      (await bffParceirosApiService.retailers.fetchStores({ status: StoreStatus.ACTIVE })).stores
        .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
        .map(store => [store.id, store])
    )

    let store = bffParceirosApiService.coordinator.getStore()
    if (!store) {
      toaster.showWarningToast(storeIsNotSetUp)
    }

    store = stores.get(String(store?.id))

    const { allowedCaptureMethods, savedCaptureMethod } = await this.getCaptureMethodConfigs(store?.id)
    const { settings } = await this.getStoreSettings(store?.id)
    this.setState({
      stores: stores,
      selectedStore: store ? { ...store, settings } : undefined,
      selectedCaptureMethod: savedCaptureMethod.captureMethod,
      allowedCaptureMethods,
    })
  }

  async getCaptureMethodConfigs(storeId?: string) {
    // Need to fetch the storey by id so we can access the settings property
    const storeWithSettings = storeId ? await bffParceirosApiService.retailers.fetchStoreById(storeId) : undefined
    const loggedOperator = bffParceirosApiService.auth.getUser()
    const savedCaptureMethod = CaptureMethodService.get()
    const allowedCaptureMethods = CaptureMethodService.allowedCaptureMethods(
      loggedOperator?.role as Role,
      storeWithSettings?.settings?.captureMethod
    )

    return { allowedCaptureMethods, savedCaptureMethod }
  }

  async getStoreSettings(storeId?: string) {
    const storeWithSettings = storeId ? await bffParceirosApiService.retailers.fetchStoreById(storeId) : undefined
    return { settings: storeWithSettings?.settings }
  }

  public onStoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const storeId = e.currentTarget.value
    const store = this.state.stores.get(storeId)
    this.setState({ selectedStore: store })
  }

  public onCaptureMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const captureMethod = e.currentTarget.value as CaptureMethodConfig
    CaptureMethodService.set({ captureMethod })
    this.setState({ selectedCaptureMethod: captureMethod })
  }

  public confirmStore = async () => {
    const { selectedStore } = this.state
    if (!selectedStore) {
      toaster.showErrorToast(storeIsNotSetUp)
      return
    }
    const { settings } = await this.getStoreSettings(selectedStore.id)
    bffParceirosApiService.coordinator.setStore({ ...selectedStore, settings })

    await LoggedUserSaverService.fetchAndSaveUser()

    toaster.showSuccessToast('Loja configurada com sucesso!')

    setTimeout(function() {
      window.location.reload()
    }, 1000)
  }

  render() {
    const { stores, selectedStore, selectedCaptureMethod, allowedCaptureMethods } = this.state
    const shouldRenderCaptureMethodConfig = !!allowedCaptureMethods?.length

    return (
      <div style={formContainerStyle}>
        <div>
          <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
            <CardTitle>Configuração de Loja</CardTitle>
            {stores.size === 0 ? (
              <Loading />
            ) : (
              <HTMLSelect
                value={selectedStore && selectedStore.id ? selectedStore.id : 0}
                onChange={this.onStoreChange}
              >
                <option value={0}>Selecione uma loja...</option>

                {Array.from(stores.values()).map((store, idx) => (
                  <option value={store.id} key={idx}>
                    {store.name}
                  </option>
                ))}
              </HTMLSelect>
            )}
            <Button style={{ marginTop: '32px' }} onClick={this.confirmStore} intent="primary">
              Confirmar Loja
            </Button>
          </Card>
        </div>

        {shouldRenderCaptureMethodConfig ? (
          <div style={{ marginTop: '2vh' }}>
            {!!!selectedCaptureMethod ? (
              <Loading />
            ) : (
              <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
                <CardTitle>Padrão de vendas para o usuário</CardTitle>
                <CardSubtitle>
                  Esta configuração permite que você escolha se deseja fazer as vendas com a Ume identificando o cliente
                  via CPF ou utilizando captura via QR Code.
                  <br />
                  Você pode alterar essa configuração a qualquer momento.
                </CardSubtitle>
                <HTMLSelect value={selectedCaptureMethod} onChange={this.onCaptureMethodChange}>
                  {allowedCaptureMethods?.map((captureMethod, idx) => (
                    <option value={captureMethod.captureMethod} key={idx}>
                      {captureMethod.label}
                    </option>
                  ))}
                </HTMLSelect>
              </Card>
            )}
          </div>
        ) : (
          undefined
        )}
      </div>
    )
  }
}

const CardSubtitle = styled.p`
  text-align: left;
`

const CardTitle = styled.h3`
  align-self: flex-start;
  margin-bottom: 1.5em;
`

const formContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100vh',
  marginTop: '5vh',
  // width: '100vw',
  flexDirection: 'column' as 'column',
}
