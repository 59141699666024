import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import LockIcon from '@material-ui/icons/Lock'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import { PhoneIcon } from '../../common/assets/images/PhoneIcon'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import { formatPhoneNumber } from '../../molecules/Application/PhoneCard'
import { useTypedSelector } from '../../redux/reducers/selectors'
import NumberField from '../../molecules/Application/NumberField'
import { NumberFormatValues } from 'react-number-format'
import { validatePhoneNumber } from './ApplicationSteps/application-utils'
import { CommunicationChannelPicker } from '../../molecules/Application/CommunicationChannelPicker'
import { COMMUNICATION_CHANNEL_OPTIONS } from '../../redux/reducers/application/application.constants'
const CODE_LENGTH = 6
const PHONE_LENGTH = 11
const DEFAULT_SMS_INTERVAL = 60

const ApplicationPhoneModal = () => {
  const dispatch = useDispatch()

  const {
    isPhoneModalVisible,
    isPhoneVerified,
    isSubmittingPhoneNumber,
    isVerifyingVerificationCode,
    phoneNumber,
    lastVerificationCodeSentTimestamp,
    isResendingCode,
    isCommunicationChannelSelected,
  } = useTypedSelector(state => ({
    phoneNumber: state.application.phoneNumber,
    isPhoneModalVisible: state.application.isPhoneModalVisible,
    isPhoneVerified: state.application.isPhoneVerified,
    isVerifyingVerificationCode: state.application.isVerifyingVerificationCode,
    isSubmittingPhoneNumber: state.application.isSubmittingPhoneNumber,
    lastVerificationCodeSentTimestamp: state.application.lastVerificationCodeSentTimestamp,
    isResendingCode: state.application.isResendingCode,
    isCommunicationChannelSelected: state.application.isCommunicationChannelSelected,
  }))

  const intervalRef = useRef<any>(null)

  const [formVerificationCode, setFormVerificationCode] = useState<string>('')
  const [isVerificationCodeIncorrectAnimation, setVerificationCodeIncorrectAnimation] = useState<boolean>(false)
  const [secondsToSendSMS, setSecondsToSendSMS] = useState<number>(DEFAULT_SMS_INTERVAL)
  const [isChangingPhone, setIsChangingPhone] = useState<boolean>(false)

  const newPhoneRef = useRef<HTMLInputElement>(null)
  const [formNewPhoneNumber, setFormNewPhoneNumber] = useState<string>('')

  const isAbleToResendCode = secondsToSendSMS === 0

  const channelLabels = COMMUNICATION_CHANNEL_OPTIONS.map(channel => channel.label)

  // Side Effect: Send SMS Ticker
  useEffect(() => {
    if (!lastVerificationCodeSentTimestamp) return

    intervalRef.current = setInterval(() => {
      const currentTimestamp = new Date()
      const seconds = Math.trunc((currentTimestamp.getTime() - lastVerificationCodeSentTimestamp?.getTime()) / 1000)

      setSecondsToSendSMS(DEFAULT_SMS_INTERVAL - seconds)

      if (seconds === DEFAULT_SMS_INTERVAL) {
        return clearInterval(intervalRef.current)
      }
    }, 1000)
  }, [lastVerificationCodeSentTimestamp])

  // Side Effect: if phone number has changed
  useEffect(() => {
    setIsChangingPhone(false)
    setFormNewPhoneNumber('')
    // dispatch(ApplicationSliceReducer.actions.phoneModalAction(false))
  }, [phoneNumber, dispatch])

  // Side Effect: clear verification code if incorrect
  useEffect(() => {
    if (!isVerifyingVerificationCode) {
      setFormVerificationCode('')
      setVerificationCodeIncorrectAnimation(true)

      setTimeout(() => {
        setVerificationCodeIncorrectAnimation(false)
      }, 500)
    }
  }, [isVerifyingVerificationCode])

  const handleCloseModal = () => {
    dispatch(ApplicationSliceReducer.actions.phoneModalAction(false))
  }

  const handleOnVerificationCodeChange = (event: NumberFormatValues) => {
    setFormVerificationCode(event.value)

    if (event.value.length === CODE_LENGTH) {
      return dispatch(ApplicationSliceReducer.actions.verifyVerificationCode({ verificationCode: event.value }))
    }
  }

  const handleResendVerificationCodeOnClick = () => {
    dispatch(ApplicationSliceReducer.actions.makeAbleResendVerificationCode(true))
  }

  const handleSelectCommunicationChannelOnClick = (index: number) => {
    dispatch(ApplicationSliceReducer.actions.selectCommunicationChannel({ channelIndex: index.toString() }))
  }

  const handleChangeNumber = () => {
    setIsChangingPhone(!isChangingPhone)
    setFormNewPhoneNumber('')
    // For some reason the timeout is required in order to focus
    // -- Probably because the component hasn't been mounted yet
    setTimeout(() => newPhoneRef.current?.focus(), 500)
  }

  const handleNewPhoneNumberOnChange = (event: NumberFormatValues) => {
    // let data = getOnlyNumbersInString(event.target.value as string)
    // setNewPhoneNumber(data)
    setFormNewPhoneNumber(event.value)
  }

  const handleSubmitNewPhoneNumber = () => {
    if (formNewPhoneNumber === phoneNumber) {
      return toaster.showErrorToast(`Digite um telefone diferente do já registrado.`)
    }

    if (!formNewPhoneNumber || formNewPhoneNumber.length !== PHONE_LENGTH) {
      // TODO - Improve error
      return toaster.showErrorToast(`Digite um telefone válido.`)
    }

    dispatch(ApplicationSliceReducer.actions.sendVerificationCode({ phoneNumber: formNewPhoneNumber }))
  }

  const handleSubmitNewVerificationCode = () => {
    dispatch(ApplicationSliceReducer.actions.sendVerificationCode({ phoneNumber: phoneNumber }))
  }

  const isVerificationCodeValid = formVerificationCode.length === CODE_LENGTH
  const isNewPhoneNumberValid = validatePhoneNumber(formNewPhoneNumber)

  return (
    <Modal
      bodyStyle={{ padding: '14px 20px 14px 20px' }}
      visible={isPhoneModalVisible}
      footer={null}
      title={null}
      onCancel={handleCloseModal}
    >
      <ModalContainer>
        <PhoneIcon color="#11B048" height="60px" width="60px" />

        {isPhoneVerified ? (
          <>
            <TitleDescription>
              <BoldGreenText>Ótimo!</BoldGreenText> Seu telefone foi verificado!
            </TitleDescription>
            <PhoneNumber> {formatPhoneNumber(phoneNumber)} </PhoneNumber>
          </>
        ) : isChangingPhone ? (
          <>
            <TitleDescription>Informe o novo número para enviar o código de SMS</TitleDescription>
            {/* Empty so we can keep the modal distance between input and title */}
            <PhoneNumber></PhoneNumber>
            <NumberField
              inputRef={newPhoneRef}
              autoFocus
              Icon={isPhoneVerified ? CheckCircleIcon : PhoneInTalkIcon}
              value={formNewPhoneNumber}
              label="Novo celular principal"
              placeholder="(92) 90000-0000"
              style={{ width: '100%' }}
              type="tel"
              format="(##) #####-####"
              mask="_"
              onValueChange={handleNewPhoneNumberOnChange}
              onEnterPressed={handleSubmitNewPhoneNumber}
              isValidated={isNewPhoneNumberValid}
              error={formNewPhoneNumber.length > 0 && !isNewPhoneNumberValid}
              disabled={isPhoneVerified || isSubmittingPhoneNumber}
            />
            <SmallText bold>Um código de verificação será enviado para o número</SmallText>
            <Footer>
              <CancelButton onClick={handleChangeNumber}> Cancelar </CancelButton>
              <ChangeNumberButton
                disabled={!isNewPhoneNumberValid}
                loading={isSubmittingPhoneNumber}
                onClick={handleSubmitNewPhoneNumber}
              >
                Enviar
              </ChangeNumberButton>
            </Footer>
          </>
        ) : !isResendingCode ? (
          <>
            <TitleDescription>Um código foi enviado para o número:</TitleDescription>
            <PhoneNumber> {formatPhoneNumber(phoneNumber)} </PhoneNumber>
            <NumberField
              isAnimating={isVerificationCodeIncorrectAnimation}
              autoFocus
              value={formVerificationCode}
              Icon={isPhoneVerified ? CheckCircleIcon : LockIcon}
              label="Código de verificação"
              placeholder="000-000"
              format="###-###"
              mask="_"
              type="tel"
              style={{ width: '100%' }}
              onValueChange={handleOnVerificationCodeChange}
              isLoading={isVerifyingVerificationCode}
              isValidated={isVerificationCodeValid}
              error={
                (formVerificationCode.length > 0 && !isVerificationCodeValid) || isVerificationCodeIncorrectAnimation
              }
              disabled={isPhoneVerified || isSubmittingPhoneNumber || isVerifyingVerificationCode}
            />

            {isAbleToResendCode ? (
              <SmallText>
                Um <strong>novo código</strong> pode ser enviado
              </SmallText>
            ) : (
              <SmallText>Reenviar código em {secondsToSendSMS} segundos</SmallText>
            )}
            <Footer>
              <FooterButton onClick={handleChangeNumber}> Modificar número </FooterButton>
              <ResendButton
                disabled={!isAbleToResendCode}
                loading={isSubmittingPhoneNumber}
                onClick={handleResendVerificationCodeOnClick}
              >
                Reenviar código
              </ResendButton>
              {/* Modal to select document type before taking photo with camera */}
            </Footer>
          </>
        ) : (
          <>
            <TitleDescription>Como deseja receber seu código de verificação?</TitleDescription>
            <PhoneNumber> {formatPhoneNumber(phoneNumber)} </PhoneNumber>
            <CommunicationChannelPicker
              style={{ width: '100%' }}
              selectOptions={channelLabels}
              onDropDownSelect={(index?: number) => {
                if (index === null || index === undefined) {
                  return
                }
                handleSelectCommunicationChannelOnClick(index)
              }}
            />
            <Footer>
              <FooterButton onClick={handleChangeNumber}> Modificar número </FooterButton>
              <ResendButton
                disabled={!isCommunicationChannelSelected}
                loading={isSubmittingPhoneNumber}
                onClick={handleSubmitNewVerificationCode}
              >
                Reenviar código
              </ResendButton>

              {/* Modal to select document type before taking photo with camera */}
            </Footer>
          </>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default ApplicationPhoneModal

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TitleDescription = styled.span`
  margin-top: 1vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  letter-spacing: 0.1px;

  color: #000000;
`

const BoldGreenText = styled.span`
  color: #11b048;
  font-weight: 800;
`

const PhoneNumber = styled.span`
  margin-top: 1vh;
  margin-bottom: 2vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.15px;

  color: #11b048;
`

const SmallText = styled.span`
  margin-top: 1vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: ${(props: any) => (props && props.bold ? '500' : '')};
  font-size: 10px;
  line-height: 20px;

  text-align: center;

  color: #000000;
`

const Footer = styled.div`
  margin-top: 2vh;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const FooterButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 12px !important;
  text-align: center !important;

  color: #696974 !important;

  // BUTTON
  margin: 0px !important;
  height: 100% !important;
  width: 40% !important;
  background-color: #fff !important;
  padding: 10px !important;
  border: 1px solid #e2e2ea !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  // TODO - Add hover
  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')}

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: white !important;
    opacity: 0.5;
  }

  &:focus {
    background-color: white !important;
  }

  &:active {
    background-color: white !important;
  }
`
const ResendButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 12px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  margin: 0px !important;
  height: 100% !important;
  width: 40% !important;

  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  // TODO - Add hover
  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')}
`

const CancelButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048 !important;

  // BUTTON
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #11b048 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  width: 40% !important;
  height: 100% !important;
`

const ChangeNumberButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 40% !important;
  height: 100% !important;
  margin-left: 6vw !important;

  // TODO - Add hover
  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')}
`
