import { Box, Layer as GrommetSideBar } from 'grommet'
import styled from 'styled-components'
import { black, white } from '../utils/_colors'

const StyledSideBar = styled(GrommetSideBar)`
  padding: ${props => (props.padding ? props.padding : '24px 48px 48px 48px')};
  margin-top: ${props => (props.showNavBar ? '55px' : '0px')};
  color: ${props => (props.color ? props.color : white.primary)};
  border-radius: 0;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : black.primary)};
  ${props => {
    if (props.position === 'top') {
      return 'transform: translateY(0); animation: jYyoao 0.3s ease-in-out forwards;'
    } else if (props.position === 'bottom') {
      return 'transform: translateY(0); animation: iowhYu 0.2s ease-in-out forwards;'
    } else if (props.position === 'right') {
      return 'transform: translateX(0); animation: geNrKo 0.3s ease-in-out forwards;'
    } else if (props.position === 'left') {
      return 'transform: translateX(0); animation: kqOaZP 0.2s ease-in-out forwards;'
    } else {
      return 'animation: gdMPTF 0.3s ease-in-out forwards; '
    }
  }}
`

const BottomNavItemsBox = styled(Box)`
  // position: absolute;
  // bottom: 60px;
`

export { StyledSideBar, BottomNavItemsBox }
