import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import { StoreErrors } from '../../common/ErrorStrings'
import { validate_cpf } from '../../common/Validators'
import OperatorServiceCenterReevaluationResultPage from '../../organisms/OperatorServiceCenter/OperatorServiceCenterApplicationReevaluationResultPage'
import OperatorServiceCenterBorrowerData from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerData'
import { OperatorServiceCenterBorrowerReevaluation } from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerReevaluation'
import OperatorServiceCenterBorrowerRestriction from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerRestriction'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { green } from '../../ui/utils/_colors'
import OperatorsHome from '../Operators/OperatorsHome'
import { OPERATOR_SERVICE_CENTER_PAGES } from './enums/operator-service-center-pages.enum'

const theme = createTheme({
  palette: {
    primary: {
      main: green.primary300,
    },
  },
})

const OperatorServiceCenter: React.FC = () => {
  const { page, borrowerRestriction } = useTypedSelector(state => ({
    page: state.operatorServiceCenter.page,
    borrowerRestriction: state.operatorServiceCenter.borrowerRestriction,
  }))

  const dispatch = useDispatch()

  const handleConfirmDocument = (document: string, storeId?: string) => {
    if (!storeId) {
      return toaster.showErrorToast(StoreErrors.STORE_CONFIGURATION_REQUIRED)
    }

    if (!validate_cpf(document)) {
      return toaster.showErrorToast(`Preencha um CPF válido.`)
    }

    dispatch(OperatorServiceCenterSliceReducer.actions.fetchBorrower({ document, storeId }))
  }

  const renderPage = () => {
    switch (page) {
      case OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE:
        return (
          <Container>
            <OperatorsHome onConfirmDocument={handleConfirmDocument} />
          </Container>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterBorrowerRestriction restriction={borrowerRestriction!} />
          </PhoneLayout>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_PAGE:
        return (
          <Container>
            <OperatorServiceCenterBorrowerData />
          </Container>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_REEVALUATION_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterBorrowerReevaluation />
          </PhoneLayout>
        )
      case OPERATOR_SERVICE_CENTER_PAGES.APPLICATION_REEVALUATION_RESULT_PAGE:
        return (
          <PhoneLayout>
            <OperatorServiceCenterReevaluationResultPage />
          </PhoneLayout>
        )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <>{renderPage()}</>
    </ThemeProvider>
  )
}

export default OperatorServiceCenter

const Container = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``
