export enum AnalyticsEvents {
    budget_button = 'budget_button',
    cashier_purchase_button = 'cashier_purchase_button',
    cashier_payment_button = 'cashier_payment_button',
    qr_code_payment_creation = 'qr_code_payment_creation',
    qr_code_payment_invalid_value = 'qr_code_payment_invalid_value',
    qr_code_payment_go_cashier = 'qr_code_payment_go_cashier',
    qr_code_payment_go_input = 'qr_code_payment_go_input',
    qr_code_payment_go_legacy = 'qr_code_payment_go_legacy',
    qr_code_payment_zoom = 'qr_code_payment_zoom',
    qr_code_payment_print_qr_code = 'qr_code_payment_print_qr_code',
    qr_code_payment_success = 'qr_code_payment_success',
    qr_code_payment_print_receipt = 'qr_code_payment_print_receipt',
    purchase_borrower_document = 'purchase_borrower_document',
    purchase_proposal = 'purchase_proposal',
    purchase_proposal_details_dropdown = 'purchase_proposal_details_dropdown',
    purchase_send_borrower_limit = 'purchase_send_borrower_limit',
    purchase_selected_installments = 'purchase_selected_installments',
    purchase_otp_verification = 'purchase_otp_verification',
    purchase_success = 'purchase_success',
    purchase_print_receipt = 'purchase_print_receipt',

    christmas_banner = 'christmas_banner'
}