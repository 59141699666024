import * as React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import withForm, { Form, FormProps } from '../../../hoc/withForm';
import NumberField from '../../../molecules/Application/NumberField';
import { Title } from '../../../molecules/Budget';
import { BigButton } from '../../../molecules/Budget/BigButton';
import { BodyText } from '../../../molecules/Budget/BodyText';
import BudgetReducer from '../../../redux/reducers/budget/budget.reducer';
import { useTypedSelector } from '../../../redux/reducers/selectors';
import { phoneSchema } from '../../../schemas/defaultSchemas';

const BudgetPhone = ({ inputChange, fields, valid, errors }: Form): JSX.Element => {
  const dispatch = useDispatch();

  const { phone: phoneOnRedux } = useTypedSelector((state) => state.budget);
  React.useEffect(() => {
    if (phoneOnRedux) onChangePhone({ formattedValue: phoneOnRedux } as NumberFormatValues);
  }, [phoneOnRedux]);

  const handlePhoneNumberSubmit = () => {
    if (valid) {
      const payload = { phone: String(fields.phone) };
      dispatch(BudgetReducer.actions.setPhone(payload));
    }
  };

  const onChangePhone = ({ formattedValue }: NumberFormatValues) => {
    inputChange({ field: 'phone', value: formattedValue });
  };

  return (
    <>
      <Title style={{ flex: 0.25 }}>
        <BoldText>
          Simule crédito Ume para <br/>
          seu cliente aqui!
        </BoldText>
      </Title>

      <NumberField
        autoFocus
        value={fields.phone}
        label='Digite seu telefone para continuar'
        placeholder='(92) 90000-0000'
        format='(##) #####-####'
        type='tel'
        mask='_'
        onValueChange={onChangePhone}
        onEnterPressed={handlePhoneNumberSubmit}
        isValidated={valid}
        error={!!errors.phone}
        helperText={errors.phone ? errors.phone[0] : ''}
        style={{ flexGrow: 0.25 }}
      />

      <BodyText style={{ flexGrow: 0.25 }}>
        Crédito para seu cliente comprar agora! Faça a análise e confira.
      </BodyText>

      <Footer>
        <BigButton disabled={!valid} onClick={handlePhoneNumberSubmit}>
          Confirmar
        </BigButton>
      </Footer>
    </>
  );
};


export const BoldText = styled.span`
  font-weight: bold;
  color: black;
`

const Footer = styled.span`
  display: flex;
  flex: 0.25;
  width: 100%;
  align-items: flex-end;
`;

const formProps: FormProps = {
  isValidationSync: true,
  schema: yup.object().shape({ phone: phoneSchema }),
  initialValues: { phone: '' }
};
export default withForm(BudgetPhone)(formProps);
