import { Grid } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../common/assets/utils/_breakpoints'

export const BorrowerInformationGrid = styled(Grid)`
  width: 90%;
  align-self: center;
  margin-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${tablet}) {
    justify-content: space-evenly;
  }

  p {
    font-weight: bold;
  }
`
