import styled from 'styled-components'
import { mobile } from '../../common/assets/utils/_breakpoints'

export const LoginContainerStyle = styled.div`
  text-align: start;
  justify-content: center;
  width: 100%;

  @media (min-width: ${mobile}) {
    flex-wrap: wrap;
    display: flex;
    margin: auto;
  }
`
