import { Button, Card, Divider, Elevation, H3, H4, Icon, Intent, Label } from '@blueprintjs/core'
import moment from 'moment'
import * as React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import { sortBy } from '../../common/ArrayFunctions'
import {
  creditProfileErrorResponse,
  defaultErrorMessage,
  installmentPaymentErrorResponse,
  PaymentsErrors,
} from '../../common/ErrorStrings'
import { storeConfirmation } from '../../common/StoreConfirmation'
import { validateBr } from '../../common/Validators'
import * as featureFlag from '../../config/config'
import PageHeader from '../../molecules/header/components/PageHeader/PageHeader'
import { resolvePaymentReceiptRoute, resolveRenegotiationPaymentReceiptRoute } from '../../pages/Receipts/Utils'
import ConfigurationsSliceReducer from '../../redux/reducers/configurations/configurations.reducer'
import { bffParceirosApiService } from '../../services/bff'
import { IChargeFeeInstallment, IInstallments } from '../../services/bff/coordinator/dto/Contracts.dto'
import BorrowerProfile from '../common/BorrowerProfile'
import { formatCellphoneWithoutNinthDigit } from '../common/formatCellPhone'
import {
  buttonStyle,
  formContainerStyle,
  formItemsContainerStyle,
  formItemStyle,
  h5Style,
  textInputGroupStyle,
  textInputGroupWithLabelStyle,
} from '../styles'
import { ConfirmPaymentDialog } from './ConfirmPaymentDialog'
import { InstallmentUI } from './InstallmentRow'
import { InstallmentsTable } from './InstallmentsTable'
import { PaymentInProgress } from './PaymentInProgress'
import { PaymentResumeCard } from './PaymentResumeCard'
import RenderReceipts from './RenderReceipts'

enum BorrowerFetchStatus {
  WaitingCPFInput,
  FetchingBorrowerInfo,
  BorrowerInfoFound,
}

enum PaymentRequestStatus {
  WaitingUserConfirmation,
  RunningPaymentRequest,
  PaymentRequestSuccess,
}

export interface IPaymentsProps {
  title?: string
  history?: any
  location?: any
  dispatch: Dispatch
}

export interface IPaymentsState {
  borrowerFetchInfoStatus: BorrowerFetchStatus
  paymentRequestStatus: PaymentRequestStatus
  borrowerId: string
  operatorId: string
  cpf: string
  cpfIntent: Intent
  cellphone: string
  approvedLimit: number
  availableLimit: number
  borrowerName: string
  formattedCPF: string
  formattedCellphone: string
  isShowingPaymentOverlay: boolean
  borrowerError: string
  numberOfInstallmentsToPay: number
  enableHighRecurrence: boolean
  feesTotalValue: number
  valueToPay: number
  installments: InstallmentUI[]
  fees?: IChargeFeeInstallment[]
  selectedInstallmentsIds: string[]
  installmentsReceiptEndpoint: string
}

const initialState: IPaymentsState = {
  approvedLimit: 0,
  availableLimit: 0,
  paymentRequestStatus: PaymentRequestStatus.WaitingUserConfirmation,
  borrowerFetchInfoStatus: BorrowerFetchStatus.WaitingCPFInput,
  borrowerId: '',
  operatorId: '',
  borrowerError: '',
  cellphone: '',
  cpf: '',
  formattedCPF: '',
  formattedCellphone: '',
  borrowerName: '',
  cpfIntent: Intent.NONE,
  installments: [],
  fees: undefined,
  numberOfInstallmentsToPay: 0,
  isShowingPaymentOverlay: false,
  enableHighRecurrence: false,
  feesTotalValue: 0,
  valueToPay: 0,
  installmentsReceiptEndpoint: '',
  selectedInstallmentsIds: [],
}

class Payments extends React.Component<IPaymentsProps, IPaymentsState> {
  state = { ...initialState } // use spread operator to avoid mutation

  public handleReset = () => {
    this.setState(initialState)
  }

  async componentDidMount() {
    const cpf = this.props.history.location.state && this.props.history.location.state.cpf
    if (cpf) {
      this.setState({ cpf })
      await this.fetchBorrowerInfo()
    }
  }

  public fetchBorrowerInfo = async () => {
    this.setState({
      borrowerFetchInfoStatus: BorrowerFetchStatus.FetchingBorrowerInfo,
      selectedInstallmentsIds: [],
      valueToPay: 0,
      numberOfInstallmentsToPay: 0,
      installmentsReceiptEndpoint: '',
    })

    let borrower
    let limit
    let installments: InstallmentUI[]
    let operatorId = ''

    let cpf = this.props.history.location.state && this.props.history.location.state.cpf
    if (!cpf) {
      cpf = this.state.cpf
    }

    try {
      borrower = await bffParceirosApiService.borrowers.findBorrowerByCpf(cpf)
    } catch (err) {
      let borrowerErrorStatus
      err.response
        ? (borrowerErrorStatus = creditProfileErrorResponse[err.response.data.error])
        : (borrowerErrorStatus = creditProfileErrorResponse.UNKNOWN_ERROR)

      this.setState({
        borrowerError: borrowerErrorStatus,
        borrowerFetchInfoStatus: BorrowerFetchStatus.WaitingCPFInput,
      })
      toaster.showErrorToast(this.state.borrowerError)
      console.error(err)
    }

    if (borrower) {
      try {
        let store = bffParceirosApiService.coordinator.getStore()

        const user = await bffParceirosApiService.auth.decodeJwt()
        const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)
        operatorId = operator.id

        if (!store) {
          const storeId = operator.storeId

          store = await bffParceirosApiService.retailers.fetchStoreById(storeId)
          storeConfirmation(store)
        }

        limit = await bffParceirosApiService.coordinator.fetchBorrowerLimitForStore(borrower.id, store.id)
        const renegotiationPayments = (await bffParceirosApiService.coordinator.fetchPaymentsForBorrower(borrower.id))
          .payments

        if (renegotiationPayments.filter(p => p.status === 'DUE').length) {
          installments = renegotiationPayments
            .filter(p => p.status === 'DUE')
            .map(p => ({
              id: p.id as string,
              contractId: p.renegotiationId as string,
              installmentOrder: p.order,
              dueDate: p.dueDate,
              installmentValue: p.value,
              discount: 0,
              earlyPaymentDiscount: p.earlyPaymentDiscount as number,
              monetaryCorrection: p.monetaryCorrection as number,
              defaultInterest: p.defaultInterest as number,
              defaultFine: p.defaultFine as number,
              status: moment().format('YYYY-MM-DD') <= p.dueDate ? 'PENDING' : 'PAYMENT_OVERDUE',
              isRenegotiation: false,
              paymentDue: p.paymentDue as number,
              couponDiscount: 0,
              isFromRenegotiation: true,
            }))
            .sort(sortBy(['dueDate']))
          this.setState({
            installments,
          })
        } else {
          const fetchedIntallmentsAndFees: IInstallments = await bffParceirosApiService.coordinator.fetchInstallments({
            borrowerId: borrower.id,
          })
          installments = fetchedIntallmentsAndFees.installments
            .filter(i => i.status !== 'PAID')
            .map(i => ({ ...i, isFromRenegotiation: false }))
            .sort(sortBy(['dueDate', 'installmentValue']))

          const fetchedFees = fetchedIntallmentsAndFees.fees
          const feesTotalValue = fetchedFees?.reduce((total, installment) => total + installment.paymentDue, 0) ?? 0

          this.setState({
            installments,
            feesTotalValue,
            valueToPay: feesTotalValue,
            fees: fetchedFees,
          })
        }
      } catch (err) {
        let borrowerErrorStatus
        err.response
          ? (borrowerErrorStatus = creditProfileErrorResponse[err.response.data.error])
          : (borrowerErrorStatus = creditProfileErrorResponse.UNKNOWN_ERROR)

        this.setState({
          borrowerError: borrowerErrorStatus,
          borrowerFetchInfoStatus: BorrowerFetchStatus.WaitingCPFInput,
        })
        toaster.showErrorToast(this.state.borrowerError)
        console.error(err)
      }
    }

    if (borrower && borrower.phoneNumber && borrower.name && limit) {
      const cellphone = borrower.phoneNumber
      const formattedCellphone =
        '(' + cellphone.slice(3, 5) + ') ' + cellphone.slice(5, 10) + '-' + cellphone.slice(10, 14)
      this.setState({
        borrowerFetchInfoStatus: BorrowerFetchStatus.BorrowerInfoFound,
        approvedLimit: limit.totalLimit,
        availableLimit: limit.availableLimit,
        formattedCPF: '',
        operatorId,
        formattedCellphone,
        borrowerName: borrower.name,
        cpf: '',
        cellphone: borrower.phoneNumber,
        borrowerId: borrower.id,
        enableHighRecurrence: borrower.enableHighRecurrence,
      })
    }
  }

  askForPaymentConfirmation = () => {
    const paymentTotalValue = this.state.valueToPay

    const numberOfInstallments = this.state.installments.filter(x => this.state.selectedInstallmentsIds.includes(x.id))
      .length

    if (!paymentTotalValue) {
      return
    }

    this.setState({
      numberOfInstallmentsToPay: numberOfInstallments,
      isShowingPaymentOverlay: true,
    })
  }

  confirmPayment = async () => {
    const installmentsIds = this.state.selectedInstallmentsIds

    const installmentsFromRenegotiationIds = this.state.installments
      .filter(i => installmentsIds.includes(i.id) && i.isFromRenegotiation)
      .map(i => i.id)
    const originationIntallmentsIds = installmentsIds.filter(id => !installmentsFromRenegotiationIds.includes(id))

    let store = bffParceirosApiService.coordinator.getStore()

    if (!store) {
      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)
      const storeId = operator.storeId
      store = await bffParceirosApiService.retailers.fetchStoreById(storeId)
      storeConfirmation(store)
    }

    if (installmentsIds.length === 0) {
      return
    }

    this.setState({
      paymentRequestStatus: PaymentRequestStatus.RunningPaymentRequest,
    })

    let successfulPayments: string[] = []
    const storeId = store.id

    if (installmentsFromRenegotiationIds.length) {
      try {
        for (let i = 0; i < installmentsFromRenegotiationIds.length; i++) {
          const installmentId = installmentsFromRenegotiationIds[i]

          await bffParceirosApiService.coordinator.registerRenegotiationPayment({
            id: installmentId,
            storeId: storeId,
          })
          successfulPayments.push(installmentId)
        }
      } catch (error) {
        toaster.showErrorToast(installmentPaymentErrorResponse[error.response.data.error] ?? defaultErrorMessage)
        if (error.response.data.error == PaymentsErrors.DEACTIVATED_STORE) {
          this.props.dispatch(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
        }
      }
    }

    if (originationIntallmentsIds.length) {
      try {
        const registerMultiplePaymentsResponse = await bffParceirosApiService.coordinator.registerMultiplePayments(
          originationIntallmentsIds,
          storeId
        )
        const paidOriginationInstallmentsAndFeesIds = registerMultiplePaymentsResponse.map(i => i.id)
        successfulPayments = successfulPayments.concat(paidOriginationInstallmentsAndFeesIds)
      } catch (error) {
        toaster.showErrorToast(installmentPaymentErrorResponse[error.response.data.error] ?? defaultErrorMessage)
        if (error.response.data.error == PaymentsErrors.DEACTIVATED_STORE) {
          this.props.dispatch(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
        }
      }
    }

    if (successfulPayments.length > 0) {
      const firstPaidInstallment = this.state.installments.filter(i => i.id === successfulPayments[0])[0]
      // check if this is was a renegotiation payment
      if (firstPaidInstallment.isFromRenegotiation) {
        this.setState({
          installmentsReceiptEndpoint: resolveRenegotiationPaymentReceiptRoute(successfulPayments),
          paymentRequestStatus: PaymentRequestStatus.PaymentRequestSuccess,
        })
      } else {
        this.setState({
          installmentsReceiptEndpoint: resolvePaymentReceiptRoute(successfulPayments),
          paymentRequestStatus: PaymentRequestStatus.PaymentRequestSuccess,
        })
      }
    } else {
      this.setState({
        paymentRequestStatus: PaymentRequestStatus.WaitingUserConfirmation,
        isShowingPaymentOverlay: false,
      })
    }
  }

  public toggleInstallmentSelected = (id: string) => {
    const selectedInstallment = this.state.installments.filter(x => x.id === id)[0]

    const oldestUnselectedInstallmentForContract = this.state.installments
      .filter(
        x => x.contractId === selectedInstallment.contractId && !this.state.selectedInstallmentsIds.includes(x.id)
      )
      .sort(sortBy(['-installmentOrder']))
      ?.pop()

    const newestSelectedInstallmentForContract = this.state.installments
      .filter(x => x.contractId === selectedInstallment.contractId && this.state.selectedInstallmentsIds.includes(x.id))
      .sort(sortBy(['installmentOrder']))
      ?.pop()

    // we need to assert that we only add or remove installments for payment
    // in a contract if this installment is the oldest unselected installment
    // or the newest selected

    let newInstallmentsIds = this.state.selectedInstallmentsIds

    // a removal of the newest selected installment
    if (newInstallmentsIds.includes(id) && newestSelectedInstallmentForContract?.id === id) {
      newInstallmentsIds = newInstallmentsIds.filter(i => i !== id)
    }

    // an addition of the oldest unselected installment
    if (!newInstallmentsIds.includes(id) && oldestUnselectedInstallmentForContract?.id === id) {
      newInstallmentsIds = [...newInstallmentsIds, id]
    }

    const installmentsPaymentTotalValue = this.state.installments
      .filter(x => newInstallmentsIds.includes(x.id))
      .map(x => x.paymentDue)
      .reduce((x, y) => x + y, 0)

    this.setState({
      selectedInstallmentsIds: newInstallmentsIds,
      valueToPay: installmentsPaymentTotalValue + this.state.feesTotalValue,
    })
  }

  public onCPFChange = (values: NumberFormatValues) => {
    this.setState({ cpf: values.value, borrowerError: '' })
  }

  public handleKeyPressCpf = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.fetchBorrowerInfo()
    }
  }

  public closeOverlay = () => {
    this.setState({
      isShowingPaymentOverlay: false,
      paymentRequestStatus: PaymentRequestStatus.WaitingUserConfirmation,
    })
  }

  public cpfIsValid(cpf: string) {
    return validateBr.cpf(cpf)
  }

  public render() {
    const {
      borrowerFetchInfoStatus,
      paymentRequestStatus,
      installmentsReceiptEndpoint,
      cpfIntent,
      cpf,
      borrowerId,
      operatorId,
      borrowerName,
      formattedCellphone,
      approvedLimit,
      availableLimit,
      installments,
      numberOfInstallmentsToPay,
      isShowingPaymentOverlay: shouldShowPaymentOverlay,
      valueToPay,
      enableHighRecurrence,
      fees,
      selectedInstallmentsIds,
    } = this.state

    if (paymentRequestStatus === PaymentRequestStatus.RunningPaymentRequest) {
      return (
        <div style={formContainerStyle}>
          <PaymentInProgress />
        </div>
      )
    }

    if (paymentRequestStatus === PaymentRequestStatus.PaymentRequestSuccess) {
      return (
        <div style={formContainerStyle}>
          <RenderReceipts
            numberOfInstallments={numberOfInstallmentsToPay}
            paidValue={valueToPay}
            renderURL={installmentsReceiptEndpoint}
          />
        </div>
      )
    }

    const anyInstallmentRenegotiated = installments.filter(x => x.isRenegotiation).length > 0

    return (
      <div>
        <PageHeader identificationUserContainerStyle={{ marginBottom: '2%', marginTop: '2%', marginLeft: '2%' }} />
        <Header>
          <h1 style={h5Style} className="bp3-heading">
            Pagamentos
          </h1>
        </Header>
        <div style={formContainerStyle}>
          <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
            <div style={textInputGroupStyle} onKeyDown={this.handleKeyPressCpf}>
              <div style={textInputGroupWithLabelStyle}>
                <Label style={{ ...formItemStyle, marginTop: '4px', marginBottom: '4px' }} className="bp3-label">
                  CPF
                </Label>
                <NumberFormat
                  style={formItemStyle}
                  format="###.###.###-##"
                  mask="_"
                  id="text-input"
                  placeholder="000.000.000-00"
                  tabIndex={1}
                  intent={cpfIntent}
                  value={cpf}
                  autoComplete="off"
                  className={`bp3-input-group bp3-input ${
                    cpf && !this.cpfIsValid(cpf) ? 'bp3-intent-danger' : 'bp3-intent-none'
                  } `}
                  onValueChange={this.onCPFChange}
                  autoFocus
                />
              </div>
              <div>
                <Button
                  style={{ ...formItemStyle, marginLeft: '16px', ...buttonStyle }}
                  rightIcon={borrowerFetchInfoStatus === BorrowerFetchStatus.WaitingCPFInput && 'search'}
                  intent="primary"
                  text={borrowerFetchInfoStatus === BorrowerFetchStatus.WaitingCPFInput && 'Pesquisar'}
                  disabled={borrowerFetchInfoStatus !== BorrowerFetchStatus.WaitingCPFInput}
                  onClick={this.fetchBorrowerInfo}
                  loading={borrowerFetchInfoStatus === BorrowerFetchStatus.FetchingBorrowerInfo}
                >
                  {borrowerFetchInfoStatus === BorrowerFetchStatus.BorrowerInfoFound && <Icon icon="tick" />}
                </Button>
              </div>
            </div>
            {borrowerFetchInfoStatus === BorrowerFetchStatus.BorrowerInfoFound && (
              <>
                <BorrowerProfile
                  borrowerId={borrowerId}
                  operatorId={operatorId}
                  phoneNumber={formattedCellphone}
                  name={borrowerName}
                  approvedLimit={approvedLimit}
                  availableLimit={availableLimit}
                  enableHighRecurrence={enableHighRecurrence}
                />
                <Divider />
              </>
            )}
            {installments.length === 0 && borrowerFetchInfoStatus === BorrowerFetchStatus.BorrowerInfoFound && (
              <div style={{ marginTop: '32px' }}>
                <H4>Não há parcelas pendentes</H4>
              </div>
            )}
            {installments.length > 0 && (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '32px',
                    flexWrap: 'wrap',
                  }}
                >
                  <H3 className="bp3-heading">Parcelas</H3>
                  <PaymentResumeCard
                    valueToPay={valueToPay}
                    hasSelectedRows={Boolean(selectedInstallmentsIds.length)}
                    hasFees={Boolean(fees?.length)}
                  />
                  <Button
                    style={{ width: '10em' }}
                    intent="success"
                    large
                    text={'Pagar'}
                    onClick={this.askForPaymentConfirmation}
                    disabled={installments.filter(x => this.state.selectedInstallmentsIds.includes(x.id)).length === 0}
                    loading={borrowerFetchInfoStatus === BorrowerFetchStatus.FetchingBorrowerInfo}
                  />
                </div>
                <Divider />
                <InstallmentsTable
                  installments={installments}
                  selectedInstallmentsIds={selectedInstallmentsIds}
                  onInstallmentSelected={this.toggleInstallmentSelected}
                />
                <br />
                {anyInstallmentRenegotiated && ( // message shows only if there is any installments renegotiated
                  <span>
                    <strong>** Caso de renegociação.</strong>
                    <br />
                    Se o cliente tiver dúvidas, peça para entrar em contato com a UME pelo WhatsApp:
                    {formatCellphoneWithoutNinthDigit(featureFlag.UME_PHONES.customerService.whatsapp)}
                  </span>
                )}
              </>
            )}
          </Card>
          <ConfirmPaymentDialog
            shouldShowPaymentOverlay={shouldShowPaymentOverlay}
            hasFees={Boolean(fees?.length)}
            numberOfInstallments={numberOfInstallmentsToPay}
            totalValue={valueToPay}
            confirmPayment={this.confirmPayment}
            closeOverlay={this.closeOverlay}
          />
        </div>
      </div>
    )
  }
}

const Header = styled.div`
  display: flex;
  margin-top: 70px;
  margin-left: 30px;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
export default connect()(Payments)
