import Lottie from "lottie-react";
import styled from 'styled-components';
import { green, white } from '../../ui/utils/_colors';

export const MainContainer = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
`

export const InnerContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${green.background1};
    margin-top: 56px;
`

export const ContentContainer = styled.div`
    position: relative;
`

export const Animation = styled(Lottie)`
    width: 120px;
    heigth: 120px;
`

export const Text = styled.p`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 5px;
    font-size: 1.125rem;
    font-weight: 500;
    color: ${white.primary};
    margin: 0;
`