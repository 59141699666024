import React, { PureComponent } from 'react'
import { Icon } from './styles'

export default class SmartphoneIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props}>
        <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.25 86.25C24.1875 86.25 22.4219 85.5156 20.9531 84.0469C19.4844 82.5781 18.75 80.8125 18.75 78.75V11.25C18.75 9.1875 19.4844 7.42188 20.9531 5.95312C22.4219 4.48438 24.1875 3.75 26.25 3.75H63.75C65.8125 3.75 67.5781 4.48438 69.0469 5.95312C70.5156 7.42188 71.25 9.1875 71.25 11.25V78.75C71.25 80.8125 70.5156 82.5781 69.0469 84.0469C67.5781 85.5156 65.8125 86.25 63.75 86.25H26.25ZM26.25 67.5V78.75H63.75V67.5H26.25ZM45 76.875C46.0625 76.875 46.9531 76.5156 47.6719 75.7969C48.3906 75.0781 48.75 74.1875 48.75 73.125C48.75 72.0625 48.3906 71.1719 47.6719 70.4531C46.9531 69.7344 46.0625 69.375 45 69.375C43.9375 69.375 43.0469 69.7344 42.3281 70.4531C41.6094 71.1719 41.25 72.0625 41.25 73.125C41.25 74.1875 41.6094 75.0781 42.3281 75.7969C43.0469 76.5156 43.9375 76.875 45 76.875ZM26.25 60H63.75V22.5H26.25V60ZM26.25 15H63.75V11.25H26.25V15Z" />
        </svg>
      </Icon>
    )
  }
}
