import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import {
  IBillingStatus,
  IPaymentMethod,
} from '@scudraservicos/coordinator-client/dist/src/services/billings/interfaces/billings.req.interface'
import {
  IBilling,
  IBillingPaymentForRetailer,
  IBillingsPaymentsAgg,
} from '@scudraservicos/coordinator-client/dist/src/services/billings/interfaces/billings.res.interface'
import { IRetailer } from '@scudraservicos/coordinator-client/dist/src/services/retailers/interfaces/Retailer.res.interface'
import { IStoreListResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/StoreList.res.interface'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { Cart, Download, History } from 'grommet-icons'
import React from 'react'
import styled, { css } from 'styled-components'
import * as XLSX from 'xlsx'
import { toaster } from '../../App'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { formatDate } from '../../common/FormatDate'
import { getRandomColor } from '../../common/RandomColor'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import { FinancialCard } from '../../molecules/FinancialPayments/FinancialCard'
import { FinancialFilter } from '../../molecules/FinancialPayments/FinancialFilter'
import { Tag } from '../../molecules/FinancialPayments/Tag'
import { LoadingButton } from '../../molecules/LoadingButton/LoadingButton'
import { bffParceirosApiService } from '../../services/bff'
import {
  financialIncomingPaymentsCard,
  financialOverduePaymentsCard,
  financialPendingPaymentsCard,
  UMEDataTableHeader,
} from './config'

interface IFinancialPageProps {
  history: any
}

interface IFinancialPageState {
  retailerName: string
  storesName: string[]

  selectedStores: {
    name: string
    backgroundColor: string
    textColor: string
  }[]

  originalData?: any[]
  data?: any[]
  selectedBilling?: IBilling
  selectedTableIndex: number
  isDownloadingPayments: boolean

  pendingCard?: { quantity: number; price: number }
  overdueCard?: { quantity: number; price: number }
  incomingPaymentsCard?: { quantity: number; price: number }

  selectedRetailerIndex?: number
}

const DEFAULT_SELECT_OPTION = -1

export default class FinancialPaymentsPage extends React.Component<IFinancialPageProps, IFinancialPageState> {
  private retailer?: IRetailer
  private stores?: IStoreResponse[]

  private user?: ILoginProfileResponse
  private retailerId?: string
  private selectedStoresId: string[]

  constructor(props: IFinancialPageProps) {
    super(props)

    this.selectedStoresId = []

    this.user = bffParceirosApiService.auth.getUser()
    if (this.user) {
      this.retailerId = this.user.retailerId
    }

    this.state = {
      selectedStores: [],
      originalData: undefined,
      data: undefined,
      selectedBilling: undefined,
      retailerName: this.retailerId ? this.retailerId : '',
      storesName: [],
      isDownloadingPayments: false,
      pendingCard: undefined,
      overdueCard: undefined,
      incomingPaymentsCard: undefined,
      selectedTableIndex: DEFAULT_SELECT_OPTION,

      selectedRetailerIndex: undefined,
    }

    this.retailer = undefined
    this.loadRetailersFromAPI()
    this.fetchBillingsFromAPI()
    this.loadStoresFromAPI()
  }

  // Loads Retailers from API in order to replace the IDs coming from Billings API
  loadRetailersFromAPI = () => {
    if (!this.retailerId) return

    bffParceirosApiService.retailers
      .fetchRetailerById(this.retailerId)
      .then(retailer => {
        this.setState({ retailerName: retailer.fantasyName })
      })
      .catch(error => {
        toaster.showErrorToast(`Não foi possível carregar o varejista`)
      })
  }

  // Loads Stores from API in order to replace the IDs coming from Billings API
  loadStoresFromAPI = () => {
    bffParceirosApiService.retailers
      .fetchStores()
      .then((result: IStoreListResponse) => {
        // TODO - Create an endpoint for this
        // 1. Filter only the retailer's store
        this.stores = result.stores.filter((store: IStoreResponse) => store.retailerId === this.retailerId)

        // 2. Get only the store's name
        let storesName = this.stores.map((store: IStoreResponse) => store.name)
        this.setState({ storesName })
      })
      .catch((error: any) => {
        // TODO - Toaster with error
        toaster.showErrorToast(`Erro ao carregar lojas`)
      })
  }

  // Fetches Billings from Backend
  fetchBillingsFromAPI = () => {
    this.setState({
      data: undefined,
      incomingPaymentsCard: undefined,
      overdueCard: undefined,
      pendingCard: undefined,
      selectedBilling: undefined,
      selectedTableIndex: DEFAULT_SELECT_OPTION,
    })

    let params = {
      retailerId: this.retailerId,
      storeId: this.selectedStoresId,
    }
    this.fetchPaymentsFromAPI()

    bffParceirosApiService.billings
      .fetchBillings(params)
      .then((billingsResponse: IBilling[]) => {
        let pendingBills = { quantity: 0, price: 0 }
        let overdueBills = { quantity: 0, price: 0 }

        let billings = billingsResponse.map((billing: IBilling) => {
          // TODO - Invoice is not optional
          let stores = billing.stores
            ? billing.stores.length > 2
              ? 'Várias Lojas'
              : billing.stores.map(s => this.findStoreName(s)).join(', ')
            : 'Todas as lojas'

          // Update cards
          // Renegotiated billings count as expired
          let billingStatus = billing.status as string
          if (billing.status === 'pending' && billing.numberOfInvoices > 1) {
            billingStatus = 're-issuance'
            overdueBills.quantity += billing.paymentsCount
            overdueBills.price += billing.paymentsSum
          } else if (billing.status === 'pending') {
            pendingBills.quantity += billing.paymentsCount
            pendingBills.price += billing.paymentsSum
          } else if (billing.status === 'expired') {
            overdueBills.quantity += billing.paymentsCount
            overdueBills.price += billing.paymentsSum
          }

          return {
            ...billing,
            status: billingStatus,
            retailerName: this.state.retailerName,
            storesName: stores,
          }
        })

        this.setState({
          data: billings,
          originalData: billings,
          pendingCard: pendingBills,
          overdueCard: overdueBills,
        })
      })
      .catch((error: any) => {
        toaster.showErrorToast(`Erro ao buscar cobranças`)
      })
  }

  // Fetches payments from API
  //  - Updates card "Em Aberto": financialOpenedPaymentsCard
  fetchPaymentsFromAPI = () => {
    let params = {
      retailerId: this.retailerId,
      storeId: this.selectedStoresId,
      status: 'unbilled' as IBillingStatus,
      method: 'STORE' as IPaymentMethod,
    }

    bffParceirosApiService.billings
      .fetchBillingsPaymentsAgg(params)
      .then((paymentsAgg: IBillingsPaymentsAgg) => {
        let incomingPaymentsCard = {
          quantity: paymentsAgg.paymentsCount,
          price: paymentsAgg.paymentsSum,
        }

        this.setState({ incomingPaymentsCard })
      })
      .catch(error => {
        toaster.showErrorToast(`Erro ao buscar pagamentos!`)

        if (error && error.response && error.response.data) {
          console.error(error.response.data)
        } else {
          console.error(error)
        }
      })
  }

  // When Store is selected from Dropdown: it gives the dropdown index
  onStoreSelect = (index?: number) => {
    if (!index) {
      this.selectedStoresId = []
      this.setState({ selectedStores: [] })
      this.fetchBillingsFromAPI()
      return
    }
    if (!this.stores) return

    let selectedStore = this.stores[index]

    let selectedStores = Object.assign([], this.state.selectedStores)

    // If store was already selected, ignore it
    if (this.state.selectedStores.find(store => store.name === selectedStore.name)) {
      toaster.showWarningToast(`Loja ${selectedStore.name} já selecionada!`)
      return
    }

    // Sets random color
    let color = getRandomColor()
    this.selectedStoresId.push(selectedStore.id)
    selectedStores.push({
      name: selectedStore.name,
      backgroundColor: color.backgroundColor,
      textColor: color.textColor,
    })

    this.fetchBillingsFromAPI()

    this.setState({ selectedStores })
  }

  // When Table Row is clicked, it can be a deselection event
  onTableRowClicked = (element: any, index: number) => {
    let isDeselection = this.state.selectedTableIndex === index
    if (isDeselection) {
      this.setState({ selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
    } else {
      this.setState({ selectedBilling: element, selectedTableIndex: index })
    }
  }

  // On tag clicked: remove store
  onTagClicked = (label: string, index: number) => {
    if (!this.state.selectedStores) return
    let selectedStores = Object.assign([], this.state.selectedStores)
    selectedStores.splice(index, 1)
    this.selectedStoresId.splice(index, 1)

    // Updates screen (damn this API suffers)
    this.fetchBillingsFromAPI()
    this.setState({ selectedStores })
  }

  // On Filter selected on the Data Table: Filter data so DataTable can render only the filtered elements
  onFilterSelected = (attribute: string, valueChangedTo: string) => {
    // Reset the filtered data to the original data
    // TODO - Export the default value from Data Table as: DataTable.DEFAULT_FILTER
    if (valueChangedTo === 'Todos') {
      let data = Object.assign([], this.state.originalData)
      this.setState({ data, selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
      return
    }

    let data = Object.assign([], this.state.originalData)
    data = data.filter((el: any) => el[attribute] === valueChangedTo)

    this.setState({ data, selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
  }

  // When clicked on Download Payments
  onDownloadPayments = async () => {
    this.setState({ isDownloadingPayments: true })

    if (this.state.selectedBilling) {
      return bffParceirosApiService.billings
        .fetchBillingsPaymentsForRetailers({ billingId: String(this.state.selectedBilling.id) })
        .then((data: IBillingPaymentForRetailer[]) => {
          let dataInPortuguese = data.map(payment => ({
            id: payment.id,
            data_pagamento: formatDate(payment.paymentTimestamp),
            valor_pago: payment.paidValue,
            loja: this.findStoreName(payment.paymentStoreId),
            operador: payment.paymentOperatorId,
          }))

          // TODO - Improve this file name
          let fileName = 'pagamentos'
          if (this.state.selectedBilling) {
            fileName += `_INICIO_${this.state.selectedBilling.startDate}_FIM_${this.state.selectedBilling.startDate}.xlsx`
          } else {
            fileName += `.xlsx`
          }
          this.setState({ isDownloadingPayments: false })
          const ws = XLSX.utils.json_to_sheet(dataInPortuguese)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, `Pagamentos`)
          XLSX.writeFile(wb, fileName)
          toaster.showSuccessToast(`Pagamentos baixados!`)
        })
        .catch((error: any) => {
          toaster.showErrorToast(`Erro ao baixar pagamentos`)
          this.setState({ isDownloadingPayments: false })

          if (error && error.response && error.response.data) {
            console.error(error.response.data)
          } else {
            console.error(error)
          }
        })
    }
  }

  onDownloadIncomingPayments = async () => {
    let params = {
      retailerId: this.retailerId,
      storeId: this.selectedStoresId,
      status: 'unbilled' as IBillingStatus,
    }

    // TODO - Set Loading on UI for this
    bffParceirosApiService.billings
      .fetchBillingsPaymentsForRetailers(params)
      .then((data: IBillingPaymentForRetailer[]) => {
        let dataInPortuguese = data.map(payment => ({
          id: payment.id,
          data_pagamento: formatDate(payment.paymentTimestamp),
          valor_pago: payment.paidValue,
          loja: this.findStoreName(payment.paymentStoreId),
          operador: payment.paymentOperatorId,
        }))

        // TODO - Improve this file name
        let fileName = 'pagamentos_entrando.xlsx'

        this.setState({ isDownloadingPayments: false })
        const ws = XLSX.utils.json_to_sheet(dataInPortuguese)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Pagamentos`)
        XLSX.writeFile(wb, fileName)

        toaster.showSuccessToast(`Pagamentos baixados!`)
      })
      .catch((error: any) => {
        toaster.showErrorToast(`Erro ao baixar pagamentos`)
        this.setState({ isDownloadingPayments: false })

        if (error && error.response && error.response.data) {
          console.error(error.response.data)
        } else {
          console.error(error)
        }
      })
  }

  // Internal usage only - It could be generalized but the function name wouldn't be as clear as it is now
  findStoreName = (storeId: string) => {
    if (!this.stores) return storeId
    else {
      let store = this.stores.find(store => store.id === storeId)
      if (!store) return storeId
      else return store.name
    }
  }

  render() {
    let {
      isDownloadingPayments,
      data,
      selectedBilling,
      selectedStores,
      storesName,
      pendingCard,
      incomingPaymentsCard,
      overdueCard,
      selectedTableIndex,
    } = this.state

    return (
      <PageContainer>
        {/* FILTERS - DROPDOWN */}
        <FiltersContainer content="Filters Container">
          <FinancialUMEFilter
            Icon={<CartIcon color={UMEColors.orange.amber} />}
            label={'Escolha as lojas'}
            default={'Todas as lojas'}
            select={storesName}
            onDropDownSelect={this.onStoreSelect}
            disabled={!this.retailerId}
          />
          <TagsContainer>
            {selectedStores &&
              selectedStores.map((store, index: number) => (
                <Tag
                  index={index}
                  key={index}
                  label={store.name}
                  backgroundColor={store.backgroundColor}
                  onClick={this.onTagClicked}
                />
              ))}
          </TagsContainer>
        </FiltersContainer>

        {/* CARDS */}
        <UMEFinancialCardsContainer>
          <FinancialCard
            data={pendingCard}
            header={financialPendingPaymentsCard.header}
            card={financialPendingPaymentsCard.card}
          />
          <FinancialCard
            data={overdueCard}
            header={financialOverduePaymentsCard.header}
            card={financialOverduePaymentsCard.card}
            red
          />
          <VerticalLine />
          <FinancialCard
            data={incomingPaymentsCard}
            header={financialIncomingPaymentsCard.header}
            card={financialIncomingPaymentsCard.card}
            download={financialIncomingPaymentsCard.download}
            onDownload={this.onDownloadIncomingPayments}
          />
        </UMEFinancialCardsContainer>

        {/* TABLE SECTION */}
        <TableSectionContainer>
          <CabinetIcon />
          <TableSectionTitle> Histórico dos Boletos {data ? `(${data.length})` : ``} </TableSectionTitle>
        </TableSectionContainer>

        <TableContainer isLoading={data ? false : true}>
          <UMEDataTable
            data={data}
            header={UMEDataTableHeader}
            onRowElementClick={this.onTableRowClicked}
            onFilterSelected={this.onFilterSelected}
            selectedIndex={selectedTableIndex}
          />
        </TableContainer>

        {/* DOWNLOAD PAYMENTS */}
        <DownloadInstallmentsContainer>
          <span>
            {selectedBilling
              ? 'Baixe os pagamentos da cobrança selecionada'
              : 'Selecione uma cobrança para baixar seus pagamentos'}
          </span>

          {/* TODO - Check why Icon get small on mobile */}
          <DownloadLoadingButton
            disabled={selectedBilling ? false : true}
            icon={<Download color={UMEColors.white.primary} />}
            label="Baixar pagamentos"
            onClick={this.onDownloadPayments}
            isLoading={isDownloadingPayments}
          />
        </DownloadInstallmentsContainer>
      </PageContainer>
    )
  }
}

const PageContainer = styled.div`
  padding: 24px 70px 24px 70px;

  @media (max-width: ${mobile}) {
    padding: 14px 18px 14px 18px;
  }
`

const FinancialUMEFilter = styled(FinancialFilter)`
  flex: 1 1 400px;

  margin: 10px 10px 10px 0px;

  max-width: 400px;
  &:nth-child(1) {
    margin-right: 5vw;
  }

  @media (max-width: ${mobile}) {
    &:nth-child(1) {
      margin-right: 0vw;
    }
  }
`
const CartIcon = styled(Cart)`
  height: 28px;
  width: 28px;
`

const FiltersContainer = styled.div`
  margin-top: 0vh;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const TagsContainer = styled.div`
  margin-left: 20px;
  min-height: 6vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  // Tags inside the container
  & > div {
    margin: 10px;
  }

  @media (max-width: ${mobile}) {
    margin-left: 0;
    margin-top: 2vh;
  }
`

const UMEFinancialCardsContainer = styled.div`
  margin-top: 4vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${mobile}) {
    display: none;
    display: flex;
    justify-content: center;
  }
`

const VerticalLine = styled.div`
  box-sizing: border-box;
  height: 282px;
  width: 1px;
  border: 1px solid ${UMEColors.black.primary};
  opacity: 0.1;
  margin: 10px 39.5px 0px 39.5px;

  @media (max-width: ${mobile}) {
    display: none;
  }
`

const CabinetIcon = styled(History)`
  height: 26px;
  width: 26px;
  // TODO - Not in Design Kit
  fill: #6e6e6e;
`
const TableSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 4vh;
`

const TableSectionTitle = styled.div`
  text-align: center;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 18px;
`

const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;
`
const DownloadInstallmentsContainer = styled.div`
  margin-top: 4vh;
  padding-left: 3vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  color: ${UMEColors.black.primary}
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;

  & > span {
    flex: 1 1 400px;
    max-width: 400px;
  }

  & > button {
    flex: 1;
    max-width: 250px;
  }

  @media (max-width: ${mobile}) {
    padding-left: 0;

    & > span {
      min-width: 0px;
    }
  }
`

const DownloadLoadingButton = styled(LoadingButton)`
  margin-left: 3vw;
  border-radius: 6px;
  padding: 5px;
  // Not in
  background-color: ${UMEColors.green.dark};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);

  padding: 13px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.4;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      &:hover {
        opacity: 0.2;
        cursor: not-allowed;
        transition: 0.4s;
      }
      opacity: 0.2;
      cursor: not-allowed;
      transition: 0.4s;
    `}
`
