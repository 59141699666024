import React from 'react'
import { formatBRL } from '../../../../cash-register/common/formatBRL'
import { ReactComponent as ApprovedCheckSvg } from '../../../../common/assets/images/approved-check-circle.svg'
import { DateFormat, formatDate, TimeZone } from '../../../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../../../common/TransactionalCreditHelper'
import { AggregatedTransactionalCreditEvaluation } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import {
  AlertInfoContainer,
  AlertInfoMessage,
  Card,
  CardHeader,
  CardInfoContainer,
  CardRequestedValueContainer,
  CardTitle,
  CardValue,
  FinalizeText,
  HeaderContainer,
  InfoDescription,
  InfoValue,
  InstallmentsText,
  InstallmentsValueText,
  MainContainer,
  NavigateButton,
  Row,
  ShowSimulationIcon,
  ShowSimulationText,
  StraightLine,
  Subtitle,
  TableData,
  TableRow,
  TableWrapper
} from './TransactionalCreditApprovedResult.styles'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
  handleGoToApplication?: () => void
  handleGoBackToHomePage?: () => void
}

export const TransactionalCreditApprovedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart, handleGoToApplication, handleGoBackToHomePage } = props
  const [showSimulation, setShowSimulation] = React.useState<boolean>(false)
  const totalAmount = calculateTxCreditTotalAmount(evaluationResult.evaluation.policy!!.maxAmount, (evaluationResult.limit?.availableLimit || 0))
 
  const handleShowSimulationClick = () => {
    setShowSimulation(!showSimulation)
  }

  return (
    <div>
      <MainContainer>
        <HeaderContainer>
          <FinalizeText onClick={handleGoBackToStart}>Finalizar</FinalizeText>
        </HeaderContainer>

        <Card>
          <CardHeader>
            <ApprovedCheckSvg />
            <CardTitle>Super Compra Ume aprovada:</CardTitle>
            <CardRequestedValueContainer>
              <CardValue>{formatBRL(totalAmount)}</CardValue>
            </CardRequestedValueContainer>
          </CardHeader>

          <StraightLine />

          <CardInfoContainer>
            <InfoDescription>Validade</InfoDescription>
            <InfoValue>
              {formatDate(evaluationResult.evaluation.expirationTimestamp, DateFormat.BR_DATE_FORMAT, TimeZone.MANAUS)}
            </InfoValue>
          </CardInfoContainer>

          <StraightLine />

          <AlertInfoContainer>
            <Subtitle>Importante:</Subtitle>
            <>
              { !hasCompletedApplication && (
                <Row>
                  {`\u2022`}
                  <AlertInfoMessage>
                    Prossiga com o cadastro do cliente para utilizar o limite pré-aprovado.
                  </AlertInfoMessage>
                </Row>
              )}
              <Row>
                {`\u2022`}
                <AlertInfoMessage>
                  Solicite ao cliente que baixe o aplicativo da Ume para acompanhar o seu limite.
                </AlertInfoMessage>
              </Row>
            </>
          </AlertInfoContainer>
        </Card>

        {evaluationResult?.simulation?.length && (
          <>
            <ShowSimulationText onClick={handleShowSimulationClick}>
              {showSimulation ? 'Ocultar' : 'Visualizar'} opções de parcelamento
              <ShowSimulationIcon icon={showSimulation ? 'chevron-up' : 'chevron-down'} size={20} />
            </ShowSimulationText>
            {showSimulation && (
              <TableWrapper>
                {evaluationResult.simulation.map((installment, id) => {
                  return (
                    <TableRow key={`installment-${id}`}>
                      <TableData width="30%"><InstallmentsText>{installment.numberOfInstallments}x</InstallmentsText></TableData>
                      <TableData width="70%"><InstallmentsValueText>{formatBRL(installment.installmentAmount)}</InstallmentsValueText></TableData>
                    </TableRow>
                  )
                })}
              </TableWrapper>
            )}
          </>
        )}

        { !hasCompletedApplication && (
          <NavigateButton onClick={handleGoToApplication} backgroundColor="#12aa6a" textColor="#ffffff">
            Cadastrar cliente
          </NavigateButton>
        )}
        <NavigateButton onClick={handleGoBackToHomePage} backgroundColor="#ffffff" textColor="#12aa6a">
          Voltar para a tela inicial
        </NavigateButton>
      </MainContainer>
    </div>
  )
}
