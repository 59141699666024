import { Icon } from '@blueprintjs/core'
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import * as configs from '../config/config'
import LocalCashierSelector from '../molecules/Cashier/LocalCashierSelector'
import PageHeader from '../molecules/header/components/PageHeader/PageHeader'
import LoadingModal from '../molecules/LoadingModal/LoadingModal'
import analyticsService from '../services/analytics/analytics.service'
import { AnalyticsEvents } from '../services/analytics/events'
import { bffParceirosApiService } from '../services/bff'
import { CaptureMethodService } from '../services/captureMethodService/CaptureMethodService'
import { CashierOption, cashierOptionIcon, cashierOptions, cashierOptionsContainer } from './styles'

interface ICashRegisterSelectionState {
  focusedOption: number
  shouldRedirectTo: string | null
  loading: boolean
}

export default class CashRegisterSelection extends React.Component<{}, ICashRegisterSelectionState> {
  readonly state: ICashRegisterSelectionState = {
    focusedOption: 0,
    shouldRedirectTo: null,
    loading: false,
  }

  private optionsRefs: React.RefObject<HTMLDivElement>[]

  constructor(props: {}) {
    super(props)
    this.optionsRefs = [React.createRef(), React.createRef()]
  }

  setFocusedOption = (option: number) => {
    const card = this.optionsRefs[option].current

    this.setState({
      focusedOption: option,
    })

    if (card) {
      card.focus()
    }
  }

  componentDidMount() {
    this.setFocusedOption(0)
  }

  public redirect = () => {
    const selectedOption = this.state.focusedOption

    if (selectedOption == 0) this.handlePurchasePressed()
    else this.handlePaymentPressed()
  }

  handlePaymentPressed = () => {
    analyticsService.sendEvent(AnalyticsEvents.cashier_payment_button)
    this.setState({ ...this.state, shouldRedirectTo: '/caixa/pagamentos' })
  }

  handlePurchasePressed = async () => {
    analyticsService.sendEvent(AnalyticsEvents.cashier_purchase_button)
    const store = await this.getStoreWithSettings()

    if (CaptureMethodService.shouldUseQrCodeCaptureMethod(store?.settings?.captureMethod))
      this.setState({ ...this.state, shouldRedirectTo: '/caixa/compras/qrcode' })
    else this.setState({ ...this.state, shouldRedirectTo: '/caixa/compras' })
  }

  getStoreWithSettings = async () => {
    let store = bffParceirosApiService.coordinator.getStore()
    if (!store || !!store.settings) return store

    this.setState({ ...this.state, loading: true })
    store = await bffParceirosApiService.retailers.fetchStoreById(store.id)
    this.setState({ ...this.state, loading: false })

    if (!!store) bffParceirosApiService.coordinator.setStore(store)

    return store
  }

  public handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key.startsWith('Arrow') || e.key === 'Tab') {
      e.preventDefault()
      e.stopPropagation()

      this.setFocusedOption((this.state.focusedOption + 1) % 2)
    }

    if (e.key === 'Enter') {
      this.redirect()
    }
  }

  public render() {
    const { focusedOption, shouldRedirectTo, loading } = this.state

    if (shouldRedirectTo) {
      return <Redirect push to={shouldRedirectTo} />
    }

    return (
      <div>
        <PageHeader identificationUserContainerStyle={{ marginLeft: 70, marginTop: 30 }} />

        <LoadingModal enabled={loading} />

        {configs.showLocalCashierSelector && <LocalCashierSelector />}

        <div style={cashierOptionsContainer} onKeyDown={this.handleKeyPress}>
          <div style={cashierOptions}>
            <CashierOption
              className={`bp3-card bp3-elevation-${focusedOption === 0 ? '3' : '1'}`}
              ref={this.optionsRefs[0]}
              tabIndex={1}
              onMouseOver={() => this.setFocusedOption(0)}
              onFocus={() => this.setFocusedOption(0)}
              onClick={this.redirect}
            >
              <h2>Compras</h2>
              <Icon style={cashierOptionIcon} icon="shopping-cart" iconSize={160} intent={'primary'} />
            </CashierOption>
            <CashierOption
              className={`bp3-card bp3-elevation-${focusedOption === 1 ? '3' : '1'}`}
              ref={this.optionsRefs[1]}
              tabIndex={2}
              onMouseOver={() => this.setFocusedOption(1)}
              onFocus={() => this.setFocusedOption(1)}
              onClick={this.redirect}
            >
              <h2>Pagamentos</h2>
              <Icon style={cashierOptionIcon} icon="dollar" iconSize={160} intent={'primary'} />
            </CashierOption>
          </div>
        </div>
      </div>
    )
  }
}
