import { TextInputProps as GrommetTextInputProps } from 'grommet'
import React, { Component } from 'react'
import { StyledBoxInput, StyledLabel, StyledTextInput } from './styles'

interface TextInputProps {
  label?: string
  errorIntent?: boolean
}

export default class TextInput extends Component<
  TextInputProps & GrommetTextInputProps & Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder'>
> {
  render() {
    return (
      <StyledBoxInput errorIntent={this.props.errorIntent}>
        <StyledTextInput {...this.props}>{this.props.children}</StyledTextInput>
        {this.props.label && <StyledLabel required={this.props.required}>{this.props.label}</StyledLabel>}
      </StyledBoxInput>
    )
  }
}
