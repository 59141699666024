import { Box } from 'grommet'
import * as React from 'react'
import ErrorString from '../../../../atoms/ErrorString/ErrorString'
import umeLogo from '../../../../common/assets/images/ume-logo.jpeg'
import { Text, TextInput } from '../../../../legacy-lib/design-kit-ui'
import { gray } from '../../../../legacy-lib/design-kit-ui/Palette'
import { LoginFormStep } from '../LoginForm/LoginForm'
import { ButtonBox, LoginHeading, LogoImg } from './style'

export interface IEmailStepProps {
  username: string
  error?: string
  onStepChange: (step: LoginFormStep) => void
  onUsernameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface IEmailStepState {}

export default class EmailStep extends React.Component<IEmailStepProps, IEmailStepState> {
  constructor(props: IEmailStepProps) {
    super(props)

    this.state = {}
  }

  public onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation()
    if (event.key === 'Enter') {
      this.props.onStepChange(LoginFormStep.PASSWORD_STEP)
    }
  }

  public render() {
    return (
      <Box margin="medium">
        <Box align="center" justify="center">
          <LogoImg src={umeLogo} />

          <Box justify="center" align="center" margin="medium">
            <LoginHeading size="small" level="3">
              Login
            </LoginHeading>
            <Text color={gray.aluminium} size="small">
              Ir para plataforma Ume
            </Text>
          </Box>
          <TextInput
            value={this.props.username}
            onChange={this.props.onUsernameChange}
            placeholder="Digite seu e-mail"
            autoFocus={true}
            type="email"
            label="E-mail"
            errorIntent={!!this.props.error}
            onKeyDown={this.onKeyDown}
          />
        </Box>
        <Box>{this.props.error && <ErrorString text={this.props.error} />}</Box>
        <ButtonBox onClick={() => this.props.onStepChange(LoginFormStep.PASSWORD_STEP)}>Continuar</ButtonBox>
      </Box>
    )
  }
}
