import React from 'react';
import styled from 'styled-components';

interface Props {
  backgroundColor?: string
  backgroundDisabledColor?: string
}

export const BigButton = (props: JSX.IntrinsicElements['button'] & Props) => {
  return (
    <Button
      {...props}
      backgroundColor={props.backgroundColor || '#13C450'}
      backgroundDisabledColor={props.backgroundDisabledColor || '#a9e1bc'}
    >
      {props.children}
    </Button>
  );
};

const Button = styled.button`
  // BUTTON
  background: ${(props: JSX.IntrinsicElements['button'] & Props) => (
    props.disabled ? props.backgroundDisabledColor : props.backgroundColor
  )};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 70px;
  width: 100%;

  // TEXT
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;

  // SHADOW
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25));
`;