import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { OperatorPerformanceInfoResponse, OperatorServiceCenterBorrowerResponse } from './dtos/OperatorServiceCenter.dto'

export default class OperatorServiceCenterService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async fetchBorrower(document: string, storeId: string): Promise<OperatorServiceCenterBorrowerResponse> {
    const response = await this.axiosInstance.get<OperatorServiceCenterBorrowerResponse>(`/operator-service-center`, {
      params: { document, storeId },
    })

    return response.data
  }

  async fetchOperatorPerformance(): Promise<OperatorPerformanceInfoResponse> {
    const response = await this.axiosInstance.get<OperatorPerformanceInfoResponse>(`/operator-service-center/home`)

    return response.data
  }
}
