import { DocumentLabelType } from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { ExecuteBiometryDto } from './dto/Biometry.dto'
import { BiometryStatusDto } from './dto/BiometryStatus.dto'
import {
  DocumentImagesDto,
  DocumentInsertResponseDto,
  IPostBiometryBody,
  IPostDocumentBody,
} from './dto/DocumentImages.dto'
import { ImageDto } from './dto/Image.dto'

export default class DatalakeAcessoService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async executeBiometry(borrowerId: string, body: IPostBiometryBody): Promise<ExecuteBiometryDto> {
    const response = await this.axiosInstance.post(`/datalake-acesso/biometry/borrowers/${borrowerId}`, body)

    return response.data
  }

  async insertDocumentSide(
    borrowerId: string,
    type: DocumentLabelType,
    body: IPostDocumentBody
  ): Promise<DocumentInsertResponseDto> {
    const response = await this.axiosInstance.post<DocumentInsertResponseDto>(
      `/datalake-acesso/biometry/borrowers/${borrowerId}/document/${type}`,
      body
    )

    return response.data
  }

  async getBiometryStatus(borrowerId: string): Promise<BiometryStatusDto> {
    const response = await this.axiosInstance.patch(`/datalake-acesso/biometry/borrowers/${borrowerId}/status`)

    return response.data
  }

  async getFaceImage(borrowerId: string): Promise<ImageDto> {
    const response = await this.axiosInstance.get(`/datalake-acesso/biometry/borrowers/${borrowerId}/face`)

    return response.data
  }

  async getDocumentImages(borrowerId: string): Promise<DocumentImagesDto> {
    const response = await this.axiosInstance.get<DocumentImagesDto>(
      `/datalake-acesso/biometry/borrowers/${borrowerId}/document`
    )

    return response.data
  }
}
