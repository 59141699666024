import React from 'react'
import styled from 'styled-components'
import { black, gray } from '../../../ui/utils/_colors'

interface Props {
  label: string
  value?: number | string | React.ReactElement<any, any>
  labelProps?: TextProps
  valueProps?: ValueProps
}

export const ListItem: React.FC<Props> = props => {
  const { label, value, labelProps, valueProps} = props

  return (
    <ListContainer>
      <Text 
        color={labelProps?.color} 
        weight = {labelProps?.weight}
      >
          {label}
      </Text>
      <Value
        color={valueProps?.color} 
        weight = {valueProps?.weight}
      >
          {value}
      </Value>
    </ListContainer>
  )
}

export default ListItem

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right as right;
  padding: 0.2em 1em 0.2em 1em;
  font-size: 20px;
`
interface TextProps {
  color?: string,
  weight?: string,
}

const Text = styled.text<TextProps>`
  text-align: start;
  font-weight: ${(props: TextProps) => props.weight || 'normal'};
  font-size: 16px;
  line-height: 20px;
  color: ${(props: TextProps) => props.color ?? gray.text};
`

interface ValueProps {
  color?: string,
  weight?: string,
}

const Value = styled.text<ValueProps>`
  font-size: 16px;
  font-weight: ${(props: ValueProps) => props.weight || 'bold'};
  color: ${(props: ValueProps) => props.color ?? black.text2};
`
