import React, { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ArrowRightNextButton } from '../../atoms/Buttons/ArrowRightNextButton'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { validate_cpf } from '../../common/Validators'
import NumberField from '../../molecules/Application/NumberField'
import OperatorHeader from '../../molecules/Operators/operator/OperatorHeader'
import { OperatorPerformance } from '../../molecules/Operators/performance/OperatorPerformance'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { IStoreResponse } from '../../services/bff/coordinator/dto/Stores.dto'
import { UserSessionService } from '../../services/userSession/user-session.service'
import { black } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'

interface OperatorsHomeProps {
  onConfirmDocument: (document: string, storeId?: string, amount?: number) => void
}

const OperatorsHome: React.FC<OperatorsHomeProps> = ({ onConfirmDocument }) => {
  const { isFetchingOperator, operator, isFetchingBorrower } = useTypedSelector(state => ({
    isFetchingOperator: state.operator.isFetchingOperator,
    operator: state.operator.operator,
    isFetchingBorrower: state.operatorServiceCenter.isFetchingBorrower,
  }))

  const dispatch = useDispatch()

  const [formDocument, setFormDocument] = useState<string>('')
  const [localStorageStore, setLocalStorageStore] = useState<IStoreResponse>()

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
    UserSessionService.fetchOperator(dispatch)
  }, [])

  const handleDocumentChange = (values: NumberFormatValues) => {
    setFormDocument(values.value)
  }

  const isDocumentValidated = formDocument.length === DocumentUtils.CPF_SIZE_WITHOUT_DIGITS && validate_cpf(formDocument)

  return (
    <>
      <MainContainer>
        <OperatorHeader operatorName={operator?.name} operatorPhoneNumber={operator?.phoneNumber} storeName={localStorageStore?.name} />
        <DocumentContainer>
          {!localStorageStore && <WarningHeader>Loja não configurada</WarningHeader>}
          {isFetchingBorrower && <LoadingSpinner text="Consultando cliente..." />}
          {isFetchingOperator && <LoadingSpinner />}

          <Title>Consultar cliente</Title>
          <InputContainer>
            <NumberField
              autoFocus
              value={formDocument}
              type="tel"
              format="###.###.###-##"
              mask="_"
              placeholder="Insira o CPF do cliente"
              label="CPF do cliente"
              onValueChange={handleDocumentChange}
              isValidated={isDocumentValidated}
              error={formDocument.length > 0 && !isDocumentValidated}
              style={{ width: '100%' }}
            />
            <ArrowRightNextButton
              onClick={() => onConfirmDocument(formDocument, localStorageStore?.id)}
              disabled={formDocument.length < DocumentUtils.CPF_SIZE_WITHOUT_DIGITS && !isDocumentValidated}
            />
          </InputContainer>
          {formDocument.length > 0 && !isDocumentValidated ? <ErrorText>Insira um CPF válido.</ErrorText> : undefined}
        </DocumentContainer>
        <OperatorPerformance operator={operator} />
      </MainContainer>
    </>
  )
}

export default OperatorsHome

const WarningHeader = styled.div`
  width: 100%;
  background-color: #fa6400;
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
  text-align: center;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 18px;
  ffont-style: normal;
  font-weight: 700;
  color: ${black.text2};
  text-align: left;
`

const ErrorText = styled.text`
  margin-top: 8px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #c92c46;
`

export const MainContainer = styled.div`
  height: calc(100% - 56px);
  width: 100%;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 16px;
`

const InputContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`
