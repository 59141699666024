import { Box } from 'grommet'
import styled from 'styled-components'
import { tablet } from '../../../../common/assets/utils/_breakpoints'
import { Heading, Text } from '../../../../legacy-lib/design-kit-ui'

export const SectionTitleStyle = styled(Heading)`
  margin-bottom: 4px;
  margin-top: 32px;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 47px;

  @media (min-width: ${tablet}) {
    margin-bottom: 12px;
    font-size: 32px;
    margin-top: 4px;
  }
`

export const SectionTextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '16px')};
  font-weight: ${(props: any) => (props.bold ? '500' : '300')};
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 28px;
`

export const TextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '16px')};
  font-weight: ${(props: any) => (props.bold ? '500' : '300')};
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 12px;
`

export const CardsBoxStyle = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const OverviewBoxStyle = styled(Box)`
  padding-bottom: 20px;
  padding-top: 10px;

  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    padding-bottom: 20px;
    padding-top: 0;

    > div {
      &.desktop {
        display: flex;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`
