import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Role } from '../../../common/constants'
import { DateUtils } from '../../../common/date-utils'
import { formatDate } from '../../../common/FormatDate'
import { numberToCurrency } from '../../../common/NumberToCurrency'
import operatorSliceReducer from '../../../redux/reducers/operator/operator.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { IOperator } from '../../../services/bff/coordinator/dto/Operators.dto'
import { gray, white } from '../../../ui/utils/_colors'
import OperatorPerformanceCard from './OperatorPerformanceCard'

enum FilterOptions {
  TODAY = 'Hoje',
  WEEK = 'Semana',
  MONTH = 'Mês',
}

interface OperatorPerformanceProps {
  operator?: IOperator
}

export const operatorRolesWithDashboardAccess = [Role.PROMOTER, Role.ATTENDANT, Role.REPRESENTATIVE, Role.MANAGER, Role.ANALYST]

export const OperatorPerformance: React.FC<OperatorPerformanceProps> = (props: OperatorPerformanceProps) => {
  const { operatorPerformance, isFetchingHomePageInfo } = useTypedSelector(state => ({
    operatorPerformance: state.operator.operatorPerformance,
    isFetchingHomePageInfo: state.operatorServiceCenter.isFetchingHomePageInfo,
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(FilterOptions.TODAY)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(operatorSliceReducer.actions.fetchOperatorPerformanceInfo())
  }, [])

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter)
  }

  const getFilteredData = () => {
    switch (selectedFilter) {
      case FilterOptions.TODAY:
        return {
          conversions: operatorPerformance?.today?.conversions ?? 0,
          applications: operatorPerformance?.today?.applications ?? 0,
          incentive: operatorPerformance?.today?.incentive ?? 0,
          dateRange: operatorPerformance?.today?.startDate ? formatDate(operatorPerformance?.today?.startDate) : null,
        }
      case FilterOptions.WEEK:
        return {
          conversions: operatorPerformance?.week?.conversions ?? 0,
          applications: operatorPerformance?.week?.applications ?? 0,
          incentive: operatorPerformance?.week?.incentive ?? 0,
          dateRange:
            operatorPerformance?.week?.startDate && operatorPerformance?.week?.endDate
              ? `${formatDate(operatorPerformance?.week?.startDate)} - ${formatDate(operatorPerformance?.week?.endDate)}`
              : null,
        }
      case FilterOptions.MONTH:
        return {
          conversions: operatorPerformance?.month?.conversions ?? 0,
          applications: operatorPerformance?.month?.applications ?? 0,
          incentive: operatorPerformance?.month?.incentive ?? 0,
          dateRange: operatorPerformance?.month?.startDate ? DateUtils.getFormattedMonthAndYear(operatorPerformance?.month?.startDate) : null,
        }
      default:
        return {
          conversions: 0,
          applications: 0,
          incentive: 0,
        }
    }
  }

  const filteredData = getFilteredData()

  if (!operatorRolesWithDashboardAccess.includes(props?.operator?.role as Role)) {
    return <></>
  }
  return (
    <Container>
      <Title>Seu desempenho</Title>
      <FilterWrapper>
        {Object.values(FilterOptions).map(filter => (
          <FilterButton key={filter} active={selectedFilter === filter} onClick={() => handleFilterClick(filter)}>
            {filter}
          </FilterButton>
        ))}
        <FilterDateRange>{filteredData.dateRange}</FilterDateRange>
      </FilterWrapper>

      <CardsWrapper>
        <OperatorPerformanceCard
          isLoading={isFetchingHomePageInfo}
          text={String(filteredData.applications)}
          title="Clientes cadastrados"
          toolTipText="Clientes aprovados e cadastrados por você."
          placement="top"
        />
        <OperatorPerformanceCard
          isLoading={isFetchingHomePageInfo}
          text={String(filteredData.conversions)}
          title="Clientes convertidos"
          toolTipText="Clientes que fizeram uma compra após o seu cadastro ou consulta."
          placement="topRight"
        />
        {operatorPerformance?.showIncentive && (
          <OperatorPerformanceCard
            isLoading={isFetchingHomePageInfo}
            text={`R$ ${numberToCurrency(filteredData.incentive)}`}
            title="Seus ganhos com a Ume"
            toolTipText="Valor em reais pago pelo seu desempenho."
            placement="topRight"
          />
        )}
      </CardsWrapper>
    </Container>
  )
}
const Container = styled.div`
  background: ${gray.light2};
  padding: 24px 16px;
  height: 100%;
`

const FilterWrapper = styled.div`
  margin: 16px 0px;
`
interface FilterButtonProps {
  active: boolean
}

const FilterButton = styled.button<FilterButtonProps>`
  padding: 6px 12px 6px 12px;
  border-radius: 32px;
  margin: 0px 8px 0px 0px;

  border: 1px solid ${({ active }: FilterButtonProps) => (active ? '#3C4852' : '#E4ECF1')};
  background-color: ${({ active }: FilterButtonProps) => (active ? '#3C4852' : `${white.primary}`)};
  color: ${({ active }: FilterButtonProps) => (active ? 'white' : 'black')};

  font-family: Inter;
  font-size: 14px;
  font-weight: ${({ active }: FilterButtonProps) => (active ? '600' : '400')};
  line-height: 20px;
  text-align: left;

  &:hover {
    background-color: ${({ active }: FilterButtonProps) => (active ? '#3C4852' : gray.scale200)};
  }
`

const FilterDateRange = styled.div`
  margin-top: 8px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${gray.scale700};
`

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 16px;
`

const Title = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
`
