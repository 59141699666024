import { Box } from 'grommet'
import * as React from 'react'
import { Loading } from '../../../../../legacy-lib/design-kit-ui'
import {
  CardTitleHeadingStyle,
  NumberBoxMobileStyle,
  NumberHeadingStyle,
  SmallCardStyle,
  SmallTextStyle,
  TextStyle,
} from './style'

export interface IFixedMonthRecordCardProps {
  fixedMonthRecord?: number | Error
}

export interface IFixedMonthRecordCardState {}

export default class FixedMonthRecordCard extends React.PureComponent<
  IFixedMonthRecordCardProps,
  IFixedMonthRecordCardState
> {
  public render() {
    const { fixedMonthRecord } = this.props

    const monthRecordBox =
      fixedMonthRecord instanceof Error ? (
        <Box fill align="center" justify="center">
          <TextStyle color="red">{'Não foi possível carregar os dados. Tente novamente.'}</TextStyle>
        </Box>
      ) : (
        fixedMonthRecord && (
          <>
            <Box className="desktop" fill justify="between">
              <Box>
                <CardTitleHeadingStyle>Recorde:</CardTitleHeadingStyle>
                <SmallTextStyle>Máximo de aplicações feitas em 1 mês.</SmallTextStyle>
              </Box>
              <Box>
                <NumberHeadingStyle>
                  {fixedMonthRecord > 9 ? fixedMonthRecord : '0' + fixedMonthRecord}
                </NumberHeadingStyle>
                <TextStyle>aplicações</TextStyle>
              </Box>
            </Box>
            <Box className="is-mobile" gap="medium" fill direction="row" justify="between">
              <Box>
                <CardTitleHeadingStyle>Recorde:</CardTitleHeadingStyle>
                <Box margin={{ top: '10px' }} width="145px">
                  <TextStyle>Máximo de aplicações feitas em 1 mês.</TextStyle>
                </Box>
              </Box>
              <NumberBoxMobileStyle align="center">
                <Box margin={{ right: '10px' }}>
                  <NumberHeadingStyle>
                    {fixedMonthRecord > 9 ? fixedMonthRecord : '0' + fixedMonthRecord}
                  </NumberHeadingStyle>
                </Box>
                <SmallTextStyle>aplicações</SmallTextStyle>
              </NumberBoxMobileStyle>
            </Box>
          </>
        )
      )

    return (
      <SmallCardStyle boxShadow={false} backgroundColor="transparent">
        {fixedMonthRecord ? (
          monthRecordBox
        ) : (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </SmallCardStyle>
    )
  }
}
