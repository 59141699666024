export const formatCellphone = (cellphone: string) =>
  '(' + cellphone.slice(3, 5) + ') ' + cellphone.slice(5, 10) + '-' + cellphone.slice(10, 14)

export const formatCellphoneWithoutNinthDigit = (cellphone: string) => {
  if (cellphone.length === 13)
    return '(' + cellphone.slice(3, 5) + ') ' + cellphone.slice(5, 9) + '-' + cellphone.slice(9, 13)

  return cellphone
}

// DDG -> Discagem Direta Gratuita (
export const formatDDGPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length === 11)
    return phoneNumber.slice(0, 4) + ' ' + phoneNumber.slice(4, 7) + ' ' + phoneNumber.slice(7, 11)

  return phoneNumber
}
