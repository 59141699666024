import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { history } from '../App'
import { bffParceirosApiService } from '../services/bff'

export const storeConfirmation = (store: IStoreResponse) => {
  // eslint-disable-next-line no-restricted-globals
  const isStoreCorrect = confirm(`Confirme se este aparelho está localizado na loja ${store.name}`)
  if (isStoreCorrect) {
    bffParceirosApiService.coordinator.setStore(store)
  } else {
    alert('Configure a loja para continuar a operação')
    history.push('/configuracao')
  }
}
