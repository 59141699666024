import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateBorrowerNextInvoiceError, Invoice } from '../../../services/bff/coordinator/dto/Invoices.dto'

interface InvoicesState {
  isCreatingBorrowerNextInvoice: boolean
  invoice?: Invoice
}

const initialState: InvoicesState = {
  isCreatingBorrowerNextInvoice: false,
  invoice: undefined,
}

const InvoicesSliceReducer = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    createBorrowerNextInvoice: (state, action: PayloadAction<string>) => {
      return { ...state, invoice: undefined }
    },
    createBorrowerNextInvoiceLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreatingBorrowerNextInvoice: true }
    },
    createBorrowerNextInvoiceSuccess: (state, action: PayloadAction<Invoice>) => {
      return {
        ...state,
        invoice: action.payload,
        isCreatingBorrowerNextInvoice: false,
      }
    },
    createBorrowerNextInvoiceError: (state, action: PayloadAction<CreateBorrowerNextInvoiceError>) => {
      return { ...state, isCreatingBorrowerNextInvoice: false }
    },
  },
})

export default InvoicesSliceReducer
