import { Divider } from '@blueprintjs/core'
import { Box } from 'grommet'
import * as React from 'react'
import Lightbox from 'react-lightbox-component'
import styled from 'styled-components'
import { toaster } from '../../App'
import { ReactComponent as BoxUserPinIcon } from '../../common/assets/images/bx-user-pin.svg'
import SendNotifyLimit from '../../molecules/Cashier/SendNotifyLimit'
import { BorrowerInformationGrid } from '../purchases-old/BorrowerInformationsGrid'

export interface IBorrowerProfileUI {
  borrowerId?: string
  operatorId?: string
  name: string
  portraitBase64?: string
  documentBase64?: string
  approvedLimit: number
  availableLimit: number
  phoneNumber: string
  enableHighRecurrence: boolean
}

export default class BorrowerProfile extends React.Component<IBorrowerProfileUI> {
  public render() {
    const {
      documentBase64,
      portraitBase64,
      name,
      phoneNumber,
      borrowerId,
      operatorId,
      enableHighRecurrence,
    } = this.props

    const images: any[] = []

    if (portraitBase64) {
      images.push({
        src: !portraitBase64.startsWith('data:') ? `data:image/jpeg;base64,${portraitBase64}` : portraitBase64,
        title: 'Foto do Comprador',
        description: 'Foto do Documento',
      })
    }

    if (documentBase64) {
      images.push({
        src: !documentBase64.startsWith('data:') ? `data:image/jpeg;base64,${documentBase64}` : documentBase64,
        title: 'Foto do Documento',
        description: 'Foto do Documento',
      })
    }

    return (
      <div style={{ marginBottom: 40 }}>
        <Divider />
        <TitleBorrower>
          <BoxUserPinIcon /> Identificação do cliente
        </TitleBorrower>
        <BorrowerInformationGrid
          rows={['1/3', '1/3', '1/3']}
          columns={['1/3', '1/3', '1/3']}
          align="start"
          gap="small"
          areas={[
            { name: 'nome', start: [0, 0], end: [0, 0] },
            { name: 'image', start: [1, 0], end: [1, 0] },
            { name: 'limites', start: [2, 0], end: [2, 0] },
          ]}
        >
          <Box alignContent="center" align="start" gridArea="nome">
            <label style={{ marginBottom: 10 }}>Nome Completo:</label>
            <p>{name}</p>
            <label style={{ marginBottom: 10 }}>Telefone:</label>
            <p>{phoneNumber}</p>
            <label style={{ marginBottom: 10 }}>Cadastro:</label>
            <p>{enableHighRecurrence ? 'Ume leve' : 'Ume convencional'}</p>
          </Box>

          {images.length > 0 && (
            <Box alignContent="center" align="start" gridArea="image">
              <Lightbox images={images} thumbnailWidth="auto" thumbnailHeight="12em" />
              <LightboxText>Clique na imagem para ampliar</LightboxText>
            </Box>
          )}

          {borrowerId && operatorId && (
            <SendNotifyLimit
              onError={() => toaster.showErrorToast('Não foi possível enviar o SMS, tente novamente mais tarde!')}
              onSuccess={() => toaster.showSuccessToast('SMS enviado com sucesso!')}
              payload={{
                borrowerId,
                operatorId,
              }}
            />
          )}
        </BorrowerInformationGrid>
      </div>
    )
  }
}

const TitleBorrower = styled.p`
  width: 300px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin-top: 52px;
  margin-bottom: -70px;

  svg {
    margin-right: 18px;
  }
`

const LightboxText = styled.label`
  font-family: 'Roboto';
  color: #0b2eff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  width: 100%;
`
