import React from 'react'
import {
  BoldText,
  Container,
  MainContainer,
  Message,
  NavigateButton,
  Title,
} from './TransactionalCreditApplicationDeniedResult.styles'

interface Props {
  handleGoBackToHomePage?: () => void
}

export const TransactionalCreditApplicationDeniedResult: React.FC<Props> = (props: Props) => {
  const { handleGoBackToHomePage } = props

  return (
    <div>
      <MainContainer>
        <Container>
          <Title>Não aprovado... 😕</Title>
          <Message>
            <BoldText>Infelizmente não conseguimos </BoldText>liberar nenhum limite para o cliente neste momento.
          </Message>
        </Container>

        <NavigateButton onClick={handleGoBackToHomePage} backgroundColor="#ffffff" textColor="#12aa6a">
          Voltar para a tela inicial
        </NavigateButton>
      </MainContainer>
    </div>
  )
}
