import { Box } from 'grommet'
import * as React from 'react'
import { Calendar, SideBar, Text } from '../../../../legacy-lib/design-kit-ui'
import { blue } from '../../../../legacy-lib/design-kit-ui/Palette'
import { CalendarToday, Close } from '../../../../ui/Icons'
import { IconCardStyle } from './style'

export interface ICalendarSidebarMobileProps {
  dateRangeSelected: (startDate: string, endDate: string) => void
  intervalSelected: (interval: 'day' | 'month' | 'week' | 'range') => void
}

export interface ICalendarSidebarMobileState {
  sidebarOpen: boolean
}

export default class CalendarSidebarMobile extends React.Component<
  ICalendarSidebarMobileProps,
  ICalendarSidebarMobileState
> {
  constructor(props: ICalendarSidebarMobileProps) {
    super(props)

    this.state = {
      sidebarOpen: false,
    }
  }

  onIconClick = () => {
    this.setState({ sidebarOpen: true })
  }

  onSidebarClose = () => {
    this.setState({ sidebarOpen: false })
  }

  public render() {
    const { intervalSelected, dateRangeSelected } = this.props
    const { sidebarOpen } = this.state

    const closeBox = (
      <Box key="close" align="center">
        <Close color="white" onClick={this.onSidebarClose} />
        <Text size="small" color="white">
          Fechar
        </Text>
      </Box>
    )

    return (
      <>
        <IconCardStyle onClick={this.onIconClick}>
          <CalendarToday width="32px" height="32px" color={blue.vivid} />
        </IconCardStyle>
        {sidebarOpen && (
          <SideBar
            showNavBar
            position="center"
            animation="fadeIn"
            headIcons={[<CalendarToday key="icon" width="40px" height="40px" color={blue.vivid} />, closeBox]}
          >
            <Box fill align="center" justify="center">
              <Calendar
                singleMonthOnly
                onIntervalSelected={intervalSelected}
                onDateIntervalChange={dateRangeSelected}
              />
            </Box>
          </SideBar>
        )}
      </>
    )
  }
}
