import { Box } from 'grommet'
import styled from 'styled-components'
import { laptop, tablet } from '../../../../common/assets/utils/_breakpoints'
import { Tab } from '../../../../legacy-lib/design-kit-ui'
import { gray, orange } from '../../../../legacy-lib/design-kit-ui/Palette'

export const ManagerViewBoxStyle = styled(Box)`
  > div {
    &.desktop {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    > div {
      &.desktop {
        display: grid;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`
export const MainBoxStyle = styled(Box)`
  background-color: ${gray.azure};
  box-shadow: 2px 0px 8px 2px rgba(152, 152, 152, 0.5);
  padding: 30px 60px 150px 100px;

  @media (max-width: ${laptop}) {
    padding: 24px 30px 150px 36px;
  }
`

export const SidebarBoxStyle = styled(Box)`
  background-color: white;
  align-items: center;
  box-shadow: 2px 2px 8px 0px rgba(152, 152, 152, 0.5);
  padding: 40px 30px;

  @media (max-width: ${laptop}) {
    padding: 40px 30px;
  }
`

export const MainMobileBoxStyle = styled(Box)`
  background-color: ${gray.azure};
  padding: 0px 44px 80px 44px;
`

export const TabStyle = styled(Tab)`
  opacity: ${(props: any) => (props.active ? 1 : 0.3)};

  &.stores {
    width: 40%;
  }

  &.operators {
    width: 60%;
  }

  @media (min-width: ${tablet}) {
    &.stores {
      width: 165px;
    }

    &.operators {
      width: 185px;
    }
  }

  & button,
  div,
  span {
    text-align: start;
    font-size: 22px;
    letter-spacing: -0.69px;
    line-height: 25px;
    margin-left: 2px;
    margin-bottom: 1px;
    color: ${orange.amber};
  }

  &:nth-child(n) {
    > div {
      border-bottom: solid 2px ${orange.amber};
    }
  }
`

export const SidebarBoxFixedStyle = styled(Box)``
