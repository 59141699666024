import React, { Component } from 'react'
import ReactLoading, { LoadingProps } from 'react-loading'
import { gray } from '../utils/_colors'

export default class Loading extends Component<LoadingProps> {
  render() {
    return (
      <ReactLoading
        type="spokes"
        color={this.props.color ? this.props.color : gray.aluminium}
        height={this.props.height ? this.props.height : 25}
        width={this.props.width ? this.props.width : 25}
        {...this.props}
      >
        {this.props.children}
      </ReactLoading>
    )
  }
}
