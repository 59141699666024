import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import { IStoreListResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/StoreList.res.interface'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import { Button, Tooltip } from 'antd'
import { Box } from 'grommet'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'
import * as XLSX from 'xlsx'
import { toaster } from '../../App'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { DataTable } from '../../legacy-lib/design-kit-ui'
import * as UMEColors from '../../legacy-lib/design-kit-ui/utils/_colors'
import { DownloadSpecificContractsButton } from '../../molecules/FinancialOriginations/ContractsButton'
import GrommetDatePicker from '../../molecules/FinancialOriginations/GrommetDatePicker'
import { OriginationsFilter } from '../../molecules/FinancialOriginations/OriginationsFilter'
import { BigNumberCard } from '../../molecules/FinancialPayments/BigNumberCard'
import { bffParceirosApiService } from '../../services/bff'
import { Loading } from '../../ui'
import { FINAL_TRANSFERS_TABLE_HEADER, IRetailersBatchTableData } from './config'

interface IFinancialOriginationsPageProps {
  history: any
}

interface IFinancialOriginationsPageState {
  startDate: string
  endDate: string

  filteredStartDate: string
  filteredEndDate: string
  selectedStoreName?: string

  isLoadingData: boolean

  storesName: string[]

  transfersData: IRetailersBatchTableData[]
  totalTransferred: number
  totalUmeFee: number
}

export default class FinancialOriginationsPage extends React.Component<
  IFinancialOriginationsPageProps,
  IFinancialOriginationsPageState
> {
  private user?: ILoginProfileResponse
  private retailerId?: string
  private selectedStoreId?: string

  private stores?: IStoreResponse[]

  constructor(props: IFinancialOriginationsPageProps) {
    super(props)

    // The filter starts with the default:
    //  -- start date: start of month
    //  -- end date: current day of month
    const startOfMonth = moment()
      .startOf('month')
      .format('YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD')

    this.state = {
      startDate: startOfMonth,
      endDate: today,

      filteredStartDate: startOfMonth,
      filteredEndDate: today,

      isLoadingData: true,

      storesName: [],

      transfersData: [],
      totalTransferred: 0,
      totalUmeFee: 0,
    }

    this.user = bffParceirosApiService.auth.getUser()

    if (this.user) {
      this.retailerId = this.user.retailerId
    }

    this.loadStoresFromAPI()
    this.loadRetailersTransfersFromAPI({ startDate: startOfMonth, endDate: today })
  }

  // Loads Stores from API in order to replace the IDs coming from Billings API
  loadStoresFromAPI = () => {
    bffParceirosApiService.retailers
      .fetchStores()
      .then((result: IStoreListResponse) => {
        // TODO - Create an endpoint for this
        // 1. Filter only the retailer's store
        this.stores = result.stores
          .filter((store: IStoreResponse) => store.retailerId === this.retailerId)
          .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))

        // 2. Get only the store's name
        let storesName = this.stores.map((store: IStoreResponse) => store.name)

        this.setState({ storesName })
      })
      .catch((error: any) => {
        // TODO - Toaster with error
        toaster.showErrorToast(`Erro ao carregar lojas`)
      })
  }

  // Loads retailers transfer from the originations API
  loadRetailersTransfersFromAPI = (query: any) => {
    this.setState({ isLoadingData: true, filteredStartDate: query.startDate, filteredEndDate: query.endDate })

    query = {
      originationsStartDate: query.startDate,
      originationsEndDate: query.endDate,
      storeId: this.selectedStoreId,
    }

    bffParceirosApiService.backofficeOriginations
      .getRetailersTransfers(String(this.retailerId), query)
      .then(result => {
        let totalTransferred = 0
        let totalUmeFee = 0

        let transfersData: IRetailersBatchTableData[] = []

        // If the user filtered by store, than show only the store transfers
        if (this.selectedStoreId) {
          transfersData = result.data.map(transfer => {
            let selectedBatch: any

            selectedBatch = transfer.storesBatch.find(storeBatch => storeBatch.storeId === this.selectedStoreId)

            if (!selectedBatch) {
              selectedBatch = transfer.retailerBatch
            }

            totalTransferred += selectedBatch.principalSum - selectedBatch.umeFeeSum
            totalUmeFee += selectedBatch.umeFeeSum

            // Show only the store transfers
            return {
              batchId: transfer.batchId,
              status: transfer.status,
              transferTimestamp: transfer.transferTimestamp,
              originationsStartDate: transfer.originationsStartDate,
              originationsEndDate: transfer.originationsEndDate,

              storeId: selectedBatch.storeId,
              retailerId: transfer.retailerBatch.retailerId,
              originationsCount: selectedBatch.originationsCount,
              transferredValue: selectedBatch.principalSum - selectedBatch.umeFeeSum,
              umeFeeSum: selectedBatch.umeFeeSum,
              principalSum: selectedBatch.principalSum,
              discountSum: selectedBatch.discountSum,
              totalSum: totalTransferred + selectedBatch.discountSum,
            }
          })
        } else {
          // Show only the whole retailer transfers
          transfersData = result.data.map(transfer => {
            totalTransferred += transfer.retailerBatch.principalSum - transfer.retailerBatch.umeFeeSum
            totalUmeFee += transfer.retailerBatch.umeFeeSum

            return {
              batchId: transfer.batchId,
              status: transfer.status,
              transferTimestamp: transfer.transferTimestamp,
              originationsStartDate: transfer.originationsStartDate,
              originationsEndDate: transfer.originationsEndDate,

              retailerId: transfer.retailerBatch.retailerId,
              originationsCount: transfer.retailerBatch.originationsCount,
              transferredValue: transfer.retailerBatch.principalSum - transfer.retailerBatch.umeFeeSum,
              umeFeeSum: transfer.retailerBatch.umeFeeSum,
              principalSum: transfer.retailerBatch.principalSum,
              discountSum: transfer.retailerBatch.discountSum,
              totalSum: totalTransferred + transfer.retailerBatch.discountSum,
            }
          })
        }

        this.setState({ isLoadingData: false, transfersData: transfersData, totalTransferred, totalUmeFee })

        if (result.data && Array.isArray(result.data) && result.data.length === 0) {
          return toaster.showWarningToast('Nenhum resultado encontrado')
        } else {
          return toaster.showSuccessToast('Busca feita com sucesso')
        }
      })
      .catch(error => {
        this.setState({ isLoadingData: false })
        toaster.showErrorToast('Erro ao baixar contratos')
      })
  }

  // Download the table transfers data
  downloadTableData = () => {
    const startDate = moment(this.state.filteredStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const endDate = moment(this.state.filteredEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    const transfersCsv = this.state.transfersData.map(transfer => ({
      status: transfer.status === 'PENDING' ? 'PENDENTE' : 'TRANSFERIDO',
      data_da_transfência: moment(new Date(transfer.transferTimestamp)).format('DD/MM/YYYY HH:mm'),
      data_inicio_contratos: moment(transfer.originationsStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      data_fim_contratos: moment(transfer.originationsEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      valor_transferido: transfer.transferredValue,
      valor_transferido_descontos: transfer.discountSum,
      valor_total: transfer.transferredValue + transfer.discountSum,
      principal: transfer.principalSum,
      taxa_ume: transfer.umeFeeSum,
    }))

    const fileName = `transferências_${startDate}_até_${endDate}.xlsx`

    const ws = XLSX.utils.json_to_sheet(transfersCsv)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, `Transferências`)
    XLSX.writeFile(wb, fileName)
  }

  onStartDateSelected = (date: string) => {
    const startDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    this.setState({ startDate })

    this.loadRetailersTransfersFromAPI({ startDate, endDate: this.state.endDate })
  }

  onEndDateSelected = (date: string) => {
    const endDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    this.setState({ endDate })

    this.loadRetailersTransfersFromAPI({ startDate: this.state.startDate, endDate })
  }

  onStoreSelected = (storeIndex: number) => {
    if (!storeIndex) {
      this.selectedStoreId = undefined
      this.setState({ selectedStoreName: undefined })
      this.loadRetailersTransfersFromAPI({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })

      return
    }

    if (!this.stores || !Array.isArray(this.stores) || storeIndex >= this.stores.length) {
      return
    }

    this.selectedStoreId = this.stores[storeIndex].id
    this.loadRetailersTransfersFromAPI({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    })
    this.setState({ selectedStoreName: this.stores[storeIndex].name })
  }

  // Internal usage only - It could be generalized but the function name wouldn't be as clear as it is now
  findStoreName = (storeId: string) => {
    if (!this.stores) return storeId
    else {
      let store = this.stores.find(store => store.id === storeId)
      if (!store) return storeId
      else return store.name
    }
  }

  onDownloadContractsByRetailerClicked = (transfer: IRetailersBatchTableData) => {
    return bffParceirosApiService.backofficeOriginations
      .getBatchOriginations({
        batchId: transfer.batchId,
        retailerId: transfer.retailerId,
        storeId: transfer.storeId,
      })
      .then(result => {
        const contractsForRetailer = result.map(contract => {
          return {
            id_contrato: contract.contractId,
            data_de_originação: moment(new Date(contract.timestamp)).format('DD/MM/YYYY HH:mm'),
            principal: contract.principal,
            taxa_ume: contract.mdr,
            valor_depositado: contract.principal - contract.mdr,
            loja: this.findStoreName(contract.storeId),
            operador: contract.operatorId,
          }
        })

        const originationsStartDate = moment(transfer.originationsStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        const originationsEndDate = moment(transfer.originationsEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

        let fileName = `contratos`
        if (originationsStartDate === originationsEndDate) {
          fileName += `_${originationsStartDate}.xlsx`
        } else {
          fileName += `_${originationsStartDate}__${originationsEndDate}.xlsx`
        }

        const ws = XLSX.utils.json_to_sheet(contractsForRetailer)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Contratos`)
        XLSX.writeFile(wb, fileName)
      })
      .catch(error => {
        toaster.showErrorToast(`Erro ao baixar os contratos`)
      })
  }

  render() {
    let {
      startDate,
      endDate,
      filteredStartDate,
      filteredEndDate,
      storesName,
      isLoadingData,
      transfersData,
      totalTransferred,
      totalUmeFee,
      selectedStoreName,
    } = this.state

    transfersData = transfersData.map(transfer => {
      return { ...transfer, totalSum: transfer.transferredValue + transfer.discountSum }
    })

    filteredStartDate = moment(filteredStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    filteredEndDate = moment(filteredEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    startDate = moment(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    endDate = moment(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    let TABLE_HEADER = Object.assign([], FINAL_TRANSFERS_TABLE_HEADER)
    TABLE_HEADER.push({
      textAlign: 'center',
      label: 'Contratos',
      attribute: 'originations',
      formatCell: (transfer: IRetailersBatchTableData) => {
        return (
          <DownloadSpecificContractsButton
            onDownloadContracts={async () => {
              return this.onDownloadContractsByRetailerClicked(transfer)
            }}
          />
        )
      },
    })

    return (
      <PageContainer>
        <Box direction="row" justify="start" align="center" gap="large" wrap>
          <FilterContainer>
            <Tooltip
              placement="top"
              title="Transferências referente a contratos criados a partir desta data"
              color={UMEColors.black.primary}
            >
              <p>
                <strong>Data de ínicio:</strong>
              </p>
            </Tooltip>

            <DatePicker
              value={startDate}
              classKey={0}
              onChange={this.onStartDateSelected}
              onDateSelect={this.onStartDateSelected}
              bounds={[
                '2019-12-01',
                moment(endDate, 'DD/MM/YYYY')
                  .add(1, 'day')
                  .format('YYYY-MM-DD'),
              ]}
            />
          </FilterContainer>

          <FilterContainer>
            <Tooltip
              placement="top"
              title="Transferências referente a contratos criados até esta data"
              color={UMEColors.black.primary}
            >
              <p>
                <strong>Data de fim:</strong>
              </p>
            </Tooltip>
            <DatePicker
              value={endDate}
              classKey={1}
              onChange={this.onEndDateSelected}
              onDateSelect={this.onEndDateSelected}
              bounds={[
                moment(startDate, 'DD/MM/YYYY')
                  .add(1, 'day')
                  .format('YYYY-MM-DD'),
                '2030-01-01',
              ]}
            />
          </FilterContainer>

          <FilterContainer>
            <p>
              <strong>Selecione uma loja: </strong>
            </p>
            <FilterSelect
              label={'Selecione uma loja: '}
              default="Todo o varejo"
              select={storesName}
              onDropDownSelect={this.onStoreSelected}
            />
          </FilterContainer>
        </Box>

        {isLoadingData ? (
          <Box align="center" justify="center" margin={{ top: '10vh' }}>
            <Loading />
          </Box>
        ) : (
          <>
            <CardsContainer>
              <Tooltip
                placement="right"
                title="Valor recebido da UME pelos contratos originados na loja e no intervalo de tempo selecionado"
                color={UMEColors.black.primary}
              >
                <OriginationsCard
                  title="Valor recebido da UME"
                  value={totalTransferred}
                  isMoney={true}
                  valueColor={UMEColors.green.primary}
                />
              </Tooltip>
              <Tooltip
                placement="right"
                title="Taxa cobrada pela UME pelos contratos originados na loja e no intervalo de tempo selecionado"
                color={UMEColors.black.primary}
              >
                <OriginationsCard
                  style={{ marginLeft: '3vw' }}
                  title="Taxa UME"
                  value={totalUmeFee}
                  isMoney={true}
                  valueColor={UMEColors.gray.spanish}
                />
              </Tooltip>
            </CardsContainer>

            <Box margin={{ top: '7vh' }} justify="between" align="center" direction="row" wrap>
              <Box direction="column">
                <TableHeader>
                  Histórico de transferências{' '}
                  {transfersData && transfersData.length === 0 ? '' : `(${transfersData.length})`}
                </TableHeader>
                <TableSubHeader>
                  Entre {filteredStartDate} até {filteredEndDate}{' '}
                  {selectedStoreName ? `para a loja ${selectedStoreName}.` : `para todo o varejo.`}
                </TableSubHeader>
              </Box>

              <DownloadTableButton type="primary" onClick={this.downloadTableData}>
                Baixar transferências
              </DownloadTableButton>
            </Box>

            <TableContainer>
              <DataTable fontSize={'12'} header={TABLE_HEADER} data={transfersData}></DataTable>
            </TableContainer>
          </>
        )}
      </PageContainer>
    )
  }
}

const OriginationsCard = styled(BigNumberCard)`
  @media (max-width: ${mobile}) {
    :nth-child(2) {
      margin-top: 3vh;
      margin-left: 0px !important;
    }
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5vh;
  flex-wrap: wrap;

  @media (max-width: ${mobile}) {
    justify-content: center;
  }
`

const PageContainer = styled.div`
  text-align: left;

  padding: 24px 70px 24px 70px;

  @media (max-width: ${mobile}) {
    padding: 14px 18px 14px 18px;
  }
`

const TableHeader = styled.p`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`
const TableSubHeader = styled.p`
  color: ${UMEColors.gray.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`

const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;
`

const DownloadTableButton = styled(Button)`
  display: inline-block;

  border: none !important;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 10px !important;
  background-color: ${UMEColors.gray.primary} !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.gray.primary};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.gray.primary};
  }

  &:active {
    background-color: ${UMEColors.gray.primary};
  }
`

const FilterContainer = styled.div`
  text-align: left;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.44px;

  @media (max-width: ${mobile}) {
    width: 100%;
    margin: 10px;
  }
`

const DatePicker = styled(GrommetDatePicker)`
  box-sizing: border-box;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in Design Kit
  color: #585858;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.47px;
`

const FilterSelect = styled(OriginationsFilter)`
  padding: 12px 15px 12px 15px;
  width: 200px;

  box-sizing: border-box;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in design kit
  color: #3b3b3b;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: -0.47px;

  ${(props: any) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    // Select Image
    background-color: #fff;
    background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .95em auto, 100%;
  }
`
