import { Box, TextInput as GrommetTextInput } from 'grommet'
import styled from 'styled-components'
import { blue, gray, red, white } from '../utils/_colors'

const StyledTextInput = styled(GrommetTextInput)`
  width: ${props => (props.width ? props.width : '280px')};
  height: ${props => (props.height ? props.height : '58px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  border: ${props =>
    props.border ? props.border : `solid 1px ${props.errorIntent ? `${red.persian}` : `${gray.gainsboro}`}`};
  box-sizing: border-box;
  // margin-top: 4px;

  // text-style
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  letter-spacing: 0;
  text-indent: 8px;
  line-height: 19px;
  color: ${gray.eclipse};

  &:nth-child(n) {
    box-shadow: none;
  }

  ::placeholder {
    color: ${gray.aluminium};
    font-size: 18px;
    line-height: 21px;
    text-indent: 10px;
    transition: all 0.3s;
  }
  :focus {
    border: ${props =>
      props.border ? props.border : `2px solid ${props.errorIntent ? `${red.persian}` : `${blue.navy}`}`};
  }
  :focus::placeholder {
    color: transparent;
    transition: all 0.3s;
  }
`

const StyledLabel = styled.label`
  background-color: ${white.primary};
  margin-left: 16px;
  padding: 0px 8px 0px 8px;
  position: absolute;
  transform: translateY(-6px);

  // text-style
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;

  &:after {
    content: ${props => (props.required ? `" *"` : ``)};
    color: red;
    display: inline;
  }
`

const StyledBoxInput = styled(Box)`
  font-family: Roboto;
  margin: 4px 8px;

  ${StyledTextInput}:placeholder-shown {
    & + ${StyledLabel} {
      display: none;
    }
  }

  ${StyledLabel} {
    color: ${props => (props.errorIntent ? `${red.persian}` : `${gray.aluminium}`)};
  }

  &:focus-within {
    > ${StyledTextInput} {
      border: ${props =>
        props.border ? props.border : `2px solid ${props.errorIntent ? `${red.persian}` : `${blue.navy}`}`};
    }
    > ${StyledLabel} {
      color: ${props => (props.errorIntent ? `${red.persian}` : `${blue.navy}`)};
      transition: all 0.3s;
      transform: translateY(-6px);
      visibility: visible;
    }
  }
`

export { StyledTextInput, StyledBoxInput, StyledLabel }
